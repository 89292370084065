import { getTheme, getFontFamily } from "components/theme/themeUtils";
import { isFunction } from "lodash";

export const getFormFieldIconColor = (props) => {
  const theme = getTheme(props.theme);
  const { innericons_and_selected } = theme.color.forms[!!props.error ? 'error' : 'default'];

  return `${innericons_and_selected}`
}

export const getFormFieldBackgroundColor = (props) => {
  const theme = getTheme(props.theme);
  const { background, primary_font } = theme.color.forms[!!props.error ? 'error' : 'default']

  return `
    background: ${background};
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px ${background} inset !important,
      -webkit-text-fill-color: ${primary_font} !important,
    }
  `
}

export const getFormFieldStyles = (props) => {
  const theme = getTheme(props.theme);
  const { primary_font, placeholder_font } = theme.color.forms[!!props.error ? 'error' : 'default']

  return `
    ${getFontFamily(theme)}
    ${getFormFieldBackgroundColor(props)}
    color: ${!!props.value ? primary_font : placeholder_font}
    width: 100%;
  `
}

export const getFormFieldContainerStyles = (props) => {
  const theme = getTheme(props.theme);
  const { border, focus } = theme.color.forms[!!props.error ? 'error' : 'default'];
  const clickable = !props.disabled && isFunction(props.onClick) ? 'cursor: pointer' : ''

  return `
    ${getFormFieldStyles(props)}
    ${clickable}
    outline: 0;
    border: 1px solid ${border}
    ${theme.radii.radius12}
    ${!props.disabled ? `
      &:focus-within {
        border: 1px solid ${focus.border}
      }
    ` : ''}
  `
}

export const getFormFieldSecondaryColor = (props) => {
  const theme = getTheme(props.theme);
  const { secondary_font, focus } = theme.color.forms[!!props.error ? 'error' : 'default'];

  return `
    color: ${secondary_font};
    &:focus-within {
      color: ${focus.secondary_font};
    }
  `
}
import React, { useState } from "react"
import { find } from "lodash"
import { Row, Col } from "reactstrap"

import { PrimaryButton, SecondaryButton } from "components/Button"
import DataPanel from "components/DataPanel"
import EmptyState from "components/EmptyState"
import DataItem from "components/DataItem"
import { PRODUCT_FORM } from "../product.const"
import Link from "components/Link"
import OnboardingImage from "assets/images/verification-img.png"

import "../style.scss"

const Summary = props => {
  const {
    selectedProductCategory,
    productInformation,
    selectedProductType,
    warehouses,
    handleSubmit,
    onClickCancel,
    goSpecificStep,
  } = props

  const [stepToEdit, setStepToEdit] = useState(null)
  const [showConfirmEditModal, setShowConfirmEditModal] = useState(false)

  const editStep = step => {
    setStepToEdit(step)
    goSpecificStep(step)
    //setShowConfirmEditModal(true)
  }

  const selectedWarehouse = find(warehouses, {
    id: productInformation.warehouseId,
  })

  return (
    <div className="page-padding mt-4">
      <Row>
        <Col md={6}>
          <div>
            <h1 className="fw-bold">Confirm product information</h1>
            <p className="mt-4 font-size-14">
              Check and confirm the information before creating the product.
            </p>

            {/* CATEGORY INFORMATION */}
            <DataPanel>
              <DataItem
                label={"Product category"}
                value={selectedProductCategory.name}
              />

              <Link
                icon="mdi mdi-pencil"
                onClick={() => editStep(PRODUCT_FORM.PRODUCT_CATEGORY)}
              >
                Edit
              </Link>
            </DataPanel>

            {/* PRODUCT TYPE INFORMATION */}
            <DataPanel className="mt-4">
              <DataItem
                label={"Product type"}
                value={selectedProductType.label}
              />

              <Link
                icon="mdi mdi-pencil"
                onClick={() => editStep(PRODUCT_FORM.PRODUCT_CATEGORY_TYPE)}
              >
                Edit
              </Link>
            </DataPanel>

            {/* PRODUCT INFORMARION */}
            <DataPanel className="mt-4">
              <DataItem
                label={"Product name"}
                value={productInformation.name}
              />

              <DataItem
                label={"Size"}
                value={`${productInformation.size} ml`}
              />

              <DataItem
                label={"Stock quantity"}
                value={productInformation.stockQuantity.toString()}
              />

              <DataItem
                label={"Resplenish level"}
                value={productInformation.replenishingLevel.toString()}
              />

              <DataItem
                label={"Price"}
                value={`Ksh. ${productInformation.primaryPrice.toString()}`}
              />

              {productInformation.primaryDiscount && (
                <DataItem
                  label={"Discount"}
                  value={`${productInformation.primaryDiscount}%`}
                />
              )}

              <DataItem
                label={"Warehouse"}
                value={selectedWarehouse?.warehouseName}
              />

              <DataItem
                label={"Description"}
                value={productInformation.description}
                truncate={true}
              />

              <Link
                icon="mdi mdi-pencil"
                onClick={() => editStep(PRODUCT_FORM.PRODUCT_INFORMATION)}
              >
                Edit
              </Link>
            </DataPanel>

            <Row>
              <Col>
                <div className="hstack gap-2 mb-0 mt-4">
                  <PrimaryButton onClick={handleSubmit} className="me-4">
                    Confirm
                  </PrimaryButton>

                  <SecondaryButton onClick={onClickCancel}>
                    Cancel
                  </SecondaryButton>
                </div>
              </Col>
            </Row>
          </div>
        </Col>

        <Col md={6}>
          <EmptyState
            visible={true}
            illustration={OnboardingImage}
            className="onboarding-user-ph-image"
            containerClassName="onboarding-user-ph"
          />
        </Col>
      </Row>
    </div>
  )
}

export default Summary

import CONST from "utils/constants"

const { MEDIA } = CONST

export const getMainInfoStyles = () => {
  return `
    display: flex;
    flex-direction: row;
    align-items: center;
  `
}

export const getAvatarStyles = () => {
  return `
    margin-right: 12px;
    ${MEDIA.TABLET} {
      width: 32px;
      height: 32px;
      .hevara-avatar-icon {
        width: 16px;
        height: 16px;
      }
    }
  `
}

export const getTitleStyles = () => {
  return `
    ${MEDIA.TABLET} {
      font-size: 14px;
      line-height: 20px;
    }
  `
}

export const getSubtitleStyles = () => {
  return `
    margin-top: 4px;
    ${MEDIA.TABLET} {
      font-size: 12px;
      line-height: 16px;
    }
  `
}

export const getInfoStyles = () => {
  return `
    display: flex;
    flex-direction: column;
  `
}

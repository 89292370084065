import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { BodyTextBold, SmallText } from "components/atoms"
import { getNavigationRowStyles } from "./NavigationRow.styles"

const StyledNavigationRow = styled.div.attrs({
  className: "hevara-navigation-row",
})`
  ${getNavigationRowStyles}
`

const StyledNavigationRowLeftContent = styled.div.attrs({
  className: "hevara-navigation-row-left-content",
})``

const StyledNavigationRowTextContent = styled.div.attrs({
  className: "hevara-navigation-row-text-content",
})``

const StyledNavigationRowTitle = styled(BodyTextBold).attrs({
  className: "hevara-navigation-row-title",
})``

const StyledNavigationRowDescription = styled(SmallText).attrs({
  className: "hevara-navigation-row-description",
})``

const StyledNavigationRowRightContent = styled.div.attrs({
  className: "hevara-navigation-row-right-content",
})``

const StyledNavigationRowArrow = styled.i.attrs({
  className: "hevara-navigation-row-arrow",
})``

export const NavigationRow = ({
  onClick,
  title,
  description,
  disabled,
  icon,
  children,
}) => {
  return (
    <StyledNavigationRow disabled={disabled} icon={icon} onClick={onClick}>
      <StyledNavigationRowLeftContent>
        {!!icon && (
          <i
            className={icon + " hevara-button-icon font-size-24 text-tertiary"}
          ></i>
        )}
      </StyledNavigationRowLeftContent>

      <StyledNavigationRowTextContent>
        <StyledNavigationRowTitle>{title}</StyledNavigationRowTitle>
        {!!description && (
          <StyledNavigationRowDescription>
            {description}
          </StyledNavigationRowDescription>
        )}
      </StyledNavigationRowTextContent>

      <StyledNavigationRowRightContent>
        {children}
      </StyledNavigationRowRightContent>
    </StyledNavigationRow>
  )
}

NavigationRow.defaultProps = {
  description: "",
  disabled: false,
  icon: undefined,
}

export default NavigationRow

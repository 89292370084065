import React from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"

import { NavigationRow as HevaraNavigationRow } from "./NavigationRow"
import withRouter from "components/Common/withRouter"

const NavigationRow = ({
  title,
  description,
  to,
  href,
  disabled,
  icon,
  className,
  style,
  children,
  ...props
}) => {
  const navigate = useNavigate()

  const onClick = () => {
    if (!!href) {
      window.open(href, "_blanck")
    } else {
      navigate(to)
    }
  }

  return (
    <HevaraNavigationRow
      title={title}
      description={description}
      onClick={onClick}
      disabled={disabled}
      icon={icon}
      className={className}
      style={style}
    >
      {children}
    </HevaraNavigationRow>
  )
}

NavigationRow.defaultProps = {
  description: "",
  disabled: false,
  icon: undefined,
  className: "",
  style: {},
}

export default withRouter(NavigationRow)

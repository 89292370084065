import React from "react"
import styled from "styled-components"

import Text from "components/atoms/Text/BasicText"
import { getLinkStyles } from "./Link.styles"

const StyledLink = styled.a.attrs({
  className: "hevara-link",
})`
  ${getLinkStyles}
`

const StyledText = styled(Text).attrs({
  className: "hevara-a-text",
})``

const StyledIcon = styled.i.attrs({
  className: "hevara-a-icon",
})``

export const Link = ({
  href,
  onClick,
  disabled,
  variant,
  icon,
  fontColor,
  children,
  target,
  rel,
  className,
  style,
}) => {
  return (
    <StyledLink
      disabled={disabled}
      href={href}
      variant={variant}
      fontColor={fontColor}
      target={target}
      rel={rel}
      onClick={onClick}
      style={style}
      className={className}
    >
      {icon && (
        <StyledIcon>
          <i className={icon + " hevara-avatar-icon font-size-20 mt-1 me-2"}></i>
        </StyledIcon>
      )}
      <StyledText variant={"text"} className="fw-bold">
        {children}
      </StyledText>
    </StyledLink>
  )
}

Link.defaultProps = {
  disabled: false,
  icon: undefined,
  fontColor: undefined,
  className: "",
}

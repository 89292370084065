import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { useNavigate } from "react-router-dom"

import MutliStepPage, { MultiStepHeader } from "components/MultiStepForm"
import {
  getNextStep,
  getPreviousStep,
  getProgress,
  isLastStep,
} from "components/MultiStepForm/multistepFomr.utils"
import CompanyTypes from "models/companyTypes"
import Countries from "models/countries"
import Roles from "models/roles"
import Company from "models/company"
import OnboardingUser from "models/onboarding"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import Loading from "components/Common/Spinner"
import CompanyTypeForm from "./wizard/companyTypeForm"
import CompanyInformationForm from "./wizard/companyInformationForm"
import PersonalInformationForm from "./wizard/personalInformation"
import Summary from "./wizard/summary"
import Success from "./wizard/successs"
import Fade from "components/Fade"
import { SmallModal } from "components/modal"
import { FORM_STEPS, ONBOARDING_FORM } from "./onboarding.const"

import "./onboarding.styles.scss"

const Onboarding = props => {
  //meta title
  document.title = "Register | hevara DMS Platform"

  const history = useNavigate()

  const [currentStep, setCurrentStep] = useState(FORM_STEPS[0])
  const [progress, setProgress] = useState(0)
  const [steps, setSteps] = useState(FORM_STEPS)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)

  const [companyTypes, setCompanyTypes] = useState([])
  const [countries, setCountries] = useState([])
  const [roles, setRoles] = useState([])

  // Form steps data
  const [selectedCompanyType, setSelectedCompanyType] = useState("")
  const [companyInfo, setCompanyInfo] = useState(null)
  const [personalInfo, setPersonalInfo] = useState(null)
  const [registeredCompany, setRegisteredCompany] = useState(null)
  const [registeredUser, setRegisteredUser] = useState(null)

  const fetchCompanyTypes = () => {
    CompanyTypes.lookUpCompanyTypes()
      .then(data => {
        setCompanyTypes(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchCountries = () => {
    Countries.lookUpCountries()
      .then(data => {
        setCountries([
          {
            status: "Default",
            name: "Select Country",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchRoles = () => {
    Roles.lookUpRoles()
      .then(data => {
        setRoles([
          {
            status: "Default",
            label: "Select company role",
            value: "default_value",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    Promise.all([fetchCompanyTypes(), fetchCountries(), fetchRoles()])
  }, [])

  useEffect(() => {
    setProgress(getProgress(currentStep, steps))
  }, [currentStep, steps])

  const onChange = (key, value) => {
    setSelectedCompanyType(value)
  }

  const companyInformation = values => {
    setCompanyInfo(values)
  }

  const personalInformation = values => {
    setPersonalInfo(values)
  }

  const onSubmit = async () => {
    setProcessing(true)

    const companyPayload = {
      companyName: companyInfo.companyName,
      country: companyInfo.country,
      type: selectedCompanyType,
      companyEmail: companyInfo.companyEmail,
      location: companyInfo.location.address,
      latitude: companyInfo.location.lat.toString(),
      longitude: companyInfo.location.lng.toString(),
      status: "Active",
    }

    const userPayload = {
      firstName: personalInfo.firstName,
      ...(personalInfo.middleName && { middleName: personalInfo.middleName }),
      lastName: personalInfo.lastName,
      email: personalInfo.email,
      mobileNumber: personalInfo.mobileNumber,
      country: personalInfo.country,
      companyRole: personalInfo.companyRole,
      role: personalInfo.role,
      tnc: personalInfo.tnc,
      password: personalInfo.password,
    }

    try {
      const company = await Company.addCompany(companyPayload)
      setRegisteredCompany(company)

      const user = await OnboardingUser.registerUser({
        companyId: company.id,
        ...userPayload,
      })

      setRegisteredUser(user)
      setCurrentStep(steps[steps.length - 1])
    } catch (error) {
      handleRegistrationError(error)
    } finally {
      setProcessing(false)
    }
  }

  const handleRegistrationError = error => {
    const errorMessage =
      error.response?.data?.message || "An error occurred during registration"
    toast.error(errorMessage)
  }

  const goNextStep = () => {
    setCurrentStep(getNextStep(currentStep, steps))
  }

  const goPreviousStep = () => {
    setCurrentStep(getPreviousStep(currentStep, steps))
  }

  const goSpecificStep = step => {
    setCurrentStep(step)
  }

  const onClickCancel = () => {
    if (isLastStep(currentStep, steps)) {
      exit()
    } else {
      setShowCancelModal(true)
    }
  }

  const onClickCloseModal = () => {
    setShowCancelModal(false)
  }

  const exit = () => {
    history("/login")
  }

  return (
    <MutliStepPage>
      {/* CANCEL MODAL */}
      <SmallModal
        modalIsOpen={showCancelModal}
        onClickClose={onClickCloseModal}
        title={"Are you sure you want to leave?"}
        confirmTxt={"Leave"}
        cancelTxt={"Stay"}
        onClickConfirm={exit}
      >
        <p className="font-size-16 m-0">
          All data will be lost if you leave now.
        </p>
      </SmallModal>

      {/* HEADER */}
      <MultiStepHeader
        progress={progress}
        hideBack={
          currentStep === ONBOARDING_FORM.COMPANY_TYPE ||
          (currentStep === steps[0] &&
            currentStep === ONBOARDING_FORM.COMPANY_INFORMATION) ||
          currentStep === ONBOARDING_FORM.SUCCESS
        }
        onClickBack={goPreviousStep}
        onClickClose={onClickCancel}
        title={`Sign up process`}
      />

      {/* PROCESSING */}
      <LoadingBackdrop
        show={processing}
        description={"We are saving your data, please wait..."}
      />

      {/* Loading */}
      {loading && (
        <div className="d-flex column center-x center-y my-2">
          <Loading />
        </div>
      )}

      <Fade in={!loading}>
        {/* STEP 1 => COMPANY TYPE */}
        <Fade in={currentStep === ONBOARDING_FORM.COMPANY_TYPE}>
          <CompanyTypeForm
            companyTypes={companyTypes}
            goNextStep={goNextStep}
            onClickCancel={onClickCancel}
            onChange={onChange}
            selectedCompanyType={selectedCompanyType}
          />
        </Fade>

        {/* STEP 2 => COMPANY INFORMATION */}
        <Fade in={currentStep === ONBOARDING_FORM.COMPANY_INFORMATION}>
          <CompanyInformationForm
            goNextStep={goNextStep}
            onClickCancel={onClickCancel}
            companyInfo={companyInfo}
            countries={countries}
            handleSubmit={values => companyInformation(values)}
          />
        </Fade>

        {/* STEP 3 => USER INFORMATION */}
        <Fade in={currentStep === ONBOARDING_FORM.PERSONAL_INFORMATION}>
          <PersonalInformationForm
            goNextStep={goNextStep}
            onClickCancel={onClickCancel}
            personalInfo={personalInfo}
            countries={countries}
            roles={roles}
            handleSubmit={values => personalInformation(values)}
          />
        </Fade>

        {/* STEP 4 => SUMMARY */}
        <Fade in={currentStep === ONBOARDING_FORM.SUMMARY}>
          <Summary
            selectedCompanyType={selectedCompanyType}
            companyInfo={companyInfo}
            personalInfo={personalInfo}
            handleSubmit={onSubmit}
            countries={countries}
            onClickCancel={onClickCancel}
            goSpecificStep={goSpecificStep}
          />
        </Fade>

        {/* STEP 5 => SUCCESS */}
        <Fade in={currentStep === ONBOARDING_FORM.SUCCESS}>
          <Success
            registeredCompany={registeredCompany}
            registeredUser={registeredUser}
          />
        </Fade>
      </Fade>

      <ToastContainer />
    </MutliStepPage>
  )
}

export default Onboarding

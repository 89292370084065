import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { Elevation } from "components/atoms"
import {
  CONTAINER_TYPE,
  getBorderStyles,
  getShadowStyles,
} from "./Container.styles"

const StyledElevationContainer = styled(Elevation)`
  ${getShadowStyles}
`
const StyledBorderContainer = styled.div`
  ${getBorderStyles}
`

export const Container = ({ children, className, ...props }) => {
  const { type, shadowType, shadowBackground, borderColor, borderBackground } =
    props

  if (type === CONTAINER_TYPE.SHADOW) {
    return (
      <StyledElevationContainer
        {...props}
        className={className}
        type={shadowType}
        shadowBackground={shadowBackground}
      >
        {children}
      </StyledElevationContainer>
    )
  }

  if (type === CONTAINER_TYPE.BORDER) {
    return (
      <StyledBorderContainer
        {...props}
        className={className}
        borderColor={borderColor}
        borderBackground={borderBackground}
      >
        {children}
      </StyledBorderContainer>
    )
  }

  return <>{children}</>
}

Container.defaultProps = {
  shadowType: 1,
  shadowBackground: undefined,
  borderColor: undefined,
  borderBackground: undefined,
}

export default Container

import CONST from "utils/constants"

const { MEDIA } = CONST

const getTheme = {
  color: {
    table_row: {
      positive_background: "#EDF7ED",
    },
  },
}

export const getPositiveAmountBackgroundColor = props => {
  const theme = getTheme
  return props.isPositive
    ? `background-color: ${theme.color.table_row.positive_background};`
    : ""
}

export const getTableRowAmountStyles = props => {
  return `
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .hevara-table-row-amount-title {
      ${props.strikethrough ? "text-decoration: line-through;" : ""}
      padding-top: 4px;
      padding-bottom: 4px;
      border-radius: 4px;
      ${getPositiveAmountBackgroundColor(props)}
      ${MEDIA.MOBILE} {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .hevara-table-row-amount-subtitle {
      ${props.strikethrough ? "text-decoration: line-through;" : ""}
      margin-top: 4px;
      padding-right: 4px;
      padding-left: 4px;
      border-radius: 4px;
      ${MEDIA.MOBILE} {
        font-size: 12px;
        line-height: 16px;
      }
    }
  `
}

import React, { useState } from "react"
import { find } from "lodash"
import { Row, Col } from "reactstrap"

import { PrimaryButton, SecondaryButton } from "components/Button"
import DataPanel from "components/DataPanel"
import EmptyState from "components/EmptyState"
import DataItem from "components/DataItem"
import { CATALOGUE_FORM } from "../productCatalogue.const"
import Link from "components/Link"
import OnboardingImage from "assets/images/verification-img.png"
import CONST from "utils/constants"
import "../style.scss"

const { COMPANY_TYPE } = CONST

const Summary = props => {
  const {
    selectedCatalogueCategory,
    catalogueInformation,
    selectedProductType,
    companyType,
    manufacturers,
    distributors,
    warehouses,
    handleSubmit,
    onClickCancel,
    goSpecificStep,
  } = props

  const [stepToEdit, setStepToEdit] = useState(null)
  const [showConfirmEditModal, setShowConfirmEditModal] = useState(false)

  const editStep = step => {
    setStepToEdit(step)
    goSpecificStep(step)
    //setShowConfirmEditModal(true)
  }

  const selectedWarehouse = find(warehouses, {
    id: catalogueInformation.warehouseId,
  })

  const selectedManufacturer = find(manufacturers, {
    id: catalogueInformation.manufacturerId,
  })

  const selectedDistributor = find(distributors, {
    id: catalogueInformation.distributorId,
  })

  return (
    <div className="mt-4">
      <Row>
        <Col md={6}>
          <div>
            <h1 className="fw-bold">Confirm product information</h1>
            <p className="mt-4 font-size-14">
              Check and confirm the information before creating the product.
            </p>

            {/* CATEGORY INFORMATION */}
            <DataPanel>
              <DataItem
                label={"Product category"}
                value={selectedCatalogueCategory.name}
              />

              <Link
                icon="mdi mdi-pencil"
                onClick={() => editStep(CATALOGUE_FORM.CATALOGUE_CATEGORY)}
              >
                Edit
              </Link>
            </DataPanel>

            {/* PRODUCT TYPE INFORMATION */}
            <DataPanel className="mt-4">
              <DataItem
                label={"Product type"}
                value={selectedProductType.label}
              />

              <Link
                icon="mdi mdi-pencil"
                onClick={() => editStep(CATALOGUE_FORM.CATALOGUE_CATEGORY_TYPE)}
              >
                Edit
              </Link>
            </DataPanel>

            {/* PRODUCT INFORMATION */}
            <DataPanel className="mt-4">
              <DataItem
                label={"Product name"}
                value={catalogueInformation.name}
              />

              <DataItem
                label={"Size"}
                value={`${catalogueInformation.size} ml`}
              />

              <DataItem
                label={"Stock quantity"}
                value={catalogueInformation.stockQuantity.toString()}
              />

              <DataItem
                label={"Replenishing level"}
                value={catalogueInformation.replenishingLevel.toString()}
              />

              {companyType === COMPANY_TYPE.DISTRIBUTOR && (
                <>
                  <DataItem
                    label={"Price"}
                    value={`Ksh. ${catalogueInformation.secondaryPrice.toString()}`}
                  />

                  {catalogueInformation.secondaryDiscount && (
                    <DataItem
                      label={"Discount"}
                      value={`${catalogueInformation.secondaryDiscount}%`}
                    />
                  )}
                </>
              )}

              {companyType === COMPANY_TYPE.WHOLESALLER && (
                <>
                  <DataItem
                    label={"Price"}
                    value={`Ksh. ${catalogueInformation.retailPrice.toString()}`}
                  />

                  {catalogueInformation.retailDiscount && (
                    <DataItem
                      label={"Discount"}
                      value={`${catalogueInformation.retailDiscount}%`}
                    />
                  )}
                </>
              )}

              <DataItem
                label={"On-request"}
                value={catalogueInformation.onRequest}
              />

              <DataItem
                label={"Manufacturer"}
                value={selectedManufacturer?.companyName}
              />

              {companyType === COMPANY_TYPE.WHOLESALLER && (
                <DataItem
                  label={"Distributor"}
                  value={selectedDistributor?.companyName}
                />
              )}

              <DataItem
                label={"Warehouse"}
                value={selectedWarehouse?.warehouseName}
              />

              <DataItem
                label={"Description"}
                value={catalogueInformation.description}
                truncate={true}
              />

              <Link
                icon="mdi mdi-pencil"
                onClick={() => editStep(CATALOGUE_FORM.CATALOGUE_INFORMATION)}
              >
                Edit
              </Link>
            </DataPanel>

            <Row>
              <Col>
                <div className="hstack gap-2 mb-0 mt-4">
                  <PrimaryButton onClick={handleSubmit} className="me-4">
                    Confirm
                  </PrimaryButton>

                  <SecondaryButton onClick={onClickCancel}>
                    Cancel
                  </SecondaryButton>
                </div>
              </Col>
            </Row>
          </div>
        </Col>

        <Col md={6}>
          <EmptyState
            visible={true}
            illustration={OnboardingImage}
            className="onboarding-user-ph-image"
            containerClassName="onboarding-user-ph"
          />
        </Col>
      </Row>
    </div>
  )
}

export default Summary

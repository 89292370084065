import React, { FC } from "react"
import styled from "styled-components"
import { isEmpty } from "lodash"

import { BodyText, BodyTextBold } from "components/atoms"
import Avatar from "components/Avatar/Avatar"
import { CONTAINER_TYPE } from "components/Container/Container.styles"
import { getSelectionBoxStyles } from "./SelectionBox.styles"
import CONST from "utils/constants"

const { SIZE } = CONST

const StyledSelectionBox = styled.div.attrs({
  className:
    "hevara-selection-box d-flex center-y center-x justify-content-center py-4",
})`
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 4px;
  ${getSelectionBoxStyles}
`
const StyledSelection = styled.div.attrs({
  className: "hevara-selection",
})``

const StyledSelectionTitle = styled(BodyTextBold).attrs({
  className: "hevara-selection-title",
})``

const StyledSelectionDescription = styled(BodyText).attrs({
  className: "hevara-selection-description",
})``

const StyledSelectionIcon = styled.i.attrs({
  className: "hevara-selection-selected-icon",
})``

const StyledSelectionSideContent = styled.div.attrs({
  className: "hevara-selection-side-content",
})``

const StyledSelectionSideData = styled(BodyText).attrs({
  className: "hevara-selection-sideData",
})``

const StyledSelectionSideSubdata = styled(BodyText).attrs({
  className: "hevara-selection-sideSubdata",
})``

export const SelectionBox = ({
  title,
  description,
  sideData,
  sideSubdata,
  onClick,
  avatar,
  selectedIcon,
  selected,
  isAllSelected,
  className,
  style,
}) => {
  return (
    <StyledSelectionBox
      type={CONTAINER_TYPE.BORDER}
      selected={selected}
      isAllSelected={isAllSelected}
      onClick={onClick}
      className={className}
      style={style}
    >
      {!isEmpty(avatar) && <Avatar {...avatar} size={SIZE.L} />}
      <StyledSelection>
        {title && <StyledSelectionTitle>{title}</StyledSelectionTitle>}
        <StyledSelectionDescription>{description}</StyledSelectionDescription>
      </StyledSelection>
      {selected && selectedIcon && (
        <StyledSelectionSideContent>
          <StyledSelectionIcon name={selectedIcon} size="24">
            {/* WHEN WE ADD IMAGES : <img src={selectedIcon} size={24}/> */}
            <i className={`${selectedIcon} me-1 fw-bolder font-size-24`} />
          </StyledSelectionIcon>
        </StyledSelectionSideContent>
      )}
      {(sideData || sideSubdata) && (
        <StyledSelectionSideContent>
          {sideData && (
            <StyledSelectionSideData>{sideData}</StyledSelectionSideData>
          )}
          {sideSubdata && (
            <StyledSelectionSideSubdata>
              {sideSubdata}
            </StyledSelectionSideSubdata>
          )}
        </StyledSelectionSideContent>
      )}
    </StyledSelectionBox>
  )
}

SelectionBox.defaultProps = {
  title: undefined,
  sideData: "",
  sideSubdata: "",
  onClick: undefined,
  isAllSelected: false,
  selected: false,
  selectedIcon: undefined,
  avatar: {},
  className: "",
  style: {},
}

import axios from "axios"
import API from "config/api.config"

export default {
  selectBillingPlan(values) {
    return axios.post(API.getApi().BILLING_PLANS.SELECT_BILLING_PLAN, values)
      .then(async ({ data }) => {
        return data
      })
  },
  lookUpBillingPlans() {
    return axios.get(API.getApi().LOOKUPS.BILLING_PLANS)
      .then(async ({ data }) => {
        return data
      })
  },
}

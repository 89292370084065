import React from "react";
import styled from "styled-components";
import { isFunction } from "lodash";

import { KEYBOARD_KEY, spacing, BodyText } from "../atoms";
import { getFormFieldIconColor, getFormFieldContainerStyles, getFormFieldSecondaryColor } from "components/Forms/Commons.styles";

const StyledInputContainer = styled.div.attrs({
  className: 'hevara-form-input-container'
})`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  ${spacing('mt', 2)}
  ${spacing('py', 2.75)}
  ${spacing('px', 3.75)}
  ${getFormFieldContainerStyles}
  ${props => isFunction(props.onClick) && !props.disabled && `cursor: pointer`}
`;

const StyledIcon = styled.i`
  color: ${getFormFieldIconColor}
`;

const StyledIconContainer = ({ name, onClick, style }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', ...style }}>
      <StyledIcon size='24' onClick={onClick}>
        <i className={name + " fw-bolder font-size-24"}></i>
      </StyledIcon>
    </div>
  )
}

const StyledBodyText = styled(BodyText)`
  ${getFormFieldSecondaryColor}
  ${spacing('ml', 4, true)}
`

/**
 * Input Container UI component
 */
const InputContainer = ({
  error,
  disabled,
  leftIcon,
  rightIcon,
  suffix,
  onClickRightIcon,
  onClickLeftIcon,
  onClickIcon,
  onClick,
  className,
  style,
  children,
  ariaLabel,
  role,
  errorMessageID,
  id
}) => {
  const _onClickLeftIcon = () => {
    if (!disabled) {
      if (isFunction(onClickLeftIcon)) {
        return onClickLeftIcon
      } else if (isFunction(onClickIcon)) {
        return onClickIcon
      }
    }
  }

  const _onClickRightIcon = () => {
    if (!disabled) {
      if (isFunction(onClickRightIcon)) {
        return onClickRightIcon
      } else if (isFunction(onClickIcon)) {
        return onClickIcon
      }
    }
  }

  return (
    <StyledInputContainer
      error={error}
      className={className}
      style={style}
      disabled={disabled}
      onClick={!disabled && isFunction(onClick) ? onClick : undefined}
      onKeyPress={(event) => {
        if (event.key === KEYBOARD_KEY.ENTER && !disabled && isFunction(onClick)) {
          onClick();
        }
      }}
      id={id}
      aria-label={ariaLabel}
      aria-invalid={Boolean(error)}
      aria-errormessage={errorMessageID}
      aria-disabled={disabled}
      role={role}
    >
      {leftIcon && (
        <StyledIconContainer
          name={leftIcon}
          style={spacing('mr', 2, true)}
          onClick={_onClickLeftIcon()}
        />
      )}
      {children}
      {!!suffix && (
        <StyledBodyText>{suffix}</StyledBodyText>
      )}
      {rightIcon && (
        <StyledIconContainer
          name={rightIcon}
          style={spacing('ml', 4, true)}
          onClick={_onClickRightIcon()}
        />
      )}
    </StyledInputContainer>
  )
};

InputContainer.defaultProps = {
  error: '',
  leftIcon: undefined,
  rightIcon: undefined,
  suffix: undefined,
  onClickRightIcon: undefined,
  onClickLeftIcon: undefined,
  onClickIcon: undefined,
  onClick: undefined
}

export default InputContainer
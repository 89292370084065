import React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Col, Row, Form, Input, FormFeedback, Label } from "reactstrap"

import { LargeModal, MODAL_STRETCH } from "components/modal"
import { PrimaryButton, SecondaryButton } from "components/Button"

const VehicleModal = ({
  modalIsOpen,
  onClickClose,
  fleet,
  isEdit,
  addFleet,
  updateFleet,
  companyId,
  vehicleTypes,
  routeAgents,
  warehouses,
  toggle,
}) => {
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      registrationNumber: (fleet && fleet.registrationNumber) || "",
      vehicleType: (fleet && fleet.vehicleType) || "",
      companyId: companyId,
      routeAgent: (fleet && fleet.routeAgent) || "",
      ...(fleet && fleet.warehouseId
        ? { warehouseId: (fleet && fleet.warehouseId) || "" }
        : {}),
    },
    validationSchema: Yup.object({
      registrationNumber: Yup.string().required(
        "Please enter vehicle registration number"
      ),
      vehicleType: Yup.string().required("Please select vehicle type"),
      companyId: Yup.string().required("Please select company"),
      routeAgent: Yup.string().required("Please select vehicle driver"),
      ...(fleet && fleet.warehouseId
        ? {
            warehouseId: Yup.string().required("Please select warehouse"),
          }
        : {}),
    }),
    onSubmit: values => {
      if (isEdit) {
        // update
        updateFleet(fleet.id, values)
        validation.resetForm()
      } else {
        // add new
        addFleet(values)
        validation.resetForm()
      }

      toggle()
    },
  })

  return (
    <LargeModal
      modalIsOpen={modalIsOpen}
      onClickClose={onClickClose}
      hideButtons={true}
      stretch={MODAL_STRETCH.NORMAL}
      title={
        isEdit ? `Edit ${fleet?.registrationNumber}'s details` : `New vehicle`
      }
    >
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
        className="mt-4"
      >
        <Row>
          <Col className="col-12">
            {/* REGISTRATION NUMBER */}
            <div className="mb-3">
              <Label className="form-label">Vehicle registration number</Label>
              <Input
                name="registrationNumber"
                type="text"
                placeholder="Registration number"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.registrationNumber || ""}
                invalid={
                  validation.touched.registrationNumber &&
                  validation.errors.registrationNumber
                    ? true
                    : false
                }
              />
              {validation.touched.registrationNumber &&
              validation.errors.registrationNumber ? (
                <FormFeedback type="invalid">
                  {validation.errors.registrationNumber}
                </FormFeedback>
              ) : null}
            </div>

            {/* VEHICLE TYPE */}
            <div className="mb-3">
              <Label className="form-label">Vehicle type</Label>
              <Input
                name="vehicleType"
                type="select"
                placeholder="Select vehicle type"
                defaultValue={"Select vehicle type"}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.vehicleType || ""}
                invalid={
                  validation.touched.vehicleType &&
                  validation.errors.vehicleType
                    ? true
                    : false
                }
              >
                {vehicleTypes.map(type => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </Input>
              {validation.touched.vehicleType &&
              validation.errors.vehicleType ? (
                <FormFeedback type="invalid">
                  {validation.errors.vehicleType}
                </FormFeedback>
              ) : null}
            </div>

            {/* ROUTE-AGENT */}
            <div className="mb-3">
              <Label className="form-label">Vehicle driver</Label>
              <Input
                name="routeAgent"
                type="select"
                placeholder="Select vehicle driver"
                defaultValue={"Select vehicle driver"}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.routeAgent || ""}
                invalid={
                  validation.touched.routeAgent && validation.errors.routeAgent
                    ? true
                    : false
                }
              >
                {routeAgents.map(routeAgent => (
                  <option key={routeAgent.id} value={routeAgent.id}>
                    {routeAgent.fullName}
                  </option>
                ))}
              </Input>
              {validation.touched.routeAgent && validation.errors.routeAgent ? (
                <FormFeedback type="invalid">
                  {validation.errors.routeAgent}
                </FormFeedback>
              ) : null}
            </div>

            {/* WAREHOUSE */}
            <div className="mb-3">
              <Label className="form-label">Warehouse (Optional)</Label>
              <Input
                name="warehouseId"
                type="select"
                placeholder="Select warehouse"
                defaultValue={"Select warehouse"}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.warehouseId || ""}
                invalid={
                  validation.touched.warehouseId &&
                  validation.errors.warehouseId
                    ? true
                    : false
                }
              >
                {warehouses.map(warehouse => (
                  <option key={warehouse.id} value={warehouse.id}>
                    {warehouse.warehouseName}
                  </option>
                ))}
              </Input>
              {validation.touched.warehouseId &&
              validation.errors.warehouseId ? (
                <FormFeedback type="invalid">
                  {validation.errors.warehouseId}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="hstack gap-2 justify-content-center mb-0 mt-4">
              <SecondaryButton type="button" onClick={toggle} className="me-4">
                Cancel
              </SecondaryButton>

              <PrimaryButton type="submit">
                {!!isEdit ? "Update vehicle" : "Add vehicle"}
              </PrimaryButton>
            </div>
          </Col>
        </Row>
      </Form>
    </LargeModal>
  )
}

export default VehicleModal

export const PRODUCT_FORM = {
  PRODUCT_CATEGORY: "PRODUCT_CATEGORY",
  PRODUCT_INFORMATION: "PRODUCT_INFORMATION",
  PRODUCT_CATEGORY_TYPE: "PRODUCT_CATEGORY_TYPE",
  SUMMARY: "SUMMARY",
}

export const FORM_STEPS = [
  PRODUCT_FORM.PRODUCT_CATEGORY,
  PRODUCT_FORM.PRODUCT_INFORMATION,
  PRODUCT_FORM.PRODUCT_CATEGORY_TYPE,
  PRODUCT_FORM.SUMMARY,
]

import CONST from "utils/constants"

const { MEDIA } = CONST

const getTheme = {
  color: {
    feedback_notification: {
      info: {
        font: "#000000",
        icon: "#F4CC0C",
        background: "#EAF8FD",
        border: "#EAF8FD",
      },
      warning: {
        font: "#1E1E1E",
        icon: "#FF9800",
        background: "#FFF4E5",
        border: "#FFF4E5",
      },
      error: {
        font: "#1E1E1E",
        icon: "#F44336",
        background: "#FDECEA",
        border: "#FDECEA",
      },
      success: {
        font: "#1E1E1E",
        icon: "#4CAF50",
        background: "#EDF7ED",
        border: "#EDF7ED",
      },
    },
  },
}

export const FEEDBACK_VARIANTS = {
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
  SUCCESS: "success",
}

export const getFeedbackNotificationStyles = props => {
  const theme = getTheme
  const backgroundColor =
    theme.color.feedback_notification[props.variant].background
  const iconColor = theme.color.feedback_notification[props.variant].icon
  const fontColor = theme.color.feedback_notification[props.variant].font
  const borderColor =
    props.styles?.borderColor ||
    theme.color.feedback_notification[props.variant].border ||
    backgroundColor

  return `
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    background-color: ${backgroundColor};
    border: 2px solid ${borderColor};
    .hevara-feedback-notification-icon {
      color: ${iconColor};
      margin-right: 8px;
    }
    .hevara-feedback-notification-title, .hevara-feedback-notification-message {
      color: ${fontColor};
    }
    .hevara-feedback-notification-content-wrapper, .hevara-feedback-custom-content > * {
      color: ${fontColor};
    }
    ${MEDIA.MOBILE} {
      flex-direction: column;
      position: relative;
      .hevara-feedback-notification-content-wrapper {
        flex-direction: column;
        margin: 0;
      }
      .hevara-feedback-notification-icon-close {
        position: absolute;
        right: 0;
        margin-right: 16px;
      }
      .hevara-feedback-notification-icon {
        margin-bottom: 8px;
      }
      .hevara-feedback-notification-right-side-button {
        align-self: start;
        margin-top: 24px;
      }
    }
  `
}

import React from "react"
import styled from "styled-components"

import Text from "components/atoms/Text/BasicText"
import CONST from "components/atoms/constants"
import { getChipStyles, CHIP_SIZES, CHIP_VARIANTS } from "./Chip.styles"

const StyledChip = styled.div.attrs({
  className: "hevara-chip",
})`
  ${getChipStyles}
`

const StyledText = styled(Text).attrs({
  className: "hevara-chip-label",
})``

const Chip = ({ size, variant, className, borderColor, ...props }) => {
  return (
    <StyledChip
      size={size}
      variant={variant}
      className={className}
      borderColor={borderColor}
      {...props}
    >
      <StyledText 
        uppercase={true}
        variant={CONST.FONT.VARIANT.TEXT}
        weight={CONST.FONT.WEIGHT.BOLD}
      >
        {props.children}
      </StyledText>
    </StyledChip>
  )
}

Chip.defaultProps = {
  variant: CHIP_VARIANTS.SUCCESS,
  size: CHIP_SIZES.M,
}

export default Chip

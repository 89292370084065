import React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Link } from "react-router-dom"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap"

import Footer from "components/Footer"
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"

import logodark from "assets/images/logo-dark.png"
import logolight from "assets/images/logo-light.png"
import CarouselPage from "./CarouselPage"

const Register = props => {
  //meta title
  document.title = "Register | hevara - DMS"

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      console.log(values)
      //dispatch(registerUser(values))
    },
  })

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col xl={4}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="dashboard" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="28"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="28"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-tertiary">Register account</h5>
                        <p className="text-muted">
                          Create your hevara DMS account
                        </p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Username</Label>
                            <Input
                              name="username"
                              type="text"
                              placeholder="Enter username"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              invalid={
                                validation.touched.username &&
                                validation.errors.username
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.username &&
                            validation.errors.username ? (
                              <FormFeedback type="invalid">
                                {validation.errors.username}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              type="password"
                              placeholder="Enter password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mt-4 text-center">
                            <button
                              className="btn btn-primary waves-effect waves-light w-xl fw-bold"
                              type="submit"
                            >
                              Register
                            </button>
                          </div>
                        </Form>

                        <div className="mt-4 text-center">
                          <p>
                            By registering you agree to the{" "}
                            <a
                              href="https://hevara.africa/terms-of-service/"
                              target="_blank"
                              rel="noreferrer"
                              className="text-tertiary fw-bolder text-decoration-underline"
                            >
                              hevara Terms of Use
                            </a>
                          </p>
                        </div>

                        <div className="mt-5 text-center">
                          <p>
                            Already have an account ?{" "}
                            <Link to="/login" className="fw-bold text-tertiary">
                              Login
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 text-center">
                      <Footer />
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register

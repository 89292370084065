export default {
  LOCAL_STORAGE: {
    NEXT_ROUTE: "nextRoute",
  },
  DEFAULT_DATE_FORMAT: "LLLL",
  DATE_FORMAT: "LL",
  DATE_YMD_FORMAT: "YYYY-MM-DD",
  DEFAULT_DATE_TIME_FORMAT: "LL, HH:mm",
  SHORT_DATE_FORMAT: "LL",
  ROLES: {
    ADMIN: "admin",
    USER: "user",
    SUPER_ADMIN: "SUPER_ADMIN",
    COMPANY_ADMIN: "COMPANY_ADMIN",
    ROUTE_AGENT: "ROUTE_AGENT",
    ACCOUNTANT: "ACCOUNTANT",
    MANAGER: "MANAGER",
    VENDOR: "VENDOR",
  },
  BILLING_PLANS: {
    FREE_PLAN: 'FP',
    BUSINESS_PLAN: 'BP',
    ECONOMY_PLAN: 'EP',
    ENTERPRISE_PLAN: 'ECP'
  },
  ORDER_STATUS: {
    PROCESSING: "PROCESSING",
    CONFIRMED: "CONFIRMED",
    DELIVERED: "DELIVERED",
    DISPATCHED: "DISPATCHED",
    INTRANSIT: "INTRANSIT",
    PACKAGING: "PACKAGING",
    RECEIVED: "RECEIVED",
    RETURNED: "RETURNED",
    SHIPMENT: "SHIPMENT",
  },
  PAYMENT_STATUS: {
    PENDING_PAYMENT: "PENDING_PAYMENT",
    PAID: "PAID",
    PATRIAL_PAYMENT: "PARTIAL_PAYMENT",
  },
  TRANSACTION_STATUS: {
    PENDING: "PENDING",
    PROCESSING: "PROCESSING",
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
  },
  TRANSACTION_TYPE: {
    TRANSFER: "transfer",
  },
  WALLET_STATUS: {
    PENDING: "PENDING",
    ACTIVE: "ACTIVE",
    CANCELLED: "CANCELLED",
  },
  ORDER_TYPES: {
    COUNTER: "COUNTER",
    SALES_FORCE: "SALES_FORCE",
    ONLINE: "ONLINE",
  },
  CUSTOMER_TYPES: {
    INDIVIDUAL: "INDIVIDUAL",
    BAR: "BAR",
    RESTAURANT: "RESTAURANT",
    EATERY: "EATERY",
    OTHER: "OTHER",
  },
  COMPANY_TYPE: {
    IMPORTER: "IMPORTER",
    MANUFACTURER: "MANUFACTURER",
    DISTRIBUTOR: "DISTRIBUTOR",
    WHOLESALLER: "WHOLESALLER",
    VENDOR: "VENDOR",
    SUPER_ADMIN: "SUPER_ADMIN",
    KEY_DISTRIBUTOR: "KEY_DISTRIBUTOR",
  },
  CATEGORY_TYPE: {
    BOTTLES: "BOTTLES",
    CANS: "CANS",
  },
  MEDIA: {
    MOBILE: "@media (max-width: 574px)",
    TABLET: "@media (max-width: 768px)",
    DESKTOP_S: "@media (max-width: 1200px)",
    DESKTOP_M: "@media (max-width: 1440px)",
    DESKTOP_L: "@media (max-width: 1600px)",
  },
  MEDIA_QUERIES: {
    mediaXS: `only screen and (min-width: 320px)`,
    mediaS: `only screen and (min-width: 576px)`,
    mediaM: `only screen and (min-width: 768px)`,
    mediaL: `only screen and (min-width: 992px)`,
    mediaXL: `only screen and (min-width: 1200px)`,
    mediaXXL: `only screen and (min-width: 1440px)`,
    mdMediaGtXs: `only screen and (min-width: 600px)`,
  },
  SIZE: {
    XXXL: "xxxl",
    XXL: "xxl",
    XL: "xl",
    L: "l",
    M: "m",
    S: "s",
    XS: "xs",
  },
  DIVIDER_ORIENTATION: {
    HORIZONTAL: "horizontal",
    VERTICAL: "vertical",
  },
  SELECTION_BOX: {
    unselected: {
      font_primary: "#000000",
      font_secondary: "#000000",
      border: "#000000",
      background: "#ffffff",
      select_all_icon: "#000000",
      select_all_background: "#ffffff",
    },
    selected: {
      font_primary: "#000000",
      font_secondary: "#000000",
      border: "#000000",
      background: "#ffffff",
      icon: "#000000",
      select_all_icon: "#000000",
      select_all_background: "#ffffff",
    },
  },
}

import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import moment from "moment"
import * as Yup from "yup"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import DataItem from "components/DataItem"
import Loading from "components/Common/Spinner"
import { EmptyErrorState } from "components/EmptyState"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import {
  getOrderStatus,
  getPaymentStatusLabel,
  getOrderStatusLabel,
  getOrderTypeLabel,
  getLastFourDigits,
  getCustomerTypeLabel,
} from "utils/utils"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import { SmallModal } from "components/modal"
import { PrimaryButton, SecondaryButton } from "components/Button"
import Link from "components/Link"
import Avatar from "components/Avatar/Avatar"
import Auth from "models/auth"
import Suborders from "models/suborders"
import Order from "models/order"
import Company from "models/company"
import OrderStatus from "models/orderStatus"
import CONST from "utils/constants"

const { SIZE, COMPANY_TYPE } = CONST

const DispatchDetails = props => {
  //meta title
  document.title = "Dispatch details | hevara DMS Platform"

  const { subOrderId, orderId } = useParams()

  const [showStatusModal, setShowStatusModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const [companyInfo, setCompanyInfo] = useState({})

  const [suborderDetails, setSuborderDetails] = useState({})
  const [orderDetails, setOrderDetails] = useState({})
  const [orderStatus, setOrderStatus] = useState([])

  const fetchSubOrderDetails = id => {
    Suborders.getSubOrder(id)
      .then(data => {
        setSuborderDetails(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchOrderDetails = id => {
    Order.getOrder(id)
      .then(data => {
        setOrderDetails(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanyInfo = id => {
    Company.getCompany(id)
      .then(data => {
        setCompanyInfo(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchOrderStatuses = () => {
    OrderStatus.lookUpOrderStatuses()
      .then(data => {
        setOrderStatus([
          {
            value: "",
            label: "Select status",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()

    if (subOrderId && orderId && profile) {
      const companyId = JSON.parse(profile).companyId

      Promise.all([
        fetchSubOrderDetails(subOrderId),
        fetchOrderDetails(orderId),
        fetchCompanyInfo(companyId),
        fetchOrderStatuses(),
      ])
      setUserProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [subOrderId, orderId])

  const getHeaderActions = () => {
    const actions = [
      userProfile.permission.CAN_MANAGE_PRODUCTS && {
        label: "Dispatch note",
        icon: "bx bxs-file-pdf",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userProfile.permission.CAN_MANAGE_PRODUCTS && {
        label: "Create shipment",
        icon: "mdi mdi-pencil",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.ACCENT,
        showInModal: false,
      },
      userProfile.permission.CAN_MANAGE_PRODUCTS && {
        label: "Update status",
        icon: "mdi mdi-pencil",
        onClick: () => toggle(),
        variant: BUTTON_VARIANT.ACCENT,
        showInModal: false,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const toggle = () => {
    if (showStatusModal) {
      setShowStatusModal(false)
    } else {
      setShowStatusModal(true)
    }

    validation.resetForm()
  }

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      status: (suborderDetails && suborderDetails.status) || "",
      paymentStatus: suborderDetails && suborderDetails.paymentStatus,
    },
    validationSchema: Yup.object({
      status: Yup.string().required("This field is required"),
    }),
    onSubmit: values => {
      updateOrderStatus(values)

      toggle()
    },
  })

  const updateOrderStatus = values => {
    setProcessing(true)

    Suborders.updateSubOrder(suborderDetails.id, values)
      .then(data => {
        setSuborderDetails(data)
        toast.success(`Order updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dispatch details")}
            breadcrumbItem={props.t("Dispatch details")}
          />

          {/* Loading */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Dispatch"}
            description={"We are processing your request, please wait..."}
          />

          {/* EDIT STATUS MODAL */}
          <SmallModal
            modalIsOpen={showStatusModal}
            onClickClose={toggle}
            title={"Edit dispatch status"}
            hideButtons={true}
          >
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              {/* STATUS */}
              <div className="mb-3">
                <Label className="form-label">Order status</Label>
                <Input
                  name="status"
                  type="select"
                  placeholder="Select status"
                  defaultValue={"Select status"}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.status || ""}
                  invalid={
                    validation.touched.status && validation.errors.status
                      ? true
                      : false
                  }
                >
                  {orderStatus.map(status => (
                    <option key={status.id} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </Input>
                {validation.touched.status && validation.errors.status ? (
                  <FormFeedback type="invalid">
                    {validation.errors.status}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="hstack gap-2 mb-0 mt-4">
                <PrimaryButton type="submit" className="me-4">
                  Confirm
                </PrimaryButton>

                <SecondaryButton type="button" onClick={toggle}>
                  Cancel
                </SecondaryButton>
              </div>
            </Form>
          </SmallModal>

          {/* DETAILS */}
          {!loading && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* PAGE HEADER */}
                    <PageHeader
                      avatar={
                        suborderDetails && {
                          initials: suborderDetails.name,
                          image: suborderDetails.image?.file,
                        }
                      }
                      title={`${suborderDetails.name} (${suborderDetails.size} ml)`}
                      showBackButton={true}
                      showDivider={true}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText>
                            {getOrderStatus(suborderDetails.status)}
                          </PageHeaderInfoText>

                          <PageHeaderInfoText className="fw-semibold">
                            <p className="mb-0 fw-bold text-tertiary">
                              {suborderDetails.code}
                            </p>
                          </PageHeaderInfoText>

                          <PageHeaderInfoText className="fw-semibold">
                            <p className="mb-0 fw-bold text-primary">
                              {suborderDetails.categoryName} -{" "}
                              {suborderDetails.categoryType}
                            </p>
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                      actions={<HeaderActions actions={getHeaderActions()} />}
                    />

                    {suborderDetails && orderDetails && (
                      <>
                        {/* DETAILS SECTIONS */}
                        <div className="hevara-product-detail">
                          <div className="tx-grid mb-3">
                            <div>
                              {/* PRODUCT ORDER DETAILS */}
                              <p className="font-size-14 mb-2 text-muted fw-bold">
                                Product details
                              </p>
                              <div className="detail-grid mb-5">
                                <DataItem
                                  label={props.t("Name")}
                                  value={`${suborderDetails.name} (${suborderDetails.size} ml)`}
                                />

                                <DataItem
                                  label={props.t("Category")}
                                  value={suborderDetails.categoryName}
                                />

                                <DataItem
                                  label={props.t("Type")}
                                  value={suborderDetails.categoryType}
                                />

                                <DataItem
                                  label={props.t("Order quantity")}
                                  value={`${suborderDetails.orderQuantity}`}
                                />

                                <DataItem
                                  label={props.t("Order amount")}
                                  value={`Ksh. ${suborderDetails.totalSubOrderPrice.toLocaleString()}`}
                                />

                                {/* DISTRIBUTOR PRICING */}
                                {companyInfo.type ===
                                  COMPANY_TYPE.DISTRIBUTOR && (
                                  <>
                                    <DataItem
                                      label={props.t("Price")}
                                      value={`Ksh. ${
                                        suborderDetails.secondaryPrice.toLocaleString() ||
                                        0
                                      }`}
                                    />

                                    {!!suborderDetails.secondaryDiscount && (
                                      <DataItem
                                        label={props.t("Discount")}
                                        value={`${suborderDetails.secondaryDiscount}% Off`}
                                      />
                                    )}
                                  </>
                                )}

                                {/* WHOLESALER PRICING */}
                                {companyInfo.type ===
                                  COMPANY_TYPE.WHOLESALLER && (
                                  <>
                                    <DataItem
                                      label={props.t("Price")}
                                      value={`Ksh. ${
                                        suborderDetails.retailPrice.toLocaleString() ||
                                        0
                                      }`}
                                    />

                                    {!!suborderDetails.retailDiscount && (
                                      <DataItem
                                        label={props.t("Discount")}
                                        value={`${suborderDetails.retailDiscount}% Off`}
                                      />
                                    )}
                                  </>
                                )}
                              </div>

                              {/* CUSTOMER DETAILS */}
                              <p className="font-size-14 mb-2 text-muted fw-bold">
                                Customer details
                              </p>
                              <div className="detail-grid mb-5">
                                {/* CUSTOMER NAMES */}
                                <DataItem
                                  label={props.t("Name")}
                                  value={`${suborderDetails.customer.firstName} ${suborderDetails.customer.lastName}`}
                                />

                                {/* PHONE NUMBER */}
                                <DataItem
                                  label={props.t("Phone number")}
                                  value={getLastFourDigits(
                                    suborderDetails.customer.phone
                                  )}
                                />

                                {/* EMAIL */}
                                {suborderDetails.customer.email && (
                                  <DataItem
                                    label={props.t("Email address")}
                                    value={suborderDetails.customer.email}
                                  />
                                )}

                                {/* CUSTOMER TYPE */}
                                {suborderDetails.customer.customerType && (
                                  <DataItem
                                    label={props.t("Customer type")}
                                    value={getCustomerTypeLabel(
                                      suborderDetails.customer.customerType
                                    )}
                                  />
                                )}

                                {/* LOCATION */}
                                {suborderDetails.customer.location && (
                                  <DataItem
                                    label={props.t("Location")}
                                    value={suborderDetails.customer.location}
                                  >
                                    <Link onClick={() => console.log("WIP")}>
                                      View on map
                                    </Link>
                                  </DataItem>
                                )}
                              </div>

                              {/* ORDER DETAILS */}
                              <p className="font-size-14 mb-2 text-muted fw-bold">
                                Order details
                              </p>
                              <div className="detail-grid">
                                <DataItem
                                  label={props.t("Order code")}
                                  value={orderDetails.orderCode}
                                />

                                <DataItem
                                  label={props.t("Payment method")}
                                  value={orderDetails.paymentMethod}
                                />

                                <DataItem
                                  label={props.t("Order type")}
                                  value={getOrderTypeLabel(
                                    orderDetails.orderType
                                  )}
                                />

                                <DataItem
                                  label={props.t("Payment status")}
                                  value={getPaymentStatusLabel(
                                    orderDetails.paymentStatus
                                  )}
                                />

                                <DataItem
                                  label={props.t("Order status")}
                                  value={getOrderStatusLabel(
                                    orderDetails.status
                                  )}
                                />

                                <DataItem
                                  label={props.t("Order date")}
                                  value={moment(
                                    orderDetails.dateCreated
                                  ).format("LL")}
                                />
                              </div>
                            </div>

                            {/* PRODUCT IMAGE */}
                            <div className="mb-3 tx-avatar">
                              <div className="container-box">
                                <Avatar
                                  size={SIZE.XXL}
                                  initials={suborderDetails.name}
                                  image={suborderDetails.image?.file}
                                  className="avatar-size"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="d-flex justify-content-center align-items-center">
                      {/* ERROR STATE */}
                      <EmptyErrorState
                        visible={error}
                        title={`Something went wrong`}
                        description={`An error has occured. Please try again later or contact hevara for support`}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

DispatchDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(DispatchDetails)

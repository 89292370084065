import React from "react"
import PropTypes from "prop-types"

import PageContainer from "components/PageContainer"

const MutliStepPage = ({ children, className }) => {
  return (
    <div className={`d-flex flex column center-y ${className}`}>
      <PageContainer>{children}</PageContainer>
    </div>
  )
}

MutliStepPage.prototypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

MutliStepPage.defaultProps = {
  className: "",
}

export default MutliStepPage

import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getUsers(limit = 10, page = 1, filters = {}) {
    const parameters = {
      limit,
      page,
      status: filters.status,
      companyId: filters.companyId,
      role: filters.role,
      companyRole: filters.companyRole,
      firstName: filters.firstName,
    }
    const url = queryParams(API.getApi().USERS.GET_USERS, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  addUser(values) {
    return axios
      .post(API.getApi().USERS.ADD_USER, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateUser(userId, values) {
    return axios
      .patch(`${API.getApi().USERS.UPDATE_USER}/${userId}`, values)
      .then(async ({ data }) => {
        return data
      })
  },
  getUserDetails(userId) {
    return axios
      .get(`${API.getApi().USERS.GET_USER_DETAILS}/${userId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  deleteUser(userId) {
    return axios
      .delete(`${API.getApi().USERS.DELETE_USER}/${userId}`)
      .then(async ({ data }) => {
        return data
      })
  },
}

import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import moment from "moment"
import * as Yup from "yup"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { includes } from "lodash"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import DataItem from "components/DataItem"
import Loading from "components/Common/Spinner"
import { EmptyErrorState } from "components/EmptyState"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import {
  getOrderStatus,
  getPaymentStatusLabel,
  getOrderStatusLabel,
  getOrderTypeLabel,
  getCategoryTypeLabel
} from "utils/utils"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import Avatar from "components/Avatar/Avatar"
import Auth from "models/auth"
import Suborders from "models/suborders"
import SuborderPayments from "models/suborderPayments"
import Order from "models/order"
import Company from "models/company"
import OrderStatus from "models/orderStatus"
import CONST from "utils/constants"
import API from "config/api.config"
import EditStatusModal from "./editStatusModal"
import PaymentModal from "./paymentModal"
import { BodyText, BodyTextBold } from "components/atoms"

const { SIZE, COMPANY_TYPE, PAYMENT_STATUS } = CONST

const OrderDetails = props => {
  //meta title
  document.title = "OrderDetails | hevara DMS Platform"

  const { subOrderId, orderId } = useParams()

  const [showStatusModal, setShowStatusModal] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const [companyInfo, setCompanyInfo] = useState({})

  const [suborderDetails, setSuborderDetails] = useState({})
  const [orderDetails, setOrderDetails] = useState({})
  const [orderStatus, setOrderStatus] = useState([])

  const fetchSubOrderDetails = id => {
    Suborders.getSubOrder(id)
      .then(data => {
        setSuborderDetails(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchOrderDetails = id => {
    Order.getOrder(id)
      .then(data => {
        setOrderDetails(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanyInfo = id => {
    Company.getCompany(id)
      .then(data => {
        setCompanyInfo(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchOrderStatuses = () => {
    OrderStatus.lookUpOrderStatuses()
      .then(data => {
        setOrderStatus([
          {
            value: "",
            label: "Select status",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (subOrderId && orderId && profile) {
      const companyId = JSON.parse(profile).companyId

      Promise.all([
        fetchSubOrderDetails(subOrderId),
        fetchOrderDetails(orderId),
        fetchCompanyInfo(companyId),
        fetchOrderStatuses(),
      ])
      setUserProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [subOrderId, orderId])

  const updateOrderStatus = (values) => {
    setProcessing(true)

    Suborders.updateSubOrder(suborderDetails.id, values)
      .then(data => {
        setSuborderDetails(data)
        toast.success(`Order updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const makePayment = (values) => {
    setProcessing(true)

    SuborderPayments.initiatePayment(values)
      .then(data => {
        toast.success(data.customerMessage)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const toggle = () => {
    if (showStatusModal) {
      setShowStatusModal(false)
    } else {
      setShowStatusModal(true)
    }
  }

  const togglePayment = () => {
    if (showPaymentModal) {
      setShowPaymentModal(false)
    } else {
      setShowPaymentModal(true)
    }
  }

  const downloadInvoice = () => {
    setProcessing(true)
    const subOrderId = suborderDetails.id

    const link = document.createElement("a")
    link.href = `${API.getApi().GENERATE_PDF.GET_SUBORDER_PDF}/${subOrderId}`
    link.download = `order_invoice_${
      suborderDetails.subOrderCode
    }_${moment().format("DDMM")}.pdf`

    // Append the link to the DOM and trigger the click
    document.body.appendChild(link)
    link.click()

    // Remove the link from the DOM
    document.body.removeChild(link)

    setProcessing(false)
  }

  const getHeaderActions = () => {
    const canPay = includes([PAYMENT_STATUS.PENDING_PAYMENT, PAYMENT_STATUS.PATRIAL_PAYMENT], suborderDetails.paymentStatus);

    const actions = [
      userProfile.permission.CAN_MANAGE_PRODUCTS && canPay && {
        label: "Pay",
        icon: "bx bxs-wallet",
        onClick: () => togglePayment(),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userProfile.permission.CAN_MANAGE_PRODUCTS && {
        label: "Download invoice",
        icon: "bx bxs-file-pdf",
        onClick: () => downloadInvoice(),
        variant: BUTTON_VARIANT.SECONDARY,
        showInModal: false,
      },
      userProfile.permission.CAN_MANAGE_PRODUCTS && {
        label: "Update status",
        icon: "mdi mdi-pencil",
        onClick: () => toggle(),
        variant: BUTTON_VARIANT.ACCENT,
        showInModal: false,
      },
      userProfile.permission.CAN_MANAGE_PRODUCTS && {
        label: `Delete`,
        icon: "mdi mdi-trash-can",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.CRITICAL,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Order details")}
            breadcrumbItem={props.t("Order details")}
          />

          {/* Loading */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Order"}
            description={"We are processing your request, please wait..."}
          />

          {/* EDIT STATUS MODAL */}
          <EditStatusModal 
            showStatusModal={showStatusModal}
            toggle={toggle}
            updateOrderStatus={updateOrderStatus}
            suborderDetails={suborderDetails}
            orderStatus={orderStatus}
          />

          {/* PAYMENT MODAL */}
          <PaymentModal
            showPaymentModal={showPaymentModal}
            togglePayment={togglePayment}
            suborderDetails={suborderDetails}
            makePayment={makePayment}
          />

          {/* DETAILS */}
          {!loading && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* PAGE HEADER */}
                    <PageHeader
                      avatar={
                        suborderDetails && {
                          initials: suborderDetails.name,
                          image: suborderDetails.image?.file,
                        }
                      }
                      title={`${suborderDetails.name} (${suborderDetails.size} ml)`}
                      showBackButton={true}
                      showDivider={true}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText>
                            {getOrderStatus(suborderDetails.status)}
                          </PageHeaderInfoText>

                          <PageHeaderInfoText className="fw-semibold">
                            <p className="mb-0 fw-bold text-tertiary">
                              {suborderDetails.code}
                            </p>
                          </PageHeaderInfoText>

                          <PageHeaderInfoText className="fw-semibold">
                            <p className="mb-0 fw-bold text-primary">
                              {suborderDetails.categoryName} -{" "}
                              {suborderDetails.categoryType}
                            </p>
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                      actions={<HeaderActions actions={getHeaderActions()} />}
                    />

                    {suborderDetails && orderDetails && (
                      <>
                        {/* DETAILS SECTIONS */}
                        <div className="hevara-product-detail">
                          <div className="tx-grid mb-3">
                            <div>
                              {/* PRODUCT ORDER DETAILS */}
                              <BodyTextBold className="mb-2 text-muted">
                                Product details
                              </BodyTextBold>
                              <div className="detail-grid mb-5">
                                <DataItem
                                  label={props.t("Name")}
                                  value={`${suborderDetails.name} (${suborderDetails.size} ml)`}
                                />

                                <DataItem
                                  label={props.t("Category")}
                                  value={suborderDetails.categoryName}
                                />

                                <DataItem
                                  label={props.t("Type")}
                                  value={getCategoryTypeLabel(suborderDetails.categoryType)}
                                />
                              </div>

                              {/* ORDER PRICING & QUANTITY */}
                              <BodyTextBold className="mb-2 text-muted">
                                Order quantity & pricing
                              </BodyTextBold>
                              <div className="detail-grid mb-5">
                                {/* ORDER QUANTITY */}
                                <DataItem
                                  label={props.t("Order quantity")}
                                  value={`${suborderDetails.orderQuantity}`}
                                />

                                {/* ORDER AMOUNT */}
                                <DataItem
                                  label={props.t("Order amount")}
                                  value={`Ksh. ${suborderDetails.totalSubOrderPrice.toLocaleString()}`}
                                />

                                {/* DISTRIBUTOR PRICING */}
                                {companyInfo.type ===
                                  COMPANY_TYPE.DISTRIBUTOR && (
                                  <>
                                    <DataItem
                                      label={props.t("Price")}
                                      value={`Ksh. ${
                                        suborderDetails.secondaryPrice.toLocaleString() ||
                                        0
                                      }`}
                                    />

                                    {!!suborderDetails.secondaryDiscount && (
                                      <DataItem
                                        label={props.t("Discount")}
                                        value={`${suborderDetails.secondaryDiscount}% Off`}
                                      />
                                    )}
                                  </>
                                )}

                                {/* WHOLESALER PRICING */}
                                {companyInfo.type ===
                                  COMPANY_TYPE.WHOLESALLER && (
                                  <>
                                    <DataItem
                                      label={props.t("Price")}
                                      value={`Ksh. ${
                                        suborderDetails.retailPrice.toLocaleString() ||
                                        0
                                      }`}
                                    />

                                    {!!suborderDetails.retailDiscount && (
                                      <DataItem
                                        label={props.t("Discount")}
                                        value={`${suborderDetails.retailDiscount}% Off`}
                                      />
                                    )}
                                  </>
                                )}
                              </div>

                              {/* ORDER DETAILS */}
                              <BodyTextBold className="mb-2 text-muted">
                                Order details
                              </BodyTextBold>
                              <div className="detail-grid mb-5">
                                <DataItem
                                  label={props.t("Order code")}
                                  value={orderDetails.orderCode}
                                />

                                <DataItem
                                  label={props.t("Payment method")}
                                  value={orderDetails.paymentMethod}
                                />

                                <DataItem
                                  label={props.t("Order type")}
                                  value={getOrderTypeLabel(
                                    orderDetails.orderType
                                  )}
                                />

                                <DataItem
                                  label={props.t("Payment status")}
                                  value={getPaymentStatusLabel(
                                    orderDetails.paymentStatus
                                  )}
                                />

                                <DataItem
                                  label={props.t("Order status")}
                                  value={getOrderStatusLabel(
                                    orderDetails.status
                                  )}
                                />
                              </div>

                              {/* CUSTOMER DETAILS */}
                              <BodyTextBold className="mb-2 text-muted">
                                Customer details
                              </BodyTextBold>
                              <div className="detail-grid">
                                <DataItem
                                  label={props.t("Full name")}
                                  value={suborderDetails.customerName}
                                />

                                <DataItem
                                  label={props.t("Phone number")}
                                  value={suborderDetails.customer.phone}
                                />

                                <DataItem
                                  label={props.t("Email address")}
                                  value={suborderDetails.customer.email}
                                />

                                <DataItem
                                  label={props.t("Location")}
                                  value={suborderDetails.customer.location}
                                />
                              </div>
                            </div>

                            {/* PRODUCT IMAGE */}
                            <div className="mb-3 tx-avatar">
                              <div className="container-box">
                                <Avatar
                                  size={SIZE.XXL}
                                  initials={suborderDetails.name}
                                  image={suborderDetails.image?.file}
                                  className="avatar-size"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="d-flex justify-content-center align-items-center">
                      {/* ERROR STATE */}
                      <EmptyErrorState
                        visible={error}
                        title={`Something went wrong`}
                        description={`An error has occurred. Please try again later or contact hevara for support`}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

OrderDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(OrderDetails)

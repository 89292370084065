import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { first, isEmpty } from "lodash"
import { Col, Row, Card, CardBody, Container, Form, Input, FormFeedback, Label } from "reactstrap"
import { withTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { connect } from "react-redux";
import { compose } from "redux"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import Auth from "models/auth"
import ProductListModal from "models/productList"
import ProductsView from "models/productsView"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import { formatName, formatVolume } from "utils/utils"
import EmptyState, { EmptyErrorState } from "components/EmptyState"
import { BigTextBold } from "components/atoms"
import FeedbackNotification, { FEEDBACK_VARIANTS } from "components/FeedbackNotification"
import { Selector } from "components/Forms/Selector"
import ProductModalList from "./productListModal"
import { changeSidebarType } from "store/actions"
import { leftSidebarTypes } from "constants/layout"
import { PrimaryButton, SecondaryButton } from "components/Button"
import Warehouse from "models/warehouses"
import Manufacturers from "models/manufacturers"
import Distributors from "models/distributors"
import Company from "models/company"
import ProductCatalogue from "models/productCatalogue"
import CONST from "utils/constants"

import "./productList.scss"

const { COMPANY_TYPE } = CONST

const formatSelectedProduct = (selectedProduct) => ({
  ...selectedProduct,
  avatarImage: selectedProduct?.image?.file,
  name: `${selectedProduct?.name} - (${formatVolume(selectedProduct.size)})`,
  subtext: formatName(selectedProduct?.categoryName),
  description: formatName(selectedProduct?.type)
})

const ProductList = (props) => {
  //meta title
  document.title = "Add catalogue | hevara DMS Platform"

  const navigate = useNavigate()

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const [productsList, setProductsList] = useState([])
  const [selectedProduct, setSelectedProduct] = useState({})
  const [productListModal, setProductListModal] = useState(false)
  const [catalogue, setCatalogue] = useState({})
  const [companyInfo, setCompanyInfo] = useState({})
  const [warehouses, setWarehouses] = useState([])
  const [manufacturers, setManufacturers] = useState([])
  const [distributors, setDistributors] = useState([])

  const fetchProductLists = () => {
    ProductListModal.lookUpProductList()
      .then(data => {
        setProductsList(data)
        setSelectedProduct(first(data))
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchCompanyWarehouses = id => {
    Warehouse.lookUpCompanyWarehouses(id)
      .then(data => {
        setWarehouses([
          {
            status: "Default",
            warehouseName: "Select warehouse",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanyInfo = id => {
    Company.getCompany(id)
      .then(data => {
        setCompanyInfo(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchManufacturers = () => {
    Manufacturers.lookUpManufacturers()
      .then(data => {
        setManufacturers([
          {
            status: "Default",
            companyName: "Select manufacturer",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchDistributors = () => {
    Distributors.lookUpDistributors()
      .then(data => {
        setDistributors([
          {
            status: "Default",
            companyName: "Select distributor",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      const companyId = JSON.parse(profile).companyId
      setUserProfile(JSON.parse(profile))
      Promise.all([
        fetchProductLists(),
        fetchCompanyInfo(companyId),
        fetchCompanyWarehouses(companyId),
        fetchManufacturers(),
        fetchDistributors()
      ])
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [])

  const getHeaderActions = () => {
    const addManualProduct = userProfile.permission.CAN_MANAGE_PRODUCTS && userProfile.permission.ADD_MANUAL_PRODUCT;

    const actions = [
      addManualProduct && {
        label: "Manual addition",
        icon: "mdi mdi-plus",
        onClick: () => onClickManualAddition(),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const closeSideTopBars = (type) => {
    props.changeSidebarType(type);
  }

  const onClickProductSelector = () => {
    closeSideTopBars(leftSidebarTypes.HIDDEN);
    setProductListModal(true)
  }

  const onClickManualAddition = () => {
    closeSideTopBars(leftSidebarTypes.HIDDEN);
    navigate(`/new-product-catalogue`);
  }

  const addProductCatalogue = (data) => {
    setProcessing(true)

    ProductCatalogue.createProductCatalogue(data)
      .then(res => {
        const name = res.catalogueProduct.name
        toast.success(`${name} created successfully.`)
        navigate(`/product-catalogue`);
      })
      .catch(error => {
        const errorMessage = error.response?.data?.message || "An error occurred during product catalogue creation."
        toast.error(errorMessage)
      })
      .finally(() => setProcessing(false))
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: selectedProduct.name,
      size: selectedProduct.size,
      description: selectedProduct.description,
      category: selectedProduct.category,
      categoryType: selectedProduct.type,
      image: selectedProduct.image,
      stockQuantity: (catalogue && catalogue.stockQuantity) || "",
      replenishingLevel: (catalogue && catalogue.replenishingLevel) || "",
      onRequest: (catalogue && catalogue.onRequest) || "",
      ...(companyInfo.type === COMPANY_TYPE.DISTRIBUTOR
        ? { secondaryPrice: (catalogue && catalogue.secondaryPrice) || "" }
        : {}),
      ...(companyInfo.type === COMPANY_TYPE.DISTRIBUTOR
        ? { secondaryDiscount: (catalogue && catalogue.secondaryDiscount) || 0 }
        : {}),
      ...(companyInfo.type === COMPANY_TYPE.WHOLESALLER
        ? { retailPrice: (catalogue && catalogue.retailPrice) || "" }
        : {}),
      ...(companyInfo.type === COMPANY_TYPE.WHOLESALLER
        ? { retailDiscount: (catalogue && catalogue.retailDiscount) || 0 }
        : {}),
      ...(companyInfo.type === COMPANY_TYPE.WHOLESALLER
        ? { distributorId: (catalogue && catalogue.distributorId) || "" }
        : {}),
      warehouseId: (catalogue && catalogue.warehouseId) || "",
      manufacturerId: (catalogue && catalogue.manufacturerId) || "",
      ...(companyInfo.type === COMPANY_TYPE.DISTRIBUTOR && { distributorId: companyInfo.id }),
      ...(companyInfo.type === COMPANY_TYPE.WHOLESALLER && { wholesalerId: companyInfo.id }),
      companyId: companyInfo.id,
    },
    validationSchema: Yup.object({
      stockQuantity: Yup.string().required("This field required"),
      replenishingLevel: Yup.string().required("This field required"),
      ...(companyInfo.type === COMPANY_TYPE.DISTRIBUTOR
        ? { secondaryPrice: Yup.string().required("This field required") }
        : {}),
      ...(companyInfo.type === COMPANY_TYPE.WHOLESALLER
        ? { retailPrice: Yup.string().required("This field required") }
        : {}),
      ...(companyInfo.type === COMPANY_TYPE.WHOLESALLER
        ? { distributorId: Yup.string().required("This field required") }
        : {}),
      warehouseId: Yup.string().required("This field required"),
      manufacturerId: Yup.string().required("This field required"),
    }),
    onSubmit: values => {
      if (catalogue.id) {
        // update
        // Future development
      } else {
        // add new
        addProductCatalogue(values)
      }

      validation.resetForm()
    },
  })

  return (
    <React.Fragment>
      <div className="page-content hevara-new-product-catalogue">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Add catalogue")}
            breadcrumbItem={props.t("Add catalogue")}
          />

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Add catalogue"}
            description={"We are processing your request, please wait..."}
          />

          {/* PRODUCT LIST MODAL */}
          <ProductModalList 
            modalIsOpen={productListModal}
            onClickClose={() => {
              closeSideTopBars(leftSidebarTypes.DEFAULT)
              setProductListModal(false)
            }}
            title={`Select catalogue product`}
            onClickConfirm={(item) => {
              setSelectedProduct(item)
              closeSideTopBars(leftSidebarTypes.DEFAULT)
              setProductListModal(false)
            }}
            data={productsList}
            selectedProduct={selectedProduct}
          />

          {!loading && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Add product catalogue"}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        !isEmpty(productsList) && (
                          <PageHeaderInfo>
                            <PageHeaderInfoText className="mt-4">
                              Create your product catalogue from the master list of products provided
                            </PageHeaderInfoText>
                          </PageHeaderInfo>
                        )
                      }
                    />

                    {/* PRODUCT SELECTION */}
                    <div className="catalogue-group flex-wrap column-lt-lg mb-4">
                      <BigTextBold>Select product</BigTextBold>
                      {isEmpty(productsList) 
                        ? <FeedbackNotification 
                            message={`There are no available products to add to your catalogue.`}
                            variant={FEEDBACK_VARIANTS.WARNING}
                            className="mt-3"
                          />
                        : <Selector 
                            label=""
                            className="w-100 mt-3"
                            data={productsList}
                            value={formatSelectedProduct(selectedProduct)}
                            onClick={() => onClickProductSelector()}
                            disabled={processing || isEmpty(productsList) || productsList.length === 1}
                        />
                      }
                    </div>

                    {/* OTHER PRODUCT DETAILS */}
                    {!isEmpty(productsList) && 
                      <div className="catalogue-group flex-wrap column-lt-lg mb-5">
                        <BigTextBold>Product information</BigTextBold>
                        <div className="flex">
                          <Form
                            onSubmit={e => {
                              e.preventDefault()
                              validation.handleSubmit()
                              return false
                            }}
                            className="mt-4"
                          >
                            <Row>
                              <Col sm="6">
                                {/* IF ITS A DISTRIBUTOR: SHOW SECONDARY PRICE & SECONDARY DISCOUNT */}
                                {companyInfo.type === COMPANY_TYPE.DISTRIBUTOR && (
                                  <>
                                    {/* PRODUCT SECONDARY PRICE */}
                                    <div className="mb-3">
                                      <Label className="form-label">Product price</Label>
                                      <Input
                                        name="secondaryPrice"
                                        className="form-control"
                                        placeholder="Product price"
                                        type="number"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.secondaryPrice || ""}
                                        invalid={validation.touched.secondaryPrice && validation.errors.secondaryPrice ? true : false }
                                      />
                                      {validation.touched.secondaryPrice && validation.errors.secondaryPrice && (
                                        <FormFeedback type="invalid">
                                          {validation.errors.secondaryPrice}
                                        </FormFeedback>
                                      )}
                                    </div>

                                    {/* PRODUCT SECONDARY DISCOUNT */}
                                    <div className="mb-3">
                                      <Label className="form-label">Discount % (Optional)</Label>
                                      <Input
                                        name="secondaryDiscount"
                                        className="form-control"
                                        placeholder="Discount %"
                                        type="number"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.secondaryDiscount || ""}
                                        invalid={validation.touched.secondaryDiscount && validation.errors.secondaryDiscount ? true : false }
                                      />
                                      {validation.touched.secondaryDiscount && validation.errors.secondaryDiscount && (
                                        <FormFeedback type="invalid">
                                          {validation.errors.secondaryDiscount}
                                        </FormFeedback>
                                      )}
                                    </div>
                                  </>
                                )}

                                {/* IF ITS A WHOLESALER: SHOW RETAIL PRICE & RETAIL DISCOUNT */}
                                {companyInfo.type === COMPANY_TYPE.WHOLESALLER && (
                                  <>
                                    {/* PRODUCT RETAIL PRICE */}
                                    <div className="mb-3">
                                      <Label className="form-label">Product price</Label>
                                      <Input
                                        name="retailPrice"
                                        className="form-control"
                                        placeholder="Product price"
                                        type="number"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.retailPrice || ""}
                                        invalid={validation.touched.retailPrice && validation.errors.retailPrice ? true : false}
                                      />
                                      {validation.touched.retailPrice && validation.errors.retailPrice && (
                                        <FormFeedback type="invalid">
                                          {validation.errors.retailPrice}
                                        </FormFeedback>
                                      )}
                                    </div>

                                    {/* PRODUCT RETAIL DISCOUNT */}
                                    <div className="mb-3">
                                      <Label className="form-label">Discount % (Optional)</Label>
                                      <Input
                                        name="retailDiscount"
                                        className="form-control"
                                        placeholder="Discount %"
                                        type="number"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.retailDiscount || ""}
                                        invalid={validation.touched.retailDiscount && validation.errors.retailDiscount ? true : false}
                                      />
                                      {validation.touched.retailDiscount && validation.errors.retailDiscount && (
                                        <FormFeedback type="invalid">
                                          {validation.errors.retailDiscount}
                                        </FormFeedback>
                                      )}
                                    </div>
                                  </>
                                )}

                                {/* PRODUCT STOCK QUANTITY */}
                                <div className="mb-3">
                                  <Label className="form-label">Stock quantity</Label>
                                  <Input
                                    name="stockQuantity"
                                    className="form-control"
                                    placeholder="Stock quantity"
                                    type="number"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.stockQuantity || ""}
                                    invalid={validation.touched.stockQuantity && validation.errors.stockQuantity ? true : false}
                                  />
                                  {validation.touched.stockQuantity && validation.errors.stockQuantity && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.stockQuantity}
                                    </FormFeedback>
                                  )}
                                </div>

                                {/* PRODUCT REPLENISH LEVEL */}
                                <div className="mb-3">
                                  <Label className="form-label">Replenish level</Label>
                                  <Input
                                    name="replenishingLevel"
                                    className="form-control"
                                    placeholder="Replenish level"
                                    type="number"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.replenishingLevel || ""}
                                    invalid={validation.touched.replenishingLevel && validation.errors.replenishingLevel ? true : false}
                                  />
                                  {validation.touched.replenishingLevel && validation.errors.replenishingLevel && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.replenishingLevel}
                                    </FormFeedback>
                                  )}
                                </div>
                              </Col>

                              <Col sm="6">
                                {/* PRODUCT WAREHOUSE */}
                                <div className="mb-3">
                                  <Label className="form-label">Warehouse</Label>
                                  <Input
                                    name="warehouseId"
                                    type="select"
                                    placeholder="Select warehouse"
                                    defaultValue={"Select warehouse"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.warehouseId || ""}
                                    invalid={validation.touched.warehouseId && validation.errors.warehouseId ? true : false}
                                  >
                                    {warehouses.map(warehouse => (
                                      <option key={warehouse.id} value={warehouse.id}>
                                        {warehouse.warehouseName}
                                      </option>
                                    ))}
                                  </Input>
                                  {validation.touched.warehouseId && validation.errors.warehouseId && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.warehouseId}
                                    </FormFeedback>
                                  )}
                                </div>

                                {/* PRODUCT MANUFACTURER */}
                                <div className="mb-3">
                                  <Label className="form-label">Product manufacturer</Label>
                                  <Input
                                    name="manufacturerId"
                                    type="select"
                                    placeholder="Select manufacturer"
                                    defaultValue={"Select manufacturer"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.manufacturerId || ""}
                                    invalid={validation.touched.manufacturerId && validation.errors.manufacturerId ? true : false}
                                  >
                                    {manufacturers.map(manufacturer => (
                                      <option key={manufacturer.id} value={manufacturer.id}>
                                        {manufacturer.companyName}
                                      </option>
                                    ))}
                                  </Input>
                                  {validation.touched.manufacturerId && validation.errors.manufacturerId && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.manufacturerId}
                                    </FormFeedback>
                                  )}
                                </div>

                                {/* PRODUCT DISTRIBUTOR */}
                                {companyInfo.type === COMPANY_TYPE.WHOLESALLER && (
                                  <div className="mb-3">
                                    <Label className="form-label">Product distributor</Label>
                                    <Input
                                      name="distributorId"
                                      type="select"
                                      placeholder="Select distributor"
                                      defaultValue={"Select distributor"}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.distributorId || ""}
                                      invalid={validation.touched.distributorId && validation.errors.distributorId ? true : false}
                                    >
                                      {distributors.map(distributor => (
                                        <option key={distributor.id} value={distributor.id}>
                                          {distributor.companyName}
                                        </option>
                                      ))}
                                    </Input>
                                    {validation.touched.distributorId && validation.errors.distributorId && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.distributorId}
                                      </FormFeedback>
                                    )}
                                  </div>
                                )}

                                {/* PRODUCT ON-REQUEST (OPTIONAL) */}
                                <div className="mb-3">
                                  <Label className="form-label">On-request (Optional)</Label>
                                  <Input
                                    name="onRequest"
                                    type="select"
                                    placeholder="Select option"
                                    defaultValue={"Select option"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.onRequest || ""}
                                    invalid={validation.touched.onRequest && validation.errors.onRequest ? true : false}
                                  >
                                    <option value="">Select option</option>
                                    <option value="ON">Yes</option>
                                    <option value="OFF">No</option>
                                  </Input>
                                  {validation.touched.onRequest && validation.errors.onRequest && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.onRequest}
                                    </FormFeedback>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            
                            <Row>
                              <Col>
                                <div className="hstack gap-2 justify-content-center mb-0 mt-5">
                                  <PrimaryButton type="submit" className="me-4">
                                    Confirm catalogue
                                  </PrimaryButton>

                                  <SecondaryButton type="button" onClick={() => navigate(`/product-catalogue`)}>
                                    Cancel
                                  </SecondaryButton>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </div>
                    }

                    <div className="d-flex justify-content-center align-items-center">
                      {/* EMPTY STATE */}
                      <EmptyState
                        visible={isEmpty(productsList)}
                        title={`No products`}
                        description={`There are no products to add to your catalogue at the moment.`}
                        firstButton={
                          userProfile.permission.CAN_MANAGE_PRODUCTS && {
                            label: `Manual addition`,
                            onClick: onClickManualAddition,
                            disabled: processing,
                          }
                        }
                      />

                      {/* ERROR STATE */}
                      <EmptyErrorState
                        visible={error}
                        title={`Something went wrong`}
                        description={`An error has occuRred. Please try again later or contact hevara for support`}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

ProductList.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = state => {
  return { ...state.Layout };
};

const mapDispatchToProps = {
  changeSidebarType,
  // Any other actions to map here
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ProductList);
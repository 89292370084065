import React from "react"
import { map } from "lodash"
import { Link } from "react-router-dom"
import styled from "styled-components"

import TableRow, { MainInfoList, TableRowAmount } from "components/TableRow"
import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"
import { getWalletStatus } from "utils/utils"
import CONST from "utils/constants"
import Tooltip, { TOOLTIP_POSITIONS, TooltipText } from "components/Tooltip"
import ItemListChip from "../itemListChip"
import { CHIP_VARIANTS } from "components/Chip"

import "./walletListItem.scss"

const { WALLET_STATUS } = CONST;

const getAccountNumber = (accountNumber) => {
  return accountNumber?.slice(accountNumber.length - 4)
}

const WalletStatus = ({ wallet, position = TOOLTIP_POSITIONS.RIGHT }) => {
  const walletStatus = getWalletStatus(wallet?.status);
  const isPendingWallet = wallet?.status === WALLET_STATUS.PENDING;
  const isCancelledWallet = wallet?.status === WALLET_STATUS.CANCELLED;

  return (
    <div className="wallet-chip-layout">
      {/* WALLET STATUS */}
      {(isPendingWallet || isCancelledWallet) &&
        <div className="hevara-wallet-chip">
          <ItemListChip variant={walletStatus.variant}>{walletStatus.label}</ItemListChip>
        </div>
      }

      {/* MAIN WALLET: USED FOR CHARGING BILLING ITEMS THAT ARE NOT SPECIFIC OR SET */}
      {!!wallet.main &&
        <div className="hevara-account-chip">
          <Tooltip
            position={position}
            title={`Main wallet for ${wallet.currency}`}
            content={<TooltipText>This wallet cannot be cancelled.</TooltipText>}
          >
            <ItemListChip variant={CHIP_VARIANTS.INFO}>
              MAIN
            </ItemListChip>
          </Tooltip>
        </div>
      }
    </div>
  )
}

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const WalletListItem = ({ wallet, actions, showDivider }) => {
  const url = `/wallet-details/${wallet.id}`;
  const isPendingWallet = wallet.status === WALLET_STATUS.PENDING;
  const isCancelledWallet = wallet.status === WALLET_STATUS.CANCELLED;
  const showStatus = isPendingWallet || isCancelledWallet || !!wallet.main;

  return (
    <Link
      key={wallet.id}
      to={url}
      className="reset-link text-tertiary"
    >
      <TableRow className="hevara-wallet-grid-list-item hevara-wallet-list-item" showDivider={showDivider}>
        {/* ACCOUNT MAIN DATA */}
        <MainInfoList
          title={wallet.wallet_alias}
          avatar={{ image: wallet.assetFlag, initials: wallet.wallet_alias }}
          subtitle={`${wallet.currency} • ${getAccountNumber(wallet.account_number)}`}
          className="text-truncate"
        />

        {/* ACCOUNT STATUS DESKTOP */}
        <StyledRow className="status-desktop d-none d-xl-flex">
          <WalletStatus wallet={wallet} />
        </StyledRow>

        <StyledRow className={`balance ${showStatus ? 'hash_chip' : ''}`}>
          {/* WALLET BALANCES */}
          <TableRowAmount
            amount={wallet.available_balance}
            amountCurrency={wallet.currency}
            subamount={wallet.current_balance}
            subamountCurrency={wallet.currency}
          />

          {/* WALLET ACTIONS */}
          <ContextualModal
            style={{ marginLeft: 4 }}
            className="d-none d-xl-block"
            position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
            contentElement={map(actions, (modalItem, index) => (
              <ContextualModalListItem
                key={index}
                {...modalItem}
                onClick={e => modalItem.onClick(e, wallet)}
              />
            ))}
          />

          {/* WALLET STATUS MOBILE/TABLE */}
          <StyledRow className="status-mobile">
            <WalletStatus wallet={wallet} position={TOOLTIP_POSITIONS.LEFT}  />
          </StyledRow>
        </StyledRow>
      </TableRow>
    </Link>
  )
}

WalletListItem.defaultProps = {
  user: {},
  actions: [],
}

export default WalletListItem

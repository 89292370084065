import React, { useCallback } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import {
  getAvatarIconSizes,
  getAvatarDefaultStyles,
  getAvatarRadius,
  getAvatarSize,
  getAvatarTextSizes,
} from "./Avatar.styles.js"
import Text from "components/atoms/Text/BasicText.js"
import CONST from "utils/constants"
import { getInitials } from "utils/utils"

const { SIZE } = CONST

const StyledAvatar = styled.div.attrs({
  className: "hevara-avatar",
})`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  ${getAvatarDefaultStyles};
  ${getAvatarRadius};
  ${getAvatarSize};
`

const StyledImage = styled.img.attrs({
  className: "hevara-avatar-image",
})`
  object-fit: contain;
  width: 100%;
  ${getAvatarSize}
`

const StyledText = styled(Text).attrs({
  className: "hevara-avatar-text",
})`
  ${getAvatarTextSizes}
`

const Avatar = ({ initials, size, image, icon, ...props }) => {
  const getIconSize = useCallback(() => getAvatarIconSizes(size), [size])

  return (
    <StyledAvatar {...props} image={image} icon={icon} size={size}>
      {!!image ? <StyledImage src={image} size={size} /> : !!icon ? (
        <i className={icon + " hevara-avatar-icon font-size-" + getIconSize()}></i>
      ) : (!!initials && <StyledText size={size}>{getInitials(initials)}</StyledText>

      )}
    </StyledAvatar>
  )
}

Avatar.propTypes = {
  size: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  image: PropTypes.string,
  initials: PropTypes.string,
  icon: PropTypes.string,
}

Avatar.defaultProps = {
  size: SIZE.M, // Set the default value to 'M' or any other default size you prefer
  icon: undefined,
}

export default Avatar

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { chain, isEmpty, omit, orderBy } from "lodash"
import { withTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { Col, Row, Card, CardBody, Container } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import Auth from "models/auth"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"
import EmptyState, { EmptyErrorState } from "components/EmptyState"
import CustomerListItem from "components/itemList/customerListItem/customerListItem"
import CustomerModal from "./customerModal"
import Customer from "models/customer"

const Customers = props => {
  //meta title
  document.title = "Customers | hevara DMS Platform"

  const navigate = useNavigate()

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [userProfile, setUserProfile] = useState({})

  const [customers, setCustomers] = useState([])
  const [customer, setCustomer] = useState({})

  const fetchCustomers = (limit, page, id) => {
    const filters = {
      companyId: id,
    }
    Customer.getCustomers(limit, page, filters)
      .then(data => {
        setCustomers(data.results)
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      const companyId = JSON.parse(profile).companyId

      fetchCustomers(limit, page, companyId)
      setUserProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  const addCustomer = values => {
    setProcessing(true)

    Customer.addCusromer(values)
      .then(customer => {
        setCustomers([...customers, customer])
        toast.success(`${customer.firstName} added successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const updateCustomer = (customerId, values) => {
    setProcessing(true)

    Customer.updateCustomer(customerId, values)
      .then(customer => {
        setCustomers(
          customers.map(item =>
            item.id.toString() === customer.id.toString()
              ? { item, ...customer }
              : item
          )
        )
        toast.success(`${customer.firstName} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disableCustomer = data => {
    setProcessing(true)

    const payload = {
      ...omit(data, ["id", "dateCreated"]),
      status: "Disabled",
    }

    Customer.updateCustomer(data.id, payload)
      .then(customer => {
        setCustomers(
          customers.map(item =>
            item.id.toString() === customer.id.toString()
              ? { ...customer }
              : item
          )
        )
        toast.success(`${customer.firstName} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableCustomer = data => {
    setProcessing(true)

    const payload = {
      ...omit(data, ["id", "dateCreated"]),
      status: "Active",
    }

    Customer.updateCustomer(data.id, payload)
      .then(customer => {
        setCustomers(
          customers.map(item =>
            item.id.toString() === customer.id.toString()
              ? { ...customer }
              : item
          )
        )
        toast.success(`${customer.firstName} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const handleClick = customerData => {
    setCustomer(customerData)
    setIsEdit(true)
    toggle()
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setCustomer(null)
    } else {
      setModal(true)
    }
  }

  const getListActions = item => {
    const isActive = item.status === "Active"

    const actions = [
      userProfile.permission.CAN_MANAGE_USERS && {
        label: "View details",
        icon: "mdi mdi-monitor-eye",
        onClick: () => navigate(`/customer-details/${item.id}`),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userProfile.permission.CAN_MANAGE_USERS && {
        label: "Edit",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => handleClick(item),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userProfile.permission.CAN_MANAGE_USERS && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () =>
          isActive ? disableCustomer(item) : enableCustomer(item),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userProfile.permission.CAN_MANAGE_USERS && {
        label: "New customer",
        icon: "mdi mdi-plus",
        onClick: () => {
          setIsEdit(false)
          setModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const onClickNewWarehouse = () => {
    setIsEdit(false)
    setModal(true)
  }

  const filteredCustomers = chain(customers)
    .filter(customer => {
      // Name
      const hasName = customer.firstName
        ?.toLowerCase()
        .includes(name.toLowerCase())

      return hasName
    })
    .value()

  const data = orderBy(
    filteredCustomers,
    [p => p.firstName.toUpperCase()],
    "asc"
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Customers")}
            breadcrumbItem={props.t("Customers")}
          />

          {/* CUSTOMER MODAL */}
          <CustomerModal
            modalIsOpen={modal}
            onClickClose={toggle}
            customer={customer}
            isEdit={isEdit}
            updateCustomer={updateCustomer}
            addCustomer={addCustomer}
            companyId={userProfile.companyId}
            toggle={toggle}
          />

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Customers"}
            description={"We are processing your request, please wait..."}
          />

          {!loading && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Customers"}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        !isEmpty(customers) && (
                          <PageHeaderInfo>
                            <PageHeaderInfoText className="fw-semibold">
                              {`${customers.length} ${
                                customers.length > 1 ? "customers" : "customer"
                              }`}
                            </PageHeaderInfoText>
                          </PageHeaderInfo>
                        )
                      }
                    />

                    {/* LIST */}
                    {!isEmpty(customers) && (
                      <SectionList
                        key={"all"}
                        data={groupListByKey(data, "firstName", true)}
                        renderItem={item => (
                          <CustomerListItem
                            item={item}
                            actions={getListActions(item)}
                          />
                        )}
                      />
                    )}

                    <div className="d-flex justify-content-center align-items-center">
                      {/* EMPTY STATE */}
                      <EmptyState
                        visible={isEmpty(customers)}
                        title={`No customers`}
                        description={`Click on the button below to create.`}
                        firstButton={
                          userProfile.permission.CAN_MANAGE_USERS && {
                            label: `New customer`,
                            onClick: onClickNewWarehouse,
                            disabled: processing,
                          }
                        }
                      />

                      {/* ERROR STATE */}
                      <EmptyErrorState
                        visible={error}
                        title={`Something went wrong`}
                        description={`An error has occured. Please try again later or contact hevara for support`}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

Customers.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Customers)

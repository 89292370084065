export const KEYBOARD_KEY = {
  ENTER: 'Enter',
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  LEFT: 'ArrowLeft',
  RIGHT: 'ArrowRight',
  BACK: 'Backspace',
}

export const COLOR_VARIANT = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  QUATERNARY: 'quaternary',
}

export const SIZE = {
  XXL: 'xxl',
  XL: 'xl',
  L: 'l',
  M: 'm',
  S: 's',
  XS: 'xs'
}

export const WEIGHT = {
  BOLD: 'bold',
  SEMI_BOLD: 'semi-bold',
  REGULAR: 'regular',
  ITALIC: 'italic',
  MEDIUM: 'medium',
  LIGHT: 'light',
}

export const VARIANT = {
  HEADING: 'heading',
  PRE_HEADING: 'pre-heading',
  TEXT: 'text',
  CAPTION: 'caption',
  AMOUNT: 'amount',
}

export default { 
  SIZE, 
  COLOR_VARIANT, 
  FONT: { WEIGHT, VARIANT },
  MEDIA: {
    MOBILE: '@media (max-width: 574px)',
    TABLET: '@media (max-width: 768px)',
    DESKTOP_S: '@media (max-width: 1200px)',
    DESKTOP_M: '@media (max-width: 1440px)',
    DESKTOP_L: '@media (max-width: 1600px)',
  },
}
import React from "react"
import { map } from "lodash"
import { Link } from "react-router-dom"

import TableRow, { MainInfoList, TableRowAmount, TableRowInfo } from "components/TableRow"
import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"
import { getTransactionStatus } from "utils/utils"
import CONST from "utils/constants"
import ItemListChip from "../itemListChip"

import "./transactionListItem.scss"

const { TRANSACTION_STATUS } = CONST;

const getAccountNumber = (accountNumber) => {
  return `***${accountNumber?.slice(accountNumber.length - 4)}`
}

const TransactionListItem = ({ transaction, actions, walletId, hideRowInfo }) => {
  const url = `/transaction-details/${transaction.id}`;
  const transactionStatus = getTransactionStatus(transaction.status);
  const creditWalletAlias = transaction.credit_wallet.wallet_alias;
  const debitWalletAlias = transaction.debit_wallet.wallet_alias;
  const handlePositive = transaction.credit_walletId === walletId;
  const chipClassName = transaction.status === TRANSACTION_STATUS.SUCCESS ? 'hide-element' : '';
  const rowInfoClassName = hideRowInfo ? 'hide-element' : '';

  return (
    <TableRow>
      <Link key={transaction.id} to={url} className="hevara-transaction-list-item reset-link text-tertiary">
        {/* MAIN INFO */}
        <MainInfoList
          title={transaction.type}
          subtitle={transaction.narration}
          className="text-truncate"
        />

        {/* INFO */}
        <TableRowInfo
          text={handlePositive ? creditWalletAlias : debitWalletAlias}
          subtext={handlePositive ? `From: ${getAccountNumber(transaction.debit_wallet.account_number)}` : `To: ${getAccountNumber(transaction.credit_wallet.account_number)} `}
          className={`text-truncate ${rowInfoClassName}`}
        />

        <div className="status">
          <div className="status-list">
            <div className="status-chip">
              <ItemListChip className={chipClassName} variant={transactionStatus.variant}>{transactionStatus.label}</ItemListChip>
            </div>
          </div>

          <div className="actions-wrapper">
            {/* AMOUNT */}
            <TableRowAmount
              amount={transaction.amount}
              amountCurrency={transaction.amount_currency}
              isPositive={handlePositive}
              sign={handlePositive ? '' : '-'}
            />

            {/* WALLET ACTIONS */}
            <ContextualModal
              style={{ marginLeft: 4 }}
              className="transaction-actions" // d-none d-xl-block
              position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
              contentElement={map(actions, (modalItem, index) => (
                <ContextualModalListItem
                  key={index}
                  {...modalItem}
                  onClick={e => modalItem.onClick(e, transaction)}
                />
              ))}
            />
          </div>
        </div>
      </Link>
    </TableRow>
  )
}

TransactionListItem.defaultProps = {
  transaction: {},
  actions: [],
  handlePositive: false,
  hideRowInfo: false,
}

export default TransactionListItem

import React from "react";

import { spacing, SmallText } from "../atoms";

/**
 * Hint UI component
 */
const Hint = ({ hint }) => {
  if (!hint) return null

  return (
    <SmallText colorVariant="tertiary" style={spacing('mt', 1, true)}>
      {hint}
    </SmallText>
  )
}

Hint.defaultProps = {
  hint: ''
};

export default Hint
import React, { useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete"
import { Row, Col, Form, Input, FormFeedback, Label } from "reactstrap"

import { PrimaryButton, SecondaryButton } from "components/Button"
import EmptyState from "components/EmptyState"
import { BodyTextBold, VARIANT, BodyText } from "components/atoms"

import OnboardingImage from "assets/images/verification-img.png"
import "../onboarding.styles.scss"

const CompanyInformationForm = props => {
  const onContinue = values => {
    props.handleSubmit(values)
    props.goNextStep()
  }

  const companyInfoValidation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      companyName: (props.companyInfo && props.companyInfo.companyName) || "",
      companyEmail: (props.companyInfo && props.companyInfo.companyEmail) || "",
      country: (props.companyInfo && props.companyInfo.country) || "",
      location: null,
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required("Company name is required"),
      companyEmail: Yup.string().required("Company email address is required"),
      country: Yup.string().required("country is required"),
      location: Yup.object().nullable().required("Location is required"),
    }),
    onSubmit: values => {
      onContinue(values)
    },
  })

  const [address, setAddress] = useState("")

  const handleSelect = async selectedAddress => {
    const results = await geocodeByAddress(selectedAddress)
    const latLng = await getLatLng(results[0])
    const locationData = {
      lat: latLng.lat,
      lng: latLng.lng,
      address: selectedAddress,
    }
    setAddress(selectedAddress)
    companyInfoValidation.setFieldValue("location", locationData)
  }

  return (
    <div className="hevara-onboarding-user mt-4">
      <div>
        <BodyTextBold variant={VARIANT.HEADING}>Add your company information</BodyTextBold>
        <BodyText className="mt-4 mb-4">
          Provide information related to your company
        </BodyText>

        <div className="mt-2">
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              companyInfoValidation.handleSubmit()
              return false
            }}
          >
            {/* COMPANY NAME */}
            <div className="mb-3">
              <Label className="form-label">Company name</Label>
              <Input
                name="companyName"
                className="form-control"
                placeholder="Company name"
                type="text"
                onChange={companyInfoValidation.handleChange}
                onBlur={companyInfoValidation.handleBlur}
                value={companyInfoValidation.values.companyName || ""}
                invalid={companyInfoValidation.touched.companyName && companyInfoValidation.errors.companyName ? true : false}
              />
              {companyInfoValidation.touched.companyName && companyInfoValidation.errors.companyName && (
                <FormFeedback type="invalid">
                  {companyInfoValidation.errors.companyName}
                </FormFeedback>
              )}
            </div>

            {/* COMPANY EMAIL */}
            <div className="mb-3">
              <Label className="form-label">Company email address</Label>
              <Input
                name="companyEmail"
                className="form-control"
                placeholder="Company email address"
                type="email"
                onChange={companyInfoValidation.handleChange}
                onBlur={companyInfoValidation.handleBlur}
                value={companyInfoValidation.values.companyEmail || ""}
                invalid={companyInfoValidation.touched.companyEmail && companyInfoValidation.errors.companyEmail ? true : false}
              />
              {companyInfoValidation.touched.companyEmail && companyInfoValidation.errors.companyEmail && (
                <FormFeedback type="invalid">
                  {companyInfoValidation.errors.companyEmail}
                </FormFeedback>
              )}
            </div>

            {/* COMPANY COUNTRY */}
            <div className="mb-3">
              <Label className="form-label">Country</Label>
              <Input
                name="country"
                type="select"
                placeholder="Select country"
                defaultValue={"Select country"}
                onChange={companyInfoValidation.handleChange}
                onBlur={companyInfoValidation.handleBlur}
                value={companyInfoValidation.values.country || ""}
                invalid={companyInfoValidation.touched.country && companyInfoValidation.errors.country ? true : false}
              >
                {props.countries.map(country => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </Input>
              {companyInfoValidation.touched.country && companyInfoValidation.errors.country && (
                <FormFeedback type="invalid">
                  {companyInfoValidation.errors.country}
                </FormFeedback>
              )}
            </div>

            {/* COMPANY LOCATION -> GOOGLE MAPS DROPDOWN */}
            <div className="mt-3">
              <Label className="form-label">Location</Label>
              <PlacesAutocomplete
                value={address}
                onChange={value => setAddress(value)}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className="autocomplete-container">
                    <Input
                      {...getInputProps({
                        placeholder: "Type location",
                        className: `form-control location-input ${companyInfoValidation.touched.location && companyInfoValidation.errors.location ? "is-invalid" : "" }`,
                      })}
                      value={address}
                    />
                    <div className="invalid-feedback">
                      {companyInfoValidation.touched.location && companyInfoValidation.errors.location ? companyInfoValidation.errors.location : null}
                    </div>
                    <div className="suggestion-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion, index) => (
                        <div key={index} {...getSuggestionItemProps(suggestion)}>
                          {suggestion.description}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>

            <Row>
              <Col>
                <div className="hstack gap-2 mb-0 mt-4">
                  <PrimaryButton type="submit" className="me-4">
                    Continue
                  </PrimaryButton>

                  <SecondaryButton type="button" onClick={props.onClickCancel}>
                    Cancel
                  </SecondaryButton>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>

      <EmptyState
        visible={true}
        illustration={OnboardingImage}
        className="onboarding-user-ph-image"
        containerClassName="onboarding-user-ph"
      />
    </div>
  )
}

export default CompanyInformationForm

import React, { forwardRef } from "react";
import styled from "styled-components";

import CONST from "../atoms/constants";
import { getFontSizes, getFontWeight } from "../atoms/Text/BasicText";
import { getFormFieldStyles } from "../Forms/Commons.styles";
import InputContainer from "./InputContainer";

const { SIZE, FONT: { WEIGHT, VARIANT } } = CONST;

const StyledInput = styled.input.attrs({
  className: "hevara-form-input"
})`
  flex: 1;
  outline: none;
  border: 0px;
  padding: 0px;
  ${props => props.type === "date" && `height: 24px;`}
  ${getFormFieldStyles}
  ${getFontSizes({ size: SIZE.M, variant: VARIANT.TEXT })}
  ${getFontWeight({ weight: WEIGHT.REGULAR })}
`;

/**
 * Input UI component
 */
const Input = forwardRef((props, ref) => {
  const { 
    placeholder, value, onChange, error, disabled, type, max, min,
    leftIcon, rightIcon, suffix, onClickRightIcon, onClickLeftIcon, onClickIcon,
    role, errorMessageID, ariaInvalid, ariaLabel, id,
    ...rest
  } = props;

  return (
    <InputContainer
      error={error}
      disabled={disabled}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      suffix={suffix}
      onClickRightIcon={onClickRightIcon}
      onClickLeftIcon={onClickLeftIcon}
      onClickIcon={onClickIcon}
      className={props.className}
      style={props.style}
    >
      <StyledInput 
        {...rest}
        ref={ref}
        data-testid='form-input'
        value={value}
        error={error}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        max={max}
        min={min}
        onChange={(ev) => onChange(ev.target.value)}
        id={id}
        aria-disabled={disabled}
        aria-label={ariaLabel}
        aria-invalid={ariaInvalid}
        aria-errormessage={errorMessageID}
        role={role}
      />
    </InputContainer>
  )
});

Input.displayName = 'Input';

Input.defaultProps = {
  value: '',
  placeholder: '',
  error: '',
  disabled: false,
  type: 'text',
  min: '',
  max: '',
  leftIcon: undefined,
  rightIcon: undefined,
  suffix: undefined,
  onClickRightIcon: undefined,
  onClickLeftIcon: undefined,
  onClickIcon: undefined,
};

export default Input
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { TOOLTIP_POSITIONS, getTooltipStyles } from "./Tooltip.styles"

const StyledTooltip = styled.div.attrs({
  className: "hevara-tooltip",
})`
  ${getTooltipStyles}
`

const StyledWrapper = styled.div.attrs({
  className: "hevara-tooltip-wrapper",
})``

const StyledContent = styled.div.attrs({
  className: "hevara-tooltip-content",
})``

const StyledTitle = styled.p.attrs({
  className: "hevara-tooltip-title fw-bold",
})`
  margin: 0px;
`

const StyledText = styled.p.attrs({
  className: "hevara-tooltip-text",
})`
  margin: 0px;
`

const Tooltip = ({ position, ...props }) => {
  const [showTooltipContent, setShowTooltipContent] = useState(false)
  const [safePosition, setSafePosition] = useState(position)

  const wrapperRef = useRef(null)
  const contentRef = useRef(null)

  useEffect(() => {
    if (showTooltipContent) {
      getTooltipPosition()
    }
  }, [showTooltipContent, position])

  const getTooltipPosition = () => {
    const tooltipWrapper = wrapperRef?.current
    const tooltipContent = contentRef?.current
    if (tooltipWrapper && tooltipContent) {
      /** Tooltip content sizes */
      const contentHeight = tooltipContent.offsetHeight + 10 // tooltip content height + margin
      const contentWidth = tooltipContent.offsetWidth
      /** Tooltip wrapper sizes */
      const wrapperHeight = tooltipWrapper.offsetHeight
      const wrapperWidth = tooltipWrapper.offsetWidth
      const wrapperOffsetRight =
        window.innerWidth -
        (tooltipWrapper.offsetLeft + tooltipWrapper.offsetWidth)
      const wrapperOffsetBottom =
        window.innerHeight - tooltipWrapper.getClientRects()[0]?.bottom
      let placement = position
      // Check if it fits horizontally (left & right) when the position is whether top or bottom
      const contentWidthFitsHorizontally =
        tooltipWrapper.offsetLeft > (contentWidth - wrapperWidth) / 2 &&
        wrapperOffsetRight > (contentWidth - wrapperWidth) / 2
      // Check if it fits vertically (top & bottom) when the position is whether left or right
      const contentHeightFitsVertically =
        tooltipWrapper.offsetTop > (contentHeight - wrapperHeight) / 2 &&
        wrapperOffsetBottom > (contentHeight - wrapperHeight) / 2
      // Check if it fits on the top
      const fitTop =
        tooltipWrapper.getClientRects()[0]?.top > contentHeight &&
        contentWidthFitsHorizontally
      // Check if it fits on the bottom
      const fitBottom =
        wrapperOffsetBottom > contentHeight && contentWidthFitsHorizontally
      // Check if it fits on the right
      const fitRight =
        wrapperOffsetRight > contentWidth && contentHeightFitsVertically
      // Check if it fits on the left
      const fitLeft =
        tooltipWrapper.offsetLeft > contentWidth && contentHeightFitsVertically
      if (position === TOOLTIP_POSITIONS.TOP) {
        if (!fitTop && fitBottom) {
          placement = TOOLTIP_POSITIONS.BOTTOM
        } else if (!fitTop && (fitRight || fitLeft)) {
          placement = fitRight
            ? TOOLTIP_POSITIONS.RIGHT
            : TOOLTIP_POSITIONS.LEFT
        }
      } else if (position === TOOLTIP_POSITIONS.RIGHT) {
        if (!fitRight && fitLeft) {
          placement = TOOLTIP_POSITIONS.LEFT
        } else if (!fitRight && (fitTop || fitBottom)) {
          placement = fitTop ? TOOLTIP_POSITIONS.TOP : TOOLTIP_POSITIONS.BOTTOM
        }
      } else if (position === TOOLTIP_POSITIONS.LEFT) {
        if (!fitLeft && fitRight) {
          placement = TOOLTIP_POSITIONS.RIGHT
        } else if (!fitLeft && (fitTop || fitBottom)) {
          placement = fitTop ? TOOLTIP_POSITIONS.TOP : TOOLTIP_POSITIONS.BOTTOM
        }
      } else if (position === TOOLTIP_POSITIONS.BOTTOM) {
        if (!fitBottom && fitTop) {
          placement = TOOLTIP_POSITIONS.TOP
        } else if (!fitBottom && (fitRight || fitLeft)) {
          placement = fitRight
            ? TOOLTIP_POSITIONS.RIGHT
            : TOOLTIP_POSITIONS.LEFT
        }
      }
      setSafePosition(placement)
    }
  }

  return (
    <StyledTooltip
      onMouseEnter={() => setShowTooltipContent(true)}
      onMouseLeave={() => setShowTooltipContent(false)}
      position={safePosition}
      showTooltipContent={showTooltipContent}
    >
      <StyledWrapper ref={wrapperRef}>
        {props.children}
        <StyledContent ref={contentRef}>
          {props.title && <StyledTitle>{props.title}</StyledTitle>}
          {props.content}
        </StyledContent>
      </StyledWrapper>
    </StyledTooltip>
  )
}

Tooltip.defaultProps = {
  position: TOOLTIP_POSITIONS.TOP,
}

export { Tooltip, StyledText as TooltipText }

import React from "react"
import PropTypes from "prop-types"

import "./PageContainer.scss"

const PageContainer = ({ children, className }) => {
  return (
    <div className={`hevara-web-container ${className}`}>
      <div>{children}</div>
    </div>
  )
}

PageContainer.prototypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

PageContainer.defaultProps = {
  className: "",
}

export default PageContainer

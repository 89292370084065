import React from "react";

import { spacing } from "../atoms";
import { HelpNotification, HELP_VARIANTS } from "../HelpNotification";

/**
 * Error Message UI component
 */
const ErrorMessage = ({ error, errorMessageID }) => {
  return (
    <>
      {!!error && (
        <HelpNotification variant={HELP_VARIANTS.ERROR} style={spacing('mt', 1, true)} id={errorMessageID}>
          {error}
        </HelpNotification>
      )}
    </>
  )
}

ErrorMessage.defaultProps = {
  error: ''
};

export default ErrorMessage
import React from "react"
import styled from "styled-components"
import { isEmpty } from "lodash"

import { getContentWrapperStyles, getChipStyles } from "./DataPanel.styles"

const StyledContentElement = styled.div.attrs({
  className: "hevara-data-panel",
})`
  border: 2px solid #dadada;
  padding: 18px;
  border-radius: 16px;
  position: relative;
`

const StyledContentWrapper = styled.div.attrs({
  className: "hevara-data-panel",
})`
  ${getContentWrapperStyles}
`

const StyledChip = styled.div.attrs({
  className: "hevara-data-panel-chip",
})`
  ${getChipStyles}
`

export const DataPanel = ({ children, chip, className, style }) => {
  return (
    <StyledContentElement className={className} style={style}>
      {!isEmpty(chip) && <StyledChip>{chip}</StyledChip>}
      <StyledContentWrapper chip={!!chip}>{children}</StyledContentWrapper>
    </StyledContentElement>
  )
}

import CONST from "utils/constants"

const { MEDIA } = CONST
const BOX_BORDER = 2
const BOX_HEIGHT = 72 - BOX_BORDER * 2 // Remove to total height the top and bottom border

const getTheme = {
  color: {
    selection_box: {
      unselected: {
        font_primary: "#1E1E1E",
        font_secondary: "#696969",
        border: "#E7EDF1",
        background: "#FFFFFF",
        select_all_icon: "#F4CC0C",
        select_all_background: "#E7EDF1",
      },
      selected: {
        font_primary: "#1E1E1E",
        font_secondary: "#696969",
        border: "#F4CC0C",
        background: "#FFFFFF",
        icon: "#F4CC0C",
        select_all_icon: "#F4CC0C",
        select_all_background: "#E7EDF1",
      },
    },
  },
}

export const getSelectionBoxAvatarStyles = props => {
  const theme = getTheme
  const selection = props.selected ? "selected" : "unselected"

  return props.isAllSelected
    ? `
     .hevara-avatar {
       margin-right: 16px;
       background: ${theme.color.selection_box[selection].select_all_background};
     }
     .hevara-avatar-icon {
       color: ${theme.color.selection_box[selection].select_all_icon};
     }
   `
    : `
     .hevara-avatar {
       margin-right: 16px;
     }`
}

export const getSelectionBoxStyles = props => {
  const theme = getTheme
  const selection = props.selected ? "selected" : "unselected"

  return `
     flex-direction: row;
     align-items: center;
     display: flex;
     width: 100%;
     ${MEDIA.MOBILE} {
       width: auto;
     }
     height: ${BOX_HEIGHT}px;
     border: ${BOX_BORDER}px solid ${
    theme.color.selection_box[selection].border
  };
     background: ${theme.color.selection_box[selection].background};
     padding-top: 16px;
     padding-bottom: 16px;
     ${getSelectionBoxAvatarStyles(props)}
     .hevara-selection-title, .hevara-selection-sideData {
       color: ${theme.color.selection_box[selection].font_primary};
     }
     .hevara-selection-description, .hevara-selection-sideSubdata {
       color: ${theme.color.selection_box[selection].font_secondary};
     }
     .hevara-selection, .hevara-selection-title, .hevara-selection-description {
       flex: 1;
       white-space: nowrap;
       overflow: hidden;
       text-overflow: ellipsis;
     }
     .hevara-selection-sideData, .hevara-selection-sideSubdata {
       text-align: right;
     }
     .hevara-selection-selected-icon {
       margin-left: 16px;
       margin-right: 16px;
       color: ${theme.color.selection_box[selection].icon};
     }
     .hevara-selection-side-content {
       padding-left: 16px;
       margin-left: auto;
     }
   `
}

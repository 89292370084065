import React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Row, Col, Form, Input, FormFeedback, Label } from "reactstrap"

import { PrimaryButton, SecondaryButton } from "components/Button"
import EmptyState from "components/EmptyState"
import { BodyTextBold, VARIANT, BodyText } from "components/atoms"

import OnboardingImage from "assets/images/verification-img.png"
import "../onboarding.styles.scss"

const PersonalInformationForm = props => {
  const onContinue = values => {
    props.handleSubmit(values)
    props.goNextStep()
  }

  const personalInfoValidation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: (props.personalInfo && props.personalInfo.firstName) || "",
      middleName: (props.personalInfo && props.personalInfo.middleName) || "",
      lastName: (props.personalInfo && props.personalInfo.lastName) || "",
      email: (props.personalInfo && props.personalInfo.email) || "",
      mobileNumber: (props.personalInfo && props.personalInfo.mobileNumber) || "",
      country: (props.personalInfo && props.personalInfo.country) || "",
      companyRole: "COMPANY_ADMIN",
      role: "user",
      tnc: true,
      password: "hevara@12345",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string().email().required("Email is required"),
      mobileNumber: Yup.string().matches(/^\+254\d{9}$/, "Invalid phone number format, please use +2547....").required("Mobile number is required"),
      country: Yup.string().required("Country is required"),
    }),
    onSubmit: values => {
      onContinue(values)
    },
  })

  return (
    <div className="hevara-onboarding-user mt-4">
      <div>
        <BodyTextBold variant={VARIANT.HEADING}>Add your personal information</BodyTextBold>
        <BodyText className="mt-4">
          Information provided will be for the company system administrator
        </BodyText>

        <div className="mt-5">
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              personalInfoValidation.handleSubmit()
              return false
            }}
          >
            {/* FIRST NAME */}
            <div className="mb-3">
              <Label className="form-label">First name</Label>
              <Input
                name="firstName"
                className="form-control"
                placeholder="First name"
                type="text"
                onChange={personalInfoValidation.handleChange}
                onBlur={personalInfoValidation.handleBlur}
                value={personalInfoValidation.values.firstName || ""}
                invalid={personalInfoValidation.touched.firstName && personalInfoValidation.errors.firstName ? true : false}
              />
              {personalInfoValidation.touched.firstName && personalInfoValidation.errors.firstName && (
                <FormFeedback type="invalid">
                  {personalInfoValidation.errors.firstName}
                </FormFeedback>
              )}
            </div>

            {/* MIDDLE NAME */}
            <div className="mb-3">
              <Label className="form-label">Middle name (Optional)</Label>
              <Input
                name="middleName"
                className="form-control"
                placeholder="Middle name"
                type="text"
                onChange={personalInfoValidation.handleChange}
                onBlur={personalInfoValidation.handleBlur}
                value={personalInfoValidation.values.middleName || ""}
                invalid={personalInfoValidation.touched.middleName && personalInfoValidation.errors.middleName ? true : false}
              />
              {personalInfoValidation.touched.middleName && personalInfoValidation.errors.middleName && (
                <FormFeedback type="invalid">
                  {personalInfoValidation.errors.middleName}
                </FormFeedback>
              )}
            </div>

            {/* LAST NAME */}
            <div className="mb-3">
              <Label className="form-label">Last name</Label>
              <Input
                name="lastName"
                className="form-control"
                placeholder="Last name"
                type="text"
                onChange={personalInfoValidation.handleChange}
                onBlur={personalInfoValidation.handleBlur}
                value={personalInfoValidation.values.lastName || ""}
                invalid={personalInfoValidation.touched.lastName && personalInfoValidation.errors.lastName ? true : false}
              />
              {personalInfoValidation.touched.lastName && personalInfoValidation.errors.lastName && (
                <FormFeedback type="invalid">
                  {personalInfoValidation.errors.lastName}
                </FormFeedback>
              )}
            </div>

            {/* EMAIL */}
            <div className="mb-3">
              <Label className="form-label">Email address</Label>
              <Input
                name="email"
                className="form-control"
                placeholder="Email address"
                type="email"
                onChange={personalInfoValidation.handleChange}
                onBlur={personalInfoValidation.handleBlur}
                value={personalInfoValidation.values.email || ""}
                invalid={personalInfoValidation.touched.email && personalInfoValidation.errors.email ? true : false}
              />
              {personalInfoValidation.touched.email && personalInfoValidation.errors.email && (
                <FormFeedback type="invalid">
                  {personalInfoValidation.errors.email}
                </FormFeedback>
              )}
            </div>

            {/* MOBILE NUMBER*/}
            <div className="mb-3">
              <Label className="form-label">Mobile number</Label>
              <Input
                name="mobileNumber"
                type="tel"
                placeholder="Mobile number (+2547XXXXXXXX)"
                onChange={personalInfoValidation.handleChange}
                onBlur={personalInfoValidation.handleBlur}
                value={personalInfoValidation.values.mobileNumber || ""}
                invalid={personalInfoValidation.touched.mobileNumber && personalInfoValidation.errors.mobileNumber ? true : false}
              />
              {personalInfoValidation.touched.mobileNumber && personalInfoValidation.errors.mobileNumber && (
                <FormFeedback type="invalid">
                  {personalInfoValidation.errors.mobileNumber}
                </FormFeedback>
              )}
            </div>

            {/* COUNTRY */}
            <div className="mb-3">
              <Label className="form-label">Country of residence</Label>
              <Input
                name="country"
                type="select"
                placeholder="Select country"
                defaultValue={"Select country"}
                onChange={personalInfoValidation.handleChange}
                onBlur={personalInfoValidation.handleBlur}
                value={personalInfoValidation.values.country || ""}
                invalid={personalInfoValidation.touched.country && personalInfoValidation.errors.country ? true : false}
              >
                {props.countries.map(country => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </Input>
              {personalInfoValidation.touched.country && personalInfoValidation.errors.country && (
                <FormFeedback type="invalid">
                  {personalInfoValidation.errors.country}
                </FormFeedback>
              )}
            </div>

            <Row>
              <Col>
                <div className="hstack gap-2 mb-0 mt-4">
                  <PrimaryButton type="submit" className="me-4">
                    Continue
                  </PrimaryButton>

                  <SecondaryButton type="button" onClick={props.onClickCancel}>
                    Cancel
                  </SecondaryButton>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>

      <EmptyState
        visible={true}
        illustration={OnboardingImage}
        className="onboarding-user-ph-image"
        containerClassName="onboarding-user-ph"
      />
    </div>
  )
}

export default PersonalInformationForm

export const getTheme = {
  color: {
    navigation_row: {
      icon: "#5A6938",
      font: "#1E1E1E",
      arrow: "#F4CC0C",
      background: "#E7EDF1",
    },
  },
}

export const getNavigationRowStyles = props => {
  const theme = getTheme

  return `
    display: flex;
    flex-direction: row;
    align-items: center;
    opacity: ${props.disabled ? 0.4 : 1};
    cursor: ${props.disabled ? "default" : "pointer"};
    background: ${theme.color.navigation_row.background};
    border-radius: 12px;
    padding: 16px;

    .hevara-navigation-row-text-content {
      ${
        !!props.icon
          ? "margin-right: 16px; margin-left: 16px;"
          : "margin-right: 16px"
      }

      .hevara-navigation-row-title,
      .hevara-navigation-row-description {
        color: ${theme.color.navigation_row.font};
      }

      .hevara-navigation-row-left-content {
        display: flex;
        align-items: center;
      }

      .hevara-navigation-row-right-content {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
      }

      .hevara-chip {
        margin-right: 16px;
      }

      .hevara-icon {
        fill: ${theme.color.navigation_row.icon};
      }

      .hevara-navigation-row-arrow {
        color: ${theme.color.navigation_row.arrow};
      }
    }
  `
}

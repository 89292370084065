export const CATALOGUE_FORM = {
  CATALOGUE_CATEGORY: "CATALOGUE_CATEGORY",
  CATALOGUE_INFORMATION: "CATALOGUE_INFORMATION",
  CATALOGUE_CATEGORY_TYPE: "CATALOGUE_CATEGORY_TYPE",
  SUMMARY: "SUMMARY",
}

export const FORM_STEPS = [
  CATALOGUE_FORM.CATALOGUE_CATEGORY,
  CATALOGUE_FORM.CATALOGUE_INFORMATION,
  CATALOGUE_FORM.CATALOGUE_CATEGORY_TYPE,
  CATALOGUE_FORM.SUMMARY,
]

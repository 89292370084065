import React from "react"
import PropTypes from "prop-types"
import { Transition } from "react-transition-group"

const duration = 200

const defaultStyles = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const Fade = ({ in: inProp, children, className }) => (
  <Transition in={inProp} timeout={duration}>
    {state => (
      <div
        className={`${className}`}
        style={{ ...defaultStyles, ...transitionStyles[state] }}
      >
        {!!inProp && children}
      </div>
    )}
  </Transition>
)

Fade.prototypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  in: PropTypes.bool,
}

Fade.defaultProps = {
  className: "",
  in: false,
}

export default Fade

import React from "react"
import { isEmpty, map } from "lodash"
import { Link } from "react-router-dom"
import moment from "moment"

import TableRow, { MainInfoList, TableRowInfo } from "components/TableRow"
import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"
import { getLastFourDigits, getPaymentStatus } from "utils/utils"
import CONST from "utils/constants"

const { SHORT_DATE_FORMAT } = CONST

import "./billingTransactionItemList.scss"

const BillingTransactionItemList = ({ transaction, actions }) => {
  return (
    <TableRow>
      <Link
        key={transaction.id}
        to={`/billing-details/${transaction.id}`}
        className="hevara-transaction-list-item  reset-link text-tertiary"
      >
        {/* MAIN INFO */}
        <MainInfoList
          title={`${transaction.billing_plan} - ${moment(transaction.dateCreated).format(SHORT_DATE_FORMAT)}`}
          subtitle={transaction.invoice_number}
          className="text-truncate"
        />

        {/* INVOICE NUMBER */}
        <TableRowInfo
          className="text-truncate"
          text={`Ksh. ${transaction.billing_amount}`}
        />

        <div className="status">
          <div className="status-list">
            <TableRowInfo
              className="text-truncate"
              text={getLastFourDigits(transaction.phoneNumber)}
            />
          </div>

          <div className="actions-wrapper">
            {/* IS AVAILABLE */}
            {!isEmpty(transaction.paymentStatus) && getPaymentStatus(transaction.paymentStatus)}

            {/* ACTIONS */}
            <ContextualModal
              className="feature-actions"
              style={{ marginLeft: 4 }}
              position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
              contentElement={map(actions, (modalItem, index) => (
                <ContextualModalListItem
                  key={index}
                  {...modalItem}
                  onClick={e => modalItem.onClick(e, transaction)}
                />
              ))}
            />
          </div>
        </div>
      </Link>
    </TableRow>
  )
}

BillingTransactionItemList.defaultProps = {
  transaction: {},
  actions: [],
}

export default BillingTransactionItemList

import React from "react"

import EmptyState from "components/EmptyState"
import { getCompanyType } from "utils/utils"
import { BodyTextBold, VARIANT, BodyText } from "components/atoms"

import OnboardingImage from "assets/images/verification-img.png"
import "../onboarding.styles.scss"

const Success = props => {
  return (
    <div className="hevara-onboarding-user mt-4">
      <div>
        <BodyTextBold variant={VARIANT.HEADING}>
          Congratulations {props.registeredUser.firstName}!
        </BodyTextBold>

        <BodyTextBold className="mt-4">
          You have successfully registered as a <b>{getCompanyType(props.registeredCompany.type)}</b> with <b>{props.registeredCompany.companyName}</b>.
        </BodyTextBold>

        <BodyText className="mt-4">
          Please check your email inbox, we have sent an email to <b>{props.registeredUser.email}</b>.
        </BodyText>

        <BodyText className="mt-4">
          In case you do not receive any email, please check your spam folder or contact <b>technical@hevara.africa</b> for support.
        </BodyText>
      </div>

      <EmptyState
        visible={true}
        illustration={OnboardingImage}
        className="onboarding-user-ph-image"
        containerClassName="onboarding-user-ph"
      />
    </div>
  )
}

export default Success

import React from "react"
import { BodyText } from "./atoms"

const Footer = () => {
  return (
    <BodyText className="text-secondary">
      © {new Date().getFullYear()} hevara. Crafted with{" "}
      <i className="mdi mdi-heart text-danger" /> by{" "}
      <span className="text-dark">h</span>
      <span className="text-dark">e</span>
      <span className="text-tertiary">v</span>
      <span className="text-tertiary">a</span>
      <span className="text-secondary">r</span>
      <span className="text-primary">a</span>
    </BodyText>
  )
}

export default Footer

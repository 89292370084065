import { combineReducers } from "redux"
import { reduxForm as form } from "redux-form"

// Front
import Layout from "./layout/reducer"

const rootReducer = combineReducers({
  // public
  form,
  Layout,
})

export default rootReducer

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { isEmpty } from "lodash"

import { BodyText, BodyTextBold } from "components/atoms"
import { getFeedbackNotificationStyles, FEEDBACK_VARIANTS } from "./FeedbackNotification.styles"

export const getIconVariant = variant => {
  switch (variant) {
    case FEEDBACK_VARIANTS.INFO:
      return "mdi mdi-information-outline"
    case FEEDBACK_VARIANTS.WARNING:
      return "mdi mdi-alert-circle-outline"
    case FEEDBACK_VARIANTS.ERROR:
      return "mdi mdi-alert-circle-outline"
    case FEEDBACK_VARIANTS.SUCCESS:
      return "mdi mdi-check-circle-outline"
    default:
      return "mdi mdi-information-outline"
  }
}

const StyledIcon = styled.i.attrs({
  className: "hevara-feedback-notification-icon",
})``

const StyledIconClose = styled.div.attrs({
  className: "hevara-feedback-notification-icon-close",
})`
  display: flex;
  align-self: flex-start;
  cursor: pointer;
  margin-left: 16px;
`

const StyledTitle = styled(BodyTextBold).attrs({
  className: "hevara-feedback-notification-title",
})`
  margin-bottom: 1px;
`

const StyledMessage = styled(BodyText).attrs({
  className: "hevara-feedback-notification-message",
})``

const StyledFeedbackNotification = styled.div.attrs({
  className: "hevara-feedback-notification",
})`
  border: 2px solid #f6f6f6;
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 4px;
  ${getFeedbackNotificationStyles}
`

const StyledContentWrapper = styled.div.attrs({
  className: "hevara-feedback-notification-content-wrapper",
})`
  display: flex;
  flex: 1;
  margin-right: 32px;
`

const StyledBottomActions = styled.div.attrs({
  className: "hevara-feedback-notification-bottom-actions",
})`
  display: flex;
  margin-top: 16px;
  & > * {
    margin-right: 32px;
  }
`

const StyledRightSideButton = styled.div.attrs({
  className: "hevara-feedback-notification-right-side-button",
})`
  margin-right: 16px;
`
const StyledIconWrapper = styled.div.attrs({
  className: "hevara-feedback-notification-icon-wrapper",
})`
  display: flex;
  align-items: center;
  height: 24px;
`

const StyledCustomContent = styled.div.attrs({
  className: "hevara-feedback-custom-content",
})``

export const FeedbackNotificationText = ({ children }) => {
  return <StyledMessage>{children}</StyledMessage>
}

export const FeedbackNotification = ({
  variant,
  message,
  title,
  hasCloseIcon,
  rightActions,
  bottomActions,
  customContent,
  onClose,
  className,
  style,
}) => {
  const iconName = getIconVariant(variant)

  return (
    <StyledFeedbackNotification
      variant={variant}
      message={message}
      className={className}
      style={style}
    >
      <StyledContentWrapper>
        <StyledIconWrapper>
          <StyledIcon size="20">
            <i className={iconName + " font-size-20"} />
          </StyledIcon>
        </StyledIconWrapper>
        <div>
          {!!title && <StyledTitle>{title}</StyledTitle>}
          <StyledMessage>{message}</StyledMessage>
          {!isEmpty(customContent) && (
            <StyledCustomContent>{customContent}</StyledCustomContent>
          )}
          {!isEmpty(bottomActions) && (
            <StyledBottomActions>{bottomActions}</StyledBottomActions>
          )}
        </div>
      </StyledContentWrapper>

      {rightActions && !isEmpty(rightActions) && (
        <StyledRightSideButton>{rightActions}</StyledRightSideButton>
      )}

      {hasCloseIcon && (
        <StyledIconClose onClick={onClose}>
          <i className="mdi mdi-close font-size-20" />
        </StyledIconClose>
      )}
    </StyledFeedbackNotification>
  )
}

export default FeedbackNotification

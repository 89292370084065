import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getProductCatalogues(limit = 10, page = 1, filters = {}) {
    const parameters = {
      productId: filters.productId,
      name: filters.name,
      code: filters.code,
      category: filters.category,
      categoryType: filters.categoryType,
      companyId: filters.companyId,
      manufacturerId: filters.manufacturerId,
      distributorId: filters.distributorId,
      wholesalerId: filters.wholesalerId,
      warehouseId: filters.warehouseId,
      status: filters.status,
      limit,
      page,
    }
    const url = queryParams(
      API.getApi().PRODUCT_CATALOGUE.GET_PRODUCT_CATALOGUES,
      parameters
    )
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getProductCatalogue(productCatalogueId) {
    return axios
      .get(
        `${
          API.getApi().PRODUCT_CATALOGUE.GET_PRODUCT_CATALOGUE
        }/${productCatalogueId}`
      )
      .then(async ({ data }) => {
        return data
      })
  },
  addProductCatalogue(values) {
    return axios
      .post(API.getApi().PRODUCT_CATALOGUE.ADD_PRODUCT_CATALOGUE, values)
      .then(async ({ data }) => {
        return data
      })
  },
  createProductCatalogue(values) {
    return axios
      .post(API.getApi().PRODUCT_CATALOGUE.CREATE_PRODUCT_CATALOGUE, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateProductCatalogue(productCatalogueId, values) {
    return axios
      .patch(
        `${
          API.getApi().PRODUCT_CATALOGUE.UPDATE_PRODUCT_CATALOGUE
        }/${productCatalogueId}`,
        values
      )
      .then(async ({ data }) => {
        return data
      })
  },
  deleteProductCatalogue(productCatalogueId) {
    return axios
      .delete(
        `${
          API.getApi().PRODUCT_CATALOGUE.DELETE_PRODUCT_CATALOGUE
        }/${productCatalogueId}`
      )
      .then(async ({ data }) => {
        return data
      })
  },
}

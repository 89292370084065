import { includes, first, last } from "lodash"

export const resetScroll = () => {
  const privateRoute = document.getElementById(
    "hevara-header-menu-layout-content"
  )
  const publicRoute = document.getElementById("hevara-web-container")

  if (privateRoute) privateRoute.scrollTop = 0
  else if (publicRoute) publicRoute.scrollTop = 0
}

export const isLastStep = (currentStep, formSteps) => {
  return formSteps.indexOf(currentStep) === formSteps.length - 1
}

export const getNextStep = (currentStep, formSteps) => {
  const index = formSteps.indexOf(currentStep)
  if (index !== -1 && !isLastStep(currentStep, formSteps)) {
    resetScroll()
    return formSteps[index + 1]
  }
}

export const getPreviousStep = (currentStep, formSteps) => {
  const index = formSteps.indexOf(currentStep)
  if (index > 0) {
    resetScroll()
    return formSteps[index - 1]
  }
}

export const getProgress = (currentStep, formSteps) => {
  return ((formSteps.indexOf(currentStep) + 1) / formSteps.length) * 100
}

export const hideGoBackBtn = (currentStep, formSteps) => {
  return includes([first(formSteps), last(formSteps)], currentStep)
}

import React from "react"
import { map } from "lodash"
import { Row, Col } from "reactstrap"
import styled from "styled-components"

import { PrimaryButton } from "components/Button"

import "../style.scss"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  padding: 24px;
  border: 2px solid #dadada;
  border-radius: 16px;
  &:hover {
    background: rgba(0, 0, 0, 0.02);
    cursor: pointer;
  }
`

function CardGridItem({ category, onSelectProductCategory }) {
  return (
    <StyledContainer
      className="text-truncate"
      onClick={() => onSelectProductCategory(category.value)}
    >
      <p className="fw-bold font-size-16 mb-5">{category.name}</p>

      <PrimaryButton className="w-100">Select</PrimaryButton>
    </StyledContainer>
  )
}

const ProductCategory = props => {
  const { categories, onChangeProductCategory } = props

  return (
    <div className="mt-4">
      <div>
        <h2 className="fw-bold">Choose product category</h2>
        <p className="mt-4 font-size-14 mb-4">
          Select a product category that best describes the product you want to
          create.
        </p>

        <Row>
          <Col xl="12">
            <Row>
              {map(categories, category => (
                <Col md="3" key={category.value} className="mb-4">
                  <CardGridItem
                    key={category.value}
                    category={category}
                    onSelectProductCategory={() =>
                      onChangeProductCategory(category)
                    }
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ProductCategory

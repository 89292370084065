import axios from "axios"
import moment from "moment"
import jwt_decode from "jwt-decode"
import API from "config/api.config"

let refreshTimer

export default {
  login(data) {
    return axios.post(API.getApi().AUTH.LOGIN, data).then(async ({ data }) => {
      this.setAccessToken(data.tokens.access.token)
      this.setRefreshToken(data.tokens.refresh.token)
      this.setLoggedInUserData(data.user)
      this.stopRefresh()
      this.initRefresh()
      return data.user
    })
  },
  requestOTP(userId) {
    return axios
      .post(API.getApi().AUTH.REQUEST_OTP_CODE, { userId: userId })
      .then(async ({ data }) => {
        return data
      })
  },
  changeUserPassword(values) {
    return axios
      .post(API.getApi().AUTH.CHANGE_PASSWORD, values)
      .then(async ({ data }) => {
        return data
      })
  },
  checkOnboardingToken(token) {
    return axios
      .post(API.getApi().AUTH.CHECK_ONBOARDING_TOKEN, {
        onboardingToken: token,
      })
      .then(async ({ data }) => {
        return data
      })
  },
  resendOnboardingEmail(userId) {
    return axios
      .post(API.getApi().AUTH.RESEND_ONBOARDING_EMAIL, {
        userId: userId,
      })
      .then(async ({ data }) => {
        return data
      })
  },
  accountSetup(values) {
    return axios
      .post(API.getApi().AUTH.ACCOUNT_SETUP, values)
      .then(async ({ data }) => {
        this.setAccessToken(data.tokens.access.token)
        this.setRefreshToken(data.tokens.refresh.token)
        this.setLoggedInUserData(data.user)
        this.stopRefresh()
        this.initRefresh()
        return data.user
      })
  },
  logout() {
    const refresh_token = this.getRefreshToken()

    return axios
      .post(API.getApi().AUTH.LOGOUT, { refreshToken: refresh_token })
      .then(async () => {
        localStorage.removeItem("token")
        localStorage.removeItem("refresh_token")
        localStorage.removeItem("authUser")
        this.stopRefresh()
      })
  },
  setLoggedInUserData(user) {
    localStorage.setItem("authUser", JSON.stringify(user))
  },
  getLoggedInUserData() {
    return localStorage.getItem("authUser")
  },
  setAccessToken: token => {
    localStorage.setItem("token", token)
  },
  getAccessToken() {
    return localStorage.getItem("token")
  },
  setRefreshToken(token) {
    return localStorage.setItem("refresh_token", token)
  },
  getRefreshToken() {
    return localStorage.getItem("refresh_token")
  },
  refreshToken() {
    const refresh_token = this.getRefreshToken()

    return axios
      .post(API.getApi().AUTH.REFRESH_TOKEN, { refreshToken: refresh_token })
      .then(async ({ data }) => {
        this.setAccessToken(data.access.token)
        this.setRefreshToken(data.refresh.token)
        this.stopRefresh()
        this.initRefresh()
        return data
      })
  },
  forgotPassword(data) {
    return axios
      .post(API.getApi().AUTH.FORGOT_PASSWORD, data)
      .then(async ({ data }) => {
        return data
      })
  },
  recoverPassword(token, data) {
    return axios
      .post(`${API.getApi().AUTH.RECOVER_PASSWORD}?token=${token}`, {
        password: data,
      })
      .then(async ({ data }) => {
        return data
      })
  },
  stopRefresh() {
    clearTimeout(refreshTimer)
  },
  initRefresh() {
    const now = moment()
    const accessToken = this.getAccessToken()
    if (accessToken) {
      const expiration = moment(jwt_decode(accessToken).exp * 1000)
      const timeLeft = expiration.diff(now, "seconds")

      // we refresh the token when there is only 1 minutes left
      const timeFrame = 60
      const delay = Math.max(timeLeft - timeFrame, 0)
      refreshTimer = setTimeout(
        this.refreshToken.bind(this),
        delay * 1000,
        false
      )
    } else {
      this.stopRefresh()
    }
  },
}

import CONST from "utils/constants"

const { MEDIA } = CONST

export const getTextStyles = () => {
  return `
    ${MEDIA.TABLET} {
      font-size: 14px;
      line-height: 20px;
    }
  `
}

export const getSubtextStyles = () => {
  return `
    margin-top: 4px;
    ${MEDIA.TABLET} {
      font-size: 12px;
      line-height: 16px;
    }
  `
}

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { first, map, find, isFunction } from "lodash";

import { BodyText } from "../atoms";
import SelectContainer from "./SelectContainer";

const StyledText = styled(BodyText)`
  padding: 8px 0px 8px 16px;
  transition: all .25s;
  cursor: pointer;

  :hover {
    background: rgba(0, 0, 0, 0.02);
  }
`

/**
 * Select UI component
 */
const Select = ({
  value,
  defaultValue,
  placeholder: defaultPlaceholder,
  data,
  onChange,
  error,
  disabled,
  valueExtractor,
  labelExtractor,
  ariaLabel,
  id,
  role,
  errorMessageID
}) => {

  const [isShowingOptions, showOptions] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})

  useEffect(() => {
    const item = find(data, option => getValue(option) === value);
    setSelectedItem(item)
  }, [value, data])

  const selectOption = (newOption) => {
    showOptions(false)
    onChangeItem(newOption)
  }

  const onChangeItem = (newOption) => {
    onChange(getValue(newOption))
  }

  const getValue = (item) => {
    return isFunction(valueExtractor) && item ? valueExtractor(item) : item?.value;
  }

  const getLabel = (item) => {
    return isFunction(labelExtractor) && item ? labelExtractor(item) : item?.label;
  }

  const selectedLabel = getLabel(selectedItem);
  const placeholder = selectedLabel || defaultPlaceholder || defaultValue || getLabel(first(data));

  return (
    <SelectContainer
      isShowingOptions={isShowingOptions}
      showOptions={showOptions}
      placeholder={placeholder}
      error={error}
      disabled={disabled}
      data={data}
      onChangeItem={onChangeItem}
      selectedItem={selectedItem}
      selectedLabel={selectedLabel}
      ariaLabel={ariaLabel}
      errorMessageID={errorMessageID}
      id={id}
      role={role}
    >
      {defaultValue && (
        <StyledText isSelected={defaultValue === selectedLabel} onClick={() => selectOption()}>
          {defaultValue}
        </StyledText>
      )}

      {map(data, (option, index) => (
        <StyledText
          key={index}
          isSelected={getValue(option) === value}
          aria-selected={getValue(option) === value}
          onClick={() => selectOption(option)}
        >
          {getLabel(option)}
        </StyledText>
      ))}
    </SelectContainer>
  )
}

Select.defaultProps = {
  data: [],
  defaultValue: '',
  value: undefined,
  error: '',
  disabled: false,
  valueExtractor: ({ value = '' }) => value,
  labelExtractor: ({ label = '' }) => label,
}

export default Select
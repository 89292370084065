import CONST from "utils/constants"

const { MEDIA } = CONST

export const getMainInfoStyles = () => {
  return `
    display: flex;
    flex-direction: row;
    align-items: center;
  `
}

export const getAvatarStyles = () => {
  return `
    margin-right: 16px;
  `
}

export const getTitleStyles = () => {
  return `
    ${MEDIA.MOBILE} {
      font-size: 20px;
    }
  `
}

export const getInfoStyles = () => {
  return `
    display: flex;
    flex-direction: column;
  `
}

import React, { useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Col, Row, Form, Input, FormFeedback, Label } from "reactstrap"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"

import { LargeModal, MODAL_STRETCH } from "components/modal"
import { PrimaryButton, SecondaryButton } from "components/Button"

import "./customer.scss"

const CustomerModal = ({
  modalIsOpen,
  onClickClose,
  customer,
  isEdit,
  updateCustomer,
  addCustomer,
  companyId,
  toggle,
}) => {
  const [address, setAddress] = useState("")

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: (customer && customer.firstName) || "",
      lastName: (customer && customer.lastName) || "",
      phone: (customer && customer.phone) || "",
      email: (customer && customer.email) || "",
      customerType: (customer && customer.customerType) || "",
      companyId: companyId && companyId,
      location: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("This field is required"),
      lastName: Yup.string().required("This field is required"),
      phone: Yup.string()
        .matches(
          /^\+254\d{9}$/,
          "Invalid phone number format, please use +2547...."
        )
        .required("This field is required"),
      customerType: Yup.string().required("This field is required"),
    }),
    onSubmit: values => {
      const payload = {
        ...values,
        ...(values.location && {
          location: values.location.address,
          latitude: values.location.lat.toString(),
          longitude: values.location.lng.toString(),
        }),
      }

      if (isEdit) {
        updateCustomer(customer.id, payload)
      } else {
        addCustomer(payload)
      }

      validation.resetForm()
      toggle()
    },
  })

  const handleSelect = async selectedAddress => {
    const results = await geocodeByAddress(selectedAddress)
    const latLng = await getLatLng(results[0])
    const locationData = {
      lat: latLng.lat,
      lng: latLng.lng,
      address: selectedAddress,
    }
    setAddress(selectedAddress)
    validation.setFieldValue("location", locationData)
  }

  return (
    <LargeModal
      modalIsOpen={modalIsOpen}
      onClickClose={onClickClose}
      hideButtons={true}
      stretch={MODAL_STRETCH.NORMAL}
      title={
        isEdit ? `Edit ${customer?.warehouseName}'s details` : `New customer`
      }
    >
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
        className="mt-4"
      >
        <Row>
          <Col className="col-12">
            {/* FIRST NAME */}
            <div className="mb-4">
              <Label className="form-label">First name</Label>
              <Input
                name="firstName"
                type="text"
                placeholder="First name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.firstName || ""}
                invalid={
                  validation.touched.firstName && validation.errors.firstName
                    ? true
                    : false
                }
              />
              {validation.touched.firstName && validation.errors.firstName ? (
                <FormFeedback type="invalid">
                  {validation.errors.firstName}
                </FormFeedback>
              ) : null}
            </div>

            {/* LAST NAME */}
            <div className="mb-4">
              <Label className="form-label">Last name</Label>
              <Input
                name="lastName"
                type="text"
                placeholder="Last name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.lastName || ""}
                invalid={
                  validation.touched.lastName && validation.errors.lastName
                    ? true
                    : false
                }
              />
              {validation.touched.lastName && validation.errors.lastName ? (
                <FormFeedback type="invalid">
                  {validation.errors.lastName}
                </FormFeedback>
              ) : null}
            </div>

            {/* PHONE NUMBER */}
            <div className="mb-4">
              <Label className="form-label">Phone number</Label>
              <Input
                name="phone"
                type="tel"
                placeholder="Phone number (+2547XXXXXXXX)"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.phone || ""}
                invalid={
                  validation.touched.phone && validation.errors.phone
                    ? true
                    : false
                }
              />
              {validation.touched.phone && validation.errors.phone ? (
                <FormFeedback type="invalid">
                  {validation.errors.phone}
                </FormFeedback>
              ) : null}
            </div>

            {/* EMAIL */}
            <div className="mb-4">
              <Label className="form-label">Email address (Optional)</Label>
              <Input
                name="email"
                type="email"
                placeholder="Email address"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || ""}
                invalid={
                  validation.touched.email && validation.errors.email
                    ? true
                    : false
                }
              />
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">
                  {validation.errors.email}
                </FormFeedback>
              ) : null}
            </div>

            {/* CUSTOMER TYPE */}
            <div className="mb-4">
              <Label className="form-label">Customer type</Label>
              <Input
                name="customerType"
                type="select"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.customerType || ""}
                invalid={
                  validation.touched.customerType &&
                  validation.errors.customerType
                    ? true
                    : false
                }
              >
                <option>Select customer type</option>
                <option value="INDIVIDUAL">Individual</option>
                <option value="BAR">Bar</option>
                <option value="RESTAURANT">Restaurant</option>
                <option value="EATERY">Eatery</option>
                <option value="OTHER">Other</option>
              </Input>
              {validation.touched.customerType &&
              validation.errors.customerType ? (
                <FormFeedback status="invalid">
                  {validation.errors.customerType}
                </FormFeedback>
              ) : null}
            </div>

            {/* LOCATION -> FROM GOOGLE MAPS */}
            <div className="mb-4">
              <Label className="form-label">Location (Optional)</Label>
              <PlacesAutocomplete
                value={address}
                onChange={value => setAddress(value)}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className="autocomplete-container">
                    <Input
                      {...getInputProps({
                        placeholder: "Type location",
                        className: `form-control location-input ${
                          validation.touched.location &&
                          validation.errors.location
                            ? "is-invalid"
                            : ""
                        }`,
                      })}
                      value={address}
                    />
                    <div className="invalid-feedback">
                      {validation.touched.location && validation.errors.location
                        ? validation.errors.location
                        : null}
                    </div>
                    <div className="suggestion-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          {...getSuggestionItemProps(suggestion)}
                        >
                          {suggestion.description}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="hstack gap-2 justify-content-center mb-0 mt-4">
              <PrimaryButton type="submit">Confirm</PrimaryButton>

              <SecondaryButton type="button" onClick={toggle} className="me-4">
                Cancel
              </SecondaryButton>
            </div>
          </Col>
        </Row>
      </Form>
    </LargeModal>
  )
}

export default CustomerModal

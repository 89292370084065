import CONST from "utils/constants"

const { MEDIA } = CONST

export const getTableRowWrapperStyles = props => {
  return `
    padding: 12px;
    margin: 4px;
    border-radius: 8px;
    &:hover {
      background: rgba(0, 0, 0, 0.02);
      cursor: pointer;
    }
    ${MEDIA.TABLET} {
      margin-left: 0px;
      margin-right: 0px;
      padding-right: 0px;
      padding-left: 0px;
    }
    ${MEDIA.TABLET} {
      &:hover {
        background: none;
      }
    }
    & > *:not(:last-child) {
      margin-right: 16px
    }
  `
}

import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getWallets(limit = 10, page = 1, filters = {}) {
    const parameters = {
      limit,
      page,
      userId: filters.userId,
      companyId: filters.companyId,
      wallet_alias: filters.wallet_alias,
      currency: filters.currency,
      available_balance: filters.available_balance,
      status: filters.status,
    }
    const url = queryParams(API.getApi().WALLETS.GET_WALLETS, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getWallet(walletId) {
    return axios.get(`${API.getApi().WALLETS.GET_WALLET}/${walletId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  createWallet(values) {
    return axios.post(API.getApi().WALLETS.CREATE_WALLET, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateWallet(walletId, values) {
    return axios
      .patch(`${API.getApi().WALLETS.UPDATE_WALLET}/${walletId}`, values)
      .then(async ({ data }) => {
        return data
      })
  },
  lookUpCurrencies() {
    return axios
      .get(API.getApi().LOOKUPS.CURRENCIES)
      .then(async ({ data }) => {
        return data
      })
  },
  deleteWallet(walletId) {
    return axios
      .delete(`${API.getApi().WALLETS.DELETE_WALLET}/${walletId}`)
      .then(async ({ data }) => {
        return data
      })
  },
}

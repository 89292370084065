import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { chain, isEmpty, orderBy } from "lodash"
import { withTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { useNavigate } from "react-router-dom"
import {
  Col,
  Row,
  Card,
  CardBody,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"
import EmptyState, { EmptyErrorState } from "components/EmptyState"
import DispatchListItem from "components/itemList/dispatchItemList"
import { SmallModal } from "components/modal"
import { PrimaryButton, SecondaryButton } from "components/Button"
import Auth from "models/auth"
import Suborders from "models/suborders"
import OrderStatus from "models/orderStatus"
import CONST from "utils/constants"

const { ORDER_STATUS } = CONST

const Dispatches = props => {
  //meta title
  document.title = "Dispatches | hevara DMS Platform"

  const navigate = useNavigate()

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [showStatusModal, setShowStatusModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const [orders, setOrders] = useState([])
  const [order, setOrder] = useState(null)
  const [orderStatus, setOrderStatus] = useState([])

  const fetchSubOrders = id => {
    const filters = {
      companyId: id,
      status: ORDER_STATUS.DISPATCHED,
    }

    Suborders.getSubOrders(limit, page, filters)
      .then(data => {
        setOrders(data.results)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchOrderStatuses = () => {
    OrderStatus.lookUpOrderStatuses()
      .then(data => {
        setOrderStatus([
          {
            value: "",
            label: "Select status",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()

    if (profile) {
      const companyId = JSON.parse(profile).companyId
      Promise.all([fetchSubOrders(companyId), fetchOrderStatuses()])
      setUserProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      status: (order && order.status) || "",
      paymentStatus: order && order.paymentStatus,
    },
    validationSchema: Yup.object({
      status: Yup.string().required("This field is required"),
    }),
    onSubmit: values => {
      updateOrderStatus(values)

      toggle()
    },
  })

  const updateOrderStatus = values => {
    setProcessing(true)

    Suborders.updateSubOrder(order.id, values)
      .then(order => {
        setOrders(
          orders.map(item =>
            item.id.toString() === order.id.toString()
              ? { item, ...order }
              : item
          )
        )
        toast.success(`Order updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const toggle = () => {
    if (showStatusModal) {
      setShowStatusModal(false)
      setOrder(null)
    } else {
      setShowStatusModal(true)
    }

    validation.resetForm()
  }

  const getListActions = item => {
    const actions = [
      {
        label: "View details",
        icon: "mdi mdi-monitor-eye",
        onClick: () => navigate(`/dispatch-details/${item.id}/${item.orderId}`),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userProfile.permission.CAN_MANAGE_PRODUCTS && {
        label: "Create shipment",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userProfile.permission.CAN_MANAGE_PRODUCTS && {
        label: "Update status",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => {
          setOrder(item)
          setShowStatusModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const filteredOrders = chain(orders)
    .filter(order => {
      // customer first name
      const hasLabel = order.name?.toLowerCase().includes(name.toLowerCase())

      return hasLabel
    })
    .value()

  const data = orderBy(filteredOrders, [p => p.name?.toUpperCase()], "asc")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dispatches")}
            breadcrumbItem={props.t("Dispatches")}
          />

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Dispatches"}
            description={"We are processing your request, please wait..."}
          />

          {/* EDIT STATUS MODAL */}
          <SmallModal
            modalIsOpen={showStatusModal}
            onClickClose={toggle}
            title={"Edit dispatch status"}
            hideButtons={true}
          >
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              {/* STATUS */}
              <div className="mb-3">
                <Label className="form-label">Order status</Label>
                <Input
                  name="status"
                  type="select"
                  placeholder="Select status"
                  defaultValue={"Select status"}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.status || ""}
                  invalid={
                    validation.touched.status && validation.errors.status
                      ? true
                      : false
                  }
                >
                  {orderStatus.map(status => (
                    <option key={status.id} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </Input>
                {validation.touched.status && validation.errors.status ? (
                  <FormFeedback type="invalid">
                    {validation.errors.status}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="hstack gap-2 mb-0 mt-4">
                <PrimaryButton type="submit" className="me-4">
                  Confirm
                </PrimaryButton>

                <SecondaryButton type="button" onClick={toggle}>
                  Cancel
                </SecondaryButton>
              </div>
            </Form>
          </SmallModal>

          {!loading && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Dispatches"}
                      showDivider={false}
                      info={
                        !isEmpty(orders) && (
                          <PageHeaderInfo>
                            <PageHeaderInfoText className="fw-semibold">
                              {`${orders.length} ${
                                orders.length > 1 ? "Dispatches" : "Dispatch"
                              }`}
                            </PageHeaderInfoText>
                          </PageHeaderInfo>
                        )
                      }
                    />

                    {/* LIST */}
                    {!isEmpty(orders) && (
                      <SectionList
                        key={"all"}
                        data={groupListByKey(data, "name", true)}
                        renderItem={item => (
                          <DispatchListItem
                            item={item}
                            actions={getListActions(item)}
                          />
                        )}
                      />
                    )}

                    <div className="d-flex justify-content-center align-items-center">
                      {/* EMPTY STATE */}
                      <EmptyState
                        visible={isEmpty(orders)}
                        title={`No dispatches`}
                        description={`Click on the button below to create.`}
                      />

                      {/* ERROR STATE */}
                      <EmptyErrorState
                        visible={error}
                        title={`Something went wrong`}
                        description={`An error has occured. Please try again later or contact hevara for support`}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

Dispatches.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dispatches)

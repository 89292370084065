import React from "react"
import { omit } from "lodash"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Col, Row, Form, Input, FormFeedback, Label } from "reactstrap"

import { LargeModal, MODAL_STRETCH } from "components/modal"
import { PrimaryButton, SecondaryButton } from "components/Button"

const EditUserModal = ({
  modalIsOpen,
  onClickClose,
  user,
  updateUser,
  companyId,
  countries,
  toggle,
}) => {
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: (user && user.firstName) || "",
      ...(user && user.middleName
        ? { middleName: (user && user.middleName) || "" }
        : {}),
      lastName: (user && user.lastName) || "",
      email: (user && user.email) || "",
      mobileNumber: (user && user.mobileNumber) || "",
      country: (user && user.country) || "",
      companyId: companyId,
      warehouseId: user.warehouseId,
      companyRole: user.companyRole,
      role: (user && user.role) || "user",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter first name"),
      ...(user && user.middleName
        ? {
            middleName: Yup.string().required("Please enter users middle name"),
          }
        : {}),
      lastName: Yup.string().required("Please enter last name"),
      email: Yup.string().email().required("Please enter email"),
      mobileNumber: Yup.string()
        .matches(
          /^\+254\d{9}$/,
          "Invalid phone number format, please use +2547...."
        )
        .required("Mobile number is required"),
      country: Yup.string().required("Please select country"),
      companyId: Yup.string().required("Please select company"),
    }),
    onSubmit: values => {
      updateUser({
        ...omit(user, [
          "id",
          "isMobileNumberVerified",
          "isEmailVerified",
          "dateCreated",
          "otpCode",
          "tnc",
          "warehouseName",
        ]),
        ...values,
      })

      validation.resetForm()
      toggle()
    },
  })

  return (
    <LargeModal
      modalIsOpen={modalIsOpen}
      onClickClose={onClickClose}
      hideButtons={true}
      stretch={MODAL_STRETCH.NORMAL}
      title={`Edit personal details`}
    >
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
        className="mt-4"
      >
        <Row>
          <Col className="col-12">
            {/* FIRST NAME */}
            <div className="mb-3">
              <Label className="form-label">First name</Label>
              <Input
                name="firstName"
                type="text"
                placeholder="First name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.firstName || ""}
                invalid={
                  validation.touched.firstName && validation.errors.firstName
                    ? true
                    : false
                }
              />
              {validation.touched.firstName && validation.errors.firstName ? (
                <FormFeedback type="invalid">
                  {validation.errors.firstName}
                </FormFeedback>
              ) : null}
            </div>

            {/* MIDDLE NAME */}
            {user && user?.middleName && (
              <div className="mb-3">
                <Label className="form-label">Middle name</Label>
                <Input
                  name="middleName"
                  type="text"
                  placeholder="Middle name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.middleName || ""}
                  invalid={
                    validation.touched.middleName &&
                    validation.errors.middleName
                      ? true
                      : false
                  }
                />
                {validation.touched.middleName &&
                validation.errors.middleName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.middleName}
                  </FormFeedback>
                ) : null}
              </div>
            )}

            {/* LAST NAME */}
            <div className="mb-3">
              <Label className="form-label">Last name</Label>
              <Input
                name="lastName"
                type="text"
                placeholder="Last name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.lastName || ""}
                invalid={
                  validation.touched.lastName && validation.errors.lastName
                    ? true
                    : false
                }
              />
              {validation.touched.lastName && validation.errors.lastName ? (
                <FormFeedback type="invalid">
                  {validation.errors.lastName}
                </FormFeedback>
              ) : null}
            </div>

            {/* EMAIL*/}
            <div className="mb-3">
              <Label className="form-label">Email address</Label>
              <Input
                name="email"
                type="email"
                placeholder="Email address"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || ""}
                invalid={
                  validation.touched.email && validation.errors.email
                    ? true
                    : false
                }
              />
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">
                  {validation.errors.email}
                </FormFeedback>
              ) : null}
            </div>

            {/* MOBILE NUMBER*/}
            <div className="mb-3">
              <Label className="form-label">Mobile number</Label>
              <Input
                name="mobileNumber"
                type="tel"
                placeholder="Mobile number (+2547XXXXXXXX)"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.mobileNumber || ""}
                invalid={
                  validation.touched.mobileNumber &&
                  validation.errors.mobileNumber
                    ? true
                    : false
                }
              />
              {validation.touched.mobileNumber &&
              validation.errors.mobileNumber ? (
                <FormFeedback type="invalid">
                  {validation.errors.mobileNumber}
                </FormFeedback>
              ) : null}
            </div>

            {/* COUNTRY*/}
            <div className="mb-3">
              <Label className="form-label">Country</Label>
              <Input
                name="country"
                type="select"
                placeholder="Select country"
                defaultValue={"Select country"}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.country || ""}
                invalid={
                  validation.touched.country && validation.errors.country
                    ? true
                    : false
                }
              >
                {countries.map(country => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </Input>
              {validation.touched.country && validation.errors.country ? (
                <FormFeedback type="invalid">
                  {validation.errors.country}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="hstack gap-2 justify-content-center mb-0 mt-4">
              <PrimaryButton type="submit">Confirm</PrimaryButton>

              <SecondaryButton type="button" onClick={toggle} className="me-4">
                Cancel
              </SecondaryButton>
            </div>
          </Col>
        </Row>
      </Form>
    </LargeModal>
  )
}

export default EditUserModal

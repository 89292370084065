import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { chain, isEmpty, omit, orderBy } from "lodash"
import { Col, Row, Card, CardBody, Container } from "reactstrap"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import CompanyRole from "models/companyRoles"
import Auth from "models/auth"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import CompanyRoleListItem from "components/itemList/companyRoleListItem/companyRoleListItem"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"
import EmptyState, { EmptyErrorState } from "components/EmptyState"
import CompanyRoleModal from "./companyRoleModal"

const CompanyRoles = props => {
  //meta title
  document.title = "Company roles | hevara DMS Platform"

  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [companyRoles, setCompanyRoles] = useState([])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [userProfile, setUserProfile] = useState({})
  const [processing, setProcessing] = useState(false)
  const [companyRole, setCompanyRole] = useState(null)
  const [isEdit, setIsEdit] = useState(false)

  const fetchData = (limit, page, id) => {
    const filters = {
      companyId: id,
    }
    CompanyRole.getCompanyRoles(limit, page, filters)
      .then(data => {
        setCompanyRoles(data.results)
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      const companyId = JSON.parse(profile).companyId
      setUserProfile(JSON.parse(profile))
      fetchData(limit, page, companyId)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  const addCompanyRole = values => {
    setProcessing(true)
    CompanyRole.addCompanyRole(values)
      .then(companyRole => {
        setCompanyRoles([...companyRoles, companyRole])
        toast.success(`${companyRole.label} added successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const updateCompanyRole = (companyRoleId, values) => {
    setProcessing(true)
    CompanyRole.updateCompanyRole(companyRoleId, values)
      .then(companyRole => {
        setCompanyRoles(
          companyRoles.map(item =>
            item.id.toString() === companyRole.id.toString()
              ? { item, ...companyRole }
              : item
          )
        )
        toast.success(`${companyRole.label} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableRole = data => {
    setProcessing(true)
    const payload = {
      ...omit(data, ["id", "companyName", "dateCreated"]),
      status: "Active",
    }
    CompanyRole.updateCompanyRole(data.id, payload)
      .then(role => {
        setCompanyRoles(
          companyRoles.map(item =>
            item.id.toString() === role.id.toString() ? { ...role } : item
          )
        )
        toast.success(`${role.label} role has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disableRole = data => {
    setProcessing(true)
    const payload = {
      ...omit(data, ["id", "companyName", "dateCreated"]),
      status: "Disabled",
    }
    CompanyRole.updateCompanyRole(data.id, payload)
      .then(role => {
        setCompanyRoles(
          companyRoles.map(item =>
            item.id.toString() === role.id.toString() ? { ...role } : item
          )
        )
        toast.success(`${role.label} role has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const getListActions = item => {
    const isActive = item.status === "Active"

    const actions = [
      userProfile.permission.CAN_MANAGE_COMPANIES && {
        label: "Edit",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => handleClick(item),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userProfile.permission.CAN_MANAGE_COMPANIES && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () => (isActive ? disableRole(item) : enableRole(item)),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userProfile.permission.CAN_MANAGE_COMPANIES && {
        label: "New company role",
        icon: "mdi mdi-plus",
        onClick: () => {
          setIsEdit(false)
          setModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const handleClick = data => {
    setCompanyRole(data)
    setIsEdit(true)
    closeRoleModal()
  }

  const closeRoleModal = () => {
    if (modal) {
      setModal(false)
      setCompanyRole(null)
    } else {
      setModal(true)
    }
  }

  const onClickNewRole = () => {
    setIsEdit(false)
    setModal(true)
  }

  const filteredRoles = chain(companyRoles)
    .filter(role => {
      // Name
      const hasName = role.label?.toLowerCase().includes(name.toLowerCase())

      return hasName
    })
    .value()

  const data = orderBy(filteredRoles, [p => p.label.toUpperCase()], "asc")

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Company roles")}
            breadcrumbItem={props.t("Company roles")}
          />

          {/* MODAL */}
          <CompanyRoleModal
            modalIsOpen={modal}
            onClickClose={closeRoleModal}
            role={companyRole}
            companyId={userProfile.companyId}
            isEdit={isEdit}
            toggle={closeRoleModal}
            updateCompanyRole={updateCompanyRole}
            addCompanyRole={addCompanyRole}
          />

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Company roles"}
            description={"We are processing your request, please wait..."}
          />

          {!loading && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Company roles"}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        !isEmpty(companyRoles) && (
                          <PageHeaderInfo>
                            <PageHeaderInfoText className="fw-semibold">
                              {`${companyRoles.length} ${
                                companyRoles.length > 1 ? "roles" : "role"
                              }`}
                            </PageHeaderInfoText>
                          </PageHeaderInfo>
                        )
                      }
                    />

                    {/* ROLE LIST */}
                    {!isEmpty(companyRoles) && (
                      <SectionList
                        key={"all"}
                        data={groupListByKey(data, "label", true)}
                        renderItem={item => (
                          <CompanyRoleListItem
                            item={item}
                            actions={getListActions(item)}
                          />
                        )}
                      />
                    )}

                    <div className="d-flex justify-content-center align-items-center">
                      {/* EMPTY STATE */}
                      <EmptyState
                        visible={isEmpty(companyRoles)}
                        title={`No company roles`}
                        description={`Click on the button below to create.`}
                        firstButton={
                          userProfile.permission.CAN_MANAGE_COMPANIES && {
                            label: `New company role`,
                            onClick: onClickNewRole,
                            disabled: processing,
                          }
                        }
                      />

                      {/* ERROR STATE */}
                      <EmptyErrorState
                        visible={error}
                        title={`Something went wrong`}
                        description={`An error has occurred. Please try again later or contact hevara for support`}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </>
  )
}

CompanyRoles.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(CompanyRoles)

import React from "react"
import styled from "styled-components"

import { SmallText } from "components/atoms"
import { getProgressBarStyles } from "./ProgressBar.styles"

const StyledProgressBarWrapper = styled.div.attrs({
  className: "hevara-progress-bar-wrapper",
})`
  ${getProgressBarStyles}
`

const StyledProgressBar = styled.div.attrs({
  className: "hevara-progress-bar",
})``

const StyledProgressBarIndicator = styled.div.attrs({
  className: "hevara-progress-bar-indicator",
})``

const StyledValuesRow = styled.div.attrs({
  className: "hevara-progress-bar-values-row",
})``

const StyledStartValue = styled(SmallText).attrs({
  className: "hevara-progress-bar-start-value",
})``

const StyledEndValue = styled(SmallText).attrs({
  className: "hevara-progress-bar-end-value",
})``

export const ProgressBar = ({ progress, startValue, endValue }) => {
  return (
    <StyledProgressBarWrapper progress={progress} startValue={startValue}>
      <StyledProgressBar>
        <StyledProgressBarIndicator />
      </StyledProgressBar>
      <StyledValuesRow>
        {!!startValue && <StyledStartValue>{startValue}</StyledStartValue>}
        {!!endValue && <StyledEndValue>{endValue}</StyledEndValue>}
      </StyledValuesRow>
    </StyledProgressBarWrapper>
  )
}

ProgressBar.defaultProps = {
  process: 0,
  startValue: undefined,
  endValue: undefined,
}

export default ProgressBar

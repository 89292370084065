import axios from "axios"
import API from "config/api.config"

export default {
  lookUpCountries() {
    return axios.get(API.getApi().LOOKUPS.COUNTRIES).then(async ({ data }) => {
      return data
    })
  },
}

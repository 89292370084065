import React, { useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { Transition } from "react-transition-group"
import { isFunction, last } from "lodash"

import { HeadingS } from "components/atoms"
import { IconButton } from "components/Button/IconButton"
import CONST from "utils/constants"
import { MODAL_STRETCH } from "./Modal.styles"
import {
  getModalContainerStyles,
  MAX_HEIGHT,
  MIN_MARGIN_Y,
  TRANSITION_MS,
  TRANSITION_CONTAINER_STYLES,
  TRANSITION_MODAL_STYLES,
} from "./Modal.styles"

const { SIZE } = CONST

const StyledModalContainer = styled.div.attrs({
  className: "hevara-modal-box",
})`
  ${getModalContainerStyles}
`

const StyledModal = styled.div.attrs({
  className: "hevara-modal",
})`
  border: 2px solid #F4CC0C;
  padding: 18px;
  border-radius: 16px;
`

const StyledModalHeaderWrapper = styled.div.attrs({
  className: "hevara-modal-header-wrapper",
})``

const StyledModalHeader = styled.div.attrs({
  className: "hevara-modal-header",
})``

const StyledModalTitleWrapper = styled.div.attrs({
  className: "hevara-modal-title-wrapper",
})``

const StyledModalTitle = styled(HeadingS).attrs({
  className: "hevara-modal-title",
})``
const StyledModalIcon = styled(IconButton).attrs({
  className: "hevara-modal-icon",
})``

const StyledModalContent = styled.div.attrs({
  className: "hevara-modal-content",
})``

const StyledModalScrollable = styled.div.attrs({
  className: "hevara-modal-scrollable-content",
})``

const StyledModalChildren = styled.div.attrs({
  className: "hevara-modal-children",
})``

const StyledFooterContainer = styled.div.attrs({
  className: "hevara-modal-footer",
})``

export const Modal = ({
  title,
  visible,
  close,
  avoidCloseModal,
  size,
  stretch,
  footer,
  children,
  contentClassName,
  overlayClassName,
}) => {
  const isFullScreen = size === SIZE.L
  const titleRef = useRef(null)
  const modalRef = useRef(null)
  const headerRef = useRef(null)
  const [isTitleVisible, setTitleVisibility] = useState(true)

  const isInViewport = () => {
    const titleTopPosition = titleRef.current?.getBoundingClientRect()?.top ?? 0
    const stickyHeaderBottomPosition =
      headerRef.current?.getBoundingClientRect()?.bottom ?? 0

    return titleTopPosition > stickyHeaderBottomPosition
  }

  const onScroll = () => {
    if (isFullScreen) {
      const isVisible = isInViewport()

      if (isTitleVisible !== isVisible) {
        const titleElement = last(titleRef.current?.childNodes)

        if (!!titleElement) {
          const titleClone = titleElement.cloneNode?.(true)
          if (!isVisible) {
            titleElement.classList?.add("hevara-modal-title-sticky")
            !!titleClone && titleRef.current?.appendChild(titleClone)
          } else if (isVisible) {
            titleElement.classList?.remove("hevara-modal-title-sticky")
            if (titleRef.current?.childNodes.length > 1) {
              titleRef.current?.removeChild(titleRef.current.firstChild)
            }
          }
        }
      }

      setTitleVisibility(isVisible)
    }
  }

  const getModalMaxHeight = useCallback(() => {
    const isInnerHeightSmallerThanMaxHeight =
      window.innerHeight < MAX_HEIGHT + MIN_MARGIN_Y

    return isInnerHeightSmallerThanMaxHeight
      ? window.innerHeight - MIN_MARGIN_Y
      : MAX_HEIGHT
  }, [window.innerHeight])

  const hideBodyOverflow = () => {
    document.body.style.overflow = "hidden"
  }

  const resetBodyOverflow = () => {
    document.body.style.overflow = "initial"
  }

  const toggleParentModalStickyTitle = () => {
    const closestParent = modalRef.current?.closest(".hevara-modal-content")

    if (!!closestParent) {
      const parentStickyTitle = closestParent.querySelector(
        ".hevara-modal-title-sticky"
      )
      !!parentStickyTitle && parentStickyTitle.classList.toggle("unset")
    }
  }

  useEffect(() => {
    if (visible) {
      hideBodyOverflow()
      modalRef.current?.focus()
    } else {
      resetBodyOverflow()
    }

    toggleParentModalStickyTitle()
    setTitleVisibility(!!visible)

    return () => {
      resetBodyOverflow()
    }
  }, [visible])

  const onKeyDown = event => {
    if (
      !avoidCloseModal &&
      (event.code === "Escape" || event.key === "Escape")
    ) {
      isFunction(close) && close()
    }
  }

  return (
    <Transition
      in={visible}
      unmountOnExit={true}
      timeout={{ enter: 0, exit: TRANSITION_MS }}
    >
      {state => {
        return (
          <StyledModalContainer
            size={size}
            stretch={stretch}
            className={overlayClassName}
            avoidCloseModal={avoidCloseModal}
            onClick={!isFullScreen && !avoidCloseModal ? close : undefined}
            maxHeight={getModalMaxHeight()}
            isFullScreen={isFullScreen}
            style={TRANSITION_CONTAINER_STYLES[state]}
            onKeyDown={onKeyDown}
            ref={modalRef}
            tabIndex={0}
          >
            <StyledModal
              onClick={event => event?.stopPropagation()}
              className={contentClassName}
              style={TRANSITION_MODAL_STYLES[state]}
            >
              <StyledModalHeaderWrapper>
                <StyledModalHeader ref={headerRef}>
                  {!isFullScreen && (
                    <StyledModalTitle>{title}</StyledModalTitle>
                  )}
                  {!avoidCloseModal && (
                    <StyledModalIcon
                      type="button"
                      name="mdi mdi-close"
                      variant="secondary"
                      size={SIZE.XS}
                      onClick={close}
                    />
                  )}
                </StyledModalHeader>
              </StyledModalHeaderWrapper>

              <StyledModalScrollable
                style={{ paddingTop: headerRef.current?.clientHeight }}
                onScroll={onScroll}
              >
                <StyledModalContent>
                  {isFullScreen && (
                    <StyledModalTitleWrapper
                      style={{
                        visibility: isTitleVisible ? "visible" : "hiddden",
                      }}
                      ref={titleRef}
                    >
                      <StyledModalTitle>{title}</StyledModalTitle>
                    </StyledModalTitleWrapper>
                  )}
                  <StyledModalChildren>{children}</StyledModalChildren>
                  {isFullScreen && !!footer && (
                    <StyledFooterContainer>{footer}</StyledFooterContainer>
                  )}
                </StyledModalContent>
              </StyledModalScrollable>
              {!isFullScreen && !!footer && (
                <StyledFooterContainer>{footer}</StyledFooterContainer>
              )}
            </StyledModal>
          </StyledModalContainer>
        )
      }}
    </Transition>
  )
}

Modal.defaultProps = {
  visible: false,
  stretch: MODAL_STRETCH.EXPANDED,
  footer: undefined,
  avoidCloseModal: false,
}

export { Modal as default, SIZE as MODAL_SIZE }

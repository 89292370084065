import React, { useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Col, Row, Form, Input, FormFeedback, Label } from "reactstrap"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"

import { LargeModal, MODAL_STRETCH } from "components/modal"
import { PrimaryButton, SecondaryButton } from "components/Button"

import "./warehouse.styles.scss"

const WarehouseModal = ({
  modalIsOpen,
  onClickClose,
  warehouse,
  isEdit,
  updateWarehouse,
  addWarehouse,
  companyId,
  toggle,
}) => {
  const [address, setAddress] = useState("")

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      warehouseName: (warehouse && warehouse.warehouseName) || "",
      companyId: companyId && companyId,
      location: null,
      status: (warehouse && warehouse.status) || "",
    },
    validationSchema: Yup.object({
      warehouseName: Yup.string().required("Please enter warehouse name"),
      companyId: Yup.string().required("Please select company"),
      location: Yup.object().nullable().required("Location is required"),
      status: Yup.string().required("Please select warehouse status"),
    }),
    onSubmit: values => {
      const payload = {
        ...values,
        location: values.location.address,
        latitude: values.location.lat.toString(),
        longitude: values.location.lng.toString(),
      }

      if (isEdit) {
        // update Country
        updateWarehouse(warehouse.id, payload)
        validation.resetForm()
      } else {
        // add new Country
        addWarehouse(payload)
        validation.resetForm()
      }

      toggle()
    },
  })

  const handleSelect = async selectedAddress => {
    const results = await geocodeByAddress(selectedAddress)
    const latLng = await getLatLng(results[0])
    const locationData = {
      lat: latLng.lat,
      lng: latLng.lng,
      address: selectedAddress,
    }
    setAddress(selectedAddress)
    validation.setFieldValue("location", locationData)
  }

  return (
    <LargeModal
      modalIsOpen={modalIsOpen}
      onClickClose={onClickClose}
      hideButtons={true}
      stretch={MODAL_STRETCH.NORMAL}
      title={isEdit ? `Edit ${warehouse?.warehouseName}'s details` : `New warehouse`}
    >
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
        className="mt-4"
      >
        <Row>
          <Col className="col-12">
            {/* WAREHOUSE NAME */}
            <div className="mb-4">
              <Label className="form-label">Warehouse name</Label>
              <Input
                name="warehouseName"
                type="text"
                placeholder="Warehouse name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.warehouseName || ""}
                invalid={
                  validation.touched.warehouseName &&
                  validation.errors.warehouseName
                    ? true
                    : false
                }
              />
              {validation.touched.warehouseName &&
              validation.errors.warehouseName && (
                <FormFeedback type="invalid">
                  {validation.errors.warehouseName}
                </FormFeedback>
              )}
            </div>

            {/* WAREHOUSE LOCATION -> FROM GOOGLE MAPS */}
            <div className="mb-4">
              <Label className="form-label">Location</Label>
              <PlacesAutocomplete
                value={address}
                onChange={value => setAddress(value)}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className="autocomplete-container">
                    <Input
                      {...getInputProps({
                        placeholder: "Type location",
                        className: `form-control location-input ${
                          validation.touched.location &&
                          validation.errors.location
                            ? "is-invalid"
                            : ""
                        }`,
                      })}
                      value={address}
                    />
                    <div className="invalid-feedback">
                      {validation.touched.location && validation.errors.location
                        ? validation.errors.location
                        : null}
                    </div>
                    <div className="suggestion-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          {...getSuggestionItemProps(suggestion)}
                        >
                          {suggestion.description}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>

            {/* WAREHOUSE STATUS */}
            <div className="mb-4">
              <Label className="form-label">Status</Label>
              <Input
                name="status"
                type="select"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.status || ""}
                invalid={
                  validation.touched.status && validation.errors.status
                    ? true
                    : false
                }
              >
                <option>Select status</option>
                <option value="Active">Active</option>
                <option value="Disabled">Disabled</option>
              </Input>
              {validation.touched.status && validation.errors.status && (
                <FormFeedback status="invalid">
                  {validation.errors.status}
                </FormFeedback>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="hstack gap-2 justify-content-center mb-0 mt-4">
              <SecondaryButton type="button" onClick={toggle} className="me-4">
                Cancel
              </SecondaryButton>

              <PrimaryButton type="submit">Confirm</PrimaryButton>
            </div>
          </Col>
        </Row>
      </Form>
    </LargeModal>
  )
}

export default WarehouseModal

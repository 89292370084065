import CONST from "utils/constants"

const { DIVIDER_ORIENTATION } = CONST

export const getDividerStyles = ({
  orientation,
  color: customColor,
  ...props
}) => {
  const color = customColor || "#c2c2c2"
  const isVertical = orientation === DIVIDER_ORIENTATION.VERTICAL

  return `
    width: ${isVertical ? "1px" : "100%"};
    height: ${isVertical ? "100%" : "1px"};
    background-color: ${color}
  `
}

import React from "react"

import { BulletList as HevaraBulletList } from "./BulletList"

export const BulletList = ({ data, iconName, className = "" }) => {
  return (
    <HevaraBulletList data={data} iconName={iconName} className={className} />
  )
}

BulletList.defaultProps = {
  data: [],
}

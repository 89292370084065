import CONST from "utils/constants"

const { SIZE } = CONST

const getTheme = {
  color: {
    chip: {
      status: {
        success: {
          font: "#4CAF50",
          background: "#EDF7ED",
          border: "#EDF7ED",
        },
        info: {
          font: "#15B2EB",
          background: "#EAF8FD",
          border: "#EAF8FD",
        },
        warning: {
          font: "#FF9800",
          background: "#FFF4E5",
          border: "#FFF4E5",
        },
        danger: {
          font: "#F44336",
          background: "#FDECEA",
          border: "#FDECEA",
        },
        neutral: {
          font: "#696969",
          background: "#DADADA",
          border: "#DADADA",
        },
      },
    },
  },
}

export const CHIP_VARIANTS = {
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  DANGER: "danger",
  NEUTRAL: "neutral",
}

export const CHIP_SIZES = {
  M: SIZE.M,
  S: SIZE.S,
}

export const ChipSizes = "m" | "s"

const STATUS_VARIANTS = ["success", "info", "warning", "danger", "neutral"]

export const getChipColor = (theme, variant, key, color) => {
  if (color) {
    return color
  } else {
    if (STATUS_VARIANTS.includes(variant)) {
      return theme.color.chip.status[variant][key]
    }

    return theme.color.chip.status[variant][key]
  }
}

export const getChipSizes = size => {
  if (size === SIZE.S) {
    return `
      padding-top: 2px;
      padding-bottom: 2px;
      padding-right: 4px;
      padding-left: 4px;
    `
  } else {
    return `
      padding-top: 4px;
      padding-bottom: 4px;
      padding-right: 8px;
      padding-left: 8px;
    `
  }
}

export const getChipStyles = ({
  variant,
  size,
  fontColor: color,
  backgroundColor,
  borderColor,
  ...props
}) => {
  const theme = getTheme
  const borderRadius =
    size === SIZE.S ? "border-radius: 4px;" : "border-radius: 8px;"
  const padding = getChipSizes(size)
  const fontColor = getChipColor(theme, variant, "font", color)
  const background = getChipColor(theme, variant, "background", backgroundColor)
  const borderColorValue =
    getChipColor(theme, variant, "border", borderColor) || background

  return `
    ${padding}
    ${borderRadius}
    display: inline-block;
    background-color: ${background};
    border: 1px solid ${borderColorValue};
    .hevara-chip-label {
      color: ${fontColor};
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.02em;
    }
  `
}

import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import { chain, isEmpty, omit, orderBy, map } from "lodash"
import { toast, ToastContainer } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import {
  Col,
  Row,
  Card,
  CardBody,
  Container,
  CardTitle,
  Table,
} from "reactstrap"

import Auth from "models/auth"
import Order from "models/order"
import Catalogue from "models/productCatalogue"
import Customer from "models/customer"
import PaymentMethods from "models/paymentMethods"
import Loading from "components/Common/Spinner"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { BUTTON_VARIANT, PrimaryButton } from "components/Button"
import { SelectionBox } from "components/SelectionBox/SelectionBox"
import EmptyState, { EmptyErrorState } from "components/EmptyState"
import Breadcrumbs from "components/Common/Breadcrumb"
import CartItemList from "components/itemList/cartItemList/cartItemList"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey, formatName, formatVolume } from "utils/utils"
import { HelpNotification, HELP_VARIANTS } from "components/HelpNotification"
import CONST from "utils/constants"
import { BodyText } from "components/atoms"

const { COMPANY_TYPE } = CONST

const NewOrder = props => {
  //meta title
  document.title = "New order | hevara DMS Platform"

  const { type } = useParams()
  const navigate = useNavigate()

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userProfile, setUserProfile] = useState({})

  const [productCatalogue, setProductCatalogue] = useState([])
  const [cartItems, setCartItems] = useState([])
  const [quantity, setQuantity] = useState(1)
  const [customers, setCustomers] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState("")
  const [paymentMethods, setPaymentMethods] = useState([])
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("")
  const [customerError, setCustomerError] = useState(false)
  const [paymentMethodError, setPaymentMethodError] = useState(false)

  const fetchProductCatalogues = (id, type) => {
    const filters = {
      companyId: id,
    }

    Catalogue.getProductCatalogues(limit, page, filters)
      .then(data => {
        // Add "quantity" property with default value to each object
        const dataWithQuantity = data.results.map(item => ({
          ...item,
          quantity: quantity,
          total: quantity * (type === COMPANY_TYPE.DISTRIBUTOR ? item.secondaryPrice : item.retailPrice),
        }))
        setProductCatalogue(dataWithQuantity)
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchCompanyCustomers = id => {
    Customer.lookUpCustomers(id)
      .then(data => {
        setCustomers([
          {
            status: "Default",
            firstName: "Select customer",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchPaymentMethods = () => {
    PaymentMethods.lookUpPaymentMethods()
      .then(data => {
        setPaymentMethods([
          {
            status: "Default",
            name: "Select payment method",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()

    if (type && profile) {
      const companyId = JSON.parse(profile).companyId

      Promise.all([
        fetchProductCatalogues(companyId, type),
        fetchCompanyCustomers(companyId),
        fetchPaymentMethods()
      ])
      setUserProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  const addToCart = catalogueId => {
    // Check if the item is already in the cart
    const isAlreadyInCart = cartItems.some(item => item.id === catalogueId)

    if (!isAlreadyInCart) {
      // If not in the cart, find the product that matches the added item and add it to the cart
      const productToAdd = productCatalogue.find(catalogue => catalogue.id === catalogueId)
      setCartItems(prevCartItems => [...prevCartItems, productToAdd])
    } else {
      // If already in the cart, you can show a message or handle it as needed
      toast.warn("Product already added to the cart")
    }
  }

  const removeFromCart = catalogueId => {
    // remove the item that matches the removed product from the cart
    const updatedCartItems = cartItems.filter(item => item.id !== catalogueId)
    setCartItems(updatedCartItems)
  }

  const increaseQuantity = item => {
    const newQuantity = item.quantity + 1
    const productPrice = type === COMPANY_TYPE.DISTRIBUTOR ? item.secondaryPrice : item.retailPrice
    const price = productPrice * newQuantity

    setCartItems(cartItems.map(p => p.id === item.id ? { ...p, quantity: newQuantity, total: price } : p))
  }

  const decreaseQuantity = item => {
    const newQuantity = item.quantity - 1
    const productPrice = type === COMPANY_TYPE.DISTRIBUTOR ? item.secondaryPrice : item.retailPrice
    const price = productPrice * newQuantity

    setCartItems(cartItems.map(p => p.id === item.id ? { ...p, quantity: newQuantity, total: price } : p))
  }

  const totalSum = cartItems.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.total
  }, 0)

  const onChangeLimitSize = event => {
    setLimit(Number(event.target.value))
  }

  const onChangeCustomer = event => {
    const selectedValue = event.target.value
    setSelectedCustomer(selectedValue)
  }

  const onChangePaymentMethod = event => {
    setSelectedPaymentMethod(event.target.value)
  }

  const onClickCreateOrder = () => {
    if (!selectedCustomer) {
      setCustomerError(true)
    } else if (!selectedPaymentMethod) {
      setPaymentMethodError(true)
    } else {
      createOrder()
    }
  }

  const createOrder = () => {
    if (!isEmpty(cartItems)) {
      setProcessing(true)

      const payload = {
        userId: selectedCustomer, // Customer Id from the dropdown
        companyId: userProfile.companyId , // company id of the user making the order
        orderType: "COUNTER",
        products: cartItems,
        paymentMethod: selectedPaymentMethod,
        totalOrderPrice: totalSum,
      }
  
      Order.createOrder(payload)
        .then(data => {
          if (data) {
            toast.success(`Order created successfully`)
            navigate(`/orders`)
          }
        })
        .catch(error => {
          toast.error(error.response?.data?.message)
        })
        .finally(() => setProcessing(false))
    } else {
      toast.warning(`Please select at least one product before placing your order`)
    }
  }

  const getListActions = item => {
    const actions = [
      {
        label: "Increase Quantity",
        icon: "mdi mdi-delete-outline",
        onClick: () => increaseQuantity(item),
        variant: BUTTON_VARIANT.SECONDARY,
        showInModal: true,
      },
      {
        label: "Decrease quantity",
        icon: "mdi mdi-delete-outline",
        onClick: () => decreaseQuantity(item),
        variant: BUTTON_VARIANT.SECONDARY,
        showInModal: true,
      },
      {
        label: "Remove",
        icon: "mdi mdi-delete-outline",
        onClick: () => removeFromCart(item.id),
        variant: BUTTON_VARIANT.CRITICAL,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const filteredCartItems = chain(cartItems)
    .filter(cartItem => {
      // order Type
      const hasLabel = cartItem.name?.toLowerCase().includes(name.toLowerCase())

      return hasLabel
    })
    .value()

  const data = orderBy(filteredCartItems, [p => p.name.toUpperCase()], "asc")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("New order")}
            breadcrumbItem={props.t("New order")}
          />

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Order"}
            description={"We are creating your order, please wait..."}
          />

          {!loading && (
            <Row>
              {!isEmpty(productCatalogue) && (
                <>
                  {/* PRODUCTS CATALOGUE SIDE */}
                  <Col xl={5}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Products</CardTitle>
                        <p className="text-muted font-size-14">
                          Select product
                        </p>

                        {/* PAGE LIMIT FILTER */}
                        <div className="d-flex custom-select-wrapper">
                          <select
                            className="form-select"
                            value={limit}
                            onChange={onChangeLimitSize}
                          >
                            {[10, 20, 30, 40, 50].map(pageLimit => (
                              <option key={pageLimit} value={pageLimit}>
                                Show {pageLimit}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* PRODUCT CATALOGUE LIST */}
                        {map(productCatalogue, catalogue => (
                          <SelectionBox
                            key={catalogue.id}
                            title={`${catalogue.name}`}
                            description={`${formatName(catalogue.categoryName)} - ${formatVolume(catalogue.size)}`}
                            sideData={`Ksh. ${type === COMPANY_TYPE.DISTRIBUTOR ? catalogue.secondaryPrice : catalogue.retailPrice }`}
                            sideSubdata={`Discount ${type === COMPANY_TYPE.DISTRIBUTOR ? catalogue.secondaryDiscount: catalogue.retailDiscount}%`}
                            avatar={{ image: catalogue.image?.file, initials: catalogue.name }}
                            selected={cartItems && cartItems.some(item => item.id === catalogue.id)}
                            selectedIcon="mdi mdi-check"
                            onClick={() => addToCart(catalogue.id)}
                            className="mt-3"
                          />
                        ))}
                      </CardBody>
                    </Card>
                  </Col>

                  {/* CART DETAILS SIDE */}
                  <Col xl="7">
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Order Details</CardTitle>
                        {/* SELECT CUSTOMER */}
                        <BodyText className="text-muted mb-2">Customer</BodyText>
                        <div className="d-flex custom-select-wrapper">
                          <select
                            className="form-select"
                            value={selectedCustomer || ""}
                            onChange={onChangeCustomer}
                          >
                            {customers.map(customer => (
                              <option key={customer.id} value={customer.id}>
                                {customer.firstName} {customer.lastName}
                              </option>
                            ))}
                          </select>
                        </div>

                        {isEmpty(selectedCustomer) && customerError && (
                          <HelpNotification className="mb-3" variant={HELP_VARIANTS.ERROR}>
                            You must select a customer before creating the order.
                          </HelpNotification>
                        )}

                        {/* SELECT PAYMENT METHOD */}
                        <BodyText className="text-muted mb-2 mt-4">Payment method</BodyText>
                        <div className="d-flex custom-select-wrapper mb-4">
                          <select
                            className="form-select"
                            value={selectedPaymentMethod || ""}
                            onChange={onChangePaymentMethod}
                          >
                            {paymentMethods.map(item => (
                              <option key={item.id} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        {isEmpty(selectedPaymentMethod) && paymentMethodError && (
                          <HelpNotification className="mb-3" variant={HELP_VARIANTS.ERROR}>
                            You must select a payment method before creating the order.
                          </HelpNotification>
                        )}

                        {/* CART ITEMS LIST */}
                        {!isEmpty(cartItems) && (
                          <>
                            <BodyText className="text-muted mb-3">Selected products</BodyText>
                            <SectionList
                              key={"all"}
                              data={groupListByKey(data, "name", true)}
                              renderItem={item => (
                                <CartItemList
                                  item={item}
                                  actions={getListActions(item)}
                                  quantity={quantity}
                                  companyType={type}
                                />
                              )}
                            />
                          </>
                        )}
                      </CardBody>
                    </Card>

                    {/* ORDER DETAILS */}
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-3">Order Summary</CardTitle>

                        <div className="table-responsive">
                          <Table className="table mb-0">
                            <tbody>
                              <tr>
                                <th>Subtotal :</th>
                                <th>Ksh. {totalSum.toLocaleString()}</th>
                              </tr>
                              <tr>
                                <th>Total :</th>
                                <th>Ksh. {totalSum.toLocaleString()}</th>
                              </tr>
                            </tbody>
                          </Table>
                        </div>

                        <Row>
                          <Col>
                            <div className="hstack gap-2 justify-content-center mb-0 mt-4">
                              <PrimaryButton onClick={onClickCreateOrder} type="button">
                                Place order
                              </PrimaryButton>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </>
              )}

              <div className="d-flex justify-content-center align-items-center">
                {/* EMPTY STATE */}
                <EmptyState
                  visible={isEmpty(productCatalogue)}
                  title={`No products`}
                  description={`Go to product catalogue section to add products.`}
                />

                {/* ERROR STATE */}
                <EmptyErrorState
                  visible={error}
                  title={`Something went wrong`}
                  description={`An error has occurred. Please try again later or contact hevara for support`}
                />
              </div>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

NewOrder.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(NewOrder)

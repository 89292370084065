import React from "react";

import Text from "../BasicText";
import CONST from "components/atoms/constants";

const { SIZE, FONT: { WEIGHT, VARIANT } } = CONST;

const AmountBold = ({ children, ...props }) => {
  return (
    <Text
      size={SIZE.M}
      variant={VARIANT.AMOUNT}
      weight={WEIGHT.BOLD}
      {...props}
    >
      {children}
    </Text>
  )
}

AmountBold.defaultProps = {}

export default AmountBold
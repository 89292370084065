import axios from "axios"
import API from "config/api.config"

export default {
  lookUpPaymentMethods() {
    return axios.get(API.getApi().LOOKUPS.PAYMENT_METHODS)
      .then(async ({ data }) => {
        return data
      })
  },
}

import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { isEmpty, omit, map } from "lodash"
import { toast, ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { Card, CardBody, Container, Row } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import Fleet from "models/fleet"
import DataItem from "components/DataItem"
import Loading from "components/Common/Spinner"
import { EmptyErrorState } from "components/EmptyState"
import User from "models/user"
import Auth from "models/auth"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import CONST from "utils/constants"
import Link, { LinkLayout } from "components/Link"
import VehicleItem from "./vehicleItem"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import { BUTTON_VARIANT } from "components/Button"
import UserModal from "./userModal"
import Warehouse from "models/warehouses"
import Roles from "models/roles"
import CompanyRoles from "models/companyRoles"
import Countries from "models/countries"
import PermissionModal from "./permissionModal"
import { getCompanyRole, getLastFourDigits, getStatus } from "utils/utils"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"

import "./style.scss"

const { ROLES } = CONST

const UserDetails = props => {
  //meta title
  document.title = "User details | hevara DMS Platform"

  const { userId } = useParams()

  const navigate = useNavigate()

  const [modal, setModal] = useState(false)
  const [permissionModal, setPermissionModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [fleets, setFleets] = useState([])
  const [user, setUser] = useState([])
  const [permissions, setPermissions] = useState({})
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userPermissions, setUserPermissions] = useState({})
  const [profile, setProfile] = useState({})
  const [warehouses, setWarehouses] = useState([])
  const [countries, setCountries] = useState([])
  const [roles, setRoles] = useState([])
  const [companyRoles, setCompanyRoles] = useState([])

  const fetchUserData = id => {
    User.getUserDetails(id)
      .then(data => {
        setUser(data)
        setPermissions(data.permission)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const getVehicles = (limit, page, id) => {
    const filters = {
      routeAgent: id,
    }

    Fleet.getFleets(limit, page, filters)
      .then(data => {
        setFleets(data.results)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanyWarehouses = id => {
    Warehouse.lookUpCompanyWarehouses(id)
      .then(data => {
        setWarehouses([
          {
            status: "Default",
            warehouseName: "Select warehouse",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCountries = () => {
    Countries.lookUpCountries()
      .then(data => {
        setCountries([
          {
            status: "Default",
            name: "Select Country",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchRoles = () => {
    Roles.lookUpRoles()
      .then(data => {
        setRoles(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanyRoles = id => {
    CompanyRoles.lookupCompanyRoles(id)
      .then(data => {
        setCompanyRoles(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (userId && profile) {
      const companyId = JSON.parse(profile).companyId

      Promise.all([
        fetchUserData(userId),
        getVehicles(limit, page, userId),
        fetchCompanyWarehouses(companyId),
        fetchCountries(),
        fetchRoles(),
        fetchCompanyRoles(companyId),
      ])

      setUserPermissions(JSON.parse(profile).permission)
      setProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [userId, limit, page])

  const toggle = () => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  const onClickPermissionModal = () => {
    if (permissionModal) {
      setPermissionModal(false)
    } else {
      setPermissionModal(true)
    }
  }

  // Function to handle changes in the toggle switches
  const handlePermissionChange = key => {
    const updatedPermissions = { ...permissions, [key]: !permissions[key] }
    setPermissions(updatedPermissions)
  }

  const updateUserPermissions = () => {
    onClickPermissionModal() // Close permission modal

    setProcessing(true)

    const payload = {
      ...omit(user, [
        "id",
        "isMobileNumberVerified",
        "isEmailVerified",
        "dateCreated",
        "otpCode",
        "warehouseName",
        "middleName",
      ]),
      permission: permissions,
    }

    User.updateUser(user.id, payload)
      .then(data => {
        setUser(data)
        setPermissions(data.permission)
        toast.success(`${data.fullName} permissions updated`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const updateUser = (userId, values) => {
    setProcessing(true)

    const payload = {
      ...(values && values.middleName
        ? { ...values, middleName: values.middleName }
        : omit(values, ["middleName"])),
    }

    User.updateUser(userId, payload)
      .then(user => {
        setUser(user)
        toast.success(`${user.fullName} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disableUser = data => {
    setProcessing(true)

    const payload = {
      ...(data && data.middleName
        ? {
            ...omit(data, [
              "id",
              "isMobileNumberVerified",
              "isEmailVerified",
              "dateCreated",
              "otpCode",
            ]),
            status: "Disabled",
          }
        : {
            ...omit(data, [
              "id",
              "isMobileNumberVerified",
              "isEmailVerified",
              "dateCreated",
              "otpCode",
              "middleName",
            ]),
            status: "Disabled",
          }),
    }

    User.updateUser(data.id, payload)
      .then(user => {
        setUser(user)
        toast.success(`${user.fullName} account has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableUser = data => {
    setProcessing(true)

    const payload = {
      ...(data && data.middleName
        ? {
            ...omit(data, [
              "id",
              "isMobileNumberVerified",
              "isEmailVerified",
              "dateCreated",
              "otpCode",
            ]),
            status: "Active",
          }
        : {
            ...omit(data, [
              "id",
              "isMobileNumberVerified",
              "isEmailVerified",
              "dateCreated",
              "otpCode",
              "middleName",
            ]),
            status: "Active",
          }),
    }

    User.updateUser(data.id, payload)
      .then(user => {
        setUser(user)
        toast.success(`${user.fullName} account has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const onClickEdit = data => {
    setUser(data)
    setIsEdit(true)
    toggle()
  }

  const onClickEditPermission = data => {
    setUser(data)
    onClickPermissionModal()
  }

  const getHeaderActions = () => {
    const canManageUsers = userPermissions.CAN_MANAGE_USERS
    const isActive = user.status === "Active"
    const resendInvitation = user.status === "Disabled"
    const canDisableEnable = userPermissions.CAN_MANAGE_USERS &&
      user.isEmailVerified &&
      user.isMobileNumberVerified;

    const actions = [
      canManageUsers && {
        label: "Edit",
        icon: "mdi mdi-pencil",
        onClick: () => onClickEdit(user),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      canManageUsers && {
        label: "Permissions",
        icon: "mdi mdi-security",
        onClick: () => onClickEditPermission(user),
        variant: BUTTON_VARIANT.SECONDARY,
      },
      resendInvitation && {
        label: "Resend",
        icon: "mdi mdi-send",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.SECONDARY,
      },
      canDisableEnable && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () => (isActive ? disableUser(user) : enableUser(user)),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  // Combine all roles from roles & company roles
  const combinedRoles = [...roles, ...companyRoles]

  // Omit "SUPER_ADMIN" and "VENDOR" from combined roles
  const filteredRoles = combinedRoles.filter(
    ({ value }) => value !== "SUPER_ADMIN" && value !== "VENDOR"
  )

  const availableRoles = [
    {
      status: "Default",
      label: "Select company role",
      value: "default_value",
      dateCreated: "2023-07-03T00:00:00Z",
      id: "default_id",
    },
    ...filteredRoles,
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("User details")}
            breadcrumbItem={props.t("User details")}
          />

          {/* USER MODAL */}
          <UserModal
            modalIsOpen={modal}
            onClickClose={toggle}
            user={user}
            isEdit={isEdit}
            updateUser={updateUser}
            companyId={user.companyId}
            countries={countries}
            roles={availableRoles}
            warehouses={warehouses}
            toggle={toggle}
          />

          {/* PERMISSION MODAL */}
          <PermissionModal
            modalIsOpen={permissionModal}
            onClickClose={onClickPermissionModal}
            user={user}
            profile={profile}
            permissions={permissions}
            handlePermissionChange={handlePermissionChange}
            updateUserPermissions={updateUserPermissions}
            onClickPermissionModal={onClickPermissionModal}
          />

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"User details"}
            description={"We are processing your request, please wait..."}
          />

          {!loading && (
            <Row>
              <Card>
                <CardBody>
                  {/* PAGE HEADER */}
                  <PageHeader
                    avatar={user && { initials: user.fullName, image: user.image?.file }}
                    title={user && user.fullName}
                    showBackButton={true}
                    actions={<HeaderActions actions={getHeaderActions()} />}
                    info={
                      <PageHeaderInfo>
                        {!isEmpty(user) && (
                          <>
                            <div className="mt-2">{getStatus(user.status)}</div>
                            <PageHeaderInfoText className="fw-semibold">
                              {getCompanyRole(user.companyRole)}
                            </PageHeaderInfoText>
                          </>
                        )}
                      </PageHeaderInfo>
                    }
                  />

                  <div className="hevara-user-data">
                    <div className="tx-grid mb-3">
                      <div className="mb-4">
                        <h5 className="text-muted">Personal details</h5>

                        <div className="detail-grid">
                          <DataItem
                            label={props.t("First name")}
                            value={user.firstName}
                          />

                          <DataItem
                            label={props.t("Middle name")}
                            value={user.middleName}
                          />

                          <DataItem
                            label={props.t("Last name")}
                            value={user.lastName}
                          />

                          <DataItem
                            label={props.t("Email address")}
                            value={user.email}
                          />

                          <DataItem
                            label={props.t("Phone")}
                            value={getLastFourDigits(user.mobileNumber)}
                          />

                          <DataItem
                            label={props.t("Country")}
                            value={user.countryName}
                          />
                        </div>
                      </div>

                      {user.companyRole === ROLES.ROUTE_AGENT && (
                        <div>
                          <h5 className="text-muted">User vehicles</h5>
                          {!isEmpty(fleets) ? (
                            <div>
                              {map(fleets.slice(0, 3), fleet => (
                                <VehicleItem
                                  key={`card-${fleet.id}`}
                                  vehicle={fleet}
                                />
                              ))}

                              {fleets.length > 3 && (
                                <LinkLayout className="mt-3">
                                  <Link onClick={() => navigate(`/vehicles/${user.companyId}`)}>
                                    View all vehicles
                                  </Link>
                                </LinkLayout>
                              )}
                            </div>
                          ) : (
                            <div className="d-flex center-y center-x permission-box justify-content-center py-4">
                              <p className="text-center fw-semibold mb-0">
                                This user does not have vehicles yet.
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center">
                    {/* ERROR STATE */}
                    <EmptyErrorState
                      visible={error}
                      title={`Something went wrong`}
                      description={`An error has occurred. Please try again later or contact hevara for support`}
                    />
                  </div>
                </CardBody>
              </Card>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

UserDetails.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(UserDetails)

import axios from "axios"
import API from "config/api.config"

export default {
  registerUser(values) {
    return axios
      .post(API.getApi().ONBOARDING.REGISTER, values)
      .then(async ({ data }) => {
        return data
      })
  },
}

import React from 'react';
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "components/Common/ChartsDynamicColor";

const CardWidgetCharts = ({ dataColors, series }) => {
    var areacharteathereumColors = getChartColorsArray(dataColors);

    var options = {
      chart: {
        width: 130,
        height: 46,
        type: "area",
        sparkline: {
            enabled: true,
        },
        toolbar: {
            show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 1.5,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.45,
          opacityTo: 0.05,
          stops: [50, 100, 100, 100],
        },
      },
      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function (seriesName) {
              return '';
            }
          }
        },
        marker: {
          show: false
        }
      },
      colors: areacharteathereumColors,
    };

    return (
      <React.Fragment>
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height="46"
          width="130"
          className="apex-charts"
        />
      </React.Fragment>
    );
};

export { CardWidgetCharts };
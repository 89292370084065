import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { isEmpty } from "lodash"
import { Row, Col } from "reactstrap"

import {
  PrimaryButton,
  SecondaryButton,
  ButtonsLayout,
  BUTTON_SIZE,
} from "components/Button"
import Modal, { MODAL_SIZE } from "./Modal"
import { MODAL_STRETCH } from "./Modal.styles"

export const ModalFixedButtonsLayout = styled(ButtonsLayout).attrs({
  className: "modal-fixed-buttons",
})`
  position: sticky;
  bottom: 0;
  left: 16px;
  right: 16px;
  align-items: flex-start;
  margin-top: 24px;
  margin-bottom: 24px;
  background: "#FFFFFF";
`

const DefaultModalButtons = props => {
  return (
    <Row id="modal-buttons">
      <Col>
        <div className="hstack gap-2 mb-0 mt-4">
          <PrimaryButton
            id="modal-confirm-button"
            onClick={props.onClickConfirm}
            size={props.buttonsSize}
            disabled={props.disableConfirmBtn}
          >
            {props.confirmTxt || "Accept"}
          </PrimaryButton>
          <SecondaryButton
            id="modal-cancel-button"
            type="button"
            size={props.buttonsSize}
            onClick={props.onClickClose}
          >
            {props.cancelTxt || "Cancel"}
          </SecondaryButton>
        </div>
      </Col>
    </Row>
  )
}

const BaseModal = props => {
  return (
    <Modal
      visible={props.modalIsOpen}
      avoidCloseModal={props.avoidCloseModal}
      close={props.onClickClose}
      title={props.title}
      size={props.size}
      stretch={props.stretch}
      contentClassName={props.contentClassName}
      overlayClassName={props.overlayClassName}
      footer={
        !props.hideButtons && isEmpty(props.footer) ? (
          <DefaultModalButtons {...props} />
        ) : (
          props.footer
        )
      }
    >
      {props.children}
    </Modal>
  )
}

BaseModal.apply.prototypes = {
  modalIsOpen: PropTypes.bool,
  avoidCloseModal: PropTypes.bool,
  onClickClose: PropTypes.func,
  onCloseConfirm: PropTypes.func,
  contentClassName: PropTypes.string,
  title: PropTypes.string,
  hideButtons: PropTypes.bool,
  cancelTxt: PropTypes.string,
  confirmTxt: PropTypes.string,
  children: PropTypes.node,
  footer: PropTypes.node,
  buttonsSize: PropTypes.oneOf([...Object.values(BUTTON_SIZE)]),
  size: PropTypes.oneOf([...Object.values(MODAL_SIZE)]),
  stretch: PropTypes.oneOf([...Object.values(MODAL_STRETCH)]),
}

const SmallModal = ({ size, ...props }) => {
  return (
    <BaseModal size={MODAL_SIZE.S} buttonsSize={BUTTON_SIZE.S} {...props} />
  )
}

const MediumModal = ({ size, ...props }) => {
  return (
    <BaseModal size={MODAL_SIZE.M} buttonsSize={BUTTON_SIZE.S} {...props} />
  )
}

const LargeModal = ({ size, ...props }) => {
  return <BaseModal size={MODAL_SIZE.L} {...props} />
}

LargeModal.defaultProps = {
  stretch: MODAL_STRETCH.CONDENSED,
}

export { SmallModal, MediumModal, LargeModal, MODAL_SIZE, MODAL_STRETCH }

import React from "react"
import { isEmpty, map } from "lodash"
import { Link } from "react-router-dom"

import TableRow, { MainInfoList, TableRowInfo } from "components/TableRow"
import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"
import { getLastFourDigits, getStatus } from "utils/utils"

import "./customerListItem.scss"

const CustomerListItem = ({ item, actions }) => {
  return (
    <TableRow>
      <Link
        key={item.id}
        to={`/customer-details/${item.id}`}
        className="hevara-user-list-item reset-link text-tertiary"
      >
        {/* MAIN INFO */}
        <MainInfoList
          title={`${item.firstName} ${item.lastName}`}
          subtitle={item.customerType}
          avatar={{
            image: item.image?.file,
            initials: `${item.firstName} ${item.lastName}`,
          }}
          className="text-truncate"
        />

        {/* PHONE & EMAIL */}
        <TableRowInfo
          className="text-truncate"
          text={`Phone number`}
          subtext={getLastFourDigits(item.phone)}
        />

        <div className="status">
          <div className="status-list">
            <TableRowInfo
              className="text-truncate"
              text={"Email address"}
              subtext={item.email ? item.email : "_"}
            />
          </div>

          <div className="actions-wrapper">
            {/* ISVAILABLE */}
            {!isEmpty(item.status) && getStatus(item.status)}

            {/* ACTIONS */}
            <ContextualModal
              className="feature-actions"
              style={{ marginLeft: 4 }}
              position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
              contentElement={map(actions, (modalItem, index) => (
                <ContextualModalListItem
                  key={index}
                  {...modalItem}
                  onClick={e => modalItem.onClick(e, item)}
                />
              ))}
            />
          </div>
        </div>
      </Link>
    </TableRow>
  )
}

CustomerListItem.defaultProps = {
  item: {},
  actions: [],
}

export default CustomerListItem

import React from "react";
import { isNil } from "lodash";
import { TableRowAmount as HevaraTableRowAmount } from "./Amount";

import { formatAmount } from "utils/utils";

const TableRowAmount = ({ amount, subamount, isPositive, strikeThrough, sign, className, style, amountCurrency, subamountCurrency, locale }) => {
  return (
    <HevaraTableRowAmount
      amount={`${sign ? sign : ''}${formatAmount(amount, locale, amountCurrency)}`}
      subamount={!isNil(subamount) ? `${sign ? sign : ''}${formatAmount(subamount, locale, subamountCurrency)}` : ''}
      isPositive={isPositive}
      strikethrough={strikeThrough}
      className={className}
      style={style}
    />
  )
}

TableRowAmount.defaultProps = {
  subamount: undefined,
  subamountCurrency: undefined,
  isPositive: false,
  strikeThrough: false,
  sign: undefined,
  className: '',
  style: {}
}

export default TableRowAmount;
import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getNotificationPreferences(limit = 10, page = 1, userId) {
    const parameters = {
      userId,
      limit,
      page,
    }
    const url = queryParams(
      API.getApi().NOTIFICATION_PREFERENCE.GET_PREFERENCES,
      parameters
    )
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  createNotificationPreference(values) {
    return axios
      .post(API.getApi().NOTIFICATION_PREFERENCE.ADD_PREFERENCE, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateNotificationPreference(notificationPreferenceId, values) {
    return axios
      .patch(
        `${
          API.getApi().NOTIFICATION_PREFERENCE.UPDATE_PREFERENCE
        }/${notificationPreferenceId}`,
        values
      )
      .then(async ({ data }) => {
        return data
      })
  },
}

import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { isEmpty, omit, map } from "lodash"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { Card, CardBody, Container, Row } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import { EmptyErrorState } from "components/EmptyState"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import DataItem from "components/DataItem"
import { getLastFourDigits, getStatus } from "utils/utils"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import { BUTTON_VARIANT } from "components/Button"
import Auth from "models/auth"
import CustomerModal from "./customerModal"
import Customer from "models/customer"

import "./customer.scss"

const CustomerDetails = props => {
  //meta title
  document.title = "Customer details | hevara DMS Platform"

  const { customerId } = useParams()

  const navigate = useNavigate()

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [customer, setCustomer] = useState({})
  const [userProfile, setUserProfile] = useState({})

  const fetchCustomerDetails = id => {
    Customer.getCustomer(id)
      .then(data => {
        setCustomer(data)
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()

    if (customerId && profile) {
      fetchCustomerDetails(customerId)
      setUserProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [customerId])

  const updateCustomer = (customerId, values) => {
    setProcessing(true)

    Customer.updateCustomer(customerId, values)
      .then(customer => {
        setCustomer(customer)
        toast.success(`${customer.firstName} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disableCustomer = data => {
    setProcessing(true)

    const payload = {
      ...omit(data, ["id", "dateCreated"]),
      status: "Disabled",
    }

    Customer.updateCustomer(data.id, payload)
      .then(customer => {
        setCustomer(customer)
        toast.success(`${customer.firstName} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableCustomer = data => {
    setProcessing(true)

    const payload = {
      ...omit(data, ["id", "dateCreated"]),
      status: "Active",
    }

    Customer.updateCustomer(data.id, payload)
      .then(customer => {
        setCustomer(customer)
        toast.success(`${customer.firstName} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  const onClickEdit = () => {
    setIsEdit(true)
    toggle()
  }

  const getHeaderActions = () => {
    const isActive = customer.status === "Active"

    const actions = [
      userProfile.permission.CAN_MANAGE_USERS && {
        label: "Edit",
        icon: "mdi mdi-pencil",
        onClick: () => onClickEdit(customer),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userProfile.permission.CAN_MANAGE_USERS && {
        label: "Create order",
        icon: "mdi mdi-clipboard-list-outline",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.ACCENT,
      },
      userProfile.permission.CAN_MANAGE_USERS && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () =>
          isActive ? disableCustomer(customer) : enableCustomer(customer),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Customer details")}
            breadcrumbItem={props.t("Customer details")}
          />

          {/* CUSTOMER MODAL */}
          <CustomerModal
            modalIsOpen={modal}
            onClickClose={toggle}
            customer={customer}
            isEdit={isEdit}
            updateCustomer={updateCustomer}
            companyId={userProfile.companyId}
            toggle={toggle}
          />

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Customer details"}
            description={"We are processing your request, please wait..."}
          />

          {!loading && (
            <Row>
              <Card>
                <CardBody>
                  {/* PAGE HEADER */}
                  <PageHeader
                    avatar={
                      customer && {
                        initials: `${customer.firstName} ${customer.lastName}`,
                        image: customer?.image?.file,
                      }
                    }
                    title={
                      customer && `${customer.firstName} ${customer.lastName}`
                    }
                    showBackButton={true}
                    actions={<HeaderActions actions={getHeaderActions()} />}
                    info={
                      <PageHeaderInfo>
                        {!isEmpty(customer) && (
                          <>
                            <div className="mt-2">
                              {getStatus(customer.status)}
                            </div>

                            <PageHeaderInfoText className="fw-semibold">
                              {customer.customerType}
                            </PageHeaderInfoText>
                          </>
                        )}
                      </PageHeaderInfo>
                    }
                  />

                  <div className="hevara-user-data">
                    <div className="tx-grid mb-3">
                      <div className="mb-4">
                        <h5 className="text-muted mb-3">Customer details</h5>

                        <div className="detail-grid">
                          <DataItem
                            label={props.t("First name")}
                            value={customer.firstName}
                          />

                          <DataItem
                            label={props.t("Last name")}
                            value={customer.lastName}
                          />

                          <DataItem
                            label={props.t("Email address")}
                            value={customer.email}
                          />

                          <DataItem
                            label={props.t("Phone")}
                            value={getLastFourDigits(customer.phone)}
                          />

                          <DataItem
                            label={props.t("Location")}
                            value={customer.location}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center">
                    {/* ERROR STATE */}
                    <EmptyErrorState
                      visible={error}
                      title={`Something went wrong`}
                      description={`An error has occured. Please try again later or contact hevara for support`}
                    />
                  </div>
                </CardBody>
              </Card>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

CustomerDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CustomerDetails)

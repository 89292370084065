import React from "react"
import styled from "styled-components"

import { BodyText, SmallText } from "components/atoms"
import { getTableRowAmountStyles } from "./Amount.styles"

const StyledTableRowAmount = styled.div.attrs({
  className: "hevara-table-row-amount",
})`
  ${getTableRowAmountStyles}
`

const StyledTitle = styled(BodyText).attrs({
  className: "hevara-table-row-amount-title",
})``

const StyledSubTitle = styled(SmallText).attrs({
  className: "hevara-able-row-amount-subtitle",
})``

export const TableRowAmount = ({
  amount,
  subamount,
  isPositive,
  strikethrough,
  className,
  style,
}) => {
  return (
    <StyledTableRowAmount
      className={className}
      style={style}
      isPositive={isPositive}
      strikethrough={strikethrough}
    >
      <StyledTitle>{amount}</StyledTitle>
      {!!subamount && <StyledSubTitle>{subamount}</StyledSubTitle>}
    </StyledTableRowAmount>
  )
}

TableRowAmount.defaultProps = {
  subamount: undefined,
  isPositive: false,
  strikethrough: false,
  className: "",
  style: {},
}

import { merge } from "lodash";

import { defaultTheme } from "./theme";

export const createTheme = (theme) => {
  if (Array.isArray(theme) || typeof theme !== 'object'){
    throw new Error('Please make your "theme" argument an object.')
  }

  return merge({}, defaultTheme, theme)
}

export const getTheme = (theme) => {
  if (theme && Object.keys(theme).length) {
    return theme;
  } else {
    return defaultTheme;
  }
}

export const getFontColorVariant = (theme, colorVariant) => {
  return theme.color.general.font[colorVariant];
}

export const getFontColor = (theme, colorVariant) => {
  return `color: ${getFontColorVariant(theme, colorVariant ? colorVariant : "primary")};`
}

export const getFontFamily = (theme) => {
  return `font-family: ${theme.typography.fontFamily};`
}

export const getTextStyles = (props) => {
  const theme = getTheme(props.theme);

  return getFontColor(theme, props.colorVariant) + getFontFamily(theme);
}

export const SCROLLBAR_WIDTH = 16;

export const getScrollBarStyles = (props) => {
  const theme = getTheme(props.theme);

  return `
    ::-webkit-scrollbar {
      width: ${SCROLLBAR_WIDTH}px;
    }
    ::-webkit-scrollbar-thumb {
      width: 4px;
      background: ${theme.color.general.scroll_bar};
      border: 6px solid transparent;
      border-radius: 8px;
      background-clip: padding-box;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
  `
}
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { SecondaryIconButton } from "components/Button"
import ProgressBar from "components/ProgressBar"
import { BodyTextBold } from "components/atoms"

import "./style.scss"

const StyledContainer = styled.div`
  position: sticky;
  z-index: 1;
  top: 0;
  background-color: "#f8f8fb";
`

const MultiStepHeader = ({
  progress,
  title,
  onClickBack,
  onClickClose,
  hideBack,
}) => {
  return (
    <StyledContainer style={{ backgroundColor: "#f8f8fb" }}>
      <div className="hevara-multistep-header d-flex py-3">
        {/* GO BACK */}
        <SecondaryIconButton
          name="mdi mdi-chevron-left"
          onClick={onClickBack}
          style={{
            visibility: hideBack ? "hidden" : "visible",
            transition: "none",
          }}
        />

        {/* TITLE */}
        <BodyTextBold>{title}</BodyTextBold>

        {/* CLOSE */}
        <SecondaryIconButton name="mdi mdi-close" onClick={onClickClose} />
      </div>

      {/* PROGRESS BAR */}
      <ProgressBar progress={progress} />
    </StyledContainer>
  )
}

MultiStepHeader.propTypes = {
  progress: PropTypes.number,
  title: PropTypes.string,
  onClickBack: PropTypes.func,
  onClickClose: PropTypes.func,
  hideBack: PropTypes.bool,
}

export default MultiStepHeader

const getTheme = {
  color: {
    progress_and_loading: {
      main: "#F4CC0C",
      background: "#f4f4f4",
    },
  },
}

export const getProgressWidth = props => {
  const indicator =
    props.progress > 100 ? 100 : props.progress < 0 ? 0 : props.progress
  return `width: ${indicator}%;`
}

export const getProgressBarStyles = props => {
  const theme = getTheme

  return `
    display: flex;
    flex-direction: column;
    .hevara-progress-bar {
      height: 4px;
      width: 100%;
      background-color: ${theme.color.progress_and_loading.background};
      border-radius: 4px;
    }
    .hevara-progress-bar-indicator {
      height: 4px;
      ${getProgressWidth(props)};
      transition: width 250ms linear;
      background-color: ${theme.color.progress_and_loading.main};
      border-radius: 4px;
    }
    .hevara-progress-bar-values-row {
      display: flex;
      flex-direction: row;
      justify-content: ${!!props.startValue ? `space-between` : `flex-end`};
      margin-top: 12px;
    }
  `
}

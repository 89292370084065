import React from "react";
import PropTypes from "prop-types";

import Chip, { CHIP_SIZES, CHIP_VARIANTS } from "components/Chip";
import './itemListChip.scss';

function ItemListChip({ className, ...props }) {
  return (
    <React.Fragment>
      <Chip {...props} className={`item-list-chip-desktop ${className}`} size={CHIP_SIZES.M} />
      <Chip {...props} className={`item-list-chip-mobile ${className}`} size={CHIP_SIZES.S} />
    </React.Fragment>
  )
}

ItemListChip.prototypes = {
  variant: PropTypes.oneOf([...Object.values(CHIP_VARIANTS)]).isRequired,
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
}

ItemListChip.defaultProps = {
  variant: CHIP_VARIANTS.SUCCESS,
}

export default ItemListChip;
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { chain, isEmpty, orderBy } from "lodash"
import { withTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { useNavigate } from "react-router-dom"
import {
  Col,
  Row,
  Card,
  CardBody,
  Container
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"
import EmptyState, { EmptyErrorState } from "components/EmptyState"
import OrderListItem from "components/itemList/orderListItem/orderListItem"
import Auth from "models/auth"
import Suborders from "models/suborders"
import Company from "models/company"
import OrderStatus from "models/orderStatus"
import EditStatusModal from "./editStatusModal"

const Orders = props => {
  //meta title
  document.title = "Orders | hevara DMS Platform"

  const navigate = useNavigate()

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalResults, setTotalResults] = useState(0)
  const [showStatusModal, setShowStatusModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const [orders, setOrders] = useState([])
  const [order, setOrder] = useState(null)
  const [companyInfo, setCompanyInfo] = useState({})
  const [orderStatus, setOrderStatus] = useState([])

  const fetchCompanyInfo = id => {
    Company.getCompany(id)
      .then(data => {
        setCompanyInfo(data)
        fetchSubOrders(data.id, data.type)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchSubOrders = id => {
    const filters = {
      companyId: id,
    }

    Suborders.getSubOrders(limit, page, filters)
      .then(data => {
        setOrders(data.results)
        setTotalPages(data.totalPages)
        setTotalResults(data.totalResults)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchOrderStatuses = () => {
    OrderStatus.lookUpOrderStatuses()
      .then(data => {
        setOrderStatus([
          {
            value: "",
            label: "Select status",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()

    if (profile) {
      const companyId = JSON.parse(profile).companyId
      Promise.all([fetchCompanyInfo(companyId), fetchOrderStatuses()])
      setUserProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  const updateOrderStatus = values => {
    setProcessing(true)

    Suborders.updateSubOrder(order.id, values)
      .then(order => {
        setOrders(
          orders.map(item =>
            item.id.toString() === order.id.toString()
              ? { item, ...order }
              : item
          )
        )
        toast.success(`Order updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const toggle = () => {
    if (showStatusModal) {
      setShowStatusModal(false)
      setOrder(null)
    } else {
      setShowStatusModal(true)
    }
  }

  const getListActions = item => {
    const actions = [
      {
        label: "View details",
        icon: "mdi mdi-monitor-eye",
        onClick: () => navigate(`/order-details/${item.id}/${item.orderId}`),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userProfile.permission.CAN_MANAGE_PRODUCTS && {
        label: "Update status",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => {
          setOrder(item)
          setShowStatusModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userProfile.permission.CAN_MANAGE_PRODUCTS && {
        label: "Delete",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.CRITICAL,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userProfile.permission.CAN_MANAGE_PRODUCTS && {
        label: `New order`,
        icon: "mdi mdi-plus",
        onClick: () => navigate(`/new-order/${companyInfo.type}`),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getNextPage = () => {
    setLimit(limit + 10)
  }

  const filteredOrders = chain(orders)
    .filter(order => {
      // order Type
      const hasLabel = order.name?.toLowerCase().includes(name.toLowerCase())

      return hasLabel
    })
    .value()

  const data = orderBy(filteredOrders, [p => p.name?.toUpperCase()], "asc")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Orders")}
            breadcrumbItem={props.t("Orders")}
          />

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Orders"}
            description={"We are processing your request, please wait..."}
          />

          {/* EDIT STATUS MODAL */}
          <EditStatusModal 
            showStatusModal={showStatusModal}
            toggle={toggle}
            updateOrderStatus={updateOrderStatus}
            suborderDetails={order}
            orderStatus={orderStatus}
          />

          {!loading && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Orders"}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        !isEmpty(orders) && (
                          <PageHeaderInfo>
                            <PageHeaderInfoText className="fw-semibold">
                              {`${orders.length} ${
                                orders.length > 1 ? "Orders" : "Order"
                              }`}
                            </PageHeaderInfoText>
                          </PageHeaderInfo>
                        )
                      }
                    />

                    {/* LIST */}
                    {!isEmpty(orders) && (
                      <SectionList
                        key={"all"}
                        data={groupListByKey(data, "name", true)}
                        hasNextPage={totalPages > 1}
                        getNextPage={getNextPage}
                        loadingPage={loading}
                        loading={loading}
                        renderItem={item => (
                          <OrderListItem
                            item={item}
                            actions={getListActions(item)}
                          />
                        )}
                      />
                    )}

                    <div className="d-flex justify-content-center align-items-center">
                      {/* EMPTY STATE */}
                      <EmptyState
                        visible={isEmpty(companyInfo) || isEmpty(orders)}
                        title={`No orders`}
                        description={`Click on the button below to create.`}
                        firstButton={
                          userProfile.permission.CAN_MANAGE_PRODUCTS && {
                            label: `New order`,
                            onClick: () =>
                              navigate(`/new-order/${companyInfo.type}`),
                            disabled: processing,
                          }
                        }
                      />

                      {/* ERROR STATE */}
                      <EmptyErrorState
                        visible={error}
                        title={`Something went wrong`}
                        description={`An error has occurred. Please try again later or contact hevara for support`}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

Orders.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Orders)

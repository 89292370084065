import React from "react"
import PropTypes from "prop-types"

import { TOOLTIP_POSITIONS } from "./Tooltip.styles"
import { Tooltip as HevaraTooltip, TooltipText } from "./Tooltip"

const Tooltip = ({ position, content, title, children }) => {
  return (
    <HevaraTooltip position={position} content={content} title={title}>
      {children}
    </HevaraTooltip>
  )
}

Tooltip.prototypes = {
  children: PropTypes.node,
  position: PropTypes.oneOf([
    TOOLTIP_POSITIONS.TOP,
    TOOLTIP_POSITIONS.BOTTOM,
    TOOLTIP_POSITIONS.LEFT,
    TOOLTIP_POSITIONS.RIGHT,
  ]),
  content: PropTypes.node,
  title: PropTypes.string,
}

Tooltip.defaultProps = {
  position: TOOLTIP_POSITIONS.TOP,
}

export { Tooltip as default, TooltipText, TOOLTIP_POSITIONS }

import React from "react"
import { isEmpty, map } from "lodash"
import { Link } from "react-router-dom"

import TableRow, { MainInfoList, TableRowInfo } from "components/TableRow"
import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"
import {
  getPaymentStatus,
  getOrderStatus,
  getCustomerTypeLabel,
} from "utils/utils"

import "./styles.scss"

const DispatchListItem = ({ item, actions }) => {
  const url = `/dispatch-details/${item.id}/${item.orderId}`
  const fullName = `${item.customer.firstName} ${item.customer.lastName}`

  return (
    <TableRow>
      <Link
        key={item.id}
        to={url}
        className="hevara-dispatch-list-item reset-link text-tertiary"
      >
        {/* ORDER CODE & ORDER TYPE */}
        <MainInfoList
          title={fullName}
          subtitle={getCustomerTypeLabel(item.customer.customerType)}
          avatar={{ image: item.customer?.image?.file, initials: fullName }}
          className="text-truncate"
        />

        <TableRowInfo
          className="text-truncate"
          text={"Amount"}
          subtext={`Ksh. ${item.totalSubOrderPrice.toLocaleString()}`}
        />

        <div className="status">
          <div className="status-list">
            {/* PAYMENT STATUS */}
            <TableRowInfo
              className="text-truncate"
              text={"Payment"}
              subtext={getPaymentStatus(item.paymentStatus)}
            />
          </div>

          <div className="actions-wrapper">
            {/* ORDER STATUS */}
            {!isEmpty(item.status) && getOrderStatus(item.status)}

            {/* ACTIONS */}
            <ContextualModal
              className="feature-actions"
              style={{ marginLeft: 4 }}
              position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
              contentElement={map(actions, (modalItem, index) => (
                <ContextualModalListItem
                  key={index}
                  {...modalItem}
                  onClick={e => modalItem.onClick(e, item)}
                />
              ))}
            />
          </div>
        </div>
      </Link>
    </TableRow>
  )
}

DispatchListItem.defaultProps = {
  user: {},
  actions: [],
}

export default DispatchListItem

import axios from "axios"
import API from "config/api.config"

export default {
  getProductsView(values) {
    const parameters = {
      companyId: values.companyId,
      ...(values.manufacturerId ? { manufacturerId: values.manufacturerId } : {}),
      ...(values.distributorId ? { distributorId: values.distributorId } : {}),
    };
    return axios.post(API.getApi().PRODUCTS_VIEW.PRODUCTS_VIEW, parameters)
      .then(async ({ data }) => {
        return data
      })
  },
}

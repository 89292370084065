import React from "react";
import styled from "styled-components";

import CONST from "../constants";
import { getTextStyles } from "components/theme/themeUtils";

const { SIZE, FONT: { WEIGHT, VARIANT }, MEDIA} = CONST;

const getPreHeadingSizes = (props) => {
  switch (props.size) {
    case SIZE.S:
      return `font-size: 12px;
              line-height: 16px;`;
    case SIZE.M:
      return `font-size: 18px;
              line-height: 24px;`;
  }
}

const getHeadingSizes = (props) => {
  switch (props.size) {
    case SIZE.S:
      return `font-size: 24px;
              line-height: 32px;
              ${MEDIA.MOBILE} {
                font-size: 20px;
                line-height: 28px;
              }`
    case SIZE.M:
      return `font-size: 32px;
              line-height: 40px;
              ${MEDIA.MOBILE} {
                font-size: 28px;
                line-height: 32px;
              }`
    case SIZE.L:
      return `font-size: 40px;
              line-height: 48px;
              ${MEDIA.MOBILE} {
                font-size: 32px;
                line-height: 40px;
              }`
    case SIZE.XL:
      return `font-size: 48px;
              line-height: 56px;
              ${MEDIA.MOBILE} {
                font-size: 40px;
                line-height: 48px;
              }`
  }
}

const getTextSizes = (props) => {
  switch (props.size) {
    case SIZE.S:
      return `font-size: 14px;
              line-height: 20px;`;
    case SIZE.M:
      return `font-size: 16px;
              line-height: 24px;`;
    case SIZE.L:
      return `font-size: 20px;
              line-height: 28px;
              ${MEDIA.MOBILE} {
                font-size: 18px;
              }`
  }
}

const getCaptionSizes = (props) => {
  if (props.size === SIZE.S) {
    return `font-size: 10px;
            line-height: 12px;`;
  }
}

const getAmountSizes = () => {
  return `
    font-size: 32px;
    line-height: 40px;
    ${MEDIA.MOBILE} {
      font-size: 20px;
      line-height: 28px;
    }`
}

export const getFontSizes = (props) => {
  switch (props.variant) {
    case VARIANT.PRE_HEADING:
      return getPreHeadingSizes(props);
    case VARIANT.HEADING:
      return getHeadingSizes(props);
    case VARIANT.TEXT:
      return getTextSizes(props);
    case VARIANT.CAPTION:
      return getCaptionSizes(props);
    case VARIANT.AMOUNT:
      return getAmountSizes(props);
  }
}

export const getFontWeight = ({ weight }) => {
  switch (weight) {
    case WEIGHT.ITALIC:
      return `font-style: italic;
              font-weight: 400;`;
    case WEIGHT.REGULAR:
      return `font-style: normal;
              font-weight: 400;`;
    case WEIGHT.SEMI_BOLD:
      return `font-style: normal;
              font-weight: 600;`;
    case WEIGHT.BOLD:
      return `font-style: normal;
              font-weight: 700;`;
  }
}

const StyledText = styled.p.attrs({
  className: "hevara-basic-text"
})`
  margin: 0px;
  ${(props) => getFontSizes(props)}
  ${(props) => getFontWeight(props)}
  ${(props) => props.uppercase && `text-transform: uppercase;`}
  ${(props) => props.monospace && `font-feature-settings: 'tnum';`}
  ${getTextStyles}
`

const Text = ({ children, onClick, ...props }) => {
  return (
    <StyledText onClick={onClick} {...props}>
      {children}
    </StyledText>
  )
}

Text.defaultProps = {
  size: SIZE.M,
  weight: WEIGHT.REGULAR,
  uppercase: false,
  className: '',
  style: {},
  monospace: false,
  onClick: undefined
}

export default Text;
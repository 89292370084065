import axios from "axios"
import API from "config/api.config"

export default {
  addCompany(values) {
    return axios
      .post(API.getApi().COMPANIES.ADD_COMPANY, values)
      .then(async ({ data }) => {
        return data
      })
  },
  getCompany(companyId) {
    return axios
      .get(`${API.getApi().COMPANIES.GET_COMPANY}/${companyId}`)
      .then(async ({ data }) => {
        return data
      })
  },

  updateCompany(companyId, values) {
    return axios
      .patch(`${API.getApi().COMPANIES.UPDATE_COMPANY}/${companyId}`, values)
      .then(async ({ data }) => {
        return data
      })
  },
}

import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import moment from "moment"
import { Card, CardBody, Container, Row } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import DataItem from "components/DataItem"
import Loading from "components/Common/Spinner"
import { EmptyErrorState } from "components/EmptyState"
import Auth from "models/auth"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import { BUTTON_VARIANT } from "components/Button"
import { getLastFourDigits, getPaymentStatus } from "utils/utils"
import CONST from "utils/constants"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import BillingPayment from "models/billingPayment"
import API from "config/api.config"

const { PAYMENT_STATUS, DEFAULT_DATE_TIME_FORMAT } = CONST

const BillingDetails = props => {
  //meta title
  document.title = "Billing details | hevara DMS Platform"

  const { billingPaymentId } = useParams()

  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const [billingDetails, setBillingDetails] = useState({})

  const fetchBillingTransactionDetails = id => {
    BillingPayment.getBillingPayment(id)
      .then(data => {
        setBillingDetails(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()

    if (billingPaymentId && profile) {
      fetchBillingTransactionDetails(billingPaymentId)
      setUserProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [billingPaymentId])

  const getHeaderActions = () => {
    const showDownloadReceipt = billingDetails.paymentStatus === PAYMENT_STATUS.PAID;

    const actions = [
      showDownloadReceipt && {
        label: "Download receipt",
        icon: "mdi mdi-pencil",
        onClick: () => downloadPDF(),
        variant: BUTTON_VARIANT.SECONDARY,
        showInModal: true,
      }
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const downloadPDF = () => {
    setProcessing(true)

    const billingPaymentId = billingDetails.id

    const link = document.createElement("a")
    link.href = `${API.getApi().GENERATE_PDF.GET_BILLING_PAYMENT_PDF}/${billingPaymentId}`
    link.download = `billing_receipt_${billingDetails.invoice_number}_${moment().format("DDMM")}.pdf`

    // Append the link to the DOM and trigger the click
    document.body.appendChild(link)
    link.click()

    // Remove the link from the DOM
    document.body.removeChild(link)

    setProcessing(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Billing details")}
            breadcrumbItem={props.t("Billing details")}
          />

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Billing"}
            description={"We are processing your request, please wait..."}
          />

          {!loading && (
            <Row>
              <Card>
                <CardBody>
                  {/* PAGE HEADER */}
                  <PageHeader
                    title={billingDetails && billingDetails.billing_plan}
                    showBackButton={true}
                    showDivider={false}
                    actions={<HeaderActions actions={getHeaderActions()} />}
                    info={
                      billingDetails && (
                        <PageHeaderInfo>
                          <div className="mt-2">
                            {getPaymentStatus(billingDetails.paymentStatus)}
                          </div>
                          <PageHeaderInfoText className="fw-semibold">
                            {billingDetails.invoice_number}
                          </PageHeaderInfoText>

                          <PageHeaderInfoText className="fw-semibold">
                            {moment(billingDetails.dateCreated).format(DEFAULT_DATE_TIME_FORMAT)}
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      )
                    }
                  />

                  <div className="hevara-fleet-data">
                    <div className="tx-grid mb-3">
                      <div className="mb-4">
                        <h5 className="text-muted mb-4">Billing details</h5>

                        <div className="detail-grid">
                          <DataItem
                            label={props.t("Amount")}
                            value={`Ksh. ${billingDetails.billing_amount}`}
                          />

                          <DataItem
                            label={props.t("Invoice number")}
                            value={billingDetails.invoice_number}
                          />

                          <DataItem
                            label={props.t("Transaction number")}
                            value={getLastFourDigits(billingDetails.phoneNumber)}
                          />

                          <DataItem
                            label={props.t("Transaction date")}
                            value={moment(billingDetails.dateCreated).format(DEFAULT_DATE_TIME_FORMAT)}
                          />

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center">
                    {/* ERROR STATE */}
                    <EmptyErrorState
                      visible={error}
                      title={`Something went wrong`}
                      description={`An error has occurred. Please try again later or contact hevara for support`}
                    />
                  </div>
                </CardBody>
              </Card>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

BillingDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(BillingDetails)

import { spacing } from "components/atoms";
import { getTheme } from "components/theme/themeUtils";
import { defaultTheme } from "components/theme/theme";

export const DATA_AMOUNT_SIZES = {
  M: 'm',
  S: 's'
}

export const DATA_AMOUNT_WEIGHT = {
  BOLD: 'bold',
  REGULAR: 'regular'
}

export const getDataAmountStyles = () => {
  return `
    display: flex;
    flex-direction: column;
    & > *:not(:last-child) {
      ${spacing("mb", 1)}
    }
  `;
}

export const getRowStyles = () => {
  return `
    display: flex;
    flex-direction: row;
    align-items: center;
  `;
}

export const getIconStyles = () => {
  const theme = getTheme(defaultTheme);

  return `fill: ${theme.color.link.default}`;
}

export const getLabelStyles = () => {
  const theme = getTheme(defaultTheme);

  return `
    ${spacing("mr", 2)}
    color: ${theme.color.general.font.secondary};
  `
}

export const getCurrencyStyles = () => {
  const theme = getTheme(defaultTheme);

  return `
    color: ${theme.color.general.font.quaternary};
    align-self: flex-end;
    ${spacing("ml", 1)}
    ${spacing("mb", 0.5)}
  `
}
import React from "react"
import { isEmpty, map } from "lodash"
import { Link } from "react-router-dom"

import TableRow, { MainInfoList, TableRowInfo } from "components/TableRow"
import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"
import { getPaymentStatus, getOrderStatus, getCategoryTypeLabel } from "utils/utils"

import "./orderListItem.scss"

const OrderListItem = ({ item, actions }) => {
  const url = `/order-details/${item.id}/${item.orderId}`

  return (
    <TableRow>
      <Link
        key={item.id}
        to={url}
        className="hevara-product-list-item reset-link text-tertiary"
      >
        {/* ORDER CODE & ORDER TYPE */}
        <MainInfoList
          title={item.name}
          subtitle={getCategoryTypeLabel(item.categoryType)}
          avatar={{ image: item.image?.file, initials: item.name }}
          className="text-truncate"
        />

        <TableRowInfo
          className="text-truncate"
          text={"Amount"}
          subtext={`ksh ${item.totalSubOrderPrice.toLocaleString()}`}
        />

        <div className="status">
          <div className="status-list">
            {/* PAYMENT STATUS */}
            <TableRowInfo
              className="text-truncate"
              text={"Payment"}
              subtext={getPaymentStatus(item.paymentStatus)}
            />
          </div>

          <div className="actions-wrapper">
            {/* ORDER STATUS */}
            {!isEmpty(item.status) && getOrderStatus(item.status)}

            {/* ACTIONS */}
            <ContextualModal
              className="feature-actions"
              style={{ marginLeft: 4 }}
              position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
              contentElement={map(actions, (modalItem, index) => (
                <ContextualModalListItem
                  key={index}
                  {...modalItem}
                  onClick={e => modalItem.onClick(e, item)}
                />
              ))}
            />
          </div>
        </div>
      </Link>
    </TableRow>
  )
}

OrderListItem.defaultProps = {
  user: {},
  actions: [],
}

export default OrderListItem

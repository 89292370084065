import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { chain, isEmpty, omit, orderBy } from "lodash"
import { Col, Row, Card, CardBody, Container } from "reactstrap"
import { withTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import Auth from "models/auth"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"
import EmptyState, { EmptyErrorState } from "components/EmptyState"
import PaymentSettingListItem from "components/itemList/paymentSettingListItem/paymentSettingListItem"
import PaymentSetting from "models/paymentSettings"
import PaymentMethods from "models/paymentMethods"
import Warehouses from "models/warehouses"
import PaymentSettingsModal from "./paymentSettingsModal"

const PaymentSettings = props => {
  //meta title
  document.title = "Payment settings | hevara DMS Platform"

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const [paymentConfigs, setPaymentConfigs] = useState([])
  const [paymentMethods, setPaymentMethods] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [paymentConfig, setPaymentConfig] = useState(null)

  const fetchData = (limit, page, id) => {
    const filters = {
      companyId: id,
    }
    PaymentSetting.getPaymentConfigs(limit, page, filters)
      .then(data => {
        setPaymentConfigs(data.results)
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchPaymentMethods = () => {
    PaymentMethods.lookUpPaymentMethods()
      .then(data => {
        setPaymentMethods([
          {
            status: "Default",
            name: "Select payment method",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanyWarehouses = id => {
    Warehouses.lookUpCompanyWarehouses(id)
      .then(data => {
        setWarehouses([
          {
            status: "Default",
            warehouseName: "Select warehouse",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      const companyId = JSON.parse(profile).companyId
      Promise.all([
        fetchData(limit, page, companyId),
        fetchPaymentMethods(),
        fetchCompanyWarehouses(companyId),
      ])
      setUserProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  const addPaymentConfig = values => {
    setProcessing(true)
    PaymentSetting.addPaymentConfig(values)
      .then(paymentConfig => {
        setPaymentConfigs([...paymentConfigs, paymentConfig])
        toast.success(`${paymentConfig.mpesa_initiatorName} added successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const updatePaymentConfig = (paymentConfigId, values) => {
    setProcessing(true)
    PaymentSetting.updatePaymentConfig(paymentConfigId, values)
      .then(paymentConfig => {
        setPaymentConfigs(
          paymentConfigs.map(item =>
            item.id.toString() === paymentConfig.id.toString()
              ? { item, ...paymentConfig }
              : item
          )
        )
        toast.success(
          `${paymentConfig.mpesa_initiatorName} updated successfully`
        )
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enablePaymentConfig = data => {
    setProcessing(true)

    const payload = {
      ...omit(data, ["id", "companyName", "dateCreated"]),
      status: "Active",
    }

    PaymentSetting.updatePaymentConfig(data.id, payload)
      .then(data => {
        setPaymentConfigs(
          paymentConfigs.map(item =>
            item.id.toString() === data.id.toString() ? { ...data } : item
          )
        )
        toast.success(`${data.mpesa_initiatorName} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disablePaymentConfig = data => {
    setProcessing(true)

    const payload = {
      ...omit(data, ["id", "companyName", "dateCreated"]),
      status: "Disabled",
    }

    PaymentSetting.updatePaymentConfig(data.id, payload)
      .then(data => {
        setPaymentConfigs(
          paymentConfigs.map(item =>
            item.id.toString() === data.id.toString() ? { ...data } : item
          )
        )
        toast.success(`${data.mpesa_initiatorName} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const getListActions = item => {
    const isActive = item.status === "Active"

    const actions = [
      userProfile.permission.CAN_MANAGE_PAYMENT_CONFIGS && {
        label: "Edit",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => handleClick(item),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userProfile.permission.CAN_MANAGE_PAYMENT_CONFIGS && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () =>
          isActive ? disablePaymentConfig(item) : enablePaymentConfig(item),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userProfile.permission.CAN_MANAGE_PAYMENT_CONFIGS && {
        label: "New payment setting",
        icon: "mdi mdi-plus",
        onClick: () => {
          setIsEdit(false)
          setModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const handleClick = data => {
    setPaymentConfig(data)
    setIsEdit(true)
    toggle()
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setPaymentConfig(null)
    } else {
      setModal(true)
    }
  }

  const onClickNewPaymentSetting = () => {
    setIsEdit(false)
    setModal(true)
  }

  const filteredConfigs = chain(paymentConfigs)
    .filter(config => {
      // mpesa_initiatorName
      const hasLabel = config.mpesa_initiatorName
        ?.toLowerCase()
        .includes(name.toLowerCase())

      return hasLabel
    })
    .value()

  const data = orderBy(
    filteredConfigs,
    [p => p.mpesa_initiatorName.toUpperCase()],
    "asc"
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Payment settings")}
            breadcrumbItem={props.t("Payment settings")}
          />

          <PaymentSettingsModal
            modalIsOpen={modal}
            onClickClose={toggle}
            paymentConfig={paymentConfig}
            isEdit={isEdit}
            updatePaymentConfig={updatePaymentConfig}
            addPaymentConfig={addPaymentConfig}
            companyId={userProfile.companyId}
            paymentMethods={paymentMethods}
            warehouses={warehouses}
            toggle={toggle}
          />

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Payment settings"}
            description={"We are processing your request, please wait..."}
          />

          {!loading && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Payment settings"}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        !isEmpty(paymentConfigs) && (
                          <PageHeaderInfo>
                            <PageHeaderInfoText className="fw-semibold">
                              {`${paymentConfigs.length} ${
                                paymentConfigs.length > 1
                                  ? "Payment settings"
                                  : "Payment setting"
                              }`}
                            </PageHeaderInfoText>
                          </PageHeaderInfo>
                        )
                      }
                    />

                    {/* LIST */}
                    {!isEmpty(paymentConfigs) && (
                      <SectionList
                        key={"all"}
                        data={groupListByKey(data, "mpesa_initiatorName", true)}
                        renderItem={item => (
                          <PaymentSettingListItem
                            item={item}
                            actions={getListActions(item)}
                          />
                        )}
                      />
                    )}

                    <div className="d-flex justify-content-center align-items-center">
                      {/* EMPTY STATE */}
                      <EmptyState
                        visible={isEmpty(paymentConfigs)}
                        title={`No payment settings`}
                        description={`Click on the button below to create.`}
                        firstButton={
                          userProfile.permission.CAN_MANAGE_PAYMENT_CONFIGS && {
                            label: `New payment setting`,
                            onClick: onClickNewPaymentSetting,
                            disabled: processing,
                          }
                        }
                      />

                      {/* ERROR STATE */}
                      <EmptyErrorState
                        visible={error}
                        title={`Something went wrong`}
                        description={`An error has occured. Please try again later or contact hevara for support`}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

PaymentSettings.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(PaymentSettings)

import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import Switch from "react-switch"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { Card, CardBody, Container, Row } from "reactstrap"

import Loading from "components/Common/Spinner"
import Auth from "models/auth"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import NotificationPreference from "models/notificationPreference"
import { EmptyErrorState } from "components/EmptyState"
import PageHeader from "components/PageHeader"

import "./style.scss"

const primary = "#42bfc1"
const smooth_gray = "#cccccc"

/**
 * Notification Item
 */
const NotificationItem = ({ label, isChecked, onChange }) => (
  <div className="d-flex center-y center-x permission-box mt-3" key={label}>
    <Switch
      uncheckedIcon={false}
      checkedIcon={false}
      width={51}
      offColor={smooth_gray}
      onColor={primary}
      onChange={() => onChange(!isChecked)}
      checked={isChecked}
      className="me-2"
    />
    <span className="mt-1">{label}</span>
  </div>
)

const Notifications = props => {
  //meta title
  document.title = "Notifications | hevara DMS Platform"

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [preferences, setPreferences] = useState({
    sms: false,
    push: true,
    email: true,
  })
  const [profile, setProfile] = useState({})
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)

  const fetchNotificationPreferences = id => {
    NotificationPreference.getNotificationPreferences(limit, page, id)
      .then(data => {
        setPreferences(data.results[0])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()

    if (profile) {
      const userId = JSON.parse(profile).id
      fetchNotificationPreferences(userId)
      setProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [])

  const handleToggle = notificationType => {
    // Update the state based on the toggled notification type
    setPreferences(prevPreferences => ({
      ...prevPreferences,
      [notificationType]: !prevPreferences[notificationType],
    }))

    updateNotificationPreference(notificationType)
  }

  const updateNotificationPreference = notificationType => {
    setProcessing(true)

    const updatedPreferences = {
      userId: preferences.userId,
      sms: preferences.sms,
      push: preferences.push,
      email: preferences.email,
    }

    // Update only the changed notification type
    updatedPreferences[notificationType] = !preferences[notificationType]

    NotificationPreference.updateNotificationPreference(
      preferences.id,
      updatedPreferences
    )
      .then(data => {
        setPreferences(data)
        toast.success(`Notification preferences updated succesfully.`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  return (
    <React.Fragment>
      <div className="page-content hevara-notification-detail">
        <Container fluid>
          {/* Loading */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Notification preferences"}
            description={"We are processing your request, please wait..."}
          />

          {!loading && (
            <Row>
              <Card>
                <CardBody>
                  {/* PAGE HEADER */}
                  <PageHeader
                    title={`Notification preferences`}
                    showBackButton={true}
                    showDivider={true}
                  />

                  {profile && (
                    <div className="settings-group">
                      {/* PUSH NOTIFICATION */}
                      <NotificationItem
                        key={`Push notifications`}
                        label={`Push notifications`}
                        isChecked={preferences && preferences.push}
                        onChange={() => handleToggle("push")}
                      />

                      {/* SMS NOTIFICATION */}
                      <NotificationItem
                        key={`SMS notifications`}
                        label={`SMS notifications`}
                        isChecked={preferences && preferences.sms}
                        onChange={() => handleToggle("sms")}
                      />

                      {/* EMAIL NOTIFICATION */}
                      <NotificationItem
                        key={`EMAIL notifications`}
                        label={`Email notifications`}
                        isChecked={preferences && preferences.email}
                        onChange={() => handleToggle("email")}
                      />
                    </div>
                  )}

                  <div className="d-flex justify-content-center align-items-center">
                    {/* ERROR STATE */}
                    <EmptyErrorState
                      visible={error}
                      title={`Something went wrong`}
                      description={`An error has occured. Please try again later or contact hevara for support`}
                    />
                  </div>
                </CardBody>
              </Card>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

Notifications.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Notifications)

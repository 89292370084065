import React from "react"

import { Button as HevaraButton } from "./Button"
import { IconButton as HevaraIconButton } from "./IconButton"
import CONST from "utils/constants"

import "./style.scss"

const { SIZE } = CONST

export const BUTTON_SIZE = {
  M: SIZE.M,
  S: SIZE.S,
  XS: SIZE.XS,
}

export const BUTTON_VARIANT = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  ACCENT: "accent",
  CRITICAL: "critical",
}

const Button = ({
  onClick,
  children,
  className,
  disabled,
  variant,
  size,
  type,
  responsive,
  leftIcon,
  ...rest
}) => {
  return (
    <HevaraButton
      className={className}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      size={size}
      responsive={responsive}
      leftIcon={leftIcon}
      type={type}
      {...rest}
    >
      {children}
    </HevaraButton>
  )
}

Button.defaultProps = {
  className: "",
  disabled: false,
  responsive: true,
  size: BUTTON_SIZE.M,
}

export const PrimaryButton = ({ children, ...props }) => {
  return (
    <Button {...props} variant={BUTTON_VARIANT.PRIMARY}>
      {children}
    </Button>
  )
}

export const SecondaryButton = ({ children, ...props }) => {
  return (
    <Button {...props} variant={BUTTON_VARIANT.SECONDARY}>
      {children}
    </Button>
  )
}

export const AccentButton = ({ children, ...props }) => {
  return (
    <Button {...props} variant={BUTTON_VARIANT.ACCENT}>
      {children}
    </Button>
  )
}

export const CriticalButton = ({ children, ...props }) => {
  return (
    <Button {...props} variant={BUTTON_VARIANT.CRITICAL}>
      {children}
    </Button>
  )
}

const IconButton = ({
  onClick,
  name,
  className,
  disabled,
  variant,
  size,
  style,
}) => {
  return (
    <HevaraIconButton
      className={className}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      size={size}
      name={name}
      style={style}
    ></HevaraIconButton>
  )
}

IconButton.defaultProps = {
  className: "",
  variant: BUTTON_VARIANT.PRIMARY,
  disabled: false,
  responsive: true,
  size: BUTTON_SIZE.M,
}

export const PrimaryIconButton = ({ children, ...props }) => {
  return (
    <IconButton {...props} variant={BUTTON_VARIANT.PRIMARY}>
      {children}
    </IconButton>
  )
}

export const SecondaryIconButton = ({ children, ...props }) => {
  return (
    <IconButton {...props} variant={BUTTON_VARIANT.SECONDARY}>
      {children}
    </IconButton>
  )
}

export const AccentIconButton = ({ children, ...props }) => {
  return (
    <IconButton {...props} variant={BUTTON_VARIANT.ACCENT}>
      {children}
    </IconButton>
  )
}

export const CriticalIconButton = ({ children, ...props }) => {
  return (
    <IconButton {...props} variant={BUTTON_VARIANT.CRITICAL}>
      {children}
    </IconButton>
  )
}

export const ButtonsLayout = ({ children, className }) => {
  return (
    <div className={`d-flex column-lt-sm buttons-layout ${className}`}>
      {children}
    </div>
  )
}

import React, { useState, useEffect } from "react";
import { map, filter, includes, isEmpty } from "lodash";

import { LargeModal, MODAL_STRETCH } from "components/modal";
import EmptyState from "components/EmptyState";
import { SelectionBox } from "components/SelectionBox/SelectionBox";
import { formatName, formatVolume } from "utils/utils";

const ProductListModal = ({ modalIsOpen, onClickClose, title, onClickConfirm, data, selectedProduct }) => {

  return (
    <LargeModal
      modalIsOpen={modalIsOpen}
      onClickClose={onClickClose}
      hideButtons={true}
      stretch={MODAL_STRETCH.CONDENSED}
      title={title}
    >
      <div className="d-flex">
        <div className="flex">
          <React.Fragment>
            {/* SEARCH INPUT HERE */}

            {/* LIST */}
            {map(data, item => (
              <SelectionBox
                key={item.id}
                title={item.name}
                description={formatName(item.categoryName)}
                sideData={formatName(item.type)}
                sideSubdata={formatVolume(item.size)}
                avatar={{ image: item.image?.file, initials: item.name }}
                selected={item.id === selectedProduct.id}
                onClick={() => onClickConfirm(item)}
                className="mt-2"
              />
            ))}

            {/* EMPTY STATE */}
            <EmptyState visible={isEmpty(data)} title={`No products found`} />
          </React.Fragment>
        </div>
      </div>
    </LargeModal>
  )
}

export default ProductListModal
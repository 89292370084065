import colors from "./colors.json"

export const defaultTheme = {
  color: {
    general: {
      background: colors.general_background,
      dividers: colors.general_dividers,
      scroll_bar: colors.general_scroll_bar,
      font: {
        primary: colors.general_font_primary,
        secondary: colors.general_font_secondary,
        tertiary: colors.general_font_tertiary,
        quaternary: colors.general_font_quaternary,
      }
    },
    containers: {},
    standard_button: {
      primary: {
        default: {
          background: colors.standard_button_primary_default_background,
          font: colors.standard_button_primary_default_font,
          border: colors.standard_button_primary_default_border,
        },
        focus: {
          background: colors.standard_button_primary_focus_background,
          font: colors.standard_button_primary_focus_font,
          border: colors.standard_button_primary_focus_border,
        },
        active: {
          background: colors.standard_button_primary_active_background,
          font: colors.standard_button_primary_active_font,
          border: colors.standard_button_primary_active_border,
        },
      },
      secondary: {
        default: {
          background: colors.standard_button_secondary_default_background,
          font: colors.standard_button_secondary_default_font,
          border: colors.standard_button_secondary_default_border,
        },
        focus: {
          background: colors.standard_button_secondary_focus_background,
          font: colors.standard_button_secondary_focus_font,
          border: colors.standard_button_secondary_focus_border,
        },
        active: {
          background: colors.standard_button_secondary_active_background,
          font: colors.standard_button_secondary_active_font,
          border: colors.standard_button_secondary_active_border,
        },
      },
      accent: {
        default: {
          background: colors.standard_button_accent_default_background,
          font: colors.standard_button_accent_default_font,
          border: colors.standard_button_accent_default_border,
        },
        focus: {
          background: colors.standard_button_accent_focus_background,
          font: colors.standard_button_accent_focus_font,
          border: colors.standard_button_accent_focus_border,
        },
        active: {
          background: colors.standard_button_accent_active_background,
          font: colors.standard_button_accent_active_font,
          border: colors.standard_button_accent_active_border,
        },
      },
      critical: {
        default: {
          background: colors.standard_button_critical_default_background,
          font: colors.standard_button_critical_default_font,
          border: colors.standard_button_critical_default_border,
        },
        focus: {
          background: colors.standard_button_critical_focus_background,
          font: colors.standard_button_critical_focus_font,
          border: colors.standard_button_critical_focus_border,
        },
        active: {
          background: colors.standard_button_critical_active_background,
          font: colors.standard_button_critical_active_font,
          border: colors.standard_button_critical_active_border,
        },
      },
    },
    icon_button: {
      primary: {
        default: {
          background: colors.icon_button_primary_default_background,
          font: colors.icon_button_primary_default_font,
          border: colors.icon_button_primary_default_border,
        },
        focus: {
          background: colors.icon_button_primary_focus_background,
          font: colors.icon_button_primary_focus_font,
          border: colors.icon_button_primary_focus_border,
        },
        active: {
          background: colors.icon_button_primary_active_background,
          font: colors.icon_button_primary_active_font,
          border: colors.icon_button_primary_active_border,
        },
      },
      secondary: {
        default: {
          background: colors.icon_button_secondary_default_background,
          font: colors.icon_button_secondary_default_font,
          border: colors.icon_button_secondary_default_border,
        },
        focus: {
          background: colors.icon_button_secondary_focus_background,
          font: colors.icon_button_secondary_focus_font,
          border: colors.icon_button_secondary_focus_border,
        },
        active: {
          background: colors.icon_button_secondary_active_background,
          font: colors.icon_button_secondary_active_font,
          border: colors.icon_button_secondary_active_border,
        },
      },
      accent: {
        default: {
          background: colors.icon_button_accent_default_background,
          font: colors.icon_button_accent_default_font,
          border: colors.icon_button_accent_default_border,
        },
        focus: {
          background: colors.icon_button_accent_focus_background,
          font: colors.icon_button_accent_focus_font,
          border: colors.icon_button_accent_focus_border,
        },
        active: {
          background: colors.icon_button_accent_active_background,
          font: colors.icon_button_accent_active_font,
          border: colors.icon_button_accent_active_border,
        },
      },
      critical: {
        default: {
          background: colors.icon_button_critical_default_background,
          font: colors.icon_button_critical_default_font,
          border: colors.icon_button_critical_default_border,
        },
        focus: {
          background: colors.icon_button_critical_focus_background,
          font: colors.icon_button_critical_focus_font,
          border: colors.icon_button_critical_focus_border,
        },
        active: {
          background: colors.icon_button_critical_active_background,
          font: colors.icon_button_critical_active_font,
          border: colors.icon_button_critical_active_border,
        },
      },
    },
    link: {
      default: colors.link_default,
      underline: colors.link_underline_border,
      critical: colors.link_critical,
    },
    contextual_modal_box: {
      background: colors.contextual_modal_box_background,
      default: {
        links: colors.contextual_modal_box_default_links,
      },
      critical: {
        links: colors.contextual_modal_box_critical_links
      }
    },
    badge: {
      background: colors.badge_background
    },
    help: {
      info: {
        icon: colors.help_info_icon,
        font: colors.general_font_tertiary,
      },
      warning: {
        icon: colors.help_warning_icon,
        font: colors.general_font_tertiary,
      },
      error: {
        icon: colors.help_error_icon,
        font: colors.help_error_font,
      }
    },
    feedback_notification: {
      info: {
        background: colors.feedback_notification_info_background,
        font: colors.feedback_notification_info_font,
        icon: colors.feedback_notification_info_icon,
        border: colors.feedback_notification_info_border,
      },
      warning: {
        background: colors.feedback_notification_warning_background,
        font: colors.feedback_notification_warning_font,
        icon: colors.feedback_notification_warning_icon,
        border: colors.feedback_notification_warning_border,
      },
      error: {
        background: colors.feedback_notification_alert_background,
        font: colors.feedback_notification_alert_font,
        icon: colors.feedback_notification_alert_icon,
        border: colors.feedback_notification_alert_border,
      },
    },
    forms: {
      default: {
        background: colors.forms_default_background,
        border: colors.forms_default_border,
        placeholder_font: colors.forms_default_placeholder_font,
        primary_font: colors.forms_default_primary_font,
        secondary_font: colors.forms_default_secondary_font,
        innericons_and_selected: colors.forms_default_innericons_and_selected,
        unselected: colors.forms_default_unselected,
        innericons_background: colors.forms_default_innericons_background,
        focus: {
          background: colors.forms_default_focus_background,
          border: colors.forms_default_focus_border,
          placeholder_font: colors.forms_default_focus_placeholder_font,
          primary_font: colors.forms_default_focus_primary_font,
          secondary_font: colors.forms_default_focus_secondary_font,
          innericons_and_selected: colors.forms_default_focus_innericons_and_selected,
          unselected: colors.forms_default_focus_unselected,
          innericons_background: colors.forms_default_focus_innericons_background,
        }
      },
      error: {
        background: colors.forms_default_error_background,
        border: colors.forms_default_error_border,
        placeholder_font: colors.forms_default_error_placeholder_font,
        primary_font: colors.forms_default_error_primary_font,
        secondary_font: colors.forms_default_error_secondary_font,
        innericons_and_selected: colors.forms_default_error_innericons_and_selected,
        unselected: colors.forms_default_error_unselected,
        innericons_background: colors.forms_default_error_innericons_background,
        focus: {
          background: colors.forms_error_focus_background,
          border: colors.forms_error_focus_border,
          placeholder_font: colors.forms_error_focus_placeholder_font,
          primary_font: colors.forms_error_focus_primary_font,
          secondary_font: colors.forms_error_focus_secondary_font,
          innericons_and_selected: colors.forms_error_focus_innericons_and_selected,
          unselected: colors.forms_error_focus_unselected,
          innericons_background: colors.forms_error_focus_innericons_background,
        }
      },
    },
    topbar: {
      background: colors.topbar_background,
      dots: colors.topbar_dots,
    },
    progress_and_loading: {
      main: colors.progress_nad_loading_main,
      background: colors.progress_and_loading_background,
    },
    tabbar: {
      option: colors.tabbar_option,
      background: colors.tabbar_background,
    },
    sidebar: {
      font: {
        active_section: colors.sidebar_font_active_section,
        non_active_section: colors.sidebar_font_non_active_section,
      },
      active_section: {
        badge: colors.sidebar_active_section_badge,
        background: colors.sidebar_active_section_background
      },
      background: colors.sidebar_background
    },
    avatar: {
      icon: {
        icon: colors.avatar_icon_icon,
        background: colors.avatar_icon_background
      },
      initials: {
        font: colors.avatar_initials_font,
        background: colors.avatar_initials_background
      },
    },
    toast: {
      success: {
        font: colors.toast_success_font,
        background: colors.toast_success_background
      },
      wrong: {
        font: colors.toast_wrong_font,
        background: colors.toast_wrong_background
      },
    },
    navigation_row: {
      icon: colors.navigation_row_icon,
      font: colors.navigation_row_font,
      arrow: colors.navigation_row_arrow,
      background: colors.navigation_row_background
    }
  },
  typography: {
    fontFamily: 'Inter, sans-serif'
  },
  shadows: {
    1: 'box-shadow: rgba(0,0,0,0.2) 0px 1px 3px 0px;',
    2: 'box-shadow: rgba(0,0,0,0.2) 0px 4px 8px -2px;',
    3: 'box-shadow: rgba(0,0,0,0.15) 0px 8px 16px -4px;',
    4: 'box-shadow: rgba(0,0,0,0.15) 0px 12px 24px -4px;',
  },
  radii: {
    radius4: 'border-radius: 4px;',
    radius8: 'border-radius: 8px;',
    radius12: 'border-radius: 12px;',
  }
}
import React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Row, Col, Form, Input, FormFeedback, Label } from "reactstrap"

import { PrimaryButton, SecondaryButton } from "components/Button"
import OnboardingImage from "assets/images/verification-img.png"
import EmptyState from "components/EmptyState"
import "../style.scss"

const WalletInformation = props => {
  const { currencies, handleSubmit, onClickCancel, companyId, userId } = props

  const onContinue = values => {
    handleSubmit(values)
  }

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      wallet_alias: "",
      currency: "",
      companyId: companyId,
      userId: userId
    },
    validationSchema: Yup.object({
      wallet_alias: Yup.string().required("This field required"),
      currency: Yup.string().required("This field required"),
    }),
    onSubmit: values => {
      onContinue(values)
    },
  })

  return (
    <div className="mt-4">
    <Row>
      <Col md={6}>
        <div>
          <h2 className="fw-bold">Add wallet information</h2>
          <p className="mt-4 font-size-14 mb-4">
            Provide information related to your wallet.
          </p>

          <div className="mt-2">
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              {/* WALLET ALIAS */}
              <div className="mb-3">
                <Label className="form-label">Wallet alias</Label>
                <Input
                  name="wallet_alias"
                  className="form-control"
                  placeholder="Wallet alias"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.wallet_alias || ""}
                  invalid={
                    validation.touched.wallet_alias && validation.errors.wallet_alias
                      ? true
                      : false
                  }
                />
                {validation.touched.wallet_alias && validation.errors.wallet_alias ? (
                  <FormFeedback type="invalid">
                    {validation.errors.wallet_alias}
                  </FormFeedback>
                ) : null}
              </div>

              {/* WALLET CURRENCY */}
              <div className="mb-3">
                <Label className="form-label">Select currency</Label>
                <Input
                  name="currency"
                  type="select"
                  placeholder="Select currency"
                  defaultValue={"Select currency"}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.currency || ""}
                  invalid={
                    validation.touched.currency &&
                    validation.errors.currency
                      ? true
                      : false
                  }
                >
                  {currencies.map(currency => (
                    <option key={currency.id} value={currency.code}>
                      {currency.name}
                    </option>
                  ))}
                </Input>
                {validation.touched.currency &&
                validation.errors.currency ? (
                  <FormFeedback type="invalid">
                    {validation.errors.currency}
                  </FormFeedback>
                ) : null}
              </div>

              <Row>
                <Col>
                  <div className="hstack gap-2 mb-0 mt-4">
                    <PrimaryButton type="submit" className="me-4">
                      Confirm
                    </PrimaryButton>

                    <SecondaryButton type="button" onClick={onClickCancel}>
                      Cancel
                    </SecondaryButton>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Col>

      <Col md={6}>
        <EmptyState
          visible={true}
          illustration={OnboardingImage}
          className="onboarding-user-ph-image"
          containerClassName="onboarding-user-ph"
        />
      </Col>
    </Row>


    </div>
  )
}

export default WalletInformation

const getTheme = {
  color: {
    group_separator: {
      background: "#F4F4F4",
      font: "#696969",
    },
  },
}

export const getGroupSeparatorStyles = props => {
  const theme = getTheme

  return `
    padding-top: 16px;
    padding-bottom: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 8px;
    background: ${theme.color.group_separator.background};
    border-color:${theme.color.group_separator.background}; 
  `
}

export const getGroupSeparatorLabelStyles = props => {
  const theme = getTheme

  return `
    color: ${theme.color.group_separator.font};
  `
}

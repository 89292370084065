import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { chain, isEmpty, orderBy } from "lodash"
import { toast, ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { Card, CardBody, Container, Row } from "reactstrap"
import moment from "moment"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import EmptyState, { EmptyErrorState } from "components/EmptyState"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import { BUTTON_VARIANT } from "components/Button"
import Wallet from "models/wallet"
import Transfers from "models/transfers"
import { formatAmount, getAccountNumber, getLastFourDigits, getTransactionStatus, getWalletStatus, groupListByKey } from "utils/utils"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import Chip, { CHIP_VARIANTS } from "components/Chip"
import FeedbackNotification, { FEEDBACK_VARIANTS } from "components/FeedbackNotification"
import constants from "utils/constants"
import DataAmount, { DATA_AMOUNT_WEIGHT } from "components/DataAmount"
import SectionList from "components/SectionList/sectionList"
import TransactionListItem from "components/itemList/transactionListItem/transactionListItem"
import { BodyTextBold, BodyText } from "components/atoms"
import DataItem from "components/DataItem"

import "./style.scss"

const { DEFAULT_DATE_FORMAT } = constants;

const TransactionDetails = props => {
  //meta title
  document.title = "Transaction details | hevara DMS Platform"

  const { transactionId } = useParams()
  const navigate = useNavigate()

  const [transactionDetails, setTransactionDetails] = useState({})
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const locale = 'en-KE';

  const fetchTransactionDetails = (id) => {
    Transfers.getTransfer(id)
      .then((data) => {
        setTransactionDetails(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (transactionId) {
      fetchTransactionDetails(transactionId)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [transactionId])

  const getHeaderActions = () => {
    const actions = [
      {
        label: "Duplicate payment",
        icon: "mdi mdi-plus",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      {
        label: "Download PDF",
        icon: "bx bxs-file-pdf",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.SECONDARY,
        showInModal: false,
      }
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const status = getTransactionStatus(transactionDetails?.status);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Transaction details")}
            breadcrumbItem={props.t("Transaction details")}
          />

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Wallets"}
            description={"We are processing your request, please wait..."}
          />

          {!loading && !isEmpty(transactionDetails) && 
            <Row>
              <Card>
                <CardBody>
                  {/* PAGE HEADER */}
                  <PageHeader
                    title={formatAmount(transactionDetails.amount, locale, transactionDetails.amount_currency)}
                    showBackButton={true}
                    actions={<HeaderActions actions={getHeaderActions()} />}
                    info={
                      <PageHeaderInfo className="text-truncate">
                        <Chip variant={status.variant}>
                          {status.label}
                        </Chip>

                        <PageHeaderInfoText className="fw-semibold">
                          {transactionDetails?.type}
                        </PageHeaderInfoText>

                        <PageHeaderInfoText className="fw-semibold">
                          {moment(transactionDetails.dateCreated).format(DEFAULT_DATE_FORMAT)}
                        </PageHeaderInfoText>

                      </PageHeaderInfo>
                    }
                  />

                  <div className="hevara-transaction-data">
                    <div className="tx-grid mb-3">
                      <div className="mb-4">
                        {/* TRANSACTION DETAILS */}
                        <BodyTextBold className="text-muted mb-4">Payment details</BodyTextBold>
                        <div className="detail-grid">
                          <DataItem
                            label={props.t("Description")}
                            value={transactionDetails.narration}
                          />

                          <DataItem
                            label={props.t("Value date")}
                            value={moment(transactionDetails.dateCreated).format(DEFAULT_DATE_FORMAT)}
                          />

                          <DataItem
                            label={props.t("Payment type")}
                            value={transactionDetails.type}
                          />
                        </div>

                        {/* ORIGIN WALLET DETAILS: DEBIT DETAILS */}
                        <div className="mt-4">
                          <BodyTextBold className="text-muted mb-4">Payment origin</BodyTextBold>
                          <div className="detail-grid">
                            <DataItem
                              label={props.t("Account number")}
                              value={getAccountNumber(transactionDetails.debit_wallet.account_number)}
                            />

                            <DataItem
                              label={props.t("Sender")}
                              value={transactionDetails.debited_user.name}
                            />

                            <DataItem
                              label={props.t("Mobile")}
                              value={getLastFourDigits(transactionDetails.debited_user.mobileNumber)}
                            />
                          </div>
                        </div>

                        {/* ORIGIN WALLET DETAILS: CREDIT DETAILS */}
                        <div className="mt-4">
                          <BodyTextBold className="text-muted mb-4">Payment destination</BodyTextBold>
                          <div className="detail-grid">
                            <DataItem
                              label={props.t("Account number")}
                              value={getAccountNumber(transactionDetails.credit_wallet.account_number)}
                            />

                            <DataItem
                              label={props.t("Beneficiary")}
                              value={transactionDetails.credited_user.name}
                            />

                            <DataItem
                              label={props.t("Mobile")}
                              value={getLastFourDigits(transactionDetails.credited_user.mobileNumber)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          }

        </Container>
      </div>
      
      <ToastContainer />
    </React.Fragment>
  )
}

TransactionDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(TransactionDetails)

import React from "react"

import { DataItem } from "components/DataItem/DataItem"
import Link from "components/Link"

const VehicleItem = ({ vehicle }) => {
  return (
    <div className="d-flex center-y center-x permission-box">
      <DataItem
        avatar={{
          icon: "mdi mdi-car-outline",
        }}
        label={vehicle.registrationNumber}
        value={vehicle.vehicleTypeName}
      >
        <Link to={`/vehicle_details/${vehicle.id}`}>View details</Link>
      </DataItem>
    </div>
  )
}

export default VehicleItem

import React, { useRef, useEffect, useState } from "react"
import "./OtpInput.scss"

const OtpInput = ({ length = 4, onSubmit }) => {
  const [otp, setOtp] = useState(new Array(length).fill(""))
  const inputRefs = useRef([])
  const firstInputRef = useRef(null) // Ref to store the reference to the first input field

  const handleInputChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtp = [...otp]
      newOtp[index] = value
      setOtp(newOtp)

      // If the input value is not empty and there is a next input field, focus on it
      if (value && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].focus()
      }
    }
  }

  const handlePaste = e => {
    e.preventDefault()
    const pasteData = e.clipboardData.getData("text/plain")
    const pasteDigits = pasteData.slice(0, length).split("")
    const newOtp = [...otp]
    pasteDigits.forEach((digit, index) => {
      if (/^\d*$/.test(digit)) {
        newOtp[index] = digit
      }
    })
    setOtp(newOtp)
  }

  const handleFocus = index => {
    inputRefs.current[index].select()
  }

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index] && inputRefs.current[index - 1]) {
      e.preventDefault()
      inputRefs.current[index - 1].focus()
    } else if (e.key === "ArrowLeft" && inputRefs.current[index - 1]) {
      e.preventDefault()
      inputRefs.current[index - 1].focus()
    } else if (e.key === "ArrowRight" && inputRefs.current[index + 1]) {
      e.preventDefault()
      inputRefs.current[index + 1].focus()
    }
  }

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus() // Focus on the first input when the component mounts
    }
  }, [])

  useEffect(() => {
    if (!otp.includes("") && otp.join("").length === length) {
      onSubmit(otp.join(""))
    }
  }, [otp, length, onSubmit])

  return (
    <div className="otp-input-container">
      {otp.map((digit, index) => (
        <input
          key={index}
          ref={ref => {
            inputRefs.current[index] = ref
            if (index === 0) {
              firstInputRef.current = ref // Store the reference to the first input field
            }
          }}
          type="text"
          maxLength={1}
          value={digit}
          onChange={e => handleInputChange(index, e.target.value)}
          onFocus={() => handleFocus(index)}
          onKeyDown={e => handleKeyDown(index, e)}
          onPaste={e => handlePaste(e)}
        />
      ))}
    </div>
  )
}

export default OtpInput

import CONST from "utils/constants"

const { MEDIA } = CONST

export const getContentWrapperStyles = ({ chip }) => {
  return `
    display: flex;
    justify-content: center;
    flex-direction: column;

    & > *:first-child {
      margin: 0;
      margin-right: ${chip ? "100px" : 0};
    }
    & > * {
      margin-top: 24px;
    }
    ${MEDIA.MOBILE} {
      & > *:first-child {
        margin-right: 0;
      }
    }
  `
}

export const getChipStyles = () => {
  return `
    position: absolute;
    right: 0;
    top: 0;
    padding: 24px;

    ${MEDIA.MOBILE} {
      display: flex;
      justify-content: right;
      position: inherit;
      padding: 0;
    }
  `
}

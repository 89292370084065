import React, { useEffect, useRef, useCallback, useState } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"

import withRouter from "components/Common/withRouter"
import Auth from "models/auth"
import Company from "models/company"
import CONST from "utils/constants"
import Loading from "components/Common/Spinner"

const { COMPANY_TYPE } = CONST

const SidebarContent = props => {
  const ref = useRef()
  const path = useLocation()

  const [userProfile, setUserProfile] = useState({})
  const [companyInfo, setCompanyInfo] = useState({})

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.length && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  const fetchCompanyInfo = id => {
    Company.getCompany(id)
      .then(data => {
        setCompanyInfo(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu"),
    activeMenu()
  }, []) 

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      setUserProfile(JSON.parse(profile))
      fetchCompanyInfo(JSON.parse(profile).companyId)
    }
  }, []) 

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  const showProductsNavItem = companyInfo?.type === COMPANY_TYPE.MANUFACTURER

  return (
    <React.Fragment>
      {userProfile && companyInfo && (
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Menu")} </li>
              <li>
                <Link to="/dashboard">
                  <i className="bx bx-home-circle text-tertiary"></i>
                  <span className="fw-semibold">{props.t("Dashboard")}</span>
                </Link>
              </li>

              {/* PLATFORM SETTING SECTION */}
              {userProfile?.permission?.VIEW_GLOBAL_SETTINGS && ( 
                <li className="menu-title">{props.t("Platform Settings")}</li>
              )}
              <li>
                <Link to="/" className="has-arrow">
                  <i className="fas fa-cog text-tertiary"></i>
                  <span className="fw-semibold">{props.t("Settings")}</span>
                </Link>
                <ul className="sub-menu">
                  {userProfile?.permission?.VIEW_GLOBAL_SETTINGS && (
                    <li>
                      <Link className="fw-semibold" to="/company-roles">
                        {props.t("Roles")}
                      </Link>
                    </li>
                  )}
                  {userProfile?.permission?.VIEW_GLOBAL_SETTINGS && (
                    <li>
                      <Link className="fw-semibold" to="/taxes">
                        {props.t("Tax")}
                      </Link>
                    </li>
                  )}
                  {userProfile?.permission?.VIEW_GLOBAL_SETTINGS && (
                    <li>
                      <Link className="fw-semibold" to="/charges">
                        {props.t("Charges")}
                      </Link>
                    </li>
                  )}
                  {userProfile?.permission?.VIEW_GLOBAL_SETTINGS && companyInfo?.features?.CAN_SEE_PAYMENTS_MODULE && userProfile?.permission?.CAN_VIEW_PAYMENT_CONFIGS && (
                    <li>
                      <Link className="fw-semibold" to="/payment-settings">
                        {props.t("Payment")}
                      </Link>
                    </li>
                  )}
                </ul>
              </li>

              {/* OPERATIONS SECTION */}
              <li className="menu-title">{props.t("Operations")}</li>

              {/* PRODUCTS: WHEN COMPANY TYPE IS MANUFACTURER */}
              {showProductsNavItem && companyInfo?.features?.CAN_SEE_INVENTORY_MODULE && userProfile?.permission?.CAN_VIEW_PRODUCTS && (
                <li>
                  <Link to="/products">
                    <i className="mdi mdi-cart text-tertiary"></i>
                    <span className="fw-semibold">{props.t("Products")}</span>
                  </Link>
                </li>
              )}

              {/* PRODUCT CATALOGUE: WHEN COMPANY TYPE IS DISTRIBUTOR OR WHOLESALER */}
              {!showProductsNavItem && companyInfo?.features?.CAN_SEE_INVENTORY_MODULE && userProfile?.permission?.CAN_VIEW_PRODUCTS && (
                <li>
                  <Link to="/product-catalogue">
                    <i className="mdi mdi-cart text-tertiary"></i>
                    <span className="fw-semibold">
                      {props.t("Product catalogue")}
                    </span>
                  </Link>
                </li>
              )}

              {/* BACK ORDERS: WHEN COMPANY TYPE IS DISTRIBUTOR OR WHOLESALER: SHOW BACK ORDER OPTION */}
              {!showProductsNavItem && (
                <li>
                  <Link to="/back-orders">
                    <i className="mdi mdi-basket-fill text-tertiary"></i>
                    <span className="fw-semibold">
                      {props.t("Back orders")}
                    </span>
                  </Link>
                </li>
              )}

              {/* ORDER */}
              {companyInfo?.features?.CAN_SEE_ORDER_MODULE && userProfile?.permission?.CAN_VIEW_ORDERS && (
                <li>
                  <Link to="/orders">
                    <i className="mdi mdi-clipboard-list-outline text-tertiary"></i>
                    <span className="fw-semibold">{props.t("Orders")}</span>
                  </Link>
                </li>
              )}

              {/* DISPATCHES */}
              {userProfile?.permission?.CAN_VIEW_DISPATCH && (
                <li>
                  <Link to="/dispatches">
                    <i className="mdi mdi-book-open text-tertiary"></i>
                    <span className="fw-semibold">{props.t("Dispatches")}</span>
                  </Link>
                </li>
              )}

              {/* SHIPMENTS */}
              {userProfile?.permission?.VIEW_SHIPMENTS && (
                <li>
                  <Link to="/#">
                    <i className="mdi mdi-truck-delivery text-tertiary"></i>
                    <span className="fw-semibold">{props.t("Shipments")}</span>
                  </Link>
                </li>
              )}

              {/* SALES */}
              {userProfile?.permission?.VIEW_SALES && (
                <li>
                  <Link to="/#">
                    <i className="mdi mdi-cash-multiple text-tertiary"></i>
                    <span className="fw-semibold">{props.t("Sales")}</span>
                  </Link>
                </li>
              )}

              {/* WAREHOUSES */}
              {userProfile?.permission?.CAN_VIEW_WAREHOUSES && (
                <li>
                  <Link to="/warehouses">
                    <i className="mdi mdi-warehouse text-tertiary"></i>
                    <span className="fw-semibold">{props.t("Warehouses")}</span>
                  </Link>
                </li>
              )}

              {/* VEHICLES */}
              {companyInfo?.features?.CAN_SEE_FLEET_MODULE && userProfile?.permission?.CAN_VIEW_FLEETS && (
                <li>
                  <Link to="/vehicles">
                    <i className="mdi mdi-car-multiple text-tertiary"></i>
                    <span className="fw-semibold">{props.t("Vehicles")}</span>
                  </Link>
                </li>
              )}

              {/* CUSTOMERS */}
              {userProfile?.permission?.VIEW_CUSTOMERS && (
                <li>
                  <Link to="/customers">
                    <i className="mdi mdi-account-box-multiple-outline text-tertiary"></i>
                    <span className="fw-semibold">{props.t("Customers")}</span>
                  </Link>
                </li>
              )}

              {/* TEAM */}
              {companyInfo?.features?.CAN_SEE_TEAM_MODULE && userProfile?.permission?.CAN_VIEW_USERS && (
                <li>
                  <Link to="/team">
                    <i className="mdi mdi-account-group text-tertiary"></i>
                    <span className="fw-semibold">{props.t("Team")}</span>
                  </Link>
                </li>
              )}

              {companyInfo?.features?.CAN_SEE_SALES_FORCE_MODULE && userProfile?.permission?.VIEW_SALES_FORCE && (
                <li>
                  <Link to="/#">
                    <i className="mdi mdi-truck-fast text-tertiary"></i>
                    <span className="fw-semibold">
                      {props.t("Hevara sales force")}
                    </span>
                  </Link>
                </li>
              )}

              {companyInfo?.features?.CAN_SEE_ANALYTICS_MODULE && userProfile?.permission?.VIEW_ANALYTICS && (
                <li>
                  <Link to="/#">
                    <i className="mdi mdi-file-pdf-outline text-tertiary"></i>
                    <span className="fw-semibold">{props.t("Reports")}</span>
                  </Link>
                </li>
              )}

              <li className="menu-title">{props.t("Other")}</li>

              <li>
                <Link to="/#">
                  <i className="mdi mdi-headset text-tertiary"></i>
                  <span className="fw-semibold">{props.t("Feedback")}</span>
                </Link>
              </li>
            </ul>
          </div>
        </SimpleBar>
      )}

      <ToastContainer />
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))

export const ONBOARDING_FORM = {
  COMPANY_TYPE: "COMPANY_TYPE",
  COMPANY_INFORMATION: "COMPANY_INFORMATION",
  PERSONAL_INFORMATION: "PERSONAL_INFORMATION",
  SUMMARY: "SUMMARY",
  SUCCESS: "SUCCESS",
}

export const FORM_STEPS = [
  ONBOARDING_FORM.COMPANY_TYPE,
  ONBOARDING_FORM.COMPANY_INFORMATION,
  ONBOARDING_FORM.PERSONAL_INFORMATION,
  ONBOARDING_FORM.SUMMARY,
  ONBOARDING_FORM.SUCCESS,
]

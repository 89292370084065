import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { Card, CardBody, Container, Row } from "reactstrap"

import Auth from "models/auth"
import DataItem from "components/DataItem"
import Loading from "components/Common/Spinner"
import Company from "models/company"
import { EmptyErrorState } from "components/EmptyState"
import { getStatus, getCompanyType } from "utils/utils"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"

import "./style.scss"

const CompanyDetails = props => {
  //meta title
  document.title = "Company details | hevara DMS Platform"

  const [profile, setProfile] = useState({})
  const [companyInfo, setCompanyInfo] = useState({})
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  const fetchCompanyInfo = id => {
    Company.getCompany(id)
      .then(data => {
        setCompanyInfo(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      const companyId = JSON.parse(profile).companyId
      fetchCompanyInfo(companyId)
      setProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Loading */}
          {!error && loading && <Loading />}

          {!loading && (
            <Row>
              <Card>
                <CardBody>
                  {/* PAGE HEADER */}
                  <PageHeader
                    avatar={
                      companyInfo && {
                        initials: companyInfo.companyName,
                        image: companyInfo.image?.file,
                      }
                    }
                    title={companyInfo && companyInfo.companyName}
                    showBackButton={true}
                    showDivider={true}
                    info={
                      <PageHeaderInfo>
                        {!isEmpty(companyInfo) && (
                          <>
                            <div className="mt-2">
                              {getStatus(companyInfo.status)}
                            </div>
                            <PageHeaderInfoText className="fw-semibold">
                              {getCompanyType(companyInfo.type)}
                            </PageHeaderInfoText>
                          </>
                        )}
                      </PageHeaderInfo>
                    }
                  />

                  <div className="hevara-user-detail">
                    <div className="tx-grid mb-3">
                      <div className="mb-4">
                        <h5 className="text-muted mb-4">Company information</h5>
                        <div className="detail-grid">
                          <DataItem
                            label={props.t("Company name")}
                            value={companyInfo.companyName}
                          />

                          <DataItem
                            label={props.t("Email address")}
                            value={companyInfo.companyEmail}
                          />

                          <DataItem
                            label={props.t("Country")}
                            value={companyInfo.countryName}
                          />

                          <DataItem
                            label={props.t("Physical location")}
                            value={companyInfo.location}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center">
                    {/* ERROR STATE */}
                    <EmptyErrorState
                      visible={error}
                      title={`Something went wrong`}
                      description={`An error has occured. Please try again later or contact hevara for support`}
                    />
                  </div>
                </CardBody>
              </Card>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

CompanyDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CompanyDetails)

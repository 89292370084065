import React from "react"
import styled from "styled-components"
import { isEmpty } from "lodash"

import { BigTextBold } from "components/atoms"
import { Button } from "components/Button/Button"
import {
  getButtonWrapperStyles,
  getEmptyStateStyles,
  getIllustrationStyles,
} from "./EmptyState.styles"
import CONST from "utils/constants"

const { SIZE } = CONST

const StyledEmptyState = styled.div.attrs({
  className: "hevara-empty-state",
})`
  ${getEmptyStateStyles}
`

const StyledIllustration = styled.img.attrs({
  className: "hevara-empty-state-image",
})`
  ${getIllustrationStyles}
`

const StyledEmptyStateTitle = styled(BigTextBold)`
  margin-bottom: 8px;
`

const StyledEmptyStateButtonWrapper = styled.div.attrs({
  className: "hevara-empty-state-wrapper",
})`
  ${getButtonWrapperStyles}
`

const StyledEmptyStateDescription = styled.p`
  text-align: center;
  margin: 0px;
  font-size: 14px;
  font-weight: semibold;
`
const StyledEmptyStateFirstButtonWrapper = styled.div.attrs({
  className: "hevara-empty-state-first-button",
})``

const StyledEmptyStateFirstButton = styled(Button)``

const StyledEmptyStateSecondButton = styled(Button)``

export const EmptyState = ({
  illustration,
  title,
  titleSize,
  titleWeight,
  description,
  firstButton,
  secondButton,
  className,
  style,
  ...props
}) => {
  return (
    <StyledEmptyState className={className} style={style} {...props}>
      {/* IMAGE */}
      <StyledIllustration src={illustration} />

      {/* TITLE */}
      {title && (
        <StyledEmptyStateTitle>
          {title}
        </StyledEmptyStateTitle>
      )}

      {/* DESCRIPTION */}
      <StyledEmptyStateDescription>{description}</StyledEmptyStateDescription>

      {/* BUTTONS */}
      {(!isEmpty(firstButton) || !isEmpty(secondButton)) && (
        <StyledEmptyStateButtonWrapper
          hasBothButtons={!isEmpty(firstButton) && !isEmpty(secondButton)}
        >
          {firstButton && (
            <StyledEmptyStateFirstButtonWrapper>
              <StyledEmptyStateFirstButton {...firstButton}>
                {firstButton.label}
              </StyledEmptyStateFirstButton>
            </StyledEmptyStateFirstButtonWrapper>
          )}
          {secondButton && (
            <StyledEmptyStateSecondButton {...secondButton}>
              {secondButton.label}
            </StyledEmptyStateSecondButton>
          )}
        </StyledEmptyStateButtonWrapper>
      )}
    </StyledEmptyState>
  )
}

EmptyState.defaultProps = {
  titleSize: SIZE.S,
  titleWeight: "bold",
  className: "",
  style: {},
}

import React from "react"
import { useNavigate } from "react-router-dom"
import { isFunction } from "lodash"

import Link from "components/Link"

const BackBtn = ({ text, className, onClick, style, ...props }) => {
  const navigate = useNavigate()

  const onClickBack = () => {
    if (isFunction(onClick)) {
      onClick()
    } else {
      navigate(-1)
    }
  }

  return (
    <Link
      className={className}
      style={style}
      icon="bx bx-left-arrow-alt"
      onClick={onClickBack}
    >
      {text || "Back"}
    </Link>
  )
}

BackBtn.defaultProps = {
  className: "",
  style: {},
  text: "",
}

export default BackBtn

import { getTheme, getTextStyles, getScrollBarStyles } from "../theme/themeUtils";
import { getFontSizes, getFontWeight } from "../atoms/Text/BasicText";
import { spacing } from "../atoms/Spacing";
import CONST from "../atoms/constants";

const { SIZE, FONT: { WEIGHT, VARIANT } } = CONST;

export const getContainerStyles = (props) => {
  const theme = getTheme(props.theme);
  const { focus } = theme.color.forms[!!props.error ? 'error' : 'default'];

  return `
    width: 100%;
    outline: none;
    position: relative;
    ${props.isShowingOptions ? `
      .hevara-form-input-container {
        border: 1px solid ${focus.border}
      }
    `: ''}
  `
}

export const getValueStyles = (props) => {
  return `
    flex: 1;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${getFontSizes({ size: SIZE.M, variant: VARIANT.TEXT })}
    ${getFontWeight({ weight: WEIGHT.REGULAR })}
    ${getTextStyles({ ...props, colorVariant: !!props.value ? 'primary' : 'quaternary' })}
  `
}

export const getElevationStyles = (props) => {
  const theme = getTheme(props.theme);

  return `
    ${spacing('mt')}
    ${getScrollBarStyles(props)}
    position: absolute;
    width: 100%;
    border-radius: 12px;
    max-height: 160px;
    overflow-y: hidden;
    z-index: 1;
    background: ${theme.color.contextual_modal_box.background}
  `
}
import React from "react";

import { DataAmount as HevaraDataAmount } from "./DataAmount";
import { DATA_AMOUNT_SIZES, DATA_AMOUNT_WEIGHT } from "./DataAmount.styles";
import { formatAmount } from "utils/utils";
import "./styles.scss"

const DataAmount = ({ 
  size,
  weight,
  label,
  value,
  currency,
  icon,
  customIcon,
  className,
  avoidFontResize,
  numberOfDecimals,
  style,
  locale
}) => {
  const resizeClass = avoidFontResize ? `amount-no-resize ${className}` : `currency-resize ${className}`;

  return (
    <HevaraDataAmount
      style={style}
      className={resizeClass}
      size={size}
      weight={weight}
      label={label}
      value={formatAmount(value, locale, '', numberOfDecimals)}
      currency={currency}
      icon={icon}
      customIcon={customIcon}
    />
  )
}

DataAmount.defaultProps = {
  size: DATA_AMOUNT_SIZES.M,
  weight: DATA_AMOUNT_WEIGHT.BOLD,
  numberOfDecimals: 2,
  className: '',
}

export { DataAmount as default, DATA_AMOUNT_SIZES, DATA_AMOUNT_WEIGHT };
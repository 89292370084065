import React from "react"
import Switch from "react-switch"
import { Col, Row } from "reactstrap"

import { LargeModal, MODAL_STRETCH } from "components/modal"
import { PrimaryButton, SecondaryButton } from "components/Button"
import { transformPermission } from "utils/utils"

import "./style.scss"

const primary = "#42bfc1"
const smooth_gray = "#cccccc"

/**
 * Permission Item Row
 */
const PermissionItem = ({ label, isChecked, onChange }) => (
  <div className="d-flex center-y center-x permission-box" key={label}>
    <Switch
      uncheckedIcon={false}
      checkedIcon={false}
      width={51}
      offColor={smooth_gray}
      onColor={primary}
      onChange={() => onChange(!isChecked)}
      checked={isChecked}
      className="me-2"
    />
    <span className="mt-1">{transformPermission(label)}</span>
  </div>
)

const PermissionModal = ({
  modalIsOpen,
  onClickClose,
  user,
  profile,
  permissions,
  handlePermissionChange,
  updateUserPermissions, 
  onClickPermissionModal,
}) => {
  return (
    <LargeModal
      modalIsOpen={modalIsOpen}
      onClickClose={onClickClose}
      hideButtons={true}
      stretch={MODAL_STRETCH.NORMAL}
      title={`Update ${user?.firstName}'s permissions`}
    >
      <div className="mt-4">
        {Object.keys(permissions).map(key => (
          <PermissionItem
            key={key}
            label={key}
            isChecked={permissions[key]}
            onChange={() => handlePermissionChange(key)}
          />
        ))}

        <Row>
          <Col>
            <div className="hstack gap-2 justify-content-center mb-0 mt-4">
              <PrimaryButton
                type="button"
                onClick={() => updateUserPermissions()}
                disabled={user.id === profile.id || !profile?.permission?.CAN_MANAGE_USERS}
              >
                Update permissions
              </PrimaryButton>

              <SecondaryButton
                type="button"
                onClick={onClickPermissionModal}
                className="me-4"
              >
                Cancel
              </SecondaryButton>
            </div>
          </Col>
        </Row>
      </div>
    </LargeModal>
  )
}

export default PermissionModal

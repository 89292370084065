import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getCompanyRoles(limit = 10, page = 1, filters = {}) {
    const parameters = {
      limit,
      page,
      companyId: filters.companyId,
      label: filters.label,
      status: filters.status,
    }
    const url = queryParams(
      API.getApi().COMPANY_ROLES.GET_COMPANY_ROLES,
      parameters
    )
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getCompanyRole(companyRoleId) {
    return axios
      .get(`${API.getApi().COMPANY_ROLES.GET_COMPANY_ROLE}/${companyRoleId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  addCompanyRole(values) {
    return axios
      .post(API.getApi().COMPANY_ROLES.ADD_COMPANY_ROLE, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateCompanyRole(companyRoleId, values) {
    return axios
      .patch(
        `${API.getApi().COMPANY_ROLES.UPDATE_COMPANY_ROLE}/${companyRoleId}`,
        values
      )
      .then(async ({ data }) => {
        return data
      })
  },
  lookupCompanyRoles(companyId) {
    return axios
      .get(`${API.getApi().LOOKUPS.COMPANY_ROLES}/${companyId}`)
      .then(async ({ data }) => {
        return data
      })
  },
}

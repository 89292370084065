import React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

import { SmallModal } from "components/modal"
import { PrimaryButton, SecondaryButton } from "components/Button"


const PaymentModal = ({ showPaymentModal, togglePayment, suborderDetails, makePayment }) => {

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      phoneNumber: (suborderDetails && suborderDetails?.customer?.phone) || "",
      subOrderId: suborderDetails && suborderDetails.id,
    },
    
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .matches(/^\+254\d{9}$/, "Invalid phone number format, please use +2547....")
        .required("Mobile number is required"),
    }),
    onSubmit: values => {
      makePayment(values)

      validation.resetForm()
      togglePayment()
    },
  })

  return (
    <SmallModal
      modalIsOpen={showPaymentModal}
      onClickClose={togglePayment}
      title={"Payment"}
      hideButtons={true}
    >
      <Form
        className="form-horizontal"
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        {/* PHONE NUMBER */}
        <div className="mb-3">
          <Label className="form-label">Mobile number</Label>
          <Input
            name="phoneNumber"
            type="tel"
            placeholder="Mobile number (+2547XXXXXXXX)"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.phoneNumber || ""}
            invalid={
              validation.touched.phoneNumber &&
              validation.errors.phoneNumber
                ? true
                : false
            }
          />
          {validation.touched.phoneNumber &&
          validation.errors.phoneNumber && (
            <FormFeedback type="invalid">
              {validation.errors.phoneNumber}
            </FormFeedback>
          )}
        </div>

        <div className="hstack gap-2 mb-0 mt-4">
          <PrimaryButton type="submit" className="me-4">
            Initiate payment
          </PrimaryButton>

          <SecondaryButton type="button" onClick={togglePayment}>
            Cancel
          </SecondaryButton>
        </div>
      </Form>
    </SmallModal>
  )
}

export default PaymentModal

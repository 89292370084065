import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getPaymentConfigs(limit = 10, page = 1, filters = {}) {
    const parameters = {
      limit,
      page,
      companyId: filters.companyId,
      warehouseId: filters.warehouseId,
    }
    const url = queryParams(
      API.getApi().PAYMENT_CONFIGS.GET_PAYMENT_CONFIGS,
      parameters
    )
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getPaymentConfig(paymentConfigId) {
    return axios
      .get(
        `${API.getApi().PAYMENT_CONFIGS.GET_PAYMENT_CONFIG}/${paymentConfigId}`
      )
      .then(async ({ data }) => {
        return data
      })
  },
  addPaymentConfig(values) {
    return axios
      .post(API.getApi().PAYMENT_CONFIGS.ADD_PAYMENT_CONFIG, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updatePaymentConfig(paymentConfigId, values) {
    return axios
      .patch(
        `${
          API.getApi().PAYMENT_CONFIGS.UPDATE_PAYMENT_CONFIG
        }/${paymentConfigId}`,
        values
      )
      .then(async ({ data }) => {
        return data
      })
  },
}

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux";
import { compose } from "redux"

import MutliStepPage, { MultiStepHeader } from "components/MultiStepForm"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import Loading from "components/Common/Spinner"
import Fade from "components/Fade"
import { SmallModal } from "components/modal"
import WalletInformation from "./wizard/walletInformation"
import { getPreviousStep, getProgress, isLastStep } from "components/MultiStepForm/multistepFomr.utils"
import { FORM_STEPS, WALLET_FORM } from "./newWallet.const"
import Auth from "models/auth"
import Company from "models/company"
import Wallet from "models/wallet"
import { changeSidebarType } from "store/actions"
import { leftSidebarTypes } from "constants/layout"

import "./style.scss"

const NewWallet = props => {
  //meta title
  document.title = "Create wallet | hevara DMS Platform"

  const navigate = useNavigate()

  const [currentStep, setCurrentStep] = useState(FORM_STEPS[0])
  const [progress, setProgress] = useState(0)
  const [steps, setSteps] = useState(FORM_STEPS)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)

  const [currencies, setCurrencies] = useState([])
  const [userProfile, setUserProfile] = useState({})
  const [companyInfo, setCompanyInfo] = useState({})

  const fetchCurrencies = () => {
    Wallet.lookUpCurrencies()
      .then(data => {
        setCurrencies([
          {
            status: "Default",
            name: "Select currency",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchCompanyInfo = id => {
    Company.getCompany(id)
      .then(data => {
        setCompanyInfo(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      setUserProfile(JSON.parse(profile))
      const companyId = JSON.parse(profile).companyId
      Promise.all([fetchCurrencies(), fetchCompanyInfo(companyId)])

    } else {
      setError(true)
      toast.error("An error occurred. Please try again later")
    }
  }, [])

  useEffect(() => {
    setProgress(getProgress(currentStep, steps))
  }, [currentStep, steps])

  const onSubmit = (values) => {
    setProcessing(true)

    Wallet.createWallet(values)
      .then(() => {
        toast.success(`wallet created successfully`)
        exit()
      })
      .catch(error => {
        const errorMessage = error.response?.data?.message || "An error occurred during wallet creation. Please try again later"
        toast.error(errorMessage)
      })
      .finally(() => setProcessing(false))
  }

  const goPreviousStep = () => {
    setCurrentStep(getPreviousStep(currentStep, steps))
  }

  const onClickCancel = () => {
    if (isLastStep(currentStep, steps)) {
      exit()
    } else {
      setShowCancelModal(true)
    }
  }

  const onClickCloseModal = () => {
    setShowCancelModal(false)
  }

  const exit = () => {
    props.changeSidebarType(leftSidebarTypes.DEFAULT);
    navigate(-1)
  }

  return (
    <MutliStepPage>
      {/* CANCEL MODAL */}
      <SmallModal
        modalIsOpen={showCancelModal}
        onClickClose={onClickCloseModal}
        title={"Are you sure you want to leave?"}
        confirmTxt={"Leave"}
        cancelTxt={"Stay"}
        onClickConfirm={exit}
      >
        <p className="font-size-16 m-0">
          All data will be lost if you leave now.
        </p>
      </SmallModal>

      {/* HEADER */}
      <MultiStepHeader
        progress={progress}
        hideBack={currentStep === WALLET_FORM.WALLET_INFORMATION}
        onClickBack={goPreviousStep}
        onClickClose={onClickCancel}
        title={`New wallet`}
      />

      {/* PROCESSING */}
      <LoadingBackdrop
        show={processing}
        description={"We are creating your wallet. This might take a moment, please wait..."}
      />

      {/* Loading */}
      {loading && (
        <div className="d-flex column center-x center-y my-2">
          <Loading />
        </div>
      )}

      <Fade in={!loading}>
        {/* STEP 1 => WALLET INFORMATION */}
        <Fade in={currentStep === WALLET_FORM.WALLET_INFORMATION}>
          <WalletInformation
            currencies={currencies}
            handleSubmit={values => onSubmit(values)}
            onClickCancel={onClickCancel}
            companyId={companyInfo.id}
            userId={userProfile.id}
          />
        </Fade>
      </Fade>
    </MutliStepPage>
  )
}

NewWallet.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = state => {
  return { ...state.Layout };
};

const mapDispatchToProps = {
  changeSidebarType,
  // Any other actions to map here
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(NewWallet);

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"

import withRouter from "components/Common/withRouter"
import Auth from "models/auth"

const Logout = () => {
  const history = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    Auth.logout()
      .then(() => {
        history("/login")
      })
      .catch(error => {
        console.log(error.response.data.message)
      })
  }, [dispatch])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)

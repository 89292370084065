import { isFunction } from "lodash"

export const CONTAINER_TYPE = {
  SHADOW: "shadow",
  BORDER: "border",
}

export const getContainerRadius = () => {
  return `
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    &::before {
      border-radius: 12px;
      -webkit-border-radius: 12px;
      -moz-border-radius: 12px;
    }
  `
}

export const getCursorStyles = props => {
  return isFunction(props.onClick) ? `cursor: pointer` : ""
}

export const getBorderStyles = props => {
  return `
    ${getContainerRadius()}
    ${getCursorStyles(props)}
    border: 1px solid ${props.borderColor || "black"}
    background: ${props.borderBackground || "#FFFFFF"}
  `
}

export const getShadowStyles = props => {
  return `
    ${getContainerRadius()}
    ${getCursorStyles(props)}
    background: ${props.shadowBackground || "#FFFFFF"}
  `
}

import React from "react"
import PropTypes from "prop-types"

import { FeedbackNotification as HevaraFeedbackNotification } from "./FeedbackNotification"
import { FEEDBACK_VARIANTS } from "./FeedbackNotification.styles"

function FeedbackNotification({
  variant,
  message,
  title,
  hasCloseIcon,
  rightActions,
  bottomActions,
  customContent,
  onClose,
  className,
  style,
}) {
  return (
    <HevaraFeedbackNotification
      variant={variant}
      title={title}
      message={message}
      hasCloseIcon={hasCloseIcon}
      onClose={onClose}
      rightActions={rightActions}
      bottomActions={bottomActions}
      customContent={customContent}
      className={className}
      style={style}
    />
  )
}

FeedbackNotification.defaultProps = {
  variant: FEEDBACK_VARIANTS.INFO,
}

export { FeedbackNotification as default, FEEDBACK_VARIANTS }

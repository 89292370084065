import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { BodyText, BodyTextBold } from "components/atoms"
import {
  getBulletListStyles,
  getBulletListItemStyles,
  getBulletListItemTitleStyles,
  getBulletListItemDescriptionStyles,
  getBulletListItemIconStyles,
} from "./BulletList.styles"

const StyledBulletList = styled.ul.attrs({
  className: "hevara-bullet-list",
})`
  ${getBulletListStyles}
`
const StyledBulletListItem = styled.li.attrs({
  className: "hevara-bullet-list-item",
})`
  ${getBulletListItemStyles}
`
const StyledBulletListItemWrapper = styled.div.attrs({
  className: " hevara-bullet-list-item-wrapper",
})``

const StyledTitle = styled(BodyTextBold).attrs({
  className: "hevara-bullet-list-title",
})`
  ${getBulletListItemTitleStyles}
`
const StyledDescription = styled(BodyText).attrs({
  className: "hevara-bullet-list-description",
})`
  ${getBulletListItemDescriptionStyles}
`
const StyledIcon = styled.i.attrs({
  className: "hevara-bullet-list-icon",
})`
  ${getBulletListItemIconStyles}
`

export const BulletList = ({ data, className, style, ...props }) => {
  return (
    <StyledBulletList className={className} style={style} {...props}>
      {!!data.length &&
        data.map((bullet, index) => (
          <StyledBulletListItem key={`${bullet.title} ${index}`}>
            <StyledIcon>
              <i
                className={
                  props.iconName ||
                  bullet.iconName ||
                  "mdi mdi-check-circle" +
                    " hevara-button-icon font-size-20 me-2 text-success"
                }
              ></i>
            </StyledIcon>
            <StyledBulletListItemWrapper>
              {bullet.title && (
                <StyledTitle fontColor={bullet.fontColor || props.fontColor}>
                  {bullet.title}
                </StyledTitle>
              )}
              <StyledDescription
                fontColor={bullet.fontColor || props.fontColor}
              >
                {bullet.description}
              </StyledDescription>
            </StyledBulletListItemWrapper>
          </StyledBulletListItem>
        ))}
    </StyledBulletList>
  )
}

BulletList.defaultProps = {
  data: [],
  className: "",
  style: {},
}

import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { omit } from "lodash"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { Card, CardBody, Container, Row, Col } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import DataItem from "components/DataItem"
import Loading from "components/Common/Spinner"
import { EmptyErrorState } from "components/EmptyState"
import Auth from "models/auth"
import Product from "models/product"
import Upload from "models/upload"
import ProductCategories from "models/productCategories"
import ProductTypes from "models/productTypes"
import Warehouse from "models/warehouses"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { getStatus } from "utils/utils"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import FeedbackNotification, { FEEDBACK_VARIANTS } from "components/FeedbackNotification"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import Link from "components/Link"
import ImageModal from "./imageModal"
import EditProductModal from "./editProductModal"
import Avatar from "components/Avatar/Avatar"
import CONST from "utils/constants"

const { SIZE } = CONST

import "./style.scss"

const ProductDetails = props => {
  //meta title
  document.title = "Product details | hevara DMS Platform"

  const { productId } = useParams()

  const [modal, setModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [productDetails, setProductDetails] = useState({})
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const [productImageInfo, setProductImageInfo] = useState({})

  const [categories, setCategories] = useState([])
  const [productTypes, setProductTypes] = useState([])
  const [warehouses, setWarehouses] = useState([])

  const fetchProductDetails = id => {
    Product.getProduct(id)
      .then(data => {
        setProductDetails(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchProductCategories = () => {
    ProductCategories.lookUpProductCategories()
      .then(data => {
        setCategories([
          {
            status: "Default",
            name: "Select category",
            value: "",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchProductTypes = () => {
    ProductTypes.lookUpProductTypes()
      .then(data => {
        setProductTypes([
          {
            status: "Default",
            label: "Select type",
            value: "",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanyWarehouses = id => {
    Warehouse.lookUpCompanyWarehouses(id)
      .then(data => {
        setWarehouses([
          {
            status: "Default",
            warehouseName: "Select warehouse",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const onClickUploadImage = file => {
    toggle()

    setProcessing(true)

    const formData = new FormData()
    formData.append("file", file[0])
    formData.append("fileType", "PRODUCT_IMAGE")
    formData.append("productId", productId)

    Upload.addUpload(formData)
      .then(data => {
        setProductImageInfo(data)
        toast.success(`Product image added successfully.`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const editProductDetails = values => {
    setProcessing(true)

    Product.updateProduct(productDetails.id, values)
      .then(product => {
        setProductDetails(product)
        toast.success(`${product.name} has been updated.`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disableProduct = () => {
    setProcessing(true)

    const payload = {
      ...omit(productDetails, ["id", "dateCreated"]),
      status: "Disabled",
    }

    Product.updateProduct(productDetails.id, payload)
      .then(product => {
        setProductDetails(product)
        toast.success(`${product.name} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableProduct = () => {
    setProcessing(true)

    const payload = {
      ...omit(productDetails, ["id", "dateCreated"]),
      status: "Active",
    }

    Product.updateProduct(productDetails.id, payload)
      .then(product => {
        setProductDetails(product)
        toast.success(`${product.name} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (productId && profile) {
      const companyId = JSON.parse(profile).companyId

      Promise.all([
        fetchProductDetails(productId),
        fetchProductCategories(),
        fetchProductTypes(),
        fetchCompanyWarehouses(companyId),
      ])
      setUserProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [productId, productImageInfo])

  const getHeaderActions = () => {
    const isActive = productDetails.status === "Active"

    const actions = [
      userProfile.permission.CAN_MANAGE_PRODUCTS && {
        label: "Edit",
        icon: "mdi mdi-pencil",
        onClick: () => {
          onClickEditProductDetails()
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userProfile.permission.CAN_MANAGE_PRODUCTS && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () => (isActive ? disableProduct() : enableProduct()),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const onClickEditProductDetails = () => {
    if (editModal) {
      setEditModal(false)
    } else {
      setEditModal(true)
    }
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Product details")}
            breadcrumbItem={props.t("Product details")}
          />

          {/* Loading */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Products"}
            description={"We are processing your request, please wait..."}
          />

          {/* IMAGE MODAL */}
          <ImageModal
            modalIsOpen={modal}
            onClickClose={toggle}
            onClickUploadImage={onClickUploadImage}
          />

          {/* EDIT PRODUCT INFORMATION MODAL */}
          <EditProductModal
            modalIsOpen={editModal}
            onClickClose={onClickEditProductDetails}
            product={productDetails}
            categories={categories}
            productTypes={productTypes}
            warehouses={warehouses}
            companyId={userProfile.companyId}
            onClickEditProductDetails={onClickEditProductDetails}
            handleSubmit={values => editProductDetails(values)}
          />

          {/* DETAILS */}
          {!loading && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* PAGE HEADER */}
                    <PageHeader
                      avatar={
                        productDetails && {
                          initials: productDetails.name,
                          image: productDetails.image?.file,
                        }
                      }
                      title={`${productDetails.name} (${productDetails.size} ml)`}
                      showBackButton={true}
                      showDivider={true}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText>
                            {getStatus(productDetails.status)}
                          </PageHeaderInfoText>

                          <PageHeaderInfoText className="fw-semibold">
                            <p className="mb-0 fw-bold text-tertiary">
                              {productDetails.code}
                            </p>
                          </PageHeaderInfoText>

                          <PageHeaderInfoText className="fw-semibold">
                            <p className="mb-0 fw-bold text-primary">
                              {productDetails.categoryName} -{" "}
                              {productDetails.categoryType}
                            </p>
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                      actions={<HeaderActions actions={getHeaderActions()} />}
                    />

                    {/* DETAILS SECTIONS */}
                    <div className="hevara-product-detail">
                      <div className="tx-grid mb-3">
                        {/* PRODUCT INFO */}
                        <div>
                          {/* BANNER FOR PRODUCT IMAGE */}
                          {!productDetails.image?.file && (
                            <FeedbackNotification
                              title={`Product image`}
                              message={`Upload product image by clicking on the link below. Your customers will be able to see this image when they view your product catalogue.`}
                              bottomActions={
                                <Link onClick={toggle}>Add photo</Link>
                              }
                              className="mb-4"
                            />
                          )}

                          {!productDetails.primaryPrice && (
                            <FeedbackNotification
                              variant={FEEDBACK_VARIANTS.WARNING}
                              title={`Product price`}
                              message={`Product price is missing. Please update product information to include its price.`}
                              className="mb-4"
                            />
                          )}

                          <p className="font-size-14 mb-2 text-muted fw-bold">
                            Description
                          </p>
                          <p className="text-muted font-size-14 mb-4">
                            {productDetails.description}
                          </p>

                          {/* PRODUCT DETAILS */}
                          <p className="font-size-14 mb-2 text-muted fw-bold">
                            Product details
                          </p>
                          <div className="detail-grid mb-4">
                            <DataItem
                              label={props.t("Name")}
                              value={`${productDetails.name} (${productDetails.size} ml)`}
                            />

                            <DataItem
                              label={props.t("Category")}
                              value={productDetails.categoryName}
                            />

                            <DataItem
                              label={props.t("Type")}
                              value={productDetails.categoryType}
                            />

                            {productDetails.warehouseName && (
                              <DataItem
                                label={props.t("Warehouse")}
                                value={productDetails.warehouseName}
                              />
                            )}
                          </div>

                          {/* PRODUCT PRICING */}
                          <p className="font-size-14 mb-2 text-muted fw-bold">
                            Product pricing
                          </p>
                          <div className="detail-grid mb-4">
                            <DataItem
                              label={props.t("Price")}
                              value={`Ksh. ${productDetails.primaryPrice}`}
                            />

                            {!!productDetails.primaryDiscount && (
                              <DataItem
                                label={props.t("Discount")}
                                value={`${productDetails.primaryDiscount}% Off`}
                              />
                            )}
                          </div>

                          {/* Other details */}
                          <p className="font-size-14 mb-2 text-muted fw-bold">
                            Other details
                          </p>
                          <div className="detail-grid">
                            <DataItem
                              label={props.t("Stock quantity")}
                              value={`${productDetails.stockQuantity || "-"}`}
                            />

                            <DataItem
                              label={props.t("Replenishing level")}
                              value={`${
                                productDetails.replenishingLevel || "-"
                              }`}
                            />

                            <DataItem
                              label={props.t("Manufacturer")}
                              value={productDetails.manufacturerName}
                            />
                          </div>
                        </div>

                        {/* PRODUCT IMAGE */}
                        <div className="mb-3 tx-avatar">
                          <Avatar
                            size={SIZE.XXL}
                            initials={productDetails.name}
                            image={productDetails.image?.file}
                            className="avatar-size"
                          />

                          <Link
                            icon="mdi mdi-camera"
                            className="d-flex justify-content-center mt-1"
                            onClick={
                              productDetails.image?.file
                                ? () => console.log("Edit image, WIP")
                                : () => toggle()
                            }
                          >
                            {productDetails.image?.file
                              ? `Edit photo`
                              : `Add photo`}
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                      {/* ERROR STATE */}
                      <EmptyErrorState
                        visible={error}
                        title={`Something went wrong`}
                        description={`An error has occurred. Please try again later or contact hevara for support`}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

ProductDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ProductDetails)

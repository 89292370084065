import React from "react"
import { Spinner } from "reactstrap"

const Spinners = () => {
  return (
    <React.Fragment>
      <Spinner
        color="primary"
        style={{
          height: "3rem",
          width: "3rem",
        }}
        className="position-absolute top-50 start-50"
      />
    </React.Fragment>
  )
}

export default Spinners

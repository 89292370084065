import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { getDividerStyles } from "./Divider.styles"
import CONST from "utils/constants"

const { DIVIDER_ORIENTATION } = CONST

const StyledDivider = styled.div.attrs({
  className: "hevara-divider",
})`
  ${getDividerStyles}
`

const Divider = ({ orientation, color, className, style }) => {
  return (
    <StyledDivider
      orientation={orientation}
      color={color}
      style={style}
      className={className}
    />
  )
}

Divider.propTypes = {
  orientation: PropTypes.string,
  color: PropTypes.string,
}

Divider.defaultProps = {
  orientation: DIVIDER_ORIENTATION.HORIZONTAL,
  className: "",
}

export default Divider

import React from "react";
import styled from "styled-components";

import { BodyText, SmallTextBold, AmountBold } from "components/atoms";
import { 
  getCurrencyStyles,
  getDataAmountStyles,
  getIconStyles,
  getLabelStyles,
  getRowStyles,
  DATA_AMOUNT_SIZES,
  DATA_AMOUNT_WEIGHT
} from "./DataAmount.styles";

const StyledDataAmount = styled.div.attrs({
  className: "hevara-data-amount"
})`
  ${getDataAmountStyles}
`
const StyledRow = styled.div`
  ${getRowStyles}
`
const StyledIcon = styled.i`
  ${getIconStyles}
`
const StyledLabel = styled(SmallTextBold)`
  ${getLabelStyles}
`
const StyledValue = styled(AmountBold).attrs({
  className: "hevara-data-amount-value"
})``

const StyledCurrency = styled(BodyText).attrs({
  className: "hevara-data-amount-currency"
})`
  ${getCurrencyStyles}
`

/**
 * Data  Amount UI component
 */
export const DataAmount = ({ className, style, label, value, currency, icon, customIcon, ...props }) => {
  return (
    <StyledDataAmount className={className} style={style}>
      <StyledRow>
        {/* LABEL */}
        <StyledLabel>{label}</StyledLabel>

        {/* ICON */}
        {!!icon && !customIcon && 
          <StyledIcon>
            <i className={props.icon + " font-size-20 me-2 text-success"}></i>
          </StyledIcon>
        }

        {!!customIcon && !icon &&
          <StyledIcon>
            <i className={props.customIcon + " font-size-20 me-2 text-success"}></i>
          </StyledIcon>
        }
      </StyledRow>

      <StyledRow>
        {/* VALUE */}
        <StyledValue size={props.size} weight={props.weight}>{value}</StyledValue>
        {/* CURRENCY */}
        {!!currency && <StyledCurrency size={props.size}>{currency}</StyledCurrency>}
      </StyledRow>
    </StyledDataAmount>
  )
}

DataAmount.defaultProps = {
  size: DATA_AMOUNT_SIZES.M,
  weight: DATA_AMOUNT_WEIGHT.BOLD
}
import CONST from "utils/constants"

const { SIZE, MEDIA } = CONST

const getTheme = {
  color: {
    standard_button: {
      primary: {
        default: {
          background: "#F4CC0C",
          font: "#0C0C0C",
          border: "#F4CC0C",
        },
        hover: {
          background: "#F9E585",
          font: "#0C0C0C",
          border: "#F9E585",
        },
        focus: {
          background: "#6C8C3C",
          font: "#F4CC0C",
          border: "#F4CC0C",
        },
        active: {
          background: "#F9E585",
          font: "#6C8C3C",
          border: "#F9E585",
        },
      },
      secondary: {
        default: {
          background: "#F2F5F7",
          font: "#0C0C0C",
          border: "#F2F5F7",
        },
        hover: {
          background: "#E7EDF1",
          font: "#5A6938",
          border: "#E7EDF1",
        },
        focus: {
          background: "#FFFFFF",
          font: "#F4CC0C",
          border: "#F4CC0C",
        },
        active: {
          background: "#E7EDF1",
          font: "#5A6938",
          border: "#E7EDF1",
        },
      },
      accent: {
        default: {
          background: "#6C8C3C",
          font: "#FFFFFF",
          border: "#6C8C3C",
        },
        hover: {
          background: "#F2F5F7",
          font: "#6C8C3C",
          border: "#F2F5F7",
        },
        focus: {
          background: "#FFFFFF",
          font: "#6C8C3C",
          border: "#6C8C3C",
        },
        active: {
          background: "#F2F5F7",
          font: "#6C8C3C",
          border: "#F2F5F7",
        },
      },
      critical: {
        default: {
          background: "#FDECEA",
          font: "#F44336",
          border: "#FDECEA",
        },
        hover: {
          background: "#F44336",
          font: "#FFFFFF",
          border: "#F44336",
        },
        focus: {
          background: "#FFFFFF",
          font: "#F44336",
          border: "#F44336",
        },
        active: {
          background: "#F44336",
          font: "#FFFFFF",
          border: "#F44336",
        },
      },
    },
    icon_button: {
      primary: {
        default: {
          background: "#F4CC0C",
          icon: "#000000",
          border: "#F4CC0C",
        },
        hover: {
          background: "#F9E585",
          icon: "#6C8C3C",
          border: "#F9E585",
        },
        focus: {
          background: "#6C8C3C",
          icon: "#F4CC0C",
          border: "#F4CC0C",
        },
        active: {
          background: "#F9E585",
          icon: "#6C8C3C",
          border: "#F9E585",
        },
      },
      secondary: {
        default: {
          background: "#F2F5F7",
          icon: "#F4CC0C",
          border: "#F2F5F7",
        },
        hover: {
          background: "#E7EDF1",
          icon: "#F4CC0C",
          border: "#E7EDF1",
        },
        focus: {
          background: "#FFFFFF",
          icon: "#F4CC0C",
          border: "#F4CC0C",
        },
        active: {
          background: "#E7EDF1",
          icon: "#F4CC0C",
          border: "#E7EDF1",
        },
      },
      accent: {
        default: {
          background: "#6C8C3C",
          icon: "#FFFFFF",
          border: "#6C8C3C",
        },
        hover: {
          background: "#F2F5F7",
          icon: "#6C8C3C",
          border: "#F2F5F7",
        },
        focus: {
          background: "#FFFFFF",
          icon: "#6C8C3C",
          border: "#6C8C3C",
        },
        active: {
          background: "#F2F5F7",
          icon: "#6C8C3C",
          border: "#F2F5F7",
        },
      },
      critical: {
        default: {
          background: "#FDECEA",
          icon: "#F44336",
          border: "#FDECEA",
        },
        hover: {
          background: "#F44336",
          icon: "#FFFFFF",
          border: "#F44336",
        },
        focus: {
          background: "#FFFFFF",
          icon: "#F44336",
          border: "#F44336",
        },
        active: {
          background: "#F44336",
          icon: "#FFFFFF",
          border: "#F44336",
        },
      },
    },
  },
}

export const BUTTON_VARIANTS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  ACCENT: "accent",
  CRITICAL: "critical",
}

export const ButtonType = ["standard_button", "icn_button"]

export const CommonButtonSizes = ["m", "s", "xs"]

export const getButtonColors = (
  theme,
  buttonType,
  variant,
  state,
  key,
  defaultColor
) => {
  if (defaultColor) {
    return defaultColor
  } else {
    return theme.color[buttonType][variant][state][key]
  }
}

export const getButtonStateStyles = (
  {
    variant,
    backgroundColor,
    borderColor,
    color: propColor,
    disabled,
    ...props
  },
  buttonType,
  state
) => {
  const theme = getTheme
  const colorType = buttonType === "standard_button" ? "font" : "icon"
  const color = getButtonColors(
    theme,
    buttonType,
    variant,
    state,
    colorType,
    propColor
  )

  return `
    background-color: ${getButtonColors(
      theme,
      buttonType,
      variant,
      state,
      "background",
      backgroundColor
    )};
    border: 2px solid ${getButtonColors(
      theme,
      buttonType,
      variant,
      state,
      "border",
      borderColor
    )};
    outline: 0;
    ${disabled ? `cursor: default; opacity: 0.4;` : `cursor: pointer;`};
    .hevara-basic-text, .hevara-button-text {
      color: ${color};
    }
    .hevara-icon.hevara-button-icon {
      fill: ${color}
    }
  `
}

export const getButtonRadius = ({ size }) => {
  if (size === SIZE.XS) {
    return `border-radius: 8px;`
  } else {
    return `border-radius: 12px;`
  }
}

export const getButtonStyles = props => {
  return `
    ${getButtonStateStyles(props, "standard_button", "default")}
    &:hover {
      ${
        !props.disabled &&
        getButtonStateStyles(props, "standard_button", "hover")
      }
    }
    &:focus-visible {
      ${
        !props.disabled &&
        getButtonStateStyles(props, "standard_button", "focus")
      }
    }
    &:active {
      ${
        !props.disabled &&
        getButtonStateStyles(props, "standard_button", "active")
      }
    }
  `
}

export const getButtonSizes = ({ size, responsive }) => {
  const responsiveStyles = responsive ? `${MEDIA.MOBILE}{width: 100%;}` : ``

  switch (size) {
    case SIZE.XS: {
      return `
        padding-right: 14px;
        padding-left: 14px;
        padding-top: 2px;
        padding-bottom: 2px;
        ${responsiveStyles}
      `
    }
    case SIZE.S: {
      return `
        padding-right: 18px;
        padding-left: 18px;
        padding-top: 6px;
        padding-bottom: 6px;
        ${responsiveStyles}
      `
    }
    default: {
      return `
        padding-right: 18px;
        padding-left: 18px;
        padding-top: 10px;
        padding-bottom: 10px;
        ${responsiveStyles}
      `
    }
  }
}

export const getIconButtonStyles = props => {
  return `
    ${getButtonStateStyles(props, "icon_button", "default")}
    &:hover {
      ${!props.disabled && getButtonStateStyles(props, "icon_button", "hover")}
    }
    &:focus-visible {
      ${!props.disabled && getButtonStateStyles(props, "icon_button", "focus")}
    }
    &:active {
      ${!props.disabled && getButtonStateStyles(props, "icon_button", "active")}
    }
  `
}

export const getIconButtonSizes = ({ size }) => {
  switch (size) {
    case SIZE.XS: {
      return `
        padding: 4px;
      `
    }
    case SIZE.S: {
      return `
        padding: 6px;
      `
    }
    default: {
      return `
        padding: 10px;
      `
    }
  }
}

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { chain, isEmpty, orderBy } from "lodash"
import { Col, Row, Card, CardBody, Container } from "reactstrap"
import { withTranslation } from "react-i18next"
import { toast, ToastContainer } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { connect } from "react-redux";
import { compose } from "redux"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import Auth from "models/auth"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"
import EmptyState, { EmptyErrorState } from "components/EmptyState"
import Wallets from "models/wallet"
import WalletListItem from "components/itemList/walletItemList/walletListItem"
import { changeSidebarType } from "store/actions"
import { leftSidebarTypes } from "constants/layout"

const Wallet = props => {
  //meta title
  document.title = "Wallet | hevara DMS Platform"

  const navigate = useNavigate()

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const [wallets, setWallets] = useState([])

  const fetchWallets = (limit, page, id) => {
    const filters = {
      userId: id,
    }

    Wallets.getWallets(limit, page, filters)
      .then(data => {
        setWallets(data.results)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()

    if (profile) {
      const userId = JSON.parse(profile).id
      fetchWallets(limit, page, userId)
      setUserProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  const deleteWallet = (wallet) => {
    setProcessing(true)

    Wallets.deleteWallet(wallet.id)
      .then(data => {
        setWallets(wallets.filter(item => item.id.toString() !== data.wallet.id.toString()));
        toast.success(data.message)
      })
      .catch(error => toast.error(error.response?.data?.message))
      .finally(() => setProcessing(false))
  }

  const getListActions = item => {
    const canCancelWallet = userProfile.permission.CAN_MANAGE_WALLETS && !item.main;

    const actions = [
      {
        label: "View details",
        icon: "mdi mdi-monitor-eye",
        onClick: () => navigate(`/wallet-details/${item.id}`),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      canCancelWallet && {
        label: "Cancel wallet",
        icon: "bx bx-trash-alt",
        onClick: () => deleteWallet(item),
        variant: BUTTON_VARIANT.CRITICAL,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userProfile.permission.CAN_MANAGE_WALLETS && {
        label: "New wallet",
        icon: "mdi mdi-plus",
        onClick: onClickNewWallet,
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const onClickNewWallet = () => {
    props.changeSidebarType(leftSidebarTypes.HIDDEN);
    navigate(`/new-wallet`);
  }

  const filteredWallets = chain(wallets)
    .filter(wallet => {
      // wallet_alias
      const hasLabel = wallet.wallet_alias?.toLowerCase().includes(name.toLowerCase())
      return hasLabel
    })
    .value()

  const data = orderBy(filteredWallets, [p => p.wallet_alias.toUpperCase()], "asc")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Wallets")}
            breadcrumbItem={props.t("Wallets")}
          />

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Wallets"}
            description={"We are processing your request, please wait..."}
          />

          {!loading && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Wallets"}
                      showBackButton={true}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        !isEmpty(wallets) && (
                          <PageHeaderInfo>
                            <PageHeaderInfoText className="fw-semibold">
                              {`${wallets.length} ${wallets.length > 1 ? "Wallets" : "Wallet"}`}
                            </PageHeaderInfoText>
                          </PageHeaderInfo>
                        )
                      }
                    />

                    {/* LIST */}
                    {!isEmpty(wallets) && (
                      <SectionList
                        key={"all"}
                        data={groupListByKey(data, "wallet_alias", true)}
                        renderItem={item => (
                          <WalletListItem
                            wallet={item}
                            actions={getListActions(item)}
                          />
                        )}
                      />
                    )}

                    <div className="d-flex justify-content-center align-items-center">
                      {/* EMPTY STATE */}
                      <EmptyState
                        visible={isEmpty(wallets)}
                        title={`No wallets`}
                        description={`Click on the button below to create.`}
                        firstButton={
                          userProfile.permission.CAN_MANAGE_WALLETS && {
                            label: `New wallet`,
                            onClick: onClickNewWallet,
                            disabled: processing,
                          }
                        }
                      />

                      {/* ERROR STATE */}
                      <EmptyErrorState
                        visible={error}
                        title={`Something went wrong`}
                        description={`An error has occurred. Please try again later or contact hevara for support`}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

Wallet.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = state => {
  return { ...state.Layout };
};

const mapDispatchToProps = {
  changeSidebarType,
  // Any other actions to map here
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Wallet);

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { chain, isEmpty, omit, orderBy } from "lodash"
import { Col, Row, Card, CardBody, Container } from "reactstrap"
import { withTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { connect } from "react-redux";
import { compose } from "redux"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import Auth from "models/auth"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"
import EmptyState, { EmptyErrorState } from "components/EmptyState"
import Catalogue from "models/productCatalogue"
import Company from "models/company"
import ProductListItem from "components/itemList/productListItem/productListItem"
import EditProductCatalogueModal from "./editProductCatalogueModal"
import Manufacturers from "models/manufacturers"
import Distributors from "models/distributors"
import ProductCategories from "models/productCategories"
import ProductTypes from "models/productTypes"
import Warehouse from "models/warehouses"
import { changeSidebarType } from "store/actions"
import { leftSidebarTypes } from "constants/layout"

const ProductCatalogue = (props) => {
  //meta title
  document.title = "Product catalogue | hevara DMS Platform"

  const navigate = useNavigate()

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [editModal, setEditModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const [productCatalogues, setProductCatalogues] = useState([])
  const [catalogue, setCatalogue] = useState(null)
  const [companyInfo, setCompanyInfo] = useState({})

  const [manufacturers, setManufacturers] = useState([])
  const [distributors, setDistributors] = useState([])
  const [categories, setCategories] = useState([])
  const [productTypes, setProductTypes] = useState([])
  const [warehouses, setWarehouses] = useState([])

  const fetchCompanyInfo = id => {
    Company.getCompany(id)
      .then(data => {
        setCompanyInfo(data)
        fetchProductCatalogues(data.id, data.type)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchProductCatalogues = id => {
    const filters = {
      companyId: id,
    }

    Catalogue.getProductCatalogues(limit, page, filters)
      .then(data => {
        setProductCatalogues(data.results)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchManufacturers = () => {
    Manufacturers.lookUpManufacturers()
      .then(data => {
        setManufacturers([
          {
            status: "Default",
            companyName: "Select manufacturer",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchDistributors = () => {
    Distributors.lookUpDistributors()
      .then(data => {
        setDistributors([
          {
            status: "Default",
            companyName: "Select distributor",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchProductCategories = () => {
    ProductCategories.lookUpProductCategories()
      .then(data => {
        setCategories([
          {
            status: "Default",
            name: "Select category",
            value: "",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchProductTypes = () => {
    ProductTypes.lookUpProductTypes()
      .then(data => {
        setProductTypes([
          {
            status: "Default",
            label: "Select type",
            value: "",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanyWarehouses = id => {
    Warehouse.lookUpCompanyWarehouses(id)
      .then(data => {
        setWarehouses([
          {
            status: "Default",
            warehouseName: "Select warehouse",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()

    if (profile) {
      const companyId = JSON.parse(profile).companyId
      Promise.all([
        fetchCompanyInfo(companyId),
        fetchManufacturers(),
        fetchDistributors(),
        fetchProductCategories(),
        fetchProductTypes(),
        fetchCompanyWarehouses(companyId),
      ])
      setUserProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  const editProductCatalogueDetails = values => {
    setProcessing(true)

    const payload = { ...values, productId: catalogue.productId }

    Catalogue.updateProductCatalogue(catalogue.id, payload)
      .then(res => {
        const catalogue = res.tradingProduct

        setProductCatalogues(
          productCatalogues.map(item =>
            item.id.toString() === catalogue.id.toString()
              ? { ...catalogue }
              : item
          )
        )
        toast.success(`${catalogue.name} has been updated`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disableProductCatalogue = data => {
    setProcessing(true)

    const payload = {
      ...omit(data, ["id", "dateCreated"]),
      status: "Disabled",
    }

    Catalogue.updateProductCatalogue(data.id, payload)
      .then(res => {
        const catalogue = res.tradingProduct

        setProductCatalogues(
          productCatalogues.map(item =>
            item.id.toString() === catalogue.id.toString()
              ? { ...catalogue }
              : item
          )
        )
        toast.success(`${catalogue.name} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableProductCatalogue = data => {
    setProcessing(true)

    const payload = {
      ...omit(data, ["id", "dateCreated"]),
      status: "Active",
    }

    Catalogue.updateProductCatalogue(data.id, payload)
      .then(res => {
        const catalogue = res.tradingProduct

        setProductCatalogues(
          productCatalogues.map(item =>
            item.id.toString() === catalogue.id.toString()
              ? { ...catalogue }
              : item
          )
        )
        toast.success(`${catalogue.name} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const getListActions = item => {
    const isActive = item.status === "Active"

    const actions = [
      {
        label: "View details",
        icon: "mdi mdi-monitor-eye",
        onClick: () => navigate(`/product-catalogue-details/${item.id}`),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userProfile.permission.CAN_MANAGE_PRODUCTS && {
        label: "Edit",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => {
          closeSideTopBars(leftSidebarTypes.HIDDEN)
          setEditModal(true)
          setCatalogue(item)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userProfile.permission.CAN_MANAGE_PRODUCTS && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () =>
          isActive
            ? disableProductCatalogue(item)
            : enableProductCatalogue(item),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const closeSideTopBars = (type) => {
    props.changeSidebarType(type);
  }

  const getHeaderActions = () => {
    const actions = [
      userProfile.permission.CAN_MANAGE_PRODUCTS && {
        label: `New product catalogue`,
        icon: "mdi mdi-plus",
        onClick: () => {
          navigate(`/product-list`)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const onClickEdit = () => {
    if (editModal) {
      setEditModal(false)
      setCatalogue(null)
    } else {
      setEditModal(true)
    }

    closeSideTopBars(leftSidebarTypes.DEFAULT)
  }

  const filteredProductCatalogues = chain(productCatalogues)
    .filter(catalogue => {
      const hasLabel = catalogue.name?.toLowerCase().includes(name.toLowerCase())
      return hasLabel
    })
    .value()

  const data = orderBy(filteredProductCatalogues, [p => p.name.toUpperCase()], "asc")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={"Product catalogue"}
            breadcrumbItem={"Product catalogue"}
          />

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Product catalogue"}
            description={"We are processing your request, please wait..."}
          />

          {/* EDIT PRODUCT INFORMATION MODAL */}
          <EditProductCatalogueModal
            modalIsOpen={editModal}
            onClickClose={onClickEdit}
            catalogue={catalogue}
            companyId={userProfile.companyId}
            distributors={distributors}
            manufacturers={manufacturers}
            warehouses={warehouses}
            companyType={companyInfo.type}
            categories={categories}
            productTypes={productTypes}
            onClickEdit={onClickEdit}
            handleSubmit={values => editProductCatalogueDetails(values)}
          />

          {!loading && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Product catalogue"}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        !isEmpty(productCatalogues) && (
                          <PageHeaderInfo>
                            <PageHeaderInfoText className="fw-semibold">
                              {`${productCatalogues.length} ${
                                productCatalogues.length > 1
                                  ? "Product catalogues"
                                  : "Product catalogue"
                              }`}
                            </PageHeaderInfoText>
                          </PageHeaderInfo>
                        )
                      }
                    />

                    {/* LIST */}
                    {!isEmpty(productCatalogues) && (
                      <SectionList
                        key={"all"}
                        data={groupListByKey(data, "name", true)}
                        renderItem={item => (
                          <ProductListItem
                            item={item}
                            actions={getListActions(item)}
                            companyType={companyInfo.type}
                            isProductCatalogue={true}
                          />
                        )}
                      />
                    )}

                    <div className="d-flex justify-content-center align-items-center">
                      {/* EMPTY STATE */}
                      <EmptyState
                        visible={
                          isEmpty(companyInfo) || isEmpty(productCatalogues)
                        }
                        title={`No product catalogue`}
                        description={`Click on the button below to create.`}
                        firstButton={
                          userProfile.permission.CAN_MANAGE_PRODUCTS && {
                            label: `New product catalogue`,
                            onClick: () => {
                              //closeSideTopBars(leftSidebarTypes.HIDDEN);
                              //navigate(`/new-product-catalogue`)
                              navigate(`/product-list`)
                            },
                            disabled: processing,
                          }
                        }
                      />

                      {/* ERROR STATE */}
                      <EmptyErrorState
                        visible={error}
                        title={`Something went wrong`}
                        description={`An error has occurred. Please try again later or contact hevara for support`}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

ProductCatalogue.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = state => {
  return { ...state.Layout };
};

const mapDispatchToProps = {
  changeSidebarType,
  // Any other actions to map here
};


export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ProductCatalogue);


import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getSubOrders(limit = 10, page = 1, filters = {}) {
    const parameters = {
      productId: filters.productId,
      productCatalogueId: filters.productCatalogueId,
      userId: filters.userId,
      orderId: filters.orderId,
      manufacturerId: filters.manufacturerId,
      distributorId: filters.distributorId,
      wholesalerId: filters.wholesalerId,
      companyId: filters.companyId,
      status: filters.status,
      limit,
      page,
    }
    const url = queryParams(API.getApi().SUB_ORDERS.GET_SUB_ORDERS, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getSubOrder(subOrderId) {
    return axios
      .get(`${API.getApi().SUB_ORDERS.GET_SUB_ORDER}/${subOrderId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  updateSubOrder(subOrderId, values) {
    return axios
      .patch(
        `${API.getApi().SUB_ORDERS.UPDATE_SUB_ORDER}/${subOrderId}`,
        values
      )
      .then(async ({ data }) => {
        return data
      })
  },
  deleteSubOrder(subOrderId) {
    return axios
      .delete(`${API.getApi().SUB_ORDERS.DELETE_SUB_ORDER}/${subOrderId}`)
      .then(async ({ data }) => {
        return data
      })
  },
}

import React from "react";
import styled from "styled-components";
import { first } from "lodash";

import { KEYBOARD_KEY, Elevation } from "../atoms";
import { getContainerStyles, getValueStyles, getElevationStyles } from "./SelectionContainer.styles";
import InputContainer from "./InputContainer";
import HiddenInput from "./HiddenInput";

const StyledContainer = styled.div.attrs({
  className: 'hevara-form-select'
})`
  ${getContainerStyles}
`
const StyledValue = styled.div`
  ${getValueStyles}
`
const StyledElevation = styled(Elevation)`
  ${getElevationStyles}
`

/**
 * Select Container UI component
 */
const SelectContainer = ({
  data,
  onChangeItem,
  isShowingOptions,
  showOptions,
  placeholder,
  error,
  disabled,
  selectedItem,
  selectedLabel,
  children,
  ariaLabel,
  id,
  role,
  errorMessageID
}) => {
  
  const onArrowPressed = (event) => {
    const index = (selectedItem ? data?.indexOf(selectedItem) : 1);
    if (data && index >= 0 && index <= data.length) {
      const previous = data[index - 1];
      const next = data[index + 1];

      if (event.code === KEYBOARD_KEY.UP && previous) {
        onChangeItem(previous)
      } else if (event.code === KEYBOARD_KEY.DOWN && next) {
        onChangeItem(next)
      }
    } else if (data && data.length > 0) {
      onChangeItem(first(data))
    }
  }

  const onBlur = (event) => {
    if (!event.relatedTarget) {
      showOptions(false)
    }
  }

  return (
    <StyledContainer
      tabIndex={-1}
      data-testid='form-select'
      aria-disabled={disabled}
      aria-errormessage={errorMessageID}
      aria-label={ariaLabel}
      aria-invalid={Boolean(error)}
      role={role}
      id={id}
      isShowingOptions={isShowingOptions}
      onKeyDown={onArrowPressed}
      onBlur={onBlur}
    >
      <InputContainer
        error={error}
        disabled={disabled}
        onClick={() => showOptions(!isShowingOptions)}
        rightIcon={isShowingOptions ? 'mdi mdi-arrow-up' : 'mdi mdi-chevron-down'}
      >
        <StyledValue value={selectedLabel}>{placeholder}</StyledValue>
        <HiddenInput />
      </InputContainer>

      {isShowingOptions && (
        <StyledElevation type={1}>
          {children}
        </StyledElevation>
      )}
    </StyledContainer>
  )
}

SelectContainer.defaultProps = {
  data: [],
  error: '',
  placeholder: '',
  disabled: false,
  selectedLabel: ''
}

export default SelectContainer
import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getWarehouses(limit = 10, page = 1, filters = {}) {
    const parameters = {
      limit,
      page,
      companyId: filters.companyId,
      warehouseName: filters.warehouseName,
      status: filters.status,
    }
    const url = queryParams(API.getApi().WAREHOUSES.GET_WAREHOUSES, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getWarehouse(warehouseId) {
    return axios
      .get(`${API.getApi().WAREHOUSES.GET_WAREHOUSE}/${warehouseId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  addWarehouse(values) {
    return axios
      .post(API.getApi().WAREHOUSES.ADD_WAREHOUSE, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateWarehouse(warehouseId, values) {
    return axios
      .patch(
        `${API.getApi().WAREHOUSES.UPDATE_WAREHOUSE}/${warehouseId}`,
        values
      )
      .then(async ({ data }) => {
        return data
      })
  },
  lookUpCompanyWarehouses(companyId) {
    return axios
      .get(`${API.getApi().LOOKUPS.COMPANY_WAREHOUSES}/${companyId}`)
      .then(async ({ data }) => {
        return data
      })
  },
}

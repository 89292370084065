import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { chain, isEmpty, omit, orderBy } from "lodash"
import { Col, Row, Card, CardBody, Container } from "reactstrap"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import TaxesListItem from "components/itemList/taxesListItem"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"
import EmptyState, { EmptyErrorState } from "components/EmptyState"
import TaxModal from "./taxModal"
import Auth from "models/auth"
import Tax from "models/tax"

const Taxes = props => {
  //meta title
  document.title = "Taxes | hevara DMS Platform"

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [taxes, setTaxes] = useState([])
  const [tax, setTax] = useState(null)

  const fetchTaxes = (limit, page, id) => {
    const filters = {
      companyId: id,
    }
    Tax.getTaxes(limit, page, filters)
      .then(data => {
        setTaxes(data.results)
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()

    if (profile) {
      const companyId = JSON.parse(profile).companyId

      setUserProfile(JSON.parse(profile))
      fetchTaxes(limit, page, companyId)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  const addTax = values => {
    setProcessing(true)

    Tax.addTax(values)
      .then(tax => {
        setTaxes([...taxes, tax])
        toast.success(`${tax.label} added successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const updateTax = (taxId, values) => {
    setProcessing(true)

    Tax.updateTax(taxId, values)
      .then(tax => {
        setTaxes(
          taxes.map(item =>
            item.id.toString() === tax.id.toString() ? { item, ...tax } : item
          )
        )
        toast.success(`${tax.label} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableTax = data => {
    setProcessing(true)

    const payload = {
      ...omit(data, ["id", "dateCreated"]),
      status: "Active",
    }

    Tax.updateTax(data.id, payload)
      .then(tax => {
        setTaxes(
          taxes.map(item =>
            item.id.toString() === tax.id.toString() ? { ...tax } : item
          )
        )
        toast.success(`${tax.label} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disableTax = data => {
    setProcessing(true)

    const payload = {
      ...omit(data, ["id", "dateCreated"]),
      status: "Disabled",
    }

    Tax.updateTax(data.id, payload)
      .then(tax => {
        setTaxes(
          taxes.map(item =>
            item.id.toString() === tax.id.toString() ? { ...tax } : item
          )
        )
        toast.success(`${tax.label} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const getListActions = item => {
    const isActive = item.status === "Active"

    const actions = [
      userProfile.permission.CAN_MANAGE_COMPANIES && {
        label: "Edit",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => handleClick(item),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userProfile.permission.CAN_MANAGE_COMPANIES && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () => (isActive ? disableTax(item) : enableTax(item)),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userProfile.permission.CAN_MANAGE_COMPANIES && {
        label: "New tax",
        icon: "mdi mdi-plus",
        onClick: () => {
          setIsEdit(false)
          setModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const handleClick = data => {
    setTax(data)
    setIsEdit(true)
    closeRoleModal()
  }

  const closeRoleModal = () => {
    if (modal) {
      setModal(false)
      setTax(null)
    } else {
      setModal(true)
    }
  }

  const onClickNewTax = () => {
    setIsEdit(false)
    setModal(true)
  }

  const filteredTaxes = chain(taxes)
    .filter(tax => {
      // Name
      const hasName = tax.label?.toLowerCase().includes(name.toLowerCase())

      return hasName
    })
    .value()

  const data = orderBy(filteredTaxes, [p => p.label.toUpperCase()], "asc")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Taxes")}
            breadcrumbItem={props.t("Taxes")}
          />

          {/* MODAL */}
          <TaxModal
            modalIsOpen={modal}
            onClickClose={closeRoleModal}
            tax={tax}
            companyId={userProfile.companyId}
            isEdit={isEdit}
            toggle={closeRoleModal}
            updateTax={updateTax}
            addTax={addTax}
          />

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Taxes"}
            description={"We are processing your request, please wait..."}
          />

          {!loading && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Taxes"}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        !isEmpty(taxes) && (
                          <PageHeaderInfo>
                            <PageHeaderInfoText className="fw-semibold">
                              {`${taxes.length} ${
                                taxes.length > 1 ? "taxes" : "tax"
                              }`}
                            </PageHeaderInfoText>
                          </PageHeaderInfo>
                        )
                      }
                    />

                    {/* LIST */}
                    {!isEmpty(taxes) && (
                      <SectionList
                        key={"all"}
                        data={groupListByKey(data, "label", true)}
                        renderItem={item => (
                          <TaxesListItem
                            item={item}
                            actions={getListActions(item)}
                          />
                        )}
                      />
                    )}

                    <div className="d-flex justify-content-center align-items-center">
                      {/* EMPTY STATE */}
                      <EmptyState
                        visible={isEmpty(taxes)}
                        title={`No taxes`}
                        description={`Click on the button below to create.`}
                        firstButton={
                          userProfile.permission.CAN_MANAGE_COMPANIES && {
                            label: `New tax`,
                            onClick: onClickNewTax,
                            disabled: processing,
                          }
                        }
                      />

                      {/* ERROR STATE */}
                      <EmptyErrorState
                        visible={error}
                        title={`Something went wrong`}
                        description={`An error has occured. Please try again later or contact hevara for support`}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

Taxes.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Taxes)

import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { chain, isEmpty, orderBy } from "lodash"
import { toast, ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { Card, CardBody, Container, Row } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import EmptyState, { EmptyErrorState } from "components/EmptyState"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import { BUTTON_VARIANT } from "components/Button"
import Wallet from "models/wallet"
import Transfers from "models/transfers"
import { getWalletStatus, groupListByKey } from "utils/utils"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import Chip, { CHIP_VARIANTS } from "components/Chip"
import FeedbackNotification, { FEEDBACK_VARIANTS } from "components/FeedbackNotification"
import constants from "utils/constants"
import DataAmount, { DATA_AMOUNT_WEIGHT } from "components/DataAmount"
import SectionList from "components/SectionList/sectionList"
import TransactionListItem from "components/itemList/transactionListItem/transactionListItem"

import "./style.scss"

const { WALLET_STATUS } = constants;

const WalletDetails = props => {
  //meta title
  document.title = "Wallet details | hevara DMS Platform"

  const { walletId } = useParams()
  const navigate = useNavigate()

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [walletDetails, setWalletDetails] = useState({})
  const [transactions, setTransactions] = useState([])
  const [loading, setLoading] = useState(true)
  const [transactionLoading, setTransactionLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)

  const fetchWalletDetails = (id) => {
    Wallet.getWallet(id)
      .then((data) => {
        setWalletDetails(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchWalletTransactions = (id) => {
    const filters = {
      wallet_Id: id,
    }

    Transfers.getTransfers(limit, page, filters)
      .then((data) => {
        setTransactions(data.results)
        setTotalPages(data.totalPages)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setTransactionLoading(false))
  }

  useEffect(() => {
    if (walletId) {
      Promise.all([fetchWalletDetails(walletId), fetchWalletTransactions(walletId)])
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [walletId, limit, page])

  const getHeaderActions = () => {
    const actions = [
      {
        label: "Top up",
        icon: "mdi mdi-plus",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      {
        label: "New payment",
        icon: "mdi mdi-wallet",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.SECONDARY,
        showInModal: false,
      },
      !walletDetails.main && {
        label: "Cancel wallet",
        icon: "mdi mdi-trash-can",
        onClick: () => console.log("WIP"),
        variant: BUTTON_VARIANT.CRITICAL,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getListActions = item => {
    const actions = [
      {
        label: "View details",
        icon: "mdi mdi-monitor-eye",
        onClick: () => navigate(`/transaction-details/${item.id}`),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getNextPage = () => {
    setLimit(limit + 10)
  }

  const filteredTransactions = chain(transactions)
    .filter(transaction => {
      const hasLabel = transaction.type?.toLowerCase().includes(name.toLowerCase())
      return hasLabel
    })
    .value()

  const data = orderBy(filteredTransactions, [p => p.type.toUpperCase()], "asc")

  const walletStatus = getWalletStatus(walletDetails?.status)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Wallet details")}
            breadcrumbItem={props.t("Wallet details")}
          />

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Wallets"}
            description={"We are processing your request, please wait..."}
          />

          {!loading && (
            <Row>
              <Card>
                <CardBody>
                  {/* PAGE HEADER */}
                  {!isEmpty(walletDetails) && 
                    <>
                      <PageHeader
                        avatar={
                          walletDetails && {
                            initials: walletDetails.wallet_alias,
                            image: walletDetails.assetFlag,
                          }
                        }
                        title={walletDetails && walletDetails.wallet_alias}
                        showBackButton={true}
                        actions={<HeaderActions actions={getHeaderActions()} />}
                        info={
                          <PageHeaderInfo>
                            {walletDetails.main &&
                              <Chip variant={CHIP_VARIANTS.INFO}>MAIN</Chip>
                            }

                            {walletDetails && walletStatus &&
                              <Chip variant={walletStatus.variant}>{walletStatus.label}</Chip>
                            }

                            {!isEmpty(walletDetails) && (
                              <PageHeaderInfoText className="fw-semibold">
                                {walletDetails?.currency}
                              </PageHeaderInfoText>
                            )}
                          </PageHeaderInfo>
                        }
                      />

                      {walletDetails.status === WALLET_STATUS.CANCELLED && 
                        <FeedbackNotification
                          message={`Its not possible to make transactions from this wallet because its cancelled.`}
                          variant={FEEDBACK_VARIANTS.ERROR}
                          className="mb-4"
                        />
                      }

                      {/* WALLET TOTALS */}
                      <div className="title-container column-lt-m mb-5">
                        <div className="d-flex wallet-totals">
                          <DataAmount
                            className="wallet-total-item"
                            avoidFontResize={true}
                            label={`Available balance`}
                            value={walletDetails.available_balance?.toLocaleString() || 0}
                            currency={walletDetails.currency}
                          />

                          <DataAmount
                            label={`Current balance`}
                            value={walletDetails.current_balance?.toLocaleString() || 0}
                            currency={walletDetails.currency}
                            weight={DATA_AMOUNT_WEIGHT.REGULAR}
                          />
                        </div>
                      </div>
                    </>
                  }

                  {/* WALLET TRANSACTION LIST */}
                  {!transactionLoading && !isEmpty(transactions) && (
                    <SectionList
                      key={"all"}
                      data={groupListByKey(data, "type", true)}
                      hasNextPage={totalPages > 1}
                      getNextPage={getNextPage}
                      loadingPage={loading}
                      loading={loading}
                      renderItem={item => (
                        <TransactionListItem
                          transaction={item}
                          actions={getListActions(item)}
                          handlePositive={true}
                          walletId={walletId}
                        />
                      )}
                    />
                  )}

                  <div className="d-flex justify-content-center align-items-center">
                    {/* EMPTY STATE */}
                    <EmptyState
                      visible={!transactionLoading && isEmpty(transactions)}
                      title={`No transactions`}
                      description={`No transactions movements yet`}
                    />

                    {/* ERROR STATE */}
                    <EmptyErrorState
                      visible={error}
                      title={`Something went wrong`}
                      description={`An error has occurred. Please try again later or contact hevara for support`}
                    />
                  </div>
                </CardBody>
              </Card>
            </Row>
          )}
        </Container>
      </div>
      
      <ToastContainer />
    </React.Fragment>
  )
}

WalletDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(WalletDetails)

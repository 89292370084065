import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { chain, isEmpty, omit, orderBy } from "lodash"
import { Col, Row, Card, CardBody, Container } from "reactstrap"
import { withTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import Auth from "models/auth"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"
import EmptyState, { EmptyErrorState } from "components/EmptyState"
import VehicleListItem from "components/itemList/vehicleListItem/vehicleListItem"
import VehicleModal from "./vehicleModal"
import Fleet from "models/fleet"
import Warehouse from "models/warehouses"
import User from "models/user"
import VehicleTypes from "models/vehicleTypes"
import CONST from "utils/constants"

const { ROLES } = CONST

const Fleets = props => {
  //meta title
  document.title = "Fleets | hevara DMS Platform"

  const navigate = useNavigate()

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const [fleets, setFleets] = useState([])
  const [fleet, setFleet] = useState(null)
  const [vehicleTypes, setVehicleTypes] = useState([])
  const [routeAgents, setRouteAgents] = useState([])
  const [warehouses, setWarehouses] = useState([])

  const fetchData = (limit, page, id) => {
    const filters = {
      companyId: id,
    }
    Fleet.getFleets(limit, page, filters)
      .then(data => {
        setFleets(data.results)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchVehicleTypes = () => {
    VehicleTypes.lookUpVehicleTypes()
      .then(data => {
        setVehicleTypes([
          {
            status: "Default",
            name: "Select vehicle type",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchRouteAgents = id => {
    const filters = {
      companyId: id,
      companyRole: ROLES.ROUTE_AGENT,
    }

    User.getUsers(limit, page, filters)
      .then(data => {
        setRouteAgents([
          {
            status: "Default",
            fullName: "Select route agent",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data.results,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanyWarehouses = id => {
    Warehouse.lookUpCompanyWarehouses(id)
      .then(data => {
        setWarehouses([
          {
            status: "Default",
            warehouseName: "Select warehouse",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()

    if (profile) {
      const companyId = JSON.parse(profile).companyId

      Promise.all([
        fetchData(limit, page, companyId),
        fetchVehicleTypes(),
        fetchRouteAgents(companyId),
        fetchCompanyWarehouses(companyId),
      ])

      setUserProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  const addFleet = values => {
    setProcessing(true)

    Fleet.addFleet(values)
      .then(fleet => {
        setFleets([...fleets, fleet])
        toast.success(`${fleet.registrationNumber} added successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const updateFleet = (vehicleId, values) => {
    setProcessing(true)

    Fleet.updateFleet(vehicleId, values)
      .then(fleet => {
        setFleets(
          fleets.map(item =>
            item.id.toString() === fleet.id.toString()
              ? { item, ...fleet }
              : item
          )
        )
        toast.success(`${fleet.registrationNumber} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disableFleet = data => {
    setProcessing(true)

    const payload = {
      ...omit(data, [
        "id",
        "companyName",
        "routeAgentName",
        "vehicleTypeName",
        "warehouseName",
        "dateCreated",
      ]),
      status: "Disabled",
    }

    Fleet.updateFleet(data.id, payload)
      .then(fleet => {
        setFleets(
          fleets.map(item =>
            item.id.toString() === fleet.id.toString() ? { ...fleet } : item
          )
        )
        toast.success(`${fleet.registrationNumber} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableFleet = data => {
    setProcessing(true)

    const payload = {
      ...omit(data, [
        "id",
        "companyName",
        "routeAgentName",
        "vehicleTypeName",
        "warehouseName",
        "dateCreated",
      ]),
      status: "Active",
    }

    Fleet.updateFleet(data.id, payload)
      .then(fleet => {
        setFleets(
          fleets.map(item =>
            item.id.toString() === fleet.id.toString() ? { ...fleet } : item
          )
        )
        toast.success(`${fleet.registrationNumber} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const getListActions = item => {
    const isActive = item.status === "Active"

    const actions = [
      {
        label: "View details",
        icon: "mdi mdi-monitor-eye",
        onClick: () => navigate(`/vehicle_details/${item.id}`),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userProfile.permission.CAN_MANAGE_FLEETS && {
        label: "Edit",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => handleClick(item),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userProfile.permission.CAN_MANAGE_FLEETS && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () => (isActive ? disableFleet(item) : enableFleet(item)),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userProfile.permission.CAN_MANAGE_FLEETS && {
        label: "New vehicle",
        icon: "mdi mdi-plus",
        onClick: () => {
          setIsEdit(false)
          setModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setFleet(null)
    } else {
      setModal(true)
    }
  }

  const handleClick = vehicleData => {
    setFleet(vehicleData)
    setIsEdit(true)
    toggle()
  }

  const onClickNewVehicle = () => {
    setIsEdit(false)
    setModal(true)
  }

  const filteredFleets = chain(fleets)
    .filter(fleet => {
      // Full name
      const hasLabel = fleet.registrationNumber
        ?.toLowerCase()
        .includes(name.toLowerCase())

      return hasLabel
    })
    .value()

  const data = orderBy(
    filteredFleets,
    [p => p.registrationNumber.toUpperCase()],
    "asc"
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Vehicles")}
            breadcrumbItem={props.t("Vehicles")}
          />

          {/* VIHICLE MODAL */}
          <VehicleModal
            modalIsOpen={modal}
            onClickClose={toggle}
            fleet={fleet}
            isEdit={isEdit}
            addFleet={addFleet}
            updateFleet={updateFleet}
            companyId={userProfile.companyId}
            vehicleTypes={vehicleTypes}
            routeAgents={routeAgents}
            warehouses={warehouses}
            toggle={toggle}
          />

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Vehicles"}
            description={"We are processing your request, please wait..."}
          />

          {!loading && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Vehicles"}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        !isEmpty(fleets) && (
                          <PageHeaderInfo>
                            <PageHeaderInfoText className="fw-semibold">
                              {`${fleets.length} ${
                                fleets.length > 1 ? "vehicles" : "vehicle"
                              }`}
                            </PageHeaderInfoText>
                          </PageHeaderInfo>
                        )
                      }
                    />

                    {/* LIST */}
                    {!isEmpty(fleets) && (
                      <SectionList
                        key={"all"}
                        data={groupListByKey(data, "registrationNumber", true)}
                        renderItem={item => (
                          <VehicleListItem
                            item={item}
                            actions={getListActions(item)}
                          />
                        )}
                      />
                    )}

                    <div className="d-flex justify-content-center align-items-center">
                      {/* EMPTY STATE */}
                      <EmptyState
                        visible={isEmpty(fleets)}
                        title={`No vehicles`}
                        description={`Click on the button below to create.`}
                        firstButton={
                          userProfile.permission.CAN_MANAGE_FLEETS && {
                            label: `New vehicle`,
                            onClick: onClickNewVehicle,
                            disabled: processing,
                          }
                        }
                      />

                      {/* ERROR STATE */}
                      <EmptyErrorState
                        visible={error}
                        title={`Something went wrong`}
                        description={`An error has occured. Please try again later or contact hevara for support`}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

Fleets.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Fleets)

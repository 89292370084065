import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { chain, isEmpty, omit, orderBy } from "lodash"
import { Col, Row, Card, CardBody, Container } from "reactstrap"
import { withTranslation } from "react-i18next"
import { toast, ToastContainer } from "react-toastify"
import { useNavigate } from "react-router-dom"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import Auth from "models/auth"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import SectionList from "components/SectionList/sectionList"
import { groupListByKey } from "utils/utils"
import EmptyState, { EmptyErrorState } from "components/EmptyState"
import User from "models/user"
import Warehouse from "models/warehouses"
import Roles from "models/roles"
import CompanyRoles from "models/companyRoles"
import Countries from "models/countries"
import UserListItem from "components/itemList/userListItem/userListItem"
import UserModal from "./userModal"

const Users = props => {
  //meta title
  document.title = "Team | hevara DMS Platform"

  const navigate = useNavigate()

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const [warehouses, setWarehouses] = useState([])
  const [users, setUsers] = useState([])
  const [user, setUser] = useState({})
  const [countries, setCountries] = useState([])
  const [roles, setRoles] = useState([])
  const [companyRoles, setCompanyRoles] = useState([])

  const fetchUsers = (limit, page, id) => {
    const filters = {
      companyId: id,
    }

    User.getUsers(limit, page, filters)
      .then(data => {
        setUsers(data.results)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchCompanyWarehouses = id => {
    Warehouse.lookUpCompanyWarehouses(id)
      .then(data => {
        setWarehouses([
          {
            status: "Default",
            warehouseName: "Select warehouse",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCountries = () => {
    Countries.lookUpCountries()
      .then(data => {
        setCountries([
          {
            status: "Default",
            name: "Select Country",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchRoles = () => {
    Roles.lookUpRoles()
      .then(data => {
        setRoles(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanyRoles = id => {
    CompanyRoles.lookupCompanyRoles(id)
      .then(data => {
        setCompanyRoles(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()

    if (profile) {
      const companyId = JSON.parse(profile).companyId

      Promise.all([
        fetchUsers(limit, page, companyId),
        fetchCompanyWarehouses(companyId),
        fetchCountries(),
        fetchRoles(),
        fetchCompanyRoles(companyId),
      ])

      setUserProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  const updateUser = (userId, values) => {
    setProcessing(true)

    const payload = {
      ...(values && values.middleName
        ? { ...values, middleName: values.middleName }
        : omit(values, ["middleName"])),
    }

    User.updateUser(userId, payload)
      .then(user => {
        setUsers(
          users.map(item =>
            item.id.toString() === user.id.toString() ? { ...user } : item
          )
        )
        toast.success(`${user.fullName} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const addUser = userData => {
    setProcessing(true)

    const payload = {
      ...(userData && userData.middleName
        ? { ...userData, middleName: userData.middleName }
        : omit(userData, ["middleName"])),
    }

    User.addUser(payload)
      .then(user => {
        setUsers([...users, user])
        toast.success(`${user.fullName} added successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disableUser = data => {
    setProcessing(true)

    const payload = {
      ...(data && data.middleName
        ? {
            ...omit(data, [
              "id",
              "isMobileNumberVerified",
              "isEmailVerified",
              "dateCreated",
              "otpCode",
            ]),
            status: "Disabled",
          }
        : {
            ...omit(data, [
              "id",
              "isMobileNumberVerified",
              "isEmailVerified",
              "dateCreated",
              "otpCode",
              "middleName",
            ]),
            status: "Disabled",
          }),
    }

    User.updateUser(data.id, payload)
      .then(user => {
        setUsers(
          users.map(item =>
            item.id.toString() === user.id.toString() ? { ...user } : item
          )
        )
        toast.success(`${user.fullName} account has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableUser = data => {
    setProcessing(true)

    const payload = {
      ...(data && data.middleName
        ? {
            ...omit(data, [
              "id",
              "isMobileNumberVerified",
              "isEmailVerified",
              "dateCreated",
              "otpCode",
            ]),
            status: "Active",
          }
        : {
            ...omit(data, [
              "id",
              "isMobileNumberVerified",
              "isEmailVerified",
              "dateCreated",
              "otpCode",
              "middleName",
            ]),
            status: "Active",
          }),
    }

    User.updateUser(data.id, payload)
      .then(user => {
        setUsers(
          users.map(item =>
            item.id.toString() === user.id.toString() ? { ...user } : item
          )
        )
        toast.success(`${user.fullName} account has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const handleClick = data => {
    setUser(data)
    setIsEdit(true)
    toggle()
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setUser(null)
    } else {
      setModal(true)
    }
  }

  const getListActions = user => {
    const isActive = user.status === "Active"
    const canDisableEnable = userProfile.permission.CAN_MANAGE_USERS &&
      user.isEmailVerified &&
      user.isMobileNumberVerified;

    const actions = [
      {
        label: "View details",
        icon: "mdi mdi-monitor-eye",
        onClick: () => navigate(`/user-details/${user.id}`),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userProfile.permission.CAN_MANAGE_USERS && {
        label: "Edit",
        icon: "mdi mdi-book-edit-outline",
        onClick: () => handleClick(user),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      canDisableEnable && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () => (isActive ? disableUser(user) : enableUser(user)),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const getHeaderActions = () => {
    const actions = [
      userProfile.permission.CAN_MANAGE_USERS && {
        label: "New user",
        icon: "mdi mdi-plus",
        onClick: () => {
          setIsEdit(false)
          setModal(true)
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const onClickNewUser = () => {
    setIsEdit(false)
    setModal(true)
  }

  // Combine all roles from roles & company roles
  const combinedRoles = [...roles, ...companyRoles]

  // Omit "SUPER_ADMIN" and "VENDOR" from combined roles
  const filteredRoles = combinedRoles.filter(
    ({ value }) => value !== "SUPER_ADMIN" && value !== "VENDOR"
  )

  const availableRoles = [
    {
      status: "Default",
      label: "Select company role",
      value: "default_value",
      dateCreated: "2023-07-03T00:00:00Z",
      id: "default_id",
    },
    ...filteredRoles,
  ]

  const filteredUsers = chain(users)
    .filter(user => {
      // Full name
      const hasLabel = user.fullName?.toLowerCase().includes(name.toLowerCase())

      return hasLabel
    })
    .value()

  const data = orderBy(filteredUsers, [p => p.fullName.toUpperCase()], "asc")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Team")}
            breadcrumbItem={props.t("Team")}
          />

          {/* USER MODAL */}
          <UserModal
            modalIsOpen={modal}
            onClickClose={toggle}
            user={user}
            isEdit={isEdit}
            updateUser={updateUser}
            addUser={addUser}
            companyId={userProfile.companyId}
            countries={countries}
            roles={availableRoles}
            warehouses={warehouses}
            toggle={toggle}
          />

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Team"}
            description={"We are processing your request, please wait..."}
          />

          {!loading && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Team"}
                      showDivider={false}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        !isEmpty(users) && (
                          <PageHeaderInfo>
                            <PageHeaderInfoText className="fw-semibold">
                              {`${users.length} ${
                                users.length > 1 ? "Users" : "User"
                              }`}
                            </PageHeaderInfoText>
                          </PageHeaderInfo>
                        )
                      }
                    />

                    {/* LIST */}
                    {!isEmpty(users) && (
                      <SectionList
                        key={"all"}
                        data={groupListByKey(data, "fullName", true)}
                        renderItem={item => (
                          <UserListItem
                            user={item}
                            actions={getListActions(item)}
                            isLoggedInUser={item.id === userProfile.id}
                          />
                        )}
                      />
                    )}

                    <div className="d-flex justify-content-center align-items-center">
                      {/* EMPTY STATE */}
                      <EmptyState
                        visible={isEmpty(users)}
                        title={`No users`}
                        description={`Click on the button below to create.`}
                        firstButton={
                          userProfile.permission.CAN_MANAGE_USERS && {
                            label: `New user`,
                            onClick: onClickNewUser,
                            disabled: processing,
                          }
                        }
                      />

                      {/* ERROR STATE */}
                      <EmptyErrorState
                        visible={error}
                        title={`Something went wrong`}
                        description={`An error has occurred. Please try again later or contact hevara for support`}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

Users.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Users)

import React from "react"

import { SmallModal } from "components/modal"
import { PrimaryButton, SecondaryButton } from "components/Button"
import { BodyText } from "components/atoms"
import { getBillingPlansLabel } from "utils/utils"

const BillingPlanModal = ({ showBillingPlanModal, plan, addNewBillingPlan, toggleBillingPlan }) => {

  return (
    <SmallModal
      modalIsOpen={showBillingPlanModal}
      onClickClose={toggleBillingPlan}
      title={"Change billing plan?"}
      hideButtons={true}
    >
      <BodyText className="mb-4">
        You are about to change your current billing plan to <b>{getBillingPlansLabel(plan)}</b>
      </BodyText>

      <div className="hstack gap-2 mb-0 mt-4">
          <PrimaryButton className="me-4" onClick={() => addNewBillingPlan(plan)}>
            Confirm
          </PrimaryButton>

          <SecondaryButton onClick={toggleBillingPlan}>
            Cancel
          </SecondaryButton>
        </div>
    </SmallModal>
  )
}

export default BillingPlanModal
import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getOrders(limit = 10, page = 1, filters = {}) {
    const parameters = {
      userId: filters.userId,
      companyId: filters.companyId,
      paymentStatus: filters.paymentStatus,
      orderType: filters.orderType,
      status: filters.status,
      limit,
      page,
    }
    const url = queryParams(API.getApi().ORDER.GET_ORDERS, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getOrder(orderId) {
    return axios
      .get(`${API.getApi().ORDER.GET_ORDER}/${orderId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  createOrder(values) {
    return axios
      .post(API.getApi().ORDER.CREATE_ORDER, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateOrder(orderId, values) {
    return axios
      .patch(`${API.getApi().ORDER.UPDATE_ORDER}/${orderId}`, values)
      .then(async ({ data }) => {
        return data
      })
  },
  deleteOrder(orderId) {
    return axios
      .delete(`${API.getApi().ORDER.DELETE_ORDER}/${orderId}`)
      .then(async ({ data }) => {
        return data
      })
  },
}

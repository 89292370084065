import React from "react";

import Text from "../BasicText";
import CONST from "components/atoms/constants";

const { SIZE, FONT: { WEIGHT, VARIANT } } = CONST;

const BigTextBold = ({ children, ...props }) => {
  return (
    <Text
      size={SIZE.L}
      variant={VARIANT.TEXT}
      weight={WEIGHT.BOLD}
      {...props}
    >
      {children}
    </Text>
  )
}

BigTextBold.defaultProps = {}

export default BigTextBold
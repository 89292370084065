import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { Card, CardBody, Container, Row, Col } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import DataItem from "components/DataItem"
import Loading from "components/Common/Spinner"
import { EmptyErrorState } from "components/EmptyState"
import Auth from "models/auth"
import Catalogue from "models/productCatalogue"
import Product from "models/product"
import Company from "models/company"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { getStatus, formatVolume, formatName } from "utils/utils"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import Avatar from "components/Avatar/Avatar"
import CONST from "utils/constants"
import { BodyText, BodyTextBold } from "components/atoms"

const { SIZE, COMPANY_TYPE } = CONST

import "./style.scss"

const BackOrderProductDetail = props => {
  //meta title
  document.title = "Product details | hevara DMS Platform"

  const { backOrderProductId } = useParams()

  const [productDetails, setProductDetails] = useState({})
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [companyInfo, setCompanyInfo] = useState({})

  const fetchCompanyInfo = id => {
    Company.getCompany(id)
      .then(data => {
        setCompanyInfo(data)
        if (data.type === COMPANY_TYPE.DISTRIBUTOR) {
          fetchProductDetails(backOrderProductId)
        } else {
          fetchProductCatalogueDetails(backOrderProductId)
        }
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
        setLoading(false)
      })
  }

  const fetchProductCatalogueDetails = id => {
    Catalogue.getProductCatalogue(id)
      .then(data => {
        setProductDetails(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchProductDetails = id => {
    Product.getProduct(id)
      .then(data => {
        setProductDetails(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (backOrderProductId && profile) {
      const companyId = JSON.parse(profile).companyId
      fetchCompanyInfo(companyId)
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [backOrderProductId])

  const getHeaderActions = () => {
    const actions = [
      {
        label: "Add to cart",
        icon: "mdi mdi-cart-plus",
        onClick: () => console.log("Add to cart process"),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      }
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Product details")}
            breadcrumbItem={props.t("Product details")}
          />

          {/* Loading */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Adding to cart"}
            description={"We are processing your request, please wait..."}
          />

          {/* DETAILS */}
          {!loading && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* PAGE HEADER */}
                    <PageHeader
                      avatar={productDetails && { initials: productDetails.name, image: productDetails.image?.file }}
                      title={`${productDetails.name} - (${formatVolume(productDetails.size)})`}
                      showBackButton={true}
                      showDivider={true}
                      actions={<HeaderActions actions={getHeaderActions()} />}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText>
                            <BodyTextBold className="text-primary">
                              {productDetails.code}
                            </BodyTextBold>
                          </PageHeaderInfoText>

                          <PageHeaderInfoText>
                            <BodyTextBold className="text-primary">
                              {formatName(productDetails.categoryName)} - {formatName(productDetails.categoryType)}
                            </BodyTextBold>
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                    />

                    {/* DETAILS SECTIONS */}
                    <div className="hevara-product-detail">
                      <div className="tx-grid mb-3">
                        {/* PRODUCT INFO */}
                        <div>
                          {/* PRODUCT DESCRIPTION */}
                          <BodyTextBold className="mb-2 text-muted">Description</BodyTextBold>
                          <BodyText className="mb-4">
                            {productDetails.description}
                          </BodyText>

                          {/* PRODUCT DETAILS */}
                          <BodyTextBold className="mb-2 text-muted">Product details</BodyTextBold>
                          <div className="detail-grid mb-5">
                            <DataItem
                              label={props.t("Name")}
                              value={productDetails.name}
                            />

                            <DataItem
                              label={props.t("Category")}
                              value={formatName(productDetails.categoryName)}
                            />

                            <DataItem
                              label={props.t("Type")}
                              value={formatName(productDetails.categoryType)}
                            />
                          </div>

                          {/* PRODUCT PRICING */}
                          <BodyTextBold className="mb-2 text-muted">Product pricing</BodyTextBold>
                          <div className="detail-grid mb-5">
                            {/* DISTRIBUTOR PRICING */}
                            {companyInfo.type === COMPANY_TYPE.DISTRIBUTOR && (
                              <>
                                <DataItem
                                  label={props.t("Price")}
                                  value={`Ksh. ${productDetails.primaryPrice || 0}`}
                                />

                                {!!productDetails.primaryDiscount && (
                                  <DataItem
                                    label={props.t("Discount")}
                                    value={`${productDetails.primaryDiscount}% Off`}
                                  />
                                )}
                              </>
                            )}

                            {/* WHOLESALER PRICING */}
                            {companyInfo.type === COMPANY_TYPE.WHOLESALLER && (
                              <>
                                <DataItem
                                  label={props.t("Price")}
                                  value={`Ksh. ${productDetails.secondaryPrice || 0}`}
                                />

                                {!!productDetails.secondaryDiscount && (
                                  <DataItem
                                    label={props.t("Discount")}
                                    value={`${productDetails.secondaryDiscount}% Off`}
                                  />
                                )}
                              </>
                            )}
                          </div>

                          {/* SUPPLIER DETAILS */}
                          <BodyTextBold className="mb-2 text-muted">Other details</BodyTextBold>
                          <div className="detail-grid">
                            {companyInfo.type === COMPANY_TYPE.WHOLESALLER && (
                              <DataItem
                                label={props.t("Supplier")}
                                value={productDetails.distributorName}
                              />
                            )}

                            {companyInfo.type === COMPANY_TYPE.DISTRIBUTOR && (
                              <DataItem
                                label={props.t("Supplier")}
                                value={productDetails.manufacturerName}
                              />
                            )}
                          </div>
                        </div>

                        {/* PRODUCT IMAGE */}
                        <div className="mb-3 tx-avatar">
                          <div className="container-box">
                            <Avatar
                              size={SIZE.XXXL}
                              initials={productDetails.name}
                              image={productDetails.image?.file}
                              className="avatar-size"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* ERROR STATE */}
                    <div className="d-flex justify-content-center align-items-center">
                      <EmptyErrorState
                        visible={error}
                        title={`Something went wrong`}
                        description={`An error has occurred. Please try again later or contact hevara for support`}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

BackOrderProductDetail.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(BackOrderProductDetail)

import React from "react"
import PropTypes from "prop-types"

import { HelpNotification as HevaraHelpNotification } from "./HelpNotification"
import { HELP_VARIANTS } from "./HelpNotification.styles"

function HelpNotification({ children, variant, href, className, style }) {
  return (
    <HevaraHelpNotification
      variant={variant}
      href={href}
      className={className}
      style={style}
    >
      {children}
    </HevaraHelpNotification>
  )
}

HelpNotification.propsTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf([...Object.values(HELP_VARIANTS)]),
  href: PropTypes.string,
}

export { HelpNotification, HELP_VARIANTS }

import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getCustomers(limit = 10, page = 1, filters = {}) {
    const parameters = {
      companyId: filters.companyId,
      status: filters.status,
      limit,
      page,
    }
    const url = queryParams(API.getApi().CUSTOMERS.GET_CUSTOMERS, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getCustomer(customerId) {
    return axios
      .get(`${API.getApi().CUSTOMERS.GET_CUSTOMER}/${customerId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  addCusromer(values) {
    return axios
      .post(API.getApi().CUSTOMERS.ADD_CUSTOMER, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateCustomer(customerId, values) {
    return axios
      .patch(`${API.getApi().CUSTOMERS.UPDATE_CUSTOMER}/${customerId}`, values)
      .then(async ({ data }) => {
        return data
      })
  },
  lookUpCustomers(companyId) {
    return axios
      .get(`${API.getApi().LOOKUPS.CUSTOMERS}/${companyId}`)
      .then(async ({ data }) => {
        return data
      })
  },
}

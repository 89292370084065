import CONST from "utils/constants"

const { MEDIA } = CONST

export const getPageHeaderStyles = () => {
  return `
    hevara-divider {
      margin-top: 16px;
    }
  `
}

export const getPageHeaderWrapper = () => {
  return `
    display: flex;
    ${MEDIA.TABLET} {
      flex-direction: column;
    }
  `
}

export const getPageHeaderSubWrapper = () => {
  return `
    display: column;
  `
}

export const getAvatarStyles = () => {
  return `
    margin-right: 16px;
    ${MEDIA.TABLET} {
      margin-bottom: 16px;
    }
  `
}

export const getPageHeaderTitle = ({ avoidtruncate }) => {
  return `
    ${
      !avoidtruncate
        ? `
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      `
        : ""
    }
  `
}

export const getPageHeaderInfoText = () => {
  return `
    ${MEDIA.MOBILE} {
      width: 100%;
    }
  `
}

export const getPageHeaderContentWrapperStyles = () => {
  return `
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    ${MEDIA.TABLET} {
      flex-wrap: wrap;
      flex-direction: column;
    }
  `
}

export const getPageHeaderActionsStyles = () => {
  return `
    display: flex;
    flex-shrink: 0;
    & > *:not(:last-child) {
      margin-right: 8px;
    }
    ${MEDIA.TABLET} {
      padding-top: 24px;
    }
    ${MEDIA.MOBILE} {
      width: 100%;
    }
  `
}

export const getPageHeaderInfoStyles = () => {
  return `
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    & > * {
      margin-top: 8px;
    }
    & > *:not(:last-child) {
      margin-right: 24px;
    }
  `
}

import React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Col, Row, Form, Input, FormFeedback, Label } from "reactstrap"

import { LargeModal, MODAL_STRETCH } from "components/modal"
import { PrimaryButton, SecondaryButton } from "components/Button"

const ChargeModal = ({
  modalIsOpen,
  onClickClose,
  charge,
  isEdit,
  updateCharge,
  addCharge,
  companyId,
  toggle,
}) => {
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      label: (charge && charge.label) || "",
      value: (charge && charge.value) || "",
      group: (charge && charge.group) || "",
      companyId: companyId && companyId,
    },
    validationSchema: Yup.object({
      label: Yup.string().required("This is required"),
      value: Yup.string().required("This is required"),
      group: Yup.string().required("This is required"),
    }),
    onSubmit: values => {
      if (isEdit) {
        // update
        updateCharge(charge.id, values)
      } else {
        // add new
        addCharge(values)
      }

      validation.resetForm()
      toggle()
    },
  })

  return (
    <LargeModal
      modalIsOpen={modalIsOpen}
      onClickClose={onClickClose}
      hideButtons={true}
      stretch={MODAL_STRETCH.NORMAL}
      title={isEdit ? `Edit ${charge?.label}'s details` : `New charge`}
    >
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
        className="mt-4"
      >
        <Row>
          <Col className="col-12">
            <div className="mb-4">
              <Label className="form-label">Charge label</Label>
              <Input
                name="label"
                type="text"
                placeholder="Charge label"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.label || ""}
                invalid={
                  validation.touched.label && validation.errors.label
                    ? true
                    : false
                }
              />
              {validation.touched.label && validation.errors.label ? (
                <FormFeedback type="invalid">
                  {validation.errors.label}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-4">
              <Label className="form-label">Charge value</Label>
              <Input
                name="value"
                type="number"
                placeholder="Charge value"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.value || ""}
                invalid={
                  validation.touched.value && validation.errors.value
                    ? true
                    : false
                }
              />
              {validation.touched.value && validation.errors.value ? (
                <FormFeedback type="invalid">
                  {validation.errors.value}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-4">
              <Label className="form-label">Group</Label>
              <Input
                name="group"
                type="select"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.group || ""}
                invalid={
                  validation.touched.group && validation.errors.group
                    ? true
                    : false
                }
              >
                <option>Select group</option>
                <option value="INDIVIDUAL">Individual</option>
                <option value="BAR">Bar</option>
                <option value="RESTAURANT">Restaurant</option>
                <option value="EATERY">Eatery</option>
                <option value="OTHER">Other</option>
              </Input>
              {validation.touched.group && validation.errors.group ? (
                <FormFeedback type="invalid">
                  {validation.errors.group}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="hstack gap-2 justify-content-center mb-0 mt-4">
              <PrimaryButton type="submit">
                {!!isEdit ? "Update charge" : "Add charge"}
              </PrimaryButton>

              <SecondaryButton type="button" onClick={toggle} className="me-4">
                Cancel
              </SecondaryButton>
            </div>
          </Col>
        </Row>
      </Form>
    </LargeModal>
  )
}

export default ChargeModal

export const LINK_VARIANT = {
  DEFAULT: "default",
  UNDERLINE: "underline",
  CRITICAL: "critical",
}

export const getLinkColor = ({ color: { link } }, variant, fontColor) => {
  return (
    fontColor ||
    link[
      !variant || variant === LINK_VARIANT.UNDERLINE
        ? LINK_VARIANT.DEFAULT
        : variant
    ]
  )
}

export const getLinkStyles = ({ disabled, variant, fontColor, ...props }) => {
  const theme = {
    color: {
      link: {
        default: "#6C8C3C",
        underline: "#5A6938",
        critical: "#F44336",
      },
    },
  }
  const color = getLinkColor(theme, variant, fontColor)
  const hoverTextDecoration = disabled ? "none" : "underline"
  const disabledStyles = disabled
    ? `pointer-events: none;
       opacity: 0.4;`
    : "cursor: pointer;"
  let textDecoration = "none"
  let textDecorationColorStyles = ""
  if (variant === LINK_VARIANT.UNDERLINE) {
    textDecoration = "underline"
    textDecorationColorStyles = `
    text-decoration-color: ${theme.color.link.underline};
    text-decoration-thickness: 2px;
    text-underline-offset: 6px;
    `
  }
  // Need important on hover and focus cause when using it on web with bootstrap, their style is overlapping our
  return `
    display: flex;
    align-items: center;
    text-decoration: ${textDecoration};
    ${textDecorationColorStyles}
    color: ${color};
    .hevara-a-icon {
      fill: ${color};
    }
    .hevara-a-text {
      color: ${color};
      display: inline;
    }
    &:hover {
      text-decoration: ${hoverTextDecoration} !important;
      color: ${color} !important;
      text-decoration-thickness: 2px !important;
      text-underline-offset: 6px;
    }
    &:focus-visible {
      text-decoration: ${hoverTextDecoration} !important;
      outline: 0px;
      text-decoration-thickness: 2px !important;
      text-underline-offset: 6px;
    }
    ${disabledStyles}
  `
}

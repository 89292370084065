import React from "react";
import styled from "styled-components";

import { getElevationStyles } from "./Elevation.styles";

const StyledElevation = styled.div.attrs({
  className: "hevara-elevation"
})`
  &{getElevationStyles}
`

const Elevation = ({ children, ...props }) => {
  return (
    <StyledElevation {...props}>
      {children}
    </StyledElevation>
  )
}

Elevation.defaultProps = {
  className: '',
  style: {},
  onClick: undefined
}

export default Elevation
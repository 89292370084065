import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import { Container, Row, Col } from "reactstrap"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { useNavigate } from "react-router-dom"

import PageHeader from "components/PageHeader"
import { BulletList } from "components/BulletList"
import Divider from "components/Divider/Divider"
import Link, { LinkLayout } from "components/Link"
import FeedbackNotification, { FEEDBACK_VARIANTS } from "components/FeedbackNotification"
import { CriticalButton, SecondaryButton } from "components/Button"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import User from "models/user"
import Auth from "models/auth"
import { BodyTextBold, BigTextBold, BodyText } from "components/atoms"

import "./style.scss"

const DeleteAccount = props => {
  //meta title
  document.title = "Delete account | hevara DMS Platform"

  const navigate = useNavigate()

  const [profile, setProfile] = useState({})
  const [error, setError] = useState(false)
  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      setProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [])

  const onClickDeleteProfile = () => {
    setProcessing(true)

    User.deleteUser(profile.id)
      .then(data => toast.success(data.message))
      .catch(error => toast.error(error.response?.data?.message))
      .finally(() => setProcessing(false))
  }

  const onClickCancel = () => {
    navigate(-1)
  }

  return (
    <React.Fragment>
      <div className="page-content hevara-delete-account">
        <Container fluid>
          <PageHeader title={`Delete account`} showBackButton={true} />

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Profile deletion"}
            description={"We are processing your request, please wait..."}
          />

          <div className="settings-group">
            {/* SECTION 1 */}
            <BigTextBold className="text-muted mb-4">
              What happens when my user profile is deleted
            </BigTextBold>
            <Divider className="mb-3" />
            <BulletList
              data={[
                {
                  description: "Your profile is deleted from our platform",
                },
                {
                  description:
                    "Your will no longer be able to access our platform",
                },
                {
                  description:
                    "Your will no longer receive emails or other notifications regarding your account activity",
                },
              ]}
            />

            {/* SECTION 2 */}
            <BigTextBold className="text-muted mb-4 mt-4">
              Before deleting your profile
            </BigTextBold>
            <Divider className="mb-3" />
            <BodyText className="mb-2">
              If you are a <b>Company administrator</b>:
            </BodyText>
            <BulletList
              data={[
                {
                  description:
                    "Be sure to assign all roles to your immediate replacement",
                },
                {
                  description:
                    "Transfer all training manuals and articles to the other team member",
                },
              ]}
            />

            {/* SECTION 3 */}
            <Divider className="mb-3" />
            <div className="mt-4">
              <LinkLayout className="font-size-14">
                <BodyText>For further information please consult our </BodyText>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://hevara.africa/privacy-policy/`}
                >
                  Privacy policy
                </Link>
              </LinkLayout>
            </div>

            <div className="mt-4">
              <LinkLayout className="font-size-14">
                <BodyText>If you have any questions, please contact our </BodyText>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`mailto: support@hevara.africa?subject=${"Account Deletion"}`}
                >
                  Support team
                </Link>
              </LinkLayout>
            </div>

            <FeedbackNotification
              className="mb-4 mt-4"
              message={`Your profile deletion does not interfere with any ongoing processes or activities that you had earlier initiated.`}
              variant={FEEDBACK_VARIANTS.WARNING}
            />

            <Row>
              <Col>
                <div className="hstack gap-2 justify-content-center mb-0 mt-4">
                  <CriticalButton onClick={onClickDeleteProfile}>
                    Delete profile
                  </CriticalButton>

                  <SecondaryButton onClick={onClickCancel} type="button">
                    Cancel
                  </SecondaryButton>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

DeleteAccount.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(DeleteAccount)

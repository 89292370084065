import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { toast, ToastContainer } from "react-toastify"
import { chain, isEmpty, orderBy, includes, map } from "lodash"
import { Card, CardBody, Container, Row  } from "reactstrap"
import moment from "moment"
import { useNavigate } from "react-router-dom"

import Auth from "models/auth"
import Company from "models/company"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import { SelectionBox } from "components/SelectionBox/SelectionBox"
import BillingPlan from "models/billingPlan"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import Loading from "components/Common/Spinner"
import FeedbackNotification, { FEEDBACK_VARIANTS } from "components/FeedbackNotification"
import { BodyText, BodyTextBold } from "components/atoms"
import EmptyState, { EmptyErrorState } from "components/EmptyState"
import { PrimaryButton, BUTTON_VARIANT } from "components/Button"
import CONST from "utils/constants"
import API from "config/api.config"
import PaymentModal from "components/paymentModal"
import BillingPayment from "models/billingPayment"
import BillingPlanModal from "./billingPlanModal"
import { groupListByKey } from "utils/utils"
import SectionList from "components/SectionList/sectionList"
import BillingTransactionItemList from "components/itemList/billingTransactionItemList/billingTransactionItemList"

const { DATE_FORMAT, BILLING_PLANS, PAYMENT_STATUS } = CONST

const Billing = (props) => {
  //meta title
  document.title = "Billing | hevara DMS Platform"

  const navigate = useNavigate()

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalResults, setTotalResults] = useState(0)
  const [profile, setProfile] = useState({})
  const [companyInfo, setCompanyInfo] = useState({})
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [billingPlans, setBillingPlans] = useState([])
  const [billingHistory, setBillingHistory] = useState([])
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [billingData, setBillingData] = useState({})
  const [showBillingPlanModal, setShowBillingPlanModal] = useState(false)
  const [selectedBillingPlan, setSelectedBillingPlan] = useState(null)

  // Logics
  const billingCycleBanner = includes([BILLING_PLANS.FREE_PLAN, BILLING_PLANS.ENTERPRISE_PLAN], companyInfo?.billing_plan);

  const fetchBillingPlans = () => {
    BillingPlan.lookUpBillingPlans()
      .then((plans) => {
        setBillingPlans(plans)
      })
      .catch((error) => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchCompanyInfo = id => {
    Company.getCompany(id)
      .then(data => {
        setCompanyInfo(data)
        setSelectedPlan(data?.billing_plan)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchBillingTransactions = (limit, page, companyId) => {
    const filters = {
      companyId: companyId
    }

    BillingPayment.getBillingPayments(limit, page, filters)
      .then((billingPlans) => {
        setBillingHistory(billingPlans.results)
        setTotalPages(billingPlans.totalPages)
        setTotalResults(billingPlans.totalResults)
      })
      .catch((error) => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      const companyId = JSON.parse(profile).companyId
      Promise.all([
        fetchBillingPlans(),
        fetchCompanyInfo(companyId),
        fetchBillingTransactions(limit, page, companyId)
      ])
      setProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [limit, page])

  const addNewBillingPlan = (plan) => {
    toggleBillingPlan() // Close billing modal
    setProcessing(true)

    BillingPlan.selectBillingPlan({ companyId: companyInfo.id, billingPlanCode: plan })
      .then((data) => {
        setCompanyInfo(data)
        setSelectedPlan(data?.billing_plan)
      })
      .catch((error) => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const togglePayment = () => {
    if (showPaymentModal) {
      setShowPaymentModal(false)
    } else {
      setShowPaymentModal(true)
    }
  }

  const toggleBillingPlan = () => {
    if (showBillingPlanModal) {
      setShowBillingPlanModal(false)
    } else {
      setShowBillingPlanModal(true)
    }
  }

  const verifyPayment = () => {
    setProcessing(true)

    BillingPayment.verifyBillingPayment(billingData.id)
      .then((data) => {
        if (data) {
          setBillingData({})
          fetchCompanyInfo(profile.companyId)
          toast.success('Payment was successfully processed.')
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "Something went wrong. Please try again later")
      })
      .finally(() => setProcessing(false))
  }

  const makePayment = (values) => {
    setProcessing(true)

    const payload = {
      companyId: profile.companyId,
      phoneNumber: values.phoneNumber,
    }

    BillingPayment.initiateBillingPayment(payload)
      .then((data) => {
        setBillingData(data)
        toast.success('Payment sent successfully. You will receive a payment prompt shortly')
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "Something went wrong. Please try again later")
      })
      .finally(() => setProcessing(false))
  }

  const getListActions = (item) => {
    const showDownloadReceipt = item.paymentStatus === PAYMENT_STATUS.PAID;

    const actions = [
      {
        label: "View details",
        icon: "mdi mdi-monitor-eye",
        onClick: () => navigate(`/billing-details/${item.id}`),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      showDownloadReceipt && {
        label: "Download receipt",
        icon: "bx bxs-file-pdf",
        onClick: () => downloadPDF(item),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      }
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const downloadPDF = (item) => {
    setProcessing(true)

    const billingPaymentId = item.id

    const link = document.createElement("a")
    link.href = `${API.getApi().GENERATE_PDF.GET_BILLING_PAYMENT_PDF}/${billingPaymentId}`
    link.download = `billing_receipt_${item.invoice_number}_${moment().format("DDMM")}.pdf`

    // Append the link to the DOM and trigger the click
    document.body.appendChild(link)
    link.click()

    // Remove the link from the DOM
    document.body.removeChild(link)

    setProcessing(false)
  }

  const getNextPage = () => {
    setLimit(limit + 10)
  }

  const filteredTransactions = chain(billingHistory)
    .filter(billing => {
      const hasLabel = billing.billing_plan?.toLowerCase().includes(name.toLowerCase())
      return hasLabel
    })
    .value()

  const data = orderBy(filteredTransactions, [p => p.dateCreated.toUpperCase()], "asc")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Billing"}
            description={"We are processing your request, please wait..."}
          />

          {/* PAYMENT MODAL */}
          <PaymentModal
            showPaymentModal={showPaymentModal}
            togglePayment={togglePayment}
            makePayment={makePayment}
          />

          {/* BILLING PLAN MODAL */}
          <BillingPlanModal
            showBillingPlanModal={showBillingPlanModal}
            plan={selectedBillingPlan}
            addNewBillingPlan={addNewBillingPlan}
            toggleBillingPlan={toggleBillingPlan}
          />

          {!loading && !isEmpty(companyInfo) && (
            <Row>
              <Card>
                <CardBody>
                  {/* PAGE HEADER */}
                  <PageHeader 
                    title={`Billing`} 
                    showBackButton={true}
                    showDivider={false}
                    info={
                      <PageHeaderInfo>
                        <PageHeaderInfoText>
                          Manage your billing and payment information
                        </PageHeaderInfoText>
                      </PageHeaderInfo>
                    }
                  />

                  {/* BANNER INFO FOR TRIAL PERIOD PLAN */}
                  {companyInfo?.billing_plan === BILLING_PLANS.FREE_PLAN && 
                    <FeedbackNotification
                      message={
                        <BodyText>
                          Please note that you are currently on the trial version of the platform for the next <b>2 months</b>, ending <b>{moment(companyInfo?.end_free_billing_plan).format(DATE_FORMAT)}</b>.
                          You can decide to upgrade at any time and enjoy the full platform features.
                        </BodyText>
                      }
                      variant={FEEDBACK_VARIANTS.WARNING}
                      className="mb-4"
                    />
                  }

                  {/* BANNER INFO TO SELECT A BILLING PLAN */}
                  {isEmpty(companyInfo?.billing_plan) && isEmpty(companyInfo?.next_billing_date) && 
                    <FeedbackNotification
                      message={
                        <BodyText>
                          Please select a billing plan in order to utilize the platform capabilities and features.
                          Once a billing plan is selected, all platform features will be active.
                        </BodyText>
                      }
                      variant={FEEDBACK_VARIANTS.WARNING}
                      className="mb-4"
                    />
                  }

                  {/* BANNER INFORMATION FOR NEXT BILLING CYCLE */}
                  {!billingCycleBanner &&
                    <FeedbackNotification
                      message={
                        <BodyText>
                          Next <b>monthly</b> billing cycle of <b>KES {companyInfo?.billing_amount}</b> is on <b>{moment(companyInfo?.next_billing_date).format(DATE_FORMAT)}</b>. 
                          Please ensure that your payment is received before or on the same date so as not to interfere with normal business operations.
                        </BodyText>
                      }
                      bottomActions={
                        <>
                          {isEmpty(billingData) && (
                            <PrimaryButton onClick={togglePayment}>
                              Pay now
                            </PrimaryButton>
                          )}
                          
                          {!isEmpty(billingData) && (
                            <PrimaryButton onClick={verifyPayment}>
                              Verify payment
                            </PrimaryButton>
                          )}
                        </>
                      }
                      variant={FEEDBACK_VARIANTS.INFO}
                      className="mb-4"
                    />
                  }

                  {/* BANNER INFORMATION FOR ENTERPRISE BILLING PLAN */}
                  {companyInfo?.billing_plan === BILLING_PLANS.ENTERPRISE_PLAN &&
                    <FeedbackNotification
                      message={
                        <BodyText>
                          Next <b>monthly</b> billing cycle of <b>KES {companyInfo?.billing_amount}</b> is on <b>{moment(companyInfo?.next_billing_date).format(DATE_FORMAT)}</b>. 
                          Please ensure that your payment is received before or on the same date so as not to interfere with normal business operations.
                        </BodyText>
                      }
                      variant={FEEDBACK_VARIANTS.INFO}
                      className="mb-4"
                    />
                  }

                  {/* BILLING PLANS */}
                  <div className="mb-4">
                    <BodyTextBold>Billing plans</BodyTextBold>

                    <BodyText className="text-muted mb-4">
                      Select one that best fits your current needs. 
                      You can always update this by selecting another option at any given time.
                    </BodyText>

                    {map(billingPlans, plan => (
                      <SelectionBox
                        key={plan.index}
                        title={plan.name}
                        description={plan.description}
                        sideData={plan.code === 'ECP' ? 'Customized' : plan.code === 'FP' ? 'FREE' : plan.value}
                        sideSubdata={`KES`}
                        avatar={{ image: plan.image, initials: plan.name }}
                        selected={plan.code === selectedPlan}
                        selectedIcon="mdi mdi-check"
                        onClick={() => {
                          setSelectedBillingPlan(plan.code)
                          setShowBillingPlanModal(true)
                        }}
                        className="mt-2"
                      />
                    ))}
                  </div>

                  {/* BILLING HISTORY */}
                  <div className="mt-4">
                    <BodyTextBold>Billing history</BodyTextBold>
                    <BodyText className="text-muted mb-4">Your billing history will appear below and you can download the receipt for each billing cycle</BodyText>

                    {/* BILLING HISTORY PAYMENT LIST */}
                    <SectionList
                      key={"all"}
                      data={groupListByKey(data, "billing_plan", true)}
                      hasNextPage={totalPages > 1}
                      getNextPage={getNextPage}
                      loadingPage={loading}
                      loading={loading}
                      renderItem={item => (
                        <BillingTransactionItemList
                          transaction={item}
                          actions={getListActions(item)}
                        />
                      )}
                    />

                    {/* EMPTY STATE FOR BILLING HISTORY */}
                    <div className="d-flex justify-content-center align-items-center">
                      <EmptyState
                        visible={isEmpty(billingHistory)}
                        title={`No billing transactions`}
                        description={`Currently there is no billing history associated with your company`}
                      />

                      {/* ERROR STATE */}
                      <EmptyErrorState
                        visible={error}
                        title={`Something went wrong`}
                        description={`An error has occurred. Please try again later or contact hevara for support`}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

Billing.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Billing)

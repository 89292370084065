import React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

import { SmallModal } from "components/modal"
import { PrimaryButton, SecondaryButton } from "components/Button"


const EditStatusModal = ({ showStatusModal, toggle, updateOrderStatus, suborderDetails, orderStatus }) => {

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      status: (suborderDetails && suborderDetails.status) || "",
      paymentStatus: suborderDetails && suborderDetails.paymentStatus,
    },
    validationSchema: Yup.object({
      status: Yup.string().required("This field is required"),
    }),
    onSubmit: values => {
      updateOrderStatus(values)

      validation.resetForm()
      toggle()
    },
  })

  return (
    <SmallModal
      modalIsOpen={showStatusModal}
      onClickClose={toggle}
      title={"Edit order status"}
      hideButtons={true}
    >
      <Form
        className="form-horizontal"
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        {/* STATUS */}
        <div className="mb-3">
          <Label className="form-label">Order status</Label>
          <Input
            name="status"
            type="select"
            placeholder="Select status"
            defaultValue={"Select status"}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.status || ""}
            invalid={
              validation.touched.status && validation.errors.status
                ? true
                : false
            }
          >
            {orderStatus.map(status => (
              <option key={status.id} value={status.value}>
                {status.label}
              </option>
            ))}
          </Input>
          {validation.touched.status && validation.errors.status && (
            <FormFeedback type="invalid">
              {validation.errors.status}
            </FormFeedback>
          )}
        </div>

        <div className="hstack gap-2 mb-0 mt-4">
          <PrimaryButton type="submit" className="me-4">
            Confirm
          </PrimaryButton>

          <SecondaryButton type="button" onClick={toggle}>
            Cancel
          </SecondaryButton>
        </div>
      </Form>
    </SmallModal>
  )
}

export default EditStatusModal

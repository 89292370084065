import React from "react";
import PropTypes from "prop-types";

import { Selector as HevaraSelector } from "./Selector";
import "./selector.scss"

export const Selector = ({
  label,
  hint,
  placeholder,
  disabled,
  error,
  isAllSelected,
  onChange,
  value,
  className,
  onClick,
  rightIcon
}) => {
  return (
    <HevaraSelector 
      label={label}
      hint={hint}
      placeholder={placeholder}
      onChange={onChange}
      onClick={onClick}
      value={value}
      error={error}
      disabled={disabled}
      className={`hevara-selector-styles ${className}`}
      isAllSelected={isAllSelected}
      rightIcon={rightIcon}
    />
  )
}

Selector.propTypes = {
  meta: PropTypes.object,
  label: PropTypes.string,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isAllSelected: PropTypes.bool,
  input: PropTypes.object,
  className: PropTypes.string,
  rightIcon: PropTypes.string,
}

Selector.defaultProps = {
  meta: {},
  label: '',
  hint: '',
  placeholder: '',
  disabled: false,
  isAllSelected: false,
  className: '',
  rightIcon: undefined
}
import React from "react";
import styled from "styled-components";

const StyledHiddenInput = styled.input.attrs({
  className: 'hevara-hidden-input'
})`
  opacity: 0;
  cursor: pointer;
  position: absolute;
  pointer-events: none;
`;

/**
 * HiddenInput UI component
 */
const HiddenInput = ({ type, value }) => {
  return (
    <StyledHiddenInput 
      type={type}
      checked={type === 'checkbox' ? !!value : undefined}
      value={typeof value === 'boolean' ? !value ? 0 : 1 : value}
      onChange={() => {
        // We need to set this as empty function or else the 'checked' will be interpreted as static prop by the browser
      }}
    />
  )
};

HiddenInput.defaultProps = {
  type: 'text'
};

export default HiddenInput;
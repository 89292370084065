import React from "react"
import styled from "styled-components"
import { isEmpty } from "lodash"

import { SmallText, BigTextBold } from "components/atoms"
import Avatar from "components/Avatar/Avatar"
import {
  getMainInfoStyles,
  getAvatarStyles,
  getTitleStyles,
  getInfoStyles,
} from "./MainInfoGrid.styles"
import CONST from "utils/constants"

const { SIZE } = CONST

const StyledMainInfo = styled.div.attrs({
  className: "hevara-table-row-main-info-grid",
})`
  ${getMainInfoStyles}
`

const StyledAvatar = styled(Avatar).attrs({
  className: "hevara-table-row-main-info-avatar",
})`
  ${getAvatarStyles}
`

const StyledTitle = styled(BigTextBold).attrs({
  className: "hevara-table-row-main-info-title",
})`
  ${getTitleStyles}
`

const StyledSubtitle = styled(SmallText).attrs({
  className: "hevara-table-row-main-info-subtitle",
})``

const StyledInfo = styled.div`
  ${getInfoStyles}
`

const MainInfoGrid = ({ avatar, title, subtitle, style, className }) => {
  return (
    <StyledMainInfo style={style} className={className}>
      {!isEmpty(avatar) && <StyledAvatar {...avatar} size={SIZE.L} />}
      <StyledInfo>
        <StyledTitle>{title}</StyledTitle>
        {!!subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
      </StyledInfo>
    </StyledMainInfo>
  )
}

MainInfoGrid.defaultProps = {
  avatar: undefined,
}

export { MainInfoGrid }

import React from "react"
import { map } from "lodash"
import { Link } from "react-router-dom"

import TableRow, { MainInfoList, TableRowInfo } from "components/TableRow"
import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"
import CONST from "utils/constants"

const { COMPANY_TYPE } = CONST

import "./cartItemList.scss"

const CartItemList = ({ item, actions, companyType }) => {
  const productPrice = companyType === COMPANY_TYPE.DISTRIBUTOR ? item.secondaryPrice : item.retailPrice;
  const totalPrice = productPrice * item.quantity;

  return (
    <TableRow>
      <Link
        key={item.id}
        className="hevara-product-list-item reset-link text-tertiary"
      >
        {/* PRODUCT NAME & CODE */}
        <MainInfoList
          title={`${item.name}`}
          subtitle={`${item.size} ml`}
          avatar={{ image: item.image?.file, initials: item.name }}
          className="text-truncate"
        />

        <TableRowInfo
          className="text-truncate"
          text={"Price"}
          subtext={`Ksh. ${productPrice.toLocaleString()}`}
        />

        <div className="status">
          <div className="status-list">
            {/* QUANTITY */}
            <TableRowInfo
              className="text-truncate"
              text={"Quantity"}
              subtext={item.quantity}
            />
          </div>

          <div className="actions-wrapper">
            {/* TOTAL */}
            <TableRowInfo
              className="text-truncate"
              text={"Total"}
              subtext={`Ksh. ${totalPrice.toLocaleString()}`}
            />

            {/* ACTIONS */}
            <ContextualModal
              className="feature-actions"
              style={{ marginLeft: 4 }}
              position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
              contentElement={map(actions, (modalItem, index) => (
                <ContextualModalListItem
                  key={index}
                  {...modalItem}
                  onClick={e => modalItem.onClick(e, item)}
                />
              ))}
            />
          </div>
        </div>
      </Link>
    </TableRow>
  )
}

CartItemList.defaultProps = {
  user: {},
  actions: [],
}

export default CartItemList

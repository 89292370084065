import { PrimaryButton } from "components/Button";
import { BodyTextBold } from "components/atoms";
import styled from "styled-components"

export const StyledProductCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #fff;
  margin: 4px;
  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
`;

export const StyledProductImageContainer = styled.div`
  width: 304px;
  height: 264px;
  min-width: 304px;
  min-height: 264px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export const StyledButton = styled(PrimaryButton)`
  margin-top: 10px;
  cursor: pointer;
`;

export const ProductTitle = styled(BodyTextBold)`
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
import axios from "axios"
import { find, isEmpty } from "lodash"
import { matchPath } from "react-router-dom"

import Auth from "models/auth"
import { authProtectedRoutes } from "routes"
import CONT from "utils/constants"

// AXIOS interceptor to add JWT
axios.interceptors.request.use(
  function (config) {
    return new Promise((resolve, reject) => {
      const token = Auth.getAccessToken()
      if (token != null && !config.headers.Authorization && !config.headers.skipAuth) {
        config.headers.Authorization = `Bearer ${token}`
      }
      resolve(config)
    })
  },
  function (err) {
    return Promise.reject(err)
  }
)

export default {
  authInterceptor: store => {
    // Add response interceptor
    axios.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        const token = Auth.getAccessToken()
        // If the user is logged in and the response is 401, logout the user
        if (token && error.response && error.response.status === 401) {
          // history("/logout")
          window.location.pathname = "/logout"
        } else if (!token && error.response && error.response.status === 401) {
          const route = find(authProtectedRoutes, route => matchPath(window.location.pathname, route.path)) || {}

          if (!isEmpty(route)) {
            localStorage.setItem(CONT.LOCAL_STORAGE.NEXT_ROUTE, `${window.location.pathname}${window.location.search}`)
          }
        }
        return Promise.reject(error)
      }
    )
  },
}

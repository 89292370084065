import React from "react";

import Text from "../BasicText";
import CONST from "components/atoms/constants";

const { SIZE, FONT: { WEIGHT, VARIANT } } = CONST;

const PreHeadingUppercase = ({ children, ...props }) => {
  return (
    <Text
      size={SIZE.M}
      variant={VARIANT.PRE_HEADING}
      weight={WEIGHT.BOLD}
      uppercase={true}
      {...props}
    >
      {children}
    </Text>
  )
}

PreHeadingUppercase.defaultProps = {}

export default PreHeadingUppercase
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { useNavigate } from "react-router-dom"
import {
  Col,
  Row,
  Card,
  CardBody,
  Container
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loading from "components/Common/Spinner"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import EmptyState, { EmptyErrorState } from "components/EmptyState"
import Auth from "models/auth"
import ProductsView from "models/productsView"
import Manufacturers from "models/manufacturers"
import Distributors from "models/distributors"
import Company from "models/company"
import { BodyText, BodyTextItalic } from "components/atoms"
import CONST from "utils/constants"
import { formatName, formatVolume } from "utils/utils"
import { 
  StyledProductCard,
  StyledButton,
  StyledProductImageContainer,
  ProductTitle
} from "./BackOrders.styles"

const { COMPANY_TYPE } = CONST

function ProductGridItem({ product, onViewDetails, onAddToCart }) {
  return (
    <StyledProductCard>
      {/* PRODUCT IMAGE */}
      <StyledProductImageContainer onClick={() => onViewDetails(product.id)}>
        <img src={product.image?.file} />
      </StyledProductImageContainer>

      {/* PRODUCT NAME */}
      <ProductTitle>
        {`${product.name} - ${formatVolume(product.size)}`}
      </ProductTitle>

      <BodyText>
        {`${formatName(product.categoryName)} - ${formatName(product.categoryType)}`}
      </BodyText>

      {/* PRODUCT PRICE */}
      <BodyTextItalic className="mt-1">
        {`ksh ${product.retailPrice || product.secondaryPrice || product.primaryPrice}`}
      </BodyTextItalic>

      {/* ADD TO CART BUTTON */}
      <StyledButton onClick={() => onAddToCart(product.id)}>Add to Cart</StyledButton>
    </StyledProductCard>
  );
}

const BackOrders = props => {
  //meta title
  document.title = "Back orders | hevara DMS Platform"

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const [products, setProducts] = useState([])
  const [manufacturers, setManufacturers] = useState([])
  const [distributors, setDistributors] = useState([])
  const [companyInfo, setCompanyInfo] = useState({})
  const [distributorFilter, setDistributorFilter] = useState(null)
  const [manufacturerFilter, setManufacturerFilter] = useState(null)

  const fetchProducts = (data) => {
    ProductsView.getProductsView(data)
      .then(data => {
        setProducts(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchManufacturers = () => {
    Manufacturers.lookUpManufacturers()
      .then(data => {
        setManufacturers([
          {
            status: "Default",
            companyName: "Filter by manufacturer",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchDistributors = () => {
    Distributors.lookUpDistributors()
      .then(data => {
        setDistributors([
          {
            status: "Default",
            companyName: "Filter by distributor",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanyInfo = id => {
    Company.getCompany(id)
      .then(data => {
        setCompanyInfo(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    setLoading(true)

    const profile = Auth.getLoggedInUserData()

    if (profile) {
      const companyId = JSON.parse(profile).companyId;
      const payload = { 
        companyId: companyId,
        manufacturerId: manufacturerFilter,
        distributorId: distributorFilter, 
      }
      Promise.all([
        fetchProducts(payload),
        fetchManufacturers(),
        fetchDistributors(),
        fetchCompanyInfo(companyId)
      ])
      setUserProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [manufacturerFilter, distributorFilter])

  const onChangeDistributorFilter = (event) => {
    if (event.target.value !== "default_id") { // TO BE REMOVED WHEN WE ADD THE DROPDOWN COMPONENT
      setDistributorFilter(event.target.value)
    }
  }

  const onChangeManufacturerFilter = (event) => {
    if (event.target.value !== "default_id") { // TO BE REMOVED WHEN WE ADD THE DROPDOWN COMPONENT
      setManufacturerFilter(event.target.value)
    }
  }

  const handleViewDetails = (id) => {
    navigate(`/back-orders-product-detail/${id}`)
  };

  const handleAddToCart = (id) => {
    console.log("Add product to cart:", id);
    // Implementation for adding to cart
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Back orders")}
            breadcrumbItem={props.t("Back orders")}
          />

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Back orders"}
            description={"We are processing your request, please wait..."}
          />

          {!loading && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PageHeader
                      title={"Back orders"}
                      showDivider={false}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText>
                            <BodyText>This section allows you to make back orders to suppliers to replenish your stock.</BodyText>
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                    />

                    {/* FILTERS */}
                    <Row className="mb-5">
                      <Col md="3">
                        {companyInfo.type === COMPANY_TYPE.DISTRIBUTOR &&
                          <div className="d-flex custom-select-wrapper">
                            <select
                              className="form-select"
                              value={manufacturerFilter}
                              onChange={onChangeManufacturerFilter}
                            >
                              {manufacturers.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.companyName}
                                </option>
                              ))}
                            </select>
                          </div>
                        }

                        {companyInfo.type === COMPANY_TYPE.WHOLESALLER &&
                          <div className="d-flex custom-select-wrapper">
                            <select
                              className="form-select"
                              value={distributorFilter}
                              onChange={onChangeDistributorFilter}
                            >
                              {distributors.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.companyName}
                                </option>
                              ))}
                            </select>
                          </div>
                        }
                      </Col>
                    </Row>

                    {/* DISPLAY PRODUCTS */}
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                      {products.map(product => (
                        <ProductGridItem
                          key={product.id}
                          product={product}
                          onViewDetails={handleViewDetails}
                          onAddToCart={handleAddToCart}
                        />
                      ))}
                    </div>

                    {/* EMPTY STATES */}
                    <div className="d-flex justify-content-center align-items-center">
                      {/* EMPTY STATE */}
                      <EmptyState
                        visible={isEmpty(products)}
                        title={`No products`}
                        description={`Thee are no products from the suppliers currently.`}
                      />

                      {/* ERROR STATE */}
                      <EmptyErrorState
                        visible={error}
                        title={`Something went wrong`}
                        description={`An error has occurred. Please try again later or contact hevara for support`}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

BackOrders.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(BackOrders)

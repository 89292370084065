import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getTaxes(limit = 10, page = 1, filters = {}) {
    const parameters = {
      companyId: filters.companyId,
      label: filters.label,
      limit,
      page,
    }
    const url = queryParams(API.getApi().TAX.GET_TAXES, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getTax(taxId) {
    return axios
      .get(`${API.getApi().TAX.GET_TAX}/${taxId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  addTax(values) {
    return axios
      .post(API.getApi().TAX.ADD_TAX, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateTax(taxId, values) {
    return axios
      .patch(`${API.getApi().TAX.UPDATE_TAX}/${taxId}`, values)
      .then(async ({ data }) => {
        return data
      })
  },
}

import React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Row, Col, Form, Input, FormFeedback, Label } from "reactstrap"

import { PrimaryButton, SecondaryButton } from "components/Button"
import CONST from "utils/constants"
import "../style.scss"

const { COMPANY_TYPE } = CONST

const ProductCatalogueInformation = props => {
  const {
    goNextStep,
    onClickCancel,
    warehouses,
    catalogueInformation,
    companyId,
    companyType,
    manufacturers,
    distributors,
    handleSubmit,
  } = props

  const onContinue = values => {
    handleSubmit(values)
    goNextStep()
  }

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (catalogueInformation && catalogueInformation.name) || "",
      size: (catalogueInformation && catalogueInformation.size) || "",
      stockQuantity: (catalogueInformation && catalogueInformation.stockQuantity) || "",
      description: (catalogueInformation && catalogueInformation.description) || "",
      replenishingLevel: (catalogueInformation && catalogueInformation.replenishingLevel) || "",
      ...(companyType === COMPANY_TYPE.DISTRIBUTOR
        ? { secondaryPrice: (catalogueInformation && catalogueInformation.secondaryPrice) || ""}
        : {}),
      ...(companyType === COMPANY_TYPE.DISTRIBUTOR
        ? { secondaryDiscount: (catalogueInformation && catalogueInformation.secondaryDiscount) || 0}
        : {}),
      ...(companyType === COMPANY_TYPE.WHOLESALLER
        ? { retailPrice: (catalogueInformation && catalogueInformation.retailPrice) || ""}
        : {}),
      ...(companyType === COMPANY_TYPE.WHOLESALLER
        ? { retailDiscount:(catalogueInformation && catalogueInformation.retailDiscount) || 0}
        : {}),
      ...(companyType === COMPANY_TYPE.WHOLESALLER
        ? { distributorId: (catalogueInformation && catalogueInformation.distributorId) || ""}
        : {}),
      onRequest: (catalogueInformation && catalogueInformation.onRequest) || "",
      warehouseId: (catalogueInformation && catalogueInformation.warehouseId) || "",
      manufacturerId: (catalogueInformation && catalogueInformation.manufacturerId) || "",
      ...(companyType === COMPANY_TYPE.DISTRIBUTOR && { distributorId: companyId }),
      ...(companyType === COMPANY_TYPE.WHOLESALLER && { wholesalerId: companyId }),
      companyId: companyId,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field required"),
      size: Yup.string().required("This field required"),
      stockQuantity: Yup.string().required("This field required"),
      description: Yup.string().required("This field required"),
      replenishingLevel: Yup.string().required("This field required"),
      ...(companyType === COMPANY_TYPE.DISTRIBUTOR
        ? { secondaryPrice: Yup.string().required("This field required") }
        : {}),
      ...(companyType === COMPANY_TYPE.WHOLESALLER
        ? { retailPrice: Yup.string().required("This field required") }
        : {}),
      ...(companyType === COMPANY_TYPE.WHOLESALLER
        ? { distributorId: Yup.string().required("This field required") }
        : {}),
      warehouseId: Yup.string().required("This field required"),
      manufacturerId: Yup.string().required("This field required"),
    }),
    onSubmit: values => {
      onContinue(values)
    },
  })

  return (
    <div className="mt-4">
      <div>
        <h2 className="fw-bold">Add product catalogue information</h2>
        <p className="mt-4 font-size-14 mb-4">
          Provide information related to the product.
        </p>

        <div className="mt-2">
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col sm="6">
                {/* PRODUCT NAME */}
                <div className="mb-3">
                  <Label className="form-label">Product name</Label>
                  <Input
                    name="name"
                    className="form-control"
                    placeholder="Product name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    invalid={
                      validation.touched.name && validation.errors.name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </div>

                {/* PRODUCT SIZE (ml's) */}
                <div className="mb-3">
                  <Label className="form-label">
                    Product size (milliliters)
                  </Label>
                  <Input
                    name="size"
                    className="form-control"
                    placeholder="Product size (milliliters)"
                    type="number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.size || ""}
                    invalid={
                      validation.touched.size && validation.errors.size
                        ? true
                        : false
                    }
                  />
                  {validation.touched.size && validation.errors.size ? (
                    <FormFeedback type="invalid">
                      {validation.errors.size}
                    </FormFeedback>
                  ) : null}
                </div>

                {/* IF ITS A DISTRIBUTOR: SHOW SECONDARY PRICE & SECONDARY DISCOUNT */}
                {companyType === COMPANY_TYPE.DISTRIBUTOR && (
                  <>
                    {/* PRODUCT SECONDARY PRICE */}
                    <div className="mb-3">
                      <Label className="form-label">Product price</Label>
                      <Input
                        name="secondaryPrice"
                        className="form-control"
                        placeholder="Product price"
                        type="number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.secondaryPrice || ""}
                        invalid={
                          validation.touched.secondaryPrice &&
                          validation.errors.secondaryPrice
                            ? true
                            : false
                        }
                      />
                      {validation.touched.secondaryPrice &&
                      validation.errors.secondaryPrice ? (
                        <FormFeedback type="invalid">
                          {validation.errors.secondaryPrice}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* PRODUCT SECONDARY DISCOUNT */}
                    <div className="mb-3">
                      <Label className="form-label">
                        Discount % (Optional)
                      </Label>
                      <Input
                        name="secondaryDiscount"
                        className="form-control"
                        placeholder="Discount %"
                        type="number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.secondaryDiscount || ""}
                        invalid={
                          validation.touched.secondaryDiscount &&
                          validation.errors.secondaryDiscount
                            ? true
                            : false
                        }
                      />
                      {validation.touched.secondaryDiscount &&
                      validation.errors.secondaryDiscount ? (
                        <FormFeedback type="invalid">
                          {validation.errors.secondaryDiscount}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </>
                )}

                {/* IF ITS A WHOLESALER: SHOW RETAIL PRICE & RETAIL DISCOUNT */}
                {companyType === COMPANY_TYPE.WHOLESALLER && (
                  <>
                    {/* PRODUCT RETAIL PRICE */}
                    <div className="mb-3">
                      <Label className="form-label">Product price</Label>
                      <Input
                        name="retailPrice"
                        className="form-control"
                        placeholder="Product price"
                        type="number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.retailPrice || ""}
                        invalid={
                          validation.touched.retailPrice &&
                          validation.errors.retailPrice
                            ? true
                            : false
                        }
                      />
                      {validation.touched.retailPrice &&
                      validation.errors.retailPrice ? (
                        <FormFeedback type="invalid">
                          {validation.errors.retailPrice}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* PRODUCT RETAIL DISCOUNT */}
                    <div className="mb-3">
                      <Label className="form-label">
                        Discount % (Optional)
                      </Label>
                      <Input
                        name="retailDiscount"
                        className="form-control"
                        placeholder="Discount %"
                        type="number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.retailDiscount || ""}
                        invalid={
                          validation.touched.retailDiscount &&
                          validation.errors.retailDiscount
                            ? true
                            : false
                        }
                      />
                      {validation.touched.retailDiscount &&
                      validation.errors.retailDiscount ? (
                        <FormFeedback type="invalid">
                          {validation.errors.retailDiscount}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </>
                )}

                {/* PRODUCT WAREHOUSE */}
                <div className="mb-3">
                  <Label className="form-label">Warehouse</Label>
                  <Input
                    name="warehouseId"
                    type="select"
                    placeholder="Select warehouse"
                    defaultValue={"Select warehouse"}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.warehouseId || ""}
                    invalid={
                      validation.touched.warehouseId &&
                      validation.errors.warehouseId
                        ? true
                        : false
                    }
                  >
                    {warehouses.map(warehouse => (
                      <option key={warehouse.id} value={warehouse.id}>
                        {warehouse.warehouseName}
                      </option>
                    ))}
                  </Input>
                  {validation.touched.warehouseId &&
                  validation.errors.warehouseId ? (
                    <FormFeedback type="invalid">
                      {validation.errors.warehouseId}
                    </FormFeedback>
                  ) : null}
                </div>

                {/* PRODUCT MANUFACTURER */}
                <div className="mb-3">
                  <Label className="form-label">Product manufacturer</Label>
                  <Input
                    name="manufacturerId"
                    type="select"
                    placeholder="Select manufacturer"
                    defaultValue={"Select manufacturer"}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.manufacturerId || ""}
                    invalid={
                      validation.touched.manufacturerId &&
                      validation.errors.manufacturerId
                        ? true
                        : false
                    }
                  >
                    {manufacturers.map(manufacturer => (
                      <option key={manufacturer.id} value={manufacturer.id}>
                        {manufacturer.companyName}
                      </option>
                    ))}
                  </Input>
                  {validation.touched.manufacturerId &&
                  validation.errors.manufacturerId ? (
                    <FormFeedback type="invalid">
                      {validation.errors.manufacturerId}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col sm="6">
                {/* PRODUCT STOCK QUANTITY */}
                <div className="mb-3">
                  <Label className="form-label">Stock quantity</Label>
                  <Input
                    name="stockQuantity"
                    className="form-control"
                    placeholder="Stock quantity"
                    type="number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.stockQuantity || ""}
                    invalid={
                      validation.touched.stockQuantity &&
                      validation.errors.stockQuantity
                        ? true
                        : false
                    }
                  />
                  {validation.touched.stockQuantity &&
                  validation.errors.stockQuantity ? (
                    <FormFeedback type="invalid">
                      {validation.errors.stockQuantity}
                    </FormFeedback>
                  ) : null}
                </div>

                {/* PRODUCT REPLENISH LEVEL */}
                <div className="mb-3">
                  <Label className="form-label">Replenish level</Label>
                  <Input
                    name="replenishingLevel"
                    className="form-control"
                    placeholder="Replenish level"
                    type="number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.replenishingLevel || ""}
                    invalid={
                      validation.touched.replenishingLevel &&
                      validation.errors.replenishingLevel
                        ? true
                        : false
                    }
                  />
                  {validation.touched.replenishingLevel &&
                  validation.errors.replenishingLevel ? (
                    <FormFeedback type="invalid">
                      {validation.errors.replenishingLevel}
                    </FormFeedback>
                  ) : null}
                </div>

                {/* PRODUCT ON-REQUEST (OPTIONAL) */}
                <div className="mb-3">
                  <Label className="form-label">On-request (Optional)</Label>
                  <Input
                    name="onRequest"
                    type="select"
                    placeholder="Select option"
                    defaultValue={"Select option"}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.onRequest || ""}
                    invalid={
                      validation.touched.onRequest &&
                      validation.errors.onRequest
                        ? true
                        : false
                    }
                  >
                    <option value="">Select option</option>
                    <option value="ON">Yes</option>
                    <option value="OFF">No</option>
                  </Input>
                  {validation.touched.onRequest &&
                  validation.errors.onRequest ? (
                    <FormFeedback type="invalid">
                      {validation.errors.onRequest}
                    </FormFeedback>
                  ) : null}
                </div>

                {/* PRODUCT DISTRIBUTOR */}
                {companyType === COMPANY_TYPE.WHOLESALLER && (
                  <div className="mb-3">
                    <Label className="form-label">Product distributor</Label>
                    <Input
                      name="distributorId"
                      type="select"
                      placeholder="Select distributor"
                      defaultValue={"Select distributor"}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.distributorId || ""}
                      invalid={
                        validation.touched.distributorId &&
                        validation.errors.distributorId
                          ? true
                          : false
                      }
                    >
                      {distributors.map(distributor => (
                        <option key={distributor.id} value={distributor.id}>
                          {distributor.companyName}
                        </option>
                      ))}
                    </Input>
                    {validation.touched.distributorId &&
                    validation.errors.distributorId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.distributorId}
                      </FormFeedback>
                    ) : null}
                  </div>
                )}

                {/* PRODUCT DESCRIPTION */}
                <div className="mb-3">
                  <Label className="form-label">Product description</Label>
                  <Input
                    name="description"
                    className="form-control"
                    placeholder="Product description"
                    type="textarea"
                    rows="5"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.description || ""}
                    invalid={
                      validation.touched.description &&
                      validation.errors.description
                        ? true
                        : false
                    }
                  />
                  {validation.touched.description &&
                  validation.errors.description ? (
                    <FormFeedback type="invalid">
                      {validation.errors.description}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="hstack gap-2 mb-0 mt-4">
                  <PrimaryButton type="submit" className="me-4">
                    Continue
                  </PrimaryButton>

                  <SecondaryButton type="button" onClick={onClickCancel}>
                    Cancel
                  </SecondaryButton>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default ProductCatalogueInformation

import React from "react";

import Text from "../BasicText";
import CONST from "components/atoms/constants";

const { SIZE, FONT: { WEIGHT, VARIANT } } = CONST;

const PreHeading = ({ children, ...props }) => {
  return (
    <Text
      size={SIZE.S}
      variant={VARIANT.PRE_HEADING}
      weight={WEIGHT.REGULAR}
      {...props}
    >
      {children}
    </Text>
  )
}

PreHeading.defaultProps = {}

export default PreHeading
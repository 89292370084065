import React from "react"
import styled from "styled-components"
import { isEmpty } from "lodash"

import { BodyText, BodyTextBold } from "components/atoms"
import constants from "components/atoms/constants"
import Avatar from "components/Avatar/Avatar"
import Link from "components/Link"
import { getDataItemStyles } from "./DataItem.styles"

import CONST from "utils/constants"

const { SIZE } = CONST
const { COLOR_VARIANT } = constants;

const StyledDataItem = styled.div.attrs({
  className: "hevara-data-item",
})`
  ${getDataItemStyles}
`

const StyledData = styled.div.attrs({
  className: "hevara-data",
})`
  overflow: hidden;
`

const StyledDataLabelRow = styled.div.attrs({
  className: "hevara-data-label-row",
})``

const StyledIcon = styled.i.attrs({
  className: "hevara-data-label-row-icon",
})``

export const DataItem = ({
  label,
  value,
  avatar,
  link,
  icon,
  onClickIcon,
  className,
  style,
  children,
  truncate,
}) => {
  return (
    <StyledDataItem className={className} style={style}>
      {!isEmpty(avatar) && <Avatar {...avatar} size={SIZE.XL} />}
      <StyledData>
        <StyledDataLabelRow>
          <BodyText colorVariant={COLOR_VARIANT.TERTIARY}>{label}</BodyText>

          {icon && !isEmpty(icon) && (
            <StyledIcon size="20" onClick={onClickIcon}>
              <i className={icon + " hevara-avatar-icon"}></i>
            </StyledIcon>
          )}
        </StyledDataLabelRow>
        {!isEmpty(value) && (
          <BodyTextBold className={`${truncate ? "text-truncate" : ""}`}>
            {value}
          </BodyTextBold>
        )}
        {children && !isEmpty(children) && (
          <React.Fragment>{children}</React.Fragment>
        )}
        {link && !isEmpty(link) && <Link {...link} />}
      </StyledData>
    </StyledDataItem>
  )
}

DataItem.defaultProps = {
  avatar: {},
  link: undefined,
  icon: undefined,
  onClickIcon: undefined,
  truncate: false,
}

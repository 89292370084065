import React from "react"
import { isFunction } from "lodash"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import { Link as HevaraLink } from "./Link"
import { LINK_VARIANT as HEVARA_LINK_VARIANT } from "./Link.styles"

export const LINK_VARIANT = {
  DEFAULT: HEVARA_LINK_VARIANT.DEFAULT,
  CRITICAL: HEVARA_LINK_VARIANT.CRITICAL,
}

export const LinkLayout = styled.div`
  width: 100%;
  & > * {
    display: initial !important;
  }
`

const Link = ({
  to,
  href,
  onClick,
  disabled,
  variant,
  icon,
  fontColor,
  className,
  style,
  children,
  target,
  rel,
  ...props
}) => {
  const history = useNavigate()

  const navigate = ev => {
    if (isFunction(onClick)) {
      onClick(ev)
    } else if (to) {
      history(to)
    }
  }

  return (
    <HevaraLink
      href={href}
      onClick={navigate}
      disabled={disabled}
      variant={variant}
      icon={icon}
      fontColor={fontColor}
      className={className}
      style={style}
      target={target}
      rel={rel}
    >
      {children}
    </HevaraLink>
  )
}

Link.defaultProps = {
  disabled: false,
  variant: LINK_VARIANT.DEFAULT,
}

export const LinkWithoutRouter = Link

export default Link

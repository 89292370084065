import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getCharges(limit = 10, page = 1, filters = {}) {
    const parameters = {
      companyId: filters.companyId,
      label: filters.label,
      group: filters.group,
      limit,
      page,
    }
    const url = queryParams(API.getApi().CHARGE.GET_CHARGES, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getCharge(chargeId) {
    return axios
      .get(`${API.getApi().CHARGE.GET_CHARGE}/${chargeId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  addCharge(values) {
    return axios
      .post(API.getApi().CHARGE.ADD_CHARGE, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateCharge(chargeId, values) {
    return axios
      .patch(`${API.getApi().CHARGE.UPDATE_CHARGE}/${chargeId}`, values)
      .then(async ({ data }) => {
        return data
      })
  },
}

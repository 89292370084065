import React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Col, Row, Form, Input, FormFeedback, Label } from "reactstrap"

import { LargeModal, MODAL_STRETCH } from "components/modal"
import { PrimaryButton, SecondaryButton } from "components/Button"

const PaymentSettingsModal = ({
  modalIsOpen,
  onClickClose,
  paymentConfig,
  isEdit,
  updatePaymentConfig,
  addPaymentConfig,
  companyId,
  paymentMethods,
  warehouses,
  toggle,
}) => {
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      companyId: companyId,
      warehouseId: (paymentConfig && paymentConfig.warehouseId) || "",
      mpesa_shortCode: (paymentConfig && paymentConfig.mpesa_shortCode) || "",
      mpesa_accountType:
        (paymentConfig && paymentConfig.mpesa_accountType) || "",
      mpesa_consumerKey:
        (paymentConfig && paymentConfig.mpesa_consumerKey) || "",
      mpesa_consumerSecret:
        (paymentConfig && paymentConfig.mpesa_consumerSecret) || "",
      mpesa_passKey: (paymentConfig && paymentConfig.mpesa_passKey) || "",
      mpesa_B2C_Security:
        (paymentConfig && paymentConfig.mpesa_B2C_Security) || "",
      mpesa_partyA: (paymentConfig && paymentConfig.mpesa_partyA) || "",
      mpesa_initiatorName:
        (paymentConfig && paymentConfig.mpesa_initiatorName) || "",
      mpesa_environment:
        (paymentConfig && paymentConfig.mpesa_environment) || "",
      paymentMethod: (paymentConfig && paymentConfig.paymentMethod) || "",
    },
    validationSchema: Yup.object({
      companyId: Yup.string().required("Company information not provided"),
      warehouseId: Yup.string().required("Please select warehouse"),
      mpesa_shortCode: Yup.string().required("Please enter mpesa short code"),
      mpesa_accountType: Yup.string().required(
        "Please enter mpesa account type"
      ),
      mpesa_consumerKey: Yup.string().required(
        "Please enter mpesa consumer key"
      ),
      mpesa_consumerSecret: Yup.string().required(
        "Please enter mpesa consumer secret"
      ),
      mpesa_passKey: Yup.string().required("Please enter mpesa pass key"),
      mpesa_B2C_Security: Yup.string().required(
        "Please enter mpesa B2C security"
      ),
      mpesa_partyA: Yup.string().required("Please enter mpesa party A"),
      mpesa_initiatorName: Yup.string().required(
        "Please enter mpesa initiator name"
      ),
      mpesa_environment: Yup.string().required(
        "Please enter mpesa environment"
      ),
      paymentMethod: Yup.string().required("Please select payment method"),
    }),
    onSubmit: values => {
      if (isEdit) {
        // update
        updatePaymentConfig(paymentConfig.id, values)
        validation.resetForm()
      } else {
        // add new
        addPaymentConfig(values)
        validation.resetForm()
      }
      toggle()
    },
  })

  return (
    <LargeModal
      modalIsOpen={modalIsOpen}
      onClickClose={onClickClose}
      hideButtons={true}
      stretch={MODAL_STRETCH.NORMAL}
      title={
        isEdit
          ? `Edit ${paymentConfig?.mpesa_initiatorName}'s details`
          : `New payment setting`
      }
    >
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
        className="mt-4"
      >
        <Row>
          <Col className="col-12">
            {/* MPESA SHORT CODE */}
            <div className="mb-3">
              <Label className="form-label">Mpesa short code</Label>
              <Input
                name="mpesa_shortCode"
                type="text"
                placeholder="Short code"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.mpesa_shortCode || ""}
                invalid={
                  validation.touched.mpesa_shortCode &&
                  validation.errors.mpesa_shortCode
                    ? true
                    : false
                }
              />
              {validation.touched.mpesa_shortCode &&
              validation.errors.mpesa_shortCode ? (
                <FormFeedback type="invalid">
                  {validation.errors.mpesa_shortCode}
                </FormFeedback>
              ) : null}
            </div>

            {/* MPESA ACCOUNT TYPE */}
            <div className="mb-3">
              <Label className="form-label">Mpesa account type</Label>
              <Input
                name="mpesa_accountType"
                type="text"
                placeholder="Mpesa account type"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.mpesa_accountType || ""}
                invalid={
                  validation.touched.mpesa_accountType &&
                  validation.errors.mpesa_accountType
                    ? true
                    : false
                }
              />
              {validation.touched.mpesa_accountType &&
              validation.errors.mpesa_accountType ? (
                <FormFeedback type="invalid">
                  {validation.errors.mpesa_accountType}
                </FormFeedback>
              ) : null}
            </div>

            {/* MPESA CONSUMER KEY */}
            <div className="mb-3">
              <Label className="form-label">Mpesa consumer key</Label>
              <Input
                name="mpesa_consumerKey"
                type="text"
                placeholder="Mpesa consumer key"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.mpesa_consumerKey || ""}
                invalid={
                  validation.touched.mpesa_consumerKey &&
                  validation.errors.mpesa_consumerKey
                    ? true
                    : false
                }
              />
              {validation.touched.mpesa_consumerKey &&
              validation.errors.mpesa_consumerKey ? (
                <FormFeedback type="invalid">
                  {validation.errors.mpesa_consumerKey}
                </FormFeedback>
              ) : null}
            </div>

            {/* MPESA CONSUMER SECRET */}
            <div className="mb-3">
              <Label className="form-label">Mpesa consumer secret</Label>
              <Input
                name="mpesa_consumerSecret"
                type="text"
                placeholder="Mpesa consumer secret"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.mpesa_consumerSecret || ""}
                invalid={
                  validation.touched.mpesa_consumerSecret &&
                  validation.errors.mpesa_consumerSecret
                    ? true
                    : false
                }
              />
              {validation.touched.mpesa_consumerSecret &&
              validation.errors.mpesa_consumerSecret ? (
                <FormFeedback type="invalid">
                  {validation.errors.mpesa_consumerSecret}
                </FormFeedback>
              ) : null}
            </div>

            {/* MPESA PASS KEY*/}
            <div className="mb-3">
              <Label className="form-label">Mpesa pass key</Label>
              <Input
                name="mpesa_passKey"
                type="text"
                placeholder="Mpesa pass key"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.mpesa_passKey || ""}
                invalid={
                  validation.touched.mpesa_passKey &&
                  validation.errors.mpesa_passKey
                    ? true
                    : false
                }
              />
              {validation.touched.mpesa_passKey &&
              validation.errors.mpesa_passKey ? (
                <FormFeedback type="invalid">
                  {validation.errors.mpesa_passKey}
                </FormFeedback>
              ) : null}
            </div>

            {/* MPESA PASS B2C SECURITY */}
            <div className="mb-3">
              <Label className="form-label">Mpesa B2C security</Label>
              <Input
                name="mpesa_B2C_Security"
                type="text"
                placeholder="Mpesa B2C security"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.mpesa_B2C_Security || ""}
                invalid={
                  validation.touched.mpesa_B2C_Security &&
                  validation.errors.mpesa_B2C_Security
                    ? true
                    : false
                }
              />
              {validation.touched.mpesa_B2C_Security &&
              validation.errors.mpesa_B2C_Security ? (
                <FormFeedback type="invalid">
                  {validation.errors.mpesa_B2C_Security}
                </FormFeedback>
              ) : null}
            </div>

            {/* MPESA PARTY A */}
            <div className="mb-3">
              <Label className="form-label">Mpesa party A</Label>
              <Input
                name="mpesa_partyA"
                type="text"
                placeholder="Mpesa party A"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.mpesa_partyA || ""}
                invalid={
                  validation.touched.mpesa_partyA &&
                  validation.errors.mpesa_partyA
                    ? true
                    : false
                }
              />
              {validation.touched.mpesa_partyA &&
              validation.errors.mpesa_partyA ? (
                <FormFeedback type="invalid">
                  {validation.errors.mpesa_partyA}
                </FormFeedback>
              ) : null}
            </div>

            {/* MPESA INITIATOR NAME */}
            <div className="mb-3">
              <Label className="form-label">Mpesa initiator name</Label>
              <Input
                name="mpesa_initiatorName"
                type="text"
                placeholder="Mpesa initiator name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.mpesa_initiatorName || ""}
                invalid={
                  validation.touched.mpesa_initiatorName &&
                  validation.errors.mpesa_initiatorName
                    ? true
                    : false
                }
              />
              {validation.touched.mpesa_initiatorName &&
              validation.errors.mpesa_initiatorName ? (
                <FormFeedback type="invalid">
                  {validation.errors.mpesa_initiatorName}
                </FormFeedback>
              ) : null}
            </div>

            {/* MPESA ENVIRONMENT */}
            <div className="mb-3">
              <Label className="form-label">Mpesa environment</Label>
              <Input
                name="mpesa_environment"
                type="text"
                placeholder="Mpesa environment"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.mpesa_environment || ""}
                invalid={
                  validation.touched.mpesa_environment &&
                  validation.errors.mpesa_environment
                    ? true
                    : false
                }
              />
              {validation.touched.mpesa_environment &&
              validation.errors.mpesa_environment ? (
                <FormFeedback type="invalid">
                  {validation.errors.mpesa_environment}
                </FormFeedback>
              ) : null}
            </div>

            {/* PAYMENT METHOD */}
            <div className="mb-3">
              <Label className="form-label">Payment method</Label>
              <Input
                name="paymentMethod"
                type="select"
                placeholder="Select payment method"
                defaultValue={"Select payment method"}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.paymentMethod || ""}
                invalid={
                  validation.touched.paymentMethod &&
                  validation.errors.paymentMethod
                    ? true
                    : false
                }
              >
                {paymentMethods.map(method => (
                  <option key={method.id} value={method.name}>
                    {method.name}
                  </option>
                ))}
              </Input>
              {validation.touched.paymentMethod &&
              validation.errors.paymentMethod ? (
                <FormFeedback type="invalid">
                  {validation.errors.paymentMethod}
                </FormFeedback>
              ) : null}
            </div>

            {/* WAREHOUSE */}
            <div className="mb-3">
              <Label className="form-label">Warehouse</Label>
              <Input
                name="warehouseId"
                type="select"
                placeholder="Select warehouse"
                defaultValue={"Select warehouse"}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.warehouseId || ""}
                invalid={
                  validation.touched.warehouseId &&
                  validation.errors.warehouseId
                    ? true
                    : false
                }
              >
                {warehouses.map(role => (
                  <option key={role.id} value={role.id}>
                    {role.warehouseName}
                  </option>
                ))}
              </Input>
              {validation.touched.warehouseId &&
              validation.errors.warehouseId ? (
                <FormFeedback type="invalid">
                  {validation.errors.warehouseId}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="hstack gap-2 justify-content-center mb-0 mt-4">
              <SecondaryButton type="button" onClick={toggle} className="me-4">
                Cancel
              </SecondaryButton>

              <PrimaryButton type="submit">
                {!!isEdit ? "Update payment setting" : "Add payment setting"}
              </PrimaryButton>
            </div>
          </Col>
        </Row>
      </Form>
    </LargeModal>
  )
}

export default PaymentSettingsModal

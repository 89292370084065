import React from "react"

import BackBtn from "components/Back_Button"
import {
  PageHeader as HevaraPageHeader,
  PageHeaderActions,
  PageHeaderInfoText,
  PageHeaderInfo,
} from "./PageHeader"

const PageHeader = ({
  avatar,
  title,
  actions,
  info,
  showDivider,
  className,
  showBackButton,
  onClickBack,
}) => {
  return (
    <>
      {showBackButton && (
        <div className="d-flex">
          <BackBtn
            onClick={onClickBack}
            style={{
              marginTop: "8px",
              marginBottom: "16px",
            }}
          />
        </div>
      )}
      <HevaraPageHeader
        title={title}
        avatar={avatar}
        actions={actions}
        info={info}
        showDivider={showDivider}
        className={className}
        style={
          showBackButton
            ? { marginBottom: "32px" }
            : { marginTop: "10px", marginBottom: "32px" }
        }
      />
    </>
  )
}

PageHeader.defaultProps = {
  className: "",
  title: "",
  showDivider: true,
  showBackButton: false,
}

export {
  PageHeader as default,
  PageHeaderActions,
  PageHeaderInfoText,
  PageHeaderInfo,
}

const getTheme = {
  color: {
    help: {
      inf: {
        icon: "#F4CC0C",
        font: "#696969",
      },
      warning: {
        icon: "#FF9800",
        font: "#696969",
      },
      error: {
        icon: "#F44336",
        font: "#F44336",
      },
      link: {
        icon: "#F4CC0C",
        font: "#6C8C3C",
      },
    },
  },
}

export const HELP_VARIANTS = {
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
  LINK: "link",
}

export const getHelpNotificationStyles = props => {
  const theme = getTheme
  const color = theme.color.help[props.variant].font
  const iconColor = theme.color.help[props.variant].icon

  return `
    display: flex;
    .hevara-help-notification-text {
      color: ${color};
    }
    .hevara-help-notification-link .hevara-a-text {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
    .hevara-help-notification-icon {
      fill: ${iconColor};
      color: ${iconColor};
    }
  `
}

const getTheme = {
  color: {
    general: {
      font: {
        primary: "#1E1E1E",
        secondary: "#434343",
        tertiary: "#696969",
        quaternary: "#8F8F8F",
      },
    },
    bullet_list: {
      icon: "#00CECE",
    },
  },
}

export const getBulletListStyles = () => {
  return `
    list-style-type: none;
    margin: 0;
    padding: 0;
  `
}

export const getBulletListItemStyles = () => {
  return `
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
  `
}

export const getBulletListItemTitleStyles = ({ fontColor, ...props }) => {
  const theme = getTheme
  const color = fontColor || theme.color.general.font.primary

  return `
    margin-bottom: 4px;
    color: ${color};
  `
}

export const getBulletListItemDescriptionStyles = ({ fontColor, ...props }) => {
  const theme = getTheme
  const color = fontColor || theme.color.general.font.primary

  return `
    margin-top: 6px;
    color: ${color};
  `
}

export const getBulletListItemIconStyles = ({ iconColor, ...props }) => {
  const theme = getTheme
  const color = iconColor || theme.color.bullet_list.icon

  return `
    margin-top: 2px;
    flex-shrink: 0;
    color: ${color};
  `
}

import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getBillingPayments(limit = 10, page = 1, filters = {}) {
    const parameters = {
      companyId: filters.companyId,
      phoneNumber: filters.phoneNumber,
      paymentStatus: filters.paymentStatus,
      limit,
      page,
    }
    const url = queryParams(API.getApi().BILLING_PAYMENT.GET_BILLING_PAYMENTS, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getBillingPayment(billingPaymentId) {
    return axios.get(`${API.getApi().BILLING_PAYMENT.GET_BILLING_PAYMENT}/${billingPaymentId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  initiateBillingPayment(values) {
    return axios.post(API.getApi().BILLING_PAYMENT.INITIATE_PAYMENT, values)
      .then(async ({ data }) => {
        return data
      })
  },
  verifyBillingPayment(billingPaymentId) {
    return axios.get(`${API.getApi().BILLING_PAYMENT.VERIFY_PAYMENT}/${billingPaymentId}`)
      .then(async ({ data }) => {
        return data
      })
  },
}

export const DEFAULT_SPACING = 4;

const properties = {
  m: 'margin',
  p: 'padding',
}

const directions = {
  t: 'Top',
  r: 'Right',
  b: 'Bottom',
  l: 'Left',
  x: ['Left', 'Right'],
  y: ['Top', 'Bottom'],
}

const allDirections = ['Top', 'Bottom', 'Left', 'Right'];
const paddingKeys = ['p', 'pt', 'pb', 'pr', 'pl', 'px', 'py'];
const marginKeys = ['m', 'mt', 'mb', 'mr', 'ml', 'mx', 'my'];
export const spacingKeys = [...marginKeys, ...paddingKeys];

export const styleToString = (style, isImportant = false) => {
  return Object.keys(style).reduce((acc, key) => (
    `${acc}${key.split(/(?=[a-z])/).join('-').toLowerCase()}:${style[key]}px${isImportant ? ' !important' : ''};`
  ), '');
}

const spacing = (key, multiple = 1, isObjectStyle = false, isImportant = false) => {
  if (!spacingKeys.includes(key)) {
    throw new Error(`"${key}" has not been found. Check possible options for spacings: ${spacingKeys.join(', ')}`);
  }

  const property = properties[key.charAt(0)];
  const direction = key.length > 1 ? directions[key.charAt(1)] : allDirections;
  const spacingValue = DEFAULT_SPACING * multiple;

  let style = {};
  if (Array.isArray(direction)) {
    style = direction.reduce((acc, value) => ({ ...(acc), [`${property}${value}`]: Number(spacingValue) }), {});
  } else {
    style = { [`${property}${direction}`]: Number(spacingValue) }
  }

  return isObjectStyle ? style : styleToString(style, isImportant);
}

export default spacing;
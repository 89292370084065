import React, { useCallback } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import CONST from 'components/atoms/constants';
import { BodyText, BodyTextBold } from 'components/atoms';
import Avatar from 'components/Avatar/Avatar';
import FieldContainer from '../TextField/FieldContainer';
import InputContainer from '../../Commons/InputContainer';
import { HiddenInput } from 'components/Commons';
import {spacing} from 'components/atoms';
import { 
  getSelectorInputContainerStyles,
  getSelectorStyles,
  getSelectorDataStyles,
  getSelectorAvatarProps
} from './Selector.styles';

const { SIZE } = CONST

const StyledInputContainer = styled(InputContainer)`
  padding: 16px;
  border-radius: 16px;
  ${getSelectorInputContainerStyles}
`
const StyledSelector = styled.div.attrs({
  className: 'hevara-selector-group'
})`
  margin-left: 14px;
  margin-right: 16px;
  ${getSelectorStyles}
`
const StyledSelectorData = styled.div.attrs({
  className: 'hevara-selector-data'
})`
  margin-left: 16px;
  ${getSelectorDataStyles}
`
const StyledSelectorPlaceholder = styled(BodyText).attrs({
  className: 'hevara-selector-placeholder'
})``
const StyledSelectorName = styled(BodyTextBold).attrs({
  className: 'hevara-selector-name'
})``
const StyledSelectorDescription = styled(BodyText).attrs({
  className: 'hevara-selector-description'
})``
const StyledSelectorSubText = styled(BodyText).attrs({
  className: 'hevara-selector-subtext'
})``

/**
 * Selector UI component
 */
const Selector = (props) => {
  const { onClick, label, hint, value, placeholder, error, disabled, className, isAllSelected, rightIcon, errorMessageID, id, ariaLabel, role } = props;
  const getAvatarProps = useCallback(() => getSelectorAvatarProps(props), [isAllSelected, value]);

  const errorElementID = errorMessageID || `${id}-error`

  return (
    <FieldContainer
      label={label}
      hint={hint}
      error={error}
      disabled={disabled}
      className={className}
      errorMessageID={errorElementID}
      id={id}
    >
      <StyledInputContainer
        error={error}
        disabled={disabled}
        onClick={onClick}
        rightIcon={!!rightIcon ? rightIcon : isEmpty(value) ? 'mdi mdi-plus' : 'mdi mdi-pencil'}
        ariaLabel={ariaLabel || label}
        errorMessageID={errorElementID}
        role={role}
        id={id}
      >
        {isEmpty(value) ? (
          <StyledSelector error={error}>
            <StyledSelectorPlaceholder>{placeholder}</StyledSelectorPlaceholder>
          </StyledSelector>
        ) : (
          <StyledSelector error={error}>
            <Avatar 
              size={SIZE.XL}
              initials={value.name}
              {...getAvatarProps()}
            />
            <StyledSelectorData>
              <StyledSelectorName>{value.name}</StyledSelectorName>
              {!!value?.subtext && <StyledSelectorSubText>{value.subtext}</StyledSelectorSubText>}
              <StyledSelectorDescription>{value.description}</StyledSelectorDescription>
            </StyledSelectorData>
          </StyledSelector>
        )}
        <HiddenInput />
      </StyledInputContainer>
    </FieldContainer>
  )
}

Selector.defaultProps = {
  value: undefined,
  placeholder: '',
  hint: '',
  error: '',
  disabled: false,
  isAllSelected: false,
  id: 'form-selector',
  role: 'option'
}

export {
  Selector
}
import React, { useState } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Row,
  Col,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

import CarouselPage from "./CarouselPage"
import Auth from "models/auth"
import withRouter from "components/Common/withRouter"
import Footer from "components/Footer"

import { BodyTextBold, BodyText } from "components/atoms"
import logodark from "assets/images/logo-dark.png"
import logolight from "assets/images/logo-light.png"
import { PrimaryButton } from "components/Button"
import Link, { LinkLayout } from "components/Link"
import FeedbackNotification, { FEEDBACK_VARIANTS } from "components/FeedbackNotification"

import CONST from "utils/constants"
import constants from "components/atoms/constants"

const { ROLES } = CONST;
const { FONT: { VARIANT } } = constants;

const Login = props => {
  //meta title
  document.title = "Login | hevara DMS Platform"

  const history = useNavigate()
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [passwordShow, setPasswordShow] = useState(false)

  const userLogin = values => {
    setLoading(true)
    
    Auth.login(values)
      .then(data => {
        const userRole = data.role
        if (userRole === ROLES.ADMIN) {
          setErrorMessage("You are not allowed to access this platform. Please use the administrator portal")
        } else {
          history("/dashboard")
        }
      })
      .catch(error => {
        setErrorMessage(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      userLogin(values)
    },
  })

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col xl={5}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="28"
                          className="logo-dark-element"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="28"
                          className="logo-light-element"
                        />
                      </Link>
                    </div>

                    <div className="my-auto">
                      <div>
                        <BodyTextBold variant={VARIANT.HEADING} className="text-tertiary">Welcome!</BodyTextBold>
                        <BodyText className="text-muted">
                          Sign in to continue to hevara DMS
                        </BodyText>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          {errorMessage && (
                            <FeedbackNotification
                              variant={FEEDBACK_VARIANTS.ERROR}
                              title={`Login attempt`}
                              message={errorMessage ? errorMessage : "An error occurred. Please try again later"}
                              className="mb-4"
                            />
                          )}

                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email address"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type={passwordShow ? "text" : "password"}
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password &&
                                  validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              <button
                                onClick={() => setPasswordShow(!passwordShow)}
                                className="btn btn-light "
                                type="button"
                                id="password-addon"
                              >
                                <i className="mdi mdi-eye-outline"></i>
                              </button>
                            </div>
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="d-flex flex-column align-items-center mt-4">
                            <PrimaryButton type="submit" className="w-lg">
                              {loading ? "Processing..." : "Login"}
                            </PrimaryButton>
                          </div>
                        </Form>

                        <div className="text-center mt-4">
                          <LinkLayout>
                            <Link to="/forgot-password">
                              Forgot your password?
                            </Link>
                          </LinkLayout>
                        </div>

                        <div className="mt-4 text-center">
                          <LinkLayout>
                            <BodyText>Don&apos;t have an account? </BodyText>
                            <Link to="/onboarding">Register here</Link>
                          </LinkLayout>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 text-center">
                      <Footer />
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}

import React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Col, Row, Form, Input, FormFeedback, Label } from "reactstrap"

import { LargeModal, MODAL_STRETCH } from "components/modal"
import { PrimaryButton, SecondaryButton } from "components/Button"
import CONST from "utils/constants"

const { COMPANY_TYPE } = CONST

const EditProductCatalogueModal = ({
  modalIsOpen,
  onClickClose,
  catalogue,
  companyId,
  distributors,
  manufacturers,
  warehouses,
  companyType,
  categories,
  productTypes,
  onClickEdit,
  handleSubmit,
}) => {
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: catalogue?.name || "",
      size: catalogue?.size || "",
      stockQuantity: catalogue?.stockQuantity || "",
      description: catalogue?.description || "",
      replenishingLevel: catalogue?.replenishingLevel || "",
      ...(companyType === COMPANY_TYPE.DISTRIBUTOR
        ? { secondaryPrice: catalogue?.secondaryPrice || ""}
        : {}),
      ...(companyType === COMPANY_TYPE.DISTRIBUTOR
        ? { secondaryDiscount: catalogue?.secondaryDiscount || 0}
        : {}),
      ...(companyType === COMPANY_TYPE.WHOLESALLER
        ? { retailPrice: catalogue?.retailPrice || ""}
        : {}),
      ...(companyType === COMPANY_TYPE.WHOLESALLER
        ? { retailDiscount: catalogue?.retailDiscount || 0}
        : {}),
      ...(companyType === COMPANY_TYPE.WHOLESALLER
        ? { distributorId: catalogue?.distributorId || ""}
        : {}),
      onRequest: catalogue?.onRequest || "",
      warehouseId: catalogue?.warehouseId || "",
      manufacturerId: catalogue?.manufacturerId || "",
      category: catalogue?.category || "",
      categoryType: catalogue?.categoryType || "",
      ...(companyType === COMPANY_TYPE.DISTRIBUTOR && { distributorId: companyId }),
      ...(companyType === COMPANY_TYPE.WHOLESALLER && { wholesalerId: companyId }),
      companyId: companyId,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field required"),
      size: Yup.string().required("This field required"),
      stockQuantity: Yup.string().required("This field required"),
      description: Yup.string().required("This field required"),
      replenishingLevel: Yup.string().required("This field required"),
      ...(companyType === COMPANY_TYPE.DISTRIBUTOR
        ? { secondaryPrice: Yup.string().required("This field required") }
        : {}),
      ...(companyType === COMPANY_TYPE.WHOLESALLER
        ? { retailPrice: Yup.string().required("This field required") }
        : {}),
      ...(companyType === COMPANY_TYPE.WHOLESALLER
        ? { distributorId: Yup.string().required("This field required") }
        : {}),
      warehouseId: Yup.string().required("This field required"),
      category: Yup.string().required("This field required"),
      categoryType: Yup.string().required("This field required"),
      manufacturerId: Yup.string().required("This field required"),
    }),
    onSubmit: values => {
      handleSubmit(values)

      validation.resetForm()
      onClickEdit()
    },
  })

  return (
    <LargeModal
      modalIsOpen={modalIsOpen}
      onClickClose={onClickClose}
      hideButtons={true}
      stretch={MODAL_STRETCH.NORMAL}
      title={`Edit ${catalogue?.name}'s details`}
    >
      <div className="mt-2">
        <Form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col sm="6">
              {/* PRODUCT NAME */}
              <div className="mb-3">
                <Label className="form-label">Product name</Label>
                <Input
                  name="name"
                  className="form-control"
                  placeholder="Product name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  disabled={!!validation.values.name}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>

              {/* PRODUCT SIZE (ml's) */}
              <div className="mb-3">
                <Label className="form-label">Product size (milliliters)</Label>
                <Input
                  name="size"
                  className="form-control"
                  placeholder="Product size (milliliters)"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.size || ""}
                  disabled={!!validation.values.size}
                  invalid={
                    validation.touched.size && validation.errors.size
                      ? true
                      : false
                  }
                />
                {validation.touched.size && validation.errors.size ? (
                  <FormFeedback type="invalid">
                    {validation.errors.size}
                  </FormFeedback>
                ) : null}
              </div>

              {/* IF ITS A DISTRIBUTOR: SHOW SECONDARY PRICE & SECONDARY DISCOUNT */}
              {companyType === COMPANY_TYPE.DISTRIBUTOR && (
                <>
                  {/* PRODUCT SECONDARY PRICE */}
                  <div className="mb-3">
                    <Label className="form-label">Product price</Label>
                    <Input
                      name="secondaryPrice"
                      className="form-control"
                      placeholder="Product price"
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.secondaryPrice || ""}
                      invalid={
                        validation.touched.secondaryPrice &&
                        validation.errors.secondaryPrice
                          ? true
                          : false
                      }
                    />
                    {validation.touched.secondaryPrice &&
                    validation.errors.secondaryPrice ? (
                      <FormFeedback type="invalid">
                        {validation.errors.secondaryPrice}
                      </FormFeedback>
                    ) : null}
                  </div>

                  {/* PRODUCT SECONDARY DISCOUNT */}
                  <div className="mb-3">
                    <Label className="form-label">Discount % (Optional)</Label>
                    <Input
                      name="secondaryDiscount"
                      className="form-control"
                      placeholder="Discount %"
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.secondaryDiscount || ""}
                      invalid={
                        validation.touched.secondaryDiscount &&
                        validation.errors.secondaryDiscount
                          ? true
                          : false
                      }
                    />
                    {validation.touched.secondaryDiscount &&
                    validation.errors.secondaryDiscount ? (
                      <FormFeedback type="invalid">
                        {validation.errors.secondaryDiscount}
                      </FormFeedback>
                    ) : null}
                  </div>
                </>
              )}

              {/* IF ITS A WHOLESALER: SHOW RETAIL PRICE & RETAIL DISCOUNT */}
              {companyType === COMPANY_TYPE.WHOLESALLER && (
                <>
                  {/* PRODUCT RETAIL PRICE */}
                  <div className="mb-3">
                    <Label className="form-label">Product price</Label>
                    <Input
                      name="retailPrice"
                      className="form-control"
                      placeholder="Product price"
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.retailPrice || ""}
                      invalid={
                        validation.touched.retailPrice &&
                        validation.errors.retailPrice
                          ? true
                          : false
                      }
                    />
                    {validation.touched.retailPrice &&
                    validation.errors.retailPrice ? (
                      <FormFeedback type="invalid">
                        {validation.errors.retailPrice}
                      </FormFeedback>
                    ) : null}
                  </div>

                  {/* PRODUCT RETAIL DISCOUNT */}
                  <div className="mb-3">
                    <Label className="form-label">Discount % (Optional)</Label>
                    <Input
                      name="retailDiscount"
                      className="form-control"
                      placeholder="Discount %"
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.retailDiscount || ""}
                      invalid={
                        validation.touched.retailDiscount &&
                        validation.errors.retailDiscount
                          ? true
                          : false
                      }
                    />
                    {validation.touched.retailDiscount &&
                    validation.errors.retailDiscount ? (
                      <FormFeedback type="invalid">
                        {validation.errors.retailDiscount}
                      </FormFeedback>
                    ) : null}
                  </div>
                </>
              )}

              {/* PRODUCT WAREHOUSE */}
              <div className="mb-3">
                <Label className="form-label">Warehouse</Label>
                <Input
                  name="warehouseId"
                  type="select"
                  placeholder="Select warehouse"
                  defaultValue={"Select warehouse"}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.warehouseId || ""}
                  invalid={
                    validation.touched.warehouseId &&
                    validation.errors.warehouseId
                      ? true
                      : false
                  }
                >
                  {warehouses &&
                    warehouses.map(warehouse => (
                      <option key={warehouse.id} value={warehouse.id}>
                        {warehouse.warehouseName}
                      </option>
                    ))}
                </Input>
                {validation.touched.warehouseId &&
                validation.errors.warehouseId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.warehouseId}
                  </FormFeedback>
                ) : null}
              </div>

              {/* PRODUCT MANUFACTURER */}
              <div className="mb-3">
                <Label className="form-label">Product manufacturer</Label>
                <Input
                  name="manufacturerId"
                  type="select"
                  placeholder="Select manufacturer"
                  defaultValue={"Select manufacturer"}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.manufacturerId || ""}
                  invalid={
                    validation.touched.manufacturerId &&
                    validation.errors.manufacturerId
                      ? true
                      : false
                  }
                >
                  {manufacturers &&
                    manufacturers.map(manufacturer => (
                      <option key={manufacturer.id} value={manufacturer.id}>
                        {manufacturer.companyName}
                      </option>
                    ))}
                </Input>
                {validation.touched.manufacturerId &&
                validation.errors.manufacturerId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.manufacturerId}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col sm="6">
              {/* PRODUCT STOCK QUANTITY */}
              <div className="mb-3">
                <Label className="form-label">Stock quantity</Label>
                <Input
                  name="stockQuantity"
                  className="form-control"
                  placeholder="Stock quantity"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.stockQuantity || ""}
                  invalid={
                    validation.touched.stockQuantity &&
                    validation.errors.stockQuantity
                      ? true
                      : false
                  }
                />
                {validation.touched.stockQuantity &&
                validation.errors.stockQuantity ? (
                  <FormFeedback type="invalid">
                    {validation.errors.stockQuantity}
                  </FormFeedback>
                ) : null}
              </div>

              {/* PRODUCT REPLENISH LEVEL */}
              <div className="mb-3">
                <Label className="form-label">Replenish level</Label>
                <Input
                  name="replenishingLevel"
                  className="form-control"
                  placeholder="Replenish level"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.replenishingLevel || ""}
                  invalid={
                    validation.touched.replenishingLevel &&
                    validation.errors.replenishingLevel
                      ? true
                      : false
                  }
                />
                {validation.touched.replenishingLevel &&
                validation.errors.replenishingLevel ? (
                  <FormFeedback type="invalid">
                    {validation.errors.replenishingLevel}
                  </FormFeedback>
                ) : null}
              </div>

              {/* PRODUCT ON-REQUEST (OPTIONAL) */}
              <div className="mb-3">
                <Label className="form-label">On-request (Optional)</Label>
                <Input
                  name="onRequest"
                  type="select"
                  placeholder="Select option"
                  defaultValue={"Select option"}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.onRequest || ""}
                  invalid={
                    validation.touched.onRequest && validation.errors.onRequest
                      ? true
                      : false
                  }
                >
                  <option value="">Select option</option>
                  <option value="ON">Yes</option>
                  <option value="OFF">No</option>
                </Input>
                {validation.touched.onRequest && validation.errors.onRequest ? (
                  <FormFeedback type="invalid">
                    {validation.errors.onRequest}
                  </FormFeedback>
                ) : null}
              </div>

              {/* PRODUCT DISTRIBUTOR */}
              {companyType === COMPANY_TYPE.WHOLESALLER && (
                <div className="mb-3">
                  <Label className="form-label">Product distributor</Label>
                  <Input
                    name="distributorId"
                    type="select"
                    placeholder="Select distributor"
                    defaultValue={"Select distributor"}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.distributorId || ""}
                    invalid={
                      validation.touched.distributorId &&
                      validation.errors.distributorId
                        ? true
                        : false
                    }
                  >
                    {distributors &&
                      distributors.map(distributor => (
                        <option key={distributor.id} value={distributor.id}>
                          {distributor.companyName}
                        </option>
                      ))}
                  </Input>
                  {validation.touched.distributorId &&
                  validation.errors.distributorId ? (
                    <FormFeedback type="invalid">
                      {validation.errors.distributorId}
                    </FormFeedback>
                  ) : null}
                </div>
              )}

              {/* PRODUCT CATEGORY */}
              <div className="mb-3">
                <Label className="form-label">Product category</Label>
                <Input
                  name="category"
                  type="select"
                  placeholder="Select category"
                  defaultValue={"Select category"}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.category || ""}
                  disabled={!!validation.values.category}
                  invalid={
                    validation.touched.category && validation.errors.category
                      ? true
                      : false
                  }
                >
                  {categories &&
                    categories.map(category => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                </Input>
                {validation.touched.category && validation.errors.category ? (
                  <FormFeedback type="invalid">
                    {validation.errors.category}
                  </FormFeedback>
                ) : null}
              </div>

              {/* PRODUCT TYPE */}
              <div className="mb-3">
                <Label className="form-label">Product type</Label>
                <Input
                  name="categoryType"
                  type="select"
                  placeholder="Select product type"
                  defaultValue={"Select product type"}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.categoryType || ""}
                  disabled={!!validation.values.categoryType}
                  invalid={
                    validation.touched.categoryType &&
                    validation.errors.categoryType
                      ? true
                      : false
                  }
                >
                  {productTypes &&
                    productTypes.map(categoryType => (
                      <option key={categoryType.id} value={categoryType.value}>
                        {categoryType.label}
                      </option>
                    ))}
                </Input>
                {validation.touched.categoryType &&
                validation.errors.categoryType ? (
                  <FormFeedback type="invalid">
                    {validation.errors.categoryType}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

            {/* PRODUCT DESCRIPTION */}
            <div className="mb-3">
              <Label className="form-label">Product description</Label>
              <Input
                name="description"
                className="form-control"
                placeholder="Product description"
                type="textarea"
                rows="5"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.description || ""}
                disabled={!!validation.values.description}
                invalid={
                  validation.touched.description &&
                  validation.errors.description
                    ? true
                    : false
                }
              />
              {validation.touched.description &&
              validation.errors.description ? (
                <FormFeedback type="invalid">
                  {validation.errors.description}
                </FormFeedback>
              ) : null}
            </div>
          </Row>

          <Row>
            <Col>
              <div className="hstack gap-2 mb-0 mt-4">
                <PrimaryButton type="submit" className="me-4">
                  Confirm
                </PrimaryButton>

                <SecondaryButton type="button" onClick={onClickEdit}>
                  Cancel
                </SecondaryButton>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </LargeModal>
  )
}

export default EditProductCatalogueModal

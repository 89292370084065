import { spacing } from "components/atoms";
import { getTheme } from "components/theme/themeUtils";

const BOX_HEIGHT = 80 - 2 - 32;

export const getSelectorStyles = (props) => {
  const theme = getTheme(props.theme);
  const { placeholder_font, primary_font, secondary_font } = theme.color.forms[props.error ? 'error' : 'default']

  return `
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    min-height: ${BOX_HEIGHT}px;
    .hevara-selector-placeholder {
      color: ${placeholder_font};
    }
    .hevara-selector-name {
      color: ${primary_font};
    }
    .hevara-selector-description, .hevara-selector-subtext {
      color: ${secondary_font};
    }
  `
}

export const getSelectorDataStyles = () => {
  return `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${spacing("ml", 4)};
  `
}

export const getSelectorInputContainerStyles = () => {
  return `
    position: relative;
    ${spacing("p", 4)}
  `
}

export const getSelectorAvatarProps = (props) => {
  const theme = getTheme(props.theme);
  const { innericons_and_selected, innericons_background } = theme.color.forms[props.error ? 'error' : 'default'];
  
  if (props.isAllSelected) {
    return {
      color: innericons_and_selected,
      backgroundColor: innericons_background,
      icon: 'mdi mdi-check',
    }
  } else {
    return {
      icon: props.value?.avatarIcon,
      color: props.value?.avatarIconColor,
      backgroundColor: props.value?.avatarIconBackgroundColor,
      image: props.value?.avatarImage
    }
  }
}

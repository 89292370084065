import React from "react"
import { Navigate } from "react-router-dom"

// Public or Unprotected routes
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Onboarding from "pages/Authentication/onboarding/onboardingUser"
import AccountSetup from "pages/Authentication/AccountSetup"
import ResetPassword from "pages/Authentication/ResetPassword"

// Protected or Private Routes
import Dashboard from "../pages/Dashboard/index"
import CompanyRoles from "pages/CompanyRoles"
import PaymentSettings from "pages/PaymentSettings"
import Warehouses from "pages/Warehouses"
import Users from "pages/Users"
import UserDetails from "pages/Users/userDetails"
import Fleets from "pages/Fleets"
import VehicleDetails from "pages/Fleets/vehicleDetails"
import Product from "pages/Products"
import NewProduct from "pages/Products/newProduct/newProduct"
import ProductDetails from "pages/Products/productDetails"
import ProductCatalogue from "pages/ProductCatalogue"
import ProductCatalogueDetails from "pages/ProductCatalogue/productCatalogueDetails"
import NewProductCatalogue from "pages/ProductCatalogue/newProductCatalogue/newProductCatalogue"
import Settings from "pages/Settings"
import PersonalDetails from "pages/Settings/personalDetails"
import ChangePassword from "pages/Settings/changePassword"
import CompanyDetails from "pages/Settings/companyDetails"
import NotificationsPreferences from "pages/Settings/notifications"
import Billing from "pages/Settings/billing"
import DeleteAccount from "pages/Settings/deleteAccount"
import Orders from "pages/Orders"
import NewOrder from "pages/Orders/newOrder"
import OrderDetails from "pages/Orders/orderDetails"
import Customers from "pages/customers"
import CustomerDetails from "pages/customers/customerDetails"
import Tax from "pages/Tax"
import Charges from "pages/Charges"
import Dispatches from "pages/Dispatches"
import DispatchDetails from "pages/Dispatches/dispatchDetails"
import Wallet from "pages/Settings/wallet"
import NewWallet from "pages/Settings/wallet/new-wallet/newWallet"
import WalletDetails from "pages/Settings/wallet/walletDetails"
import TransactionDetails from "pages/Settings/wallet/transactionDetails"
import BillingDetails from "pages/Settings/billing/billingDetails"
import ProductList from "pages/ProductCatalogue/addNewProductCatalogue/productList"
import BackOrders, { BackOrderProductDetail } from "pages/BackOrders"

const authProtectedRoutes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/company-roles",
    component: <CompanyRoles />,
  },
  {
    path: "/payment-settings",
    component: <PaymentSettings />,
  },
  {
    path: "/warehouses",
    component: <Warehouses />,
  },
  {
    path: "/team",
    component: <Users />,
  },
  {
    path: "/user-details/:userId",
    component: <UserDetails />,
  },
  {
    path: "/vehicles",
    component: <Fleets />,
  },
  {
    path: "/vehicle_details/:vehicleId",
    component: <VehicleDetails />,
  },
  {
    path: "/products",
    component: <Product />,
  },
  {
    path: "/new-product",
    component: <NewProduct />,
  },
  {
    path: "/product-details/:productId",
    component: <ProductDetails />,
  },
  {
    path: "/product-catalogue",
    component: <ProductCatalogue />,
  },
  {
    path: "/product-catalogue-details/:productCatalogueId",
    component: <ProductCatalogueDetails />,
  },
  {
    path: "/new-product-catalogue",
    component: <NewProductCatalogue />,
  },
  {
    path: "/settings",
    component: <Settings />,
  },
  {
    path: "/personal-details",
    component: <PersonalDetails />,
  },
  {
    path: "/change-password",
    component: <ChangePassword />,
  },
  {
    path: "/company-details",
    component: <CompanyDetails />,
  },
  {
    path: "/notification-preferences",
    component: <NotificationsPreferences />,
  },
  {
    path: "/billing",
    component: <Billing />,
  },
  {
    path: "/wallet",
    component: <Wallet />,
  },
  {
    path: "/delete-account",
    component: <DeleteAccount />,
  },
  {
    path: "/orders",
    component: <Orders />,
  },
  {
    path: "/new-order/:type",
    component: <NewOrder />,
  },
  {
    path: "/order-details/:subOrderId/:orderId",
    component: <OrderDetails />,
  },
  {
    path: "/customers",
    component: <Customers />,
  },
  {
    path: "/customer-details/:customerId",
    component: <CustomerDetails />,
  },
  {
    path: "/taxes",
    component: <Tax />,
  },
  {
    path: "/charges",
    component: <Charges />,
  },
  {
    path: "/dispatches",
    component: <Dispatches />,
  },
  {
    path: "/dispatch-details/:subOrderId/:orderId",
    component: <DispatchDetails />,
  },
  {
    path: "/new-wallet",
    component: <NewWallet />,
  },
  {
    path: "/wallet-details/:walletId",
    component: <WalletDetails />,
  },
  {
    path: "/transaction-details/:transactionId",
    component: <TransactionDetails />,
  },
  {
    path: "/billing-details/:billingPaymentId",
    component: <BillingDetails />,
  },
  {
    path: "/product-list",
    component: <ProductList />,
  },
  {
    path: "/back-orders",
    component: <BackOrders />,
  },
  {
    path: "/back-orders-product-detail/:backOrderProductId",
    component: <BackOrderProductDetail />,
  },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/logout",
    component: <Logout />,
  },
  {
    path: "/forgot-password",
    component: <ForgetPwd />,
  },
  {
    path: "/register",
    component: <Register />,
  },
  {
    path: "/onboarding",
    component: <Onboarding />,
  },
  {
    path: "/account",
    component: <AccountSetup />,
  },
  {
    path: "/resetPassword",
    component: <ResetPassword />,
  },
]

export { authProtectedRoutes, publicRoutes }

import React from "react";
import styled from "styled-components";

import { getFontSizes, getFontWeight } from "components/atoms/Text/BasicText";
import { getTextStyles } from "components/theme/themeUtils";
import CONST from "components/atoms/constants";

const { SIZE, FONT: { WEIGHT, VARIANT } } = CONST;

export const StyledLabelText = styled.label.attrs((props) => ({
  className: 'hevara-basic-label',
  htmlFor: props?.htmlFor
}))`
  margin: 0px;
  ${(props) => getFontSizes(props)}
  ${(props) => getFontWeight(props)}
  ${(props) => props.uppercase && `
    text-transform: uppercase;
  `}
  ${(props) => props.monospace && `
    font-feature-settings: 'tnum';
  `}
  ${getTextStyles}
`

/**
 * Label UI component
 */
const Label = ({ label, htmlFor: forID }) => {
  return (
    <StyledLabelText
      htmlFor={forID}
      size={SIZE.M}
      variant={VARIANT.TEXT}
      weight={WEIGHT.REGULAR}
    >
      {label}
    </StyledLabelText>
  )
}

Label.defaultProps = {};

export default Label
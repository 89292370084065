import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getProducts(limit = 10, page = 1, filters = {}) {
    const parameters = {
      productId: filters.productId,
      name: filters.name,
      code: filters.code,
      category: filters.category,
      categoryType: filters.categoryType,
      companyId: filters.companyId,
      warehouseId: filters.warehouseId,
      status: filters.status,
      limit,
      page,
    }
    const url = queryParams(API.getApi().PRODUCT.GET_PRODUCTS, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getProduct(productId) {
    return axios
      .get(`${API.getApi().PRODUCT.GET_PRODUCT}/${productId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  createProduct(values) {
    return axios
      .post(API.getApi().PRODUCT.CREATE_PRODUCT, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateProduct(productId, values) {
    return axios
      .patch(`${API.getApi().PRODUCT.UPDATE_PRODUCT}/${productId}`, values)
      .then(async ({ data }) => {
        return data
      })
  },
  deleteProduct(productId) {
    return axios
      .delete(`${API.getApi().PRODUCT.DELETE_PRODUCT}/${productId}`)
      .then(async ({ data }) => {
        return data
      })
  },
}

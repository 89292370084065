import React from "react"
import PropTypes from "prop-types"
import { Transition } from "react-transition-group"
import { Spinner } from "reactstrap"

import { BodyText, BodyTextBold } from "components/atoms"
import "./LoadingBackdrop.scss"

const TRANSITION_MS = 250
const TRANSITION_CONTAINER_STYLES = {
  entering: { opacity: 0 },
  entered: { opacity: 1, transition: "opacity ease-in 250ms" },
  exiting: { opacity: 0, transition: "opacity ease-out 250ms" },
  exited: { opacity: 0 },
  unmounted: {},
}

const LoadingBackdrop = ({
  show,
  title,
  description,
  children,
  className,
  style,
}) => {
  return (
    <Transition
      in={show}
      unmountOnExit={true}
      timeout={{ enter: 0, exit: TRANSITION_MS }}
    >
      {state => (
        <div
          className={`loading-backdrop ${className}`}
          style={{ ...TRANSITION_CONTAINER_STYLES[state], ...style }}
        >
          <Spinner
            color="primary"
            style={{
              height: "3rem",
              width: "3rem",
            }}
          />

          {!!title && (
            <div className="hevara-loading-backdrop-text-container">
              <BodyTextBold className="hevara-loading-backdrop-title">
                {title}
              </BodyTextBold>
            </div>
          )}

          {!!description && (
            <div className="hevara-loading-backdrop-text-container">
              <BodyText className="hevara-loading-backdrop-description">
                {description}
              </BodyText>
            </div>
          )}

          {children && (
            <div className="hevara-loading-backdrop-children-container">
              {children}
            </div>
          )}
        </div>
      )}
    </Transition>
  )
}

LoadingBackdrop.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
}

export default LoadingBackdrop

import axios from "axios"
import API from "config/api.config"

export default {
  lookUpManufacturers() {
    return axios
      .get(API.getApi().LOOKUPS.MANUFACTURERS)
      .then(async ({ data }) => {
        return data
      })
  },
}

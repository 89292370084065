import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import thunk from "redux-thunk"
import { createLogger } from "redux-logger"

import reducers from "./reducers"
import rootSaga from "./sagas"
import NetworkService from "utils/network-service"

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const cache = store => next => action => {
  if (!action.type || action.type.indexOf("FETCH") === -1) {
    return next(action)
  }

  const data = window["__data"]
  if (!data) {
    return next(action)
  }

  return store.dispatch({ type: "RECEIVE", payload: { data: `${data} (from cache)` } })
}

const middleWare = [cache, thunk]

const activateLogger = false

if (activateLogger) {
  middleWare.push(createLogger())
}

const store = createStore(reducers, composeEnhancers(applyMiddleware(sagaMiddleware, ...middleWare)))

sagaMiddleware.run(rootSaga)

NetworkService.authInterceptor(store)

export default store

import React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Col, Row, Form, Input, FormFeedback, Label } from "reactstrap"

import { LargeModal, MODAL_STRETCH } from "components/modal"
import { PrimaryButton, SecondaryButton } from "components/Button"

const EditProductModal = ({
  modalIsOpen,
  onClickClose,
  onClickEditProductDetails,
  product,
  companyId,
  categories,
  productTypes,
  warehouses,
  handleSubmit,
}) => {
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: product?.name || "",
      size: product?.size || "",
      stockQuantity: product?.stockQuantity || "",
      description: product?.description || "",
      category: product?.category || "",
      categoryType: product?.categoryType || "",
      replenishingLevel: product?.replenishingLevel || "",
      primaryPrice: product?.primaryPrice || "",
      primaryDiscount: product?.primaryDiscount || 0,
      companyId: companyId,
      manufacturerId: companyId,
      warehouseId: product?.warehouseId || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field required"),
      size: Yup.string().required("This field required"),
      stockQuantity: Yup.string().required("This field required"),
      description: Yup.string().required("This field required"),
      category: Yup.string().required("This field required"),
      categoryType: Yup.string().required("This field required"),
      replenishingLevel: Yup.string().required("This field required"),
      primaryPrice: Yup.string().required("This field required"),
      warehouseId: Yup.string().required("This field required"),
    }),
    onSubmit: values => {
      handleSubmit(values)

      validation.resetForm()
      onClickEditProductDetails()
    },
  })

  return (
    <LargeModal
      modalIsOpen={modalIsOpen}
      onClickClose={onClickClose}
      hideButtons={true}
      stretch={MODAL_STRETCH.NORMAL}
      title={`Edit ${product?.name}'s details`}
    >
      <div className="mt-2">
        <Form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col sm="6">
              {/* PRODUCT NAME */}
              <div className="mb-3">
                <Label className="form-label">Product name</Label>
                <Input
                  name="name"
                  className="form-control"
                  placeholder="Product name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>

              {/* PRODUCT SIZE (ml's) */}
              <div className="mb-3">
                <Label className="form-label">Product size (milliliters)</Label>
                <Input
                  name="size"
                  className="form-control"
                  placeholder="Product size (milliliters)"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.size || ""}
                  invalid={
                    validation.touched.size && validation.errors.size
                      ? true
                      : false
                  }
                />
                {validation.touched.size && validation.errors.size ? (
                  <FormFeedback type="invalid">
                    {validation.errors.size}
                  </FormFeedback>
                ) : null}
              </div>

              {/* PRODUCT CATEGORY */}
              <div className="mb-3">
                <Label className="form-label">Product category</Label>
                <Input
                  name="category"
                  type="select"
                  placeholder="Select category"
                  defaultValue={"Select category"}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.category || ""}
                  invalid={
                    validation.touched.category && validation.errors.category
                      ? true
                      : false
                  }
                >
                  {categories.map(category => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Input>
                {validation.touched.category && validation.errors.category ? (
                  <FormFeedback type="invalid">
                    {validation.errors.category}
                  </FormFeedback>
                ) : null}
              </div>

              {/* PRODUCT TYPE */}
              <div className="mb-3">
                <Label className="form-label">Product type</Label>
                <Input
                  name="categoryType"
                  type="select"
                  placeholder="Select product type"
                  defaultValue={"Select product type"}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.categoryType || ""}
                  invalid={
                    validation.touched.categoryType &&
                    validation.errors.categoryType
                      ? true
                      : false
                  }
                >
                  {productTypes.map(categoryType => (
                    <option key={categoryType.id} value={categoryType.value}>
                      {categoryType.label}
                    </option>
                  ))}
                </Input>
                {validation.touched.categoryType &&
                validation.errors.categoryType ? (
                  <FormFeedback type="invalid">
                    {validation.errors.categoryType}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col sm="6">
              {/* PRODUCT STOCK QUANTITY */}
              <div className="mb-3">
                <Label className="form-label">Stock quantity</Label>
                <Input
                  name="stockQuantity"
                  className="form-control"
                  placeholder="Stock quantity"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.stockQuantity || ""}
                  invalid={
                    validation.touched.stockQuantity &&
                    validation.errors.stockQuantity
                      ? true
                      : false
                  }
                />
                {validation.touched.stockQuantity &&
                validation.errors.stockQuantity ? (
                  <FormFeedback type="invalid">
                    {validation.errors.stockQuantity}
                  </FormFeedback>
                ) : null}
              </div>

              {/* PRODUCT PRIMARY PRICE */}
              <div className="mb-3">
                <Label className="form-label">Product price</Label>
                <Input
                  name="primaryPrice"
                  className="form-control"
                  placeholder="Product price"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.primaryPrice || ""}
                  invalid={
                    validation.touched.primaryPrice &&
                    validation.errors.primaryPrice
                      ? true
                      : false
                  }
                />
                {validation.touched.primaryPrice &&
                validation.errors.primaryPrice ? (
                  <FormFeedback type="invalid">
                    {validation.errors.primaryPrice}
                  </FormFeedback>
                ) : null}
              </div>

              {/* PRODUCT PRIMARY DISCOUNT */}
              <div className="mb-3">
                <Label className="form-label">Discount % (Optional)</Label>
                <Input
                  name="primaryDiscount"
                  className="form-control"
                  placeholder="Discount %"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.primaryDiscount || ""}
                  invalid={
                    validation.touched.primaryDiscount &&
                    validation.errors.primaryDiscount
                      ? true
                      : false
                  }
                />
                {validation.touched.primaryDiscount &&
                validation.errors.primaryDiscount ? (
                  <FormFeedback type="invalid">
                    {validation.errors.primaryDiscount}
                  </FormFeedback>
                ) : null}
              </div>

              {/* PRODUCT REPLENISH LEVEL */}
              <div className="mb-3">
                <Label className="form-label">Replenish level</Label>
                <Input
                  name="replenishingLevel"
                  className="form-control"
                  placeholder="Replenish level"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.replenishingLevel || ""}
                  invalid={
                    validation.touched.replenishingLevel &&
                    validation.errors.replenishingLevel
                      ? true
                      : false
                  }
                />
                {validation.touched.replenishingLevel &&
                validation.errors.replenishingLevel ? (
                  <FormFeedback type="invalid">
                    {validation.errors.replenishingLevel}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

            {/* PRODUCT WAREHOUSE */}
            <div className="mb-3">
              <Label className="form-label">Warehouse</Label>
              <Input
                name="warehouseId"
                type="select"
                placeholder="Select warehouse"
                defaultValue={"Select warehouse"}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.warehouseId || ""}
                invalid={
                  validation.touched.warehouseId &&
                  validation.errors.warehouseId
                    ? true
                    : false
                }
              >
                {warehouses.map(warehouse => (
                  <option key={warehouse.id} value={warehouse.id}>
                    {warehouse.warehouseName}
                  </option>
                ))}
              </Input>
              {validation.touched.warehouseId &&
              validation.errors.warehouseId ? (
                <FormFeedback type="invalid">
                  {validation.errors.warehouseId}
                </FormFeedback>
              ) : null}
            </div>

            {/* PRODUCT DESCRIPTION */}
            <div className="mb-3">
              <Label className="form-label">Product description</Label>
              <Input
                name="description"
                className="form-control"
                placeholder="Product description"
                type="textarea"
                rows="5"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.description || ""}
                invalid={
                  validation.touched.description &&
                  validation.errors.description
                    ? true
                    : false
                }
              />
              {validation.touched.description &&
              validation.errors.description ? (
                <FormFeedback type="invalid">
                  {validation.errors.description}
                </FormFeedback>
              ) : null}
            </div>
          </Row>

          <Row>
            <Col>
              <div className="hstack gap-2 mb-0 mt-4">
                <PrimaryButton type="submit" className="me-4">
                  Confirm
                </PrimaryButton>

                <SecondaryButton
                  type="button"
                  onClick={onClickEditProductDetails}
                >
                  Cancel
                </SecondaryButton>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </LargeModal>
  )
}

export default EditProductModal

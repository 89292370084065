import PropTypes from "prop-types"
import React from "react"
import { withTranslation } from "react-i18next"
import { Container, Row } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import CardStatSection from "components/dashboard/cardStats"
import SalesOrders from "components/dashboard/SalesOrders"
import TapProducts from "components/dashboard/TopProducts"

const Dashboard = (props) => {
  //meta title
  document.title = "Dashboard | hevara DMS Platform"

  const chartsData = [
    {
      id: 1,
      title: "Stock value",
      price: "1,140,487",
      perstangeValue: "18.89",
      bagdeColor: "success",
      seriesData: [{
        name: "Job View",
        data: [36, 21, 65, 22, 35, 50, 87, 98],
      }],
      color: '["--bs-success", "--bs-transparent"]'
    },
    {
      id: 2,
      title: "Products",
      price: "7,402",
      perstangeValue: "24.07",
      bagdeColor: "success",
      seriesData: [{
        name: "New Application",
        data: [36, 48, 10, 74, 35, 50, 70, 73],
      }],
      color: '["--bs-success", "--bs-transparent"]'
    },
    {
      id: 3,
      title: "Orders",
      price: "12,487",
      perstangeValue: " 8.41",
      bagdeColor: "success",
      seriesData: [{
        name: "Total Approved",
        data: [60, 14, 5, 60, 30, 43, 65, 84],
      }],
      color: '["--bs-success", "--bs-transparent"]'
    },
    {
      id: 4,
      title: "Dispatches",
      price: "12,487",
      perstangeValue: " 20.63",
      bagdeColor: "danger",
      istrendingArrow: true,
      seriesData: [{
        name: "Total Rejected",
        data: [32, 22, 7, 55, 20, 45, 36, 20],
      }],
      color: '["--bs-danger", "--bs-transparent"]'
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Dashboard")}
          />

          {/* CARD STATS */}
          <CardStatSection chartsData={chartsData} />

          <Row>
            <SalesOrders dataColors='["--bs-primary", "--bs-tertiary"]' />
            <TapProducts />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)

import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Row,
  Col,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

import withRouter from "components/Common/withRouter"
import * as Yup from "yup"
import { useFormik } from "formik"

import Auth from "models/auth"
import Footer from "components/Footer"
import logodark from "assets/images/logo-dark.png"
import logolight from "assets/images/logo-light.png"
import CarouselPage from "./CarouselPage"
import { PrimaryButton } from "components/Button"
import Link, { LinkLayout } from "components/Link"
import { BodyTextBold, BodyText } from "components/atoms"
import constants from "components/atoms/constants"
import FeedbackNotification, { FEEDBACK_VARIANTS } from "components/FeedbackNotification"

const { FONT: { VARIANT } } = constants;

const ForgetPasswordPage = (props) => {
  //meta title
  document.title = "Forget Password | hevara DMS Platform"

  const [forgetSuccessMsg, setForgetSuccessMsg] = useState("")
  const [forgetErrorMsg, setForgetErrorMsg] = useState("")
  const [loading, setLoading] = useState(false)

  const forgotPassword = values => {
    setLoading(true)
    Auth.forgotPassword(values)
      .then(data => {
        setForgetSuccessMsg(data.message)
      })
      .catch(error => {
        setForgetErrorMsg(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      forgotPassword(values)
    },
  })

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col xl={5}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <a href="/" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="28"
                          className="logo-dark-element"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="28"
                          className="logo-light-element"
                        />
                      </a>
                    </div>
                    <div className="my-auto">
                      <div>
                        <BodyTextBold variant={VARIANT.HEADING} className="text-tertiary">Password recovery</BodyTextBold>
                        <BodyText className="text-muted">
                          Recover your account password
                        </BodyText>
                      </div>

                      <div className="mt-4">
                        {(forgetErrorMsg || forgetSuccessMsg) && (
                          <FeedbackNotification
                            variant={forgetErrorMsg ? FEEDBACK_VARIANTS.ERROR : FEEDBACK_VARIANTS.SUCCESS}
                            title={`Password recovery`}
                            message={forgetErrorMsg ? forgetErrorMsg : forgetSuccessMsg ? forgetSuccessMsg : ""}
                            className="mt-4"
                          />
                        )}

                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="d-flex flex-column align-items-center">
                            <PrimaryButton type="submit" className="w-lg">
                              {loading ? "Processing..." : "Confirm"}
                            </PrimaryButton>
                          </div>
                        </Form>

                        <div className="mt-4 text-center">
                          <LinkLayout>
                            <BodyText>Remember it? </BodyText>
                            <Link to="/login">Login in here</Link>
                          </LinkLayout>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 text-center">
                      <Footer />
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)

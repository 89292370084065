import React, { useState } from "react"
import { find } from "lodash"
import { Row, Col } from "reactstrap"

import { PrimaryButton, SecondaryButton } from "components/Button"
import DataPanel from "components/DataPanel"
import EmptyState from "components/EmptyState"
import DataItem from "components/DataItem"
import { getCompanyType } from "utils/utils"
import { ONBOARDING_FORM } from "../onboarding.const"

import OnboardingImage from "assets/images/verification-img.png"
import "../onboarding.styles.scss"
import Link from "components/Link"

const Summary = props => {
  const [stepToEdit, setStepToEdit] = useState(null)
  const [showConfirmEditModal, setShowConfirmEditModal] = useState(false)

  const editStep = step => {
    setStepToEdit(step)
    props.goSpecificStep(step)
    //setShowConfirmEditModal(true)
  }

  const onClickEdit = () => {
    props.goSpecificStep(stepToEdit)
  }

  const selectedCountry = find(props.countries, {
    id: props.companyInfo.country,
  })

  const countryOfResidence = find(props.countries, {
    id: props.personalInfo.country,
  })

  return (
    <div className="hevara-onboarding-user mt-4">
      <div>
        <h1 className="fw-bold">Confirm your information</h1>
        <p className="mt-4 font-size-14">
          Check and confirm the information before proceeding to register.
        </p>

        {/* COMPANY TYPE */}
        <DataPanel>
          <DataItem
            label={"Company type"}
            value={getCompanyType(props.selectedCompanyType)}
          />

          <Link icon="mdi mdi-pencil" onClick={() => editStep(ONBOARDING_FORM.COMPANY_TYPE)}>
            Edit
          </Link>
        </DataPanel>

        {/* COMPANY INFORMATION */}
        <DataPanel className="mt-4">
          <DataItem
            label={"Company name"}
            value={props.companyInfo.companyName}
          />

          <DataItem
            label={"Company email"}
            value={props.companyInfo.companyEmail}
          />

          <DataItem label={"Country"} value={selectedCountry?.name} />

          <DataItem
            label={"Location address"}
            value={props.companyInfo.location.address}
          />

          <Link icon="mdi mdi-pencil" onClick={() => editStep(ONBOARDING_FORM.COMPANY_INFORMATION)}>
            Edit
          </Link>
        </DataPanel>

        {/* PERSONAL INFORMATION */}
        <DataPanel className="mt-4">
          <DataItem label={"First name"} value={props.personalInfo.firstName} />

          {props.personalInfo.middleName && (
            <DataItem
              label={"Middle name"}
              value={props.personalInfo.middleName}
            />
          )}

          <DataItem 
            label={"Last name"} 
            value={props.personalInfo.lastName} 
          />

          <DataItem 
            label={"Email address"} 
            value={props.personalInfo.email}
          />

          <DataItem
            label={"Mobile number"}
            value={props.personalInfo.mobileNumber}
          />

          <DataItem 
            label={"Country"} 
            value={countryOfResidence?.name}
          />

          <Link icon="mdi mdi-pencil" onClick={() => editStep(ONBOARDING_FORM.PERSONAL_INFORMATION)}>
            Edit
          </Link>
        </DataPanel>

        <Row>
          <Col>
            <div className="hstack gap-2 mb-0 mt-4">
              <PrimaryButton onClick={props.handleSubmit} className="me-4">
                Continue
              </PrimaryButton>

              <SecondaryButton onClick={props.onClickCancel}>
                Cancel
              </SecondaryButton>
            </div>
          </Col>
        </Row>
      </div>

      <EmptyState
        visible={true}
        illustration={OnboardingImage}
        className="onboarding-user-ph-image"
        containerClassName="onboarding-user-ph"
      />
    </div>
  )
}

export default Summary

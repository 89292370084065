import CONST from "utils/constants"
import { getRGBA } from "utils/utils"

const getTheme = {
  color: {
    modal_dialog: {
      background: "#FFFFFF",
      overlay_background: "#F4CC0C",
    },
    general: {
      scroll_bar: "#F4CC0C",
    },
  },
}

const { MEDIA, SIZE } = CONST

export const MODAL_STRETCH = {
  CONDENSED: "condensed",
  NORMAL: "normal",
  EXPANDED: "expanded",
}

const MIN_MARGIN = 56
export const DEFAULT_SPACING = 4
export const SCROLLBAR_WIDTH = 16
export const MIN_MARGIN_Y = MIN_MARGIN * 2
export const MAX_HEIGHT = 672
export const TRANSITION_MS = 250

export const TRANSITION_CONTAINER_STYLES = {
  entering: { opacity: 0 },
  entered: { opacity: 1, transition: "opacity ease-in 250ms" },
  exiting: { opacity: 0, transition: "oapcity ease-out 250ms" },
  exited: { opacity: 0 },
  unmounted: {},
}

export const TRANSITION_MODAL_STYLES = {
  entering: { transform: "translateY(10%)" },
  entered: {
    transform: "translateY(0%)",
    transition: "transform linear 250ms",
  },
  exiting: {
    transform: "translateY(10%)",
    transition: "transform linear 250ms",
  },
  exited: { transform: "translate(10%)" },
  unmounted: {},
}

export const getScrollBarStyles = props => {
  const theme = getTheme

  return `
    ::-webkit-scrollbar {
      width: ${SCROLLBAR_WIDTH}px;
    }
    ::-webkit-scrollbar-thumb {
      width: 4px;
      background: ${theme.color.general.scroll_bar};
      border: 6px solid transparent;
      border-radius: 8px;
      background-clip: padding-box;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
  `
}

export const getModalStretchSizes = props => {
  switch (props.stretch) {
    case MODAL_STRETCH.CONDENSED:
      return `
        width: 364px; 
        max-width: 364px;
        ${MEDIA.MOBILE} {
          width: 100%;
          max-width: 100%;
        }
      `
    case MODAL_STRETCH.NORMAL:
      return `
        width: 558px; 
        max-width: 558px;
        ${MEDIA.MOBILE} {
          width: 100%;
          max-width: 100%;
        }
      `
    case MODAL_STRETCH.EXPANDED:
    default:
      return `
        width: 1140px; max-width: 1140px;
        ${MEDIA.DESKTOP_S} {
          width: 100%;
          max-width: 100%;
        }
      `
  }
}

export const getModalSizes = props => {
  switch (props.size) {
    case SIZE.S:
      return `width: 558px; max-width: 558px;`
    case SIZE.M:
      return `width: 946px; max-width: 946px;`
    case SIZE.L:
    default:
      return getModalStretchSizes(props)
  }
}

export const getModalHeaderWrapperStyles = props => {
  const theme = getTheme
  const { background } = theme.color.modal_dialog

  return `
    .hevara-modal-header-wrapper {
      padding-top: 24px;
      padding-right: 24px;
      padding-left: 24px;
      ${MEDIA.MOBILE} {
        padding-right: 16px;
        padding-left: 16px;
      }
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      .hevara-modal-header {
        margin: 0 auto;
        background: ${background};
        display: flex;
        ${
          props.isFullScreen
            ? `
              ${getModalSizes({ ...props, stretch: MODAL_STRETCH.EXPANDED })}
              align-self: center;
              flex-direction: column-reverse;
            `
            : `
              padding-bottom: 24px;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            `
        }
      }
    }
    ${
      props.isFullScreen
        ? `
          .hevara-modal-title-wrapper {
            margin-top: 32px;
            ${MEDIA.MOBILE} {
              margin-top: 0;
            }
          }
        `
        : ``
    }
    .hevara-modal-title-sticky {
      position: fixed;
      top: 0;
      text-align: center;
      ${getModalSizes(props)}
      ${MEDIA.MOBILE} {
        width: calc(100% - 32px);
        max-width: calc(100% - 32px);
      }
      margin-top: 24px;
      font-size: 16px;
      line-height: 32px;
      font-weight: 600;
      animation: fadeout ${TRANSITION_MS}ms linear forwards;
      visibility: visible !important;
      pointer-events: none;
      z-index: 4;
      &.unset {
        z-index: unset;
      }
    }
    .hevara-modal-icon {
      ${
        props.isFullScreen
          ? `
            margin-bottom: 24px;
            margin-left: auto;
          `
          : `
            ${MEDIA.MOBILE} {
              margin-left: 16px;
              margin-bottom: 0;
            }
          `
      }
    }

    @keyframes fadeout {
      0% { opacity: 0 }
      100% { opacity: 1 }
    }
  `
}

export const getModalOverlayStyles = props => {
  return props.isFullScreen
    ? `
      padding-top: 24px;
      padding-right: 24px;
      padding-left: 24px;
      ${MEDIA.MOBILE} {
        padding-right: 16px;
        padding-left: 16px;
      }
      display: flex;
      flex-direction: column;
      cursor: default;
      box-shadow: none;
      width: 100%;
      max-width: 100%;
      .hevara-modal-content {
        ${getModalSizes(props)}
        display: flex;
        flex-direction: column;
        align-self: center;
        padding-bottom: 100px;
        margin: 0 auto;
      }
      .hevara-modal-scrollable-content {
        width: calc(100% + ${SCROLLBAR_WIDTH}px);
        overflow: auto;
        ${getScrollBarStyles(props)}
        ${MEDIA.MOBILE} {
          width: calc(100% - ${DEFAULT_SPACING * 4}px + ${SCROLLBAR_WIDTH}px)
        }
      }
    `
    : `
      margin: auto;
      cursor: default;
      padding: 24px;
      margin-right: 32px;
      margin-left: 32px;
      ${MEDIA.MOBILE} {
        margin-right: 16px;
        margin-left: 16px;
      }
      max-height: ${props.maxHeight || MAX_HEIGHT}px;
      display: flex;
      flex-direction: column;
      ${getModalSizes(props)}
      .hevara-modal-scrollable-content {
        width: 100%;
        overflow: auto;
        ${getScrollBarStyles(props)}
      }

    `
}

export const getModalContainerStyles = props => {
  const theme = getTheme
  const { background, overlay_background } = theme.color.modal_dialog
  const modalBackground = props.isFullScreen
    ? background
    : getRGBA(overlay_background, "0.5")

  return `
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    backgrond: ${modalBackground};
    z-index: 2;
    &:focus {
      outline: none;
    }
    .hevara-modal {
      ${getModalOverlayStyles(props)}
      background: ${background};
      ${getModalHeaderWrapperStyles(props)}
    }
    ${
      props.isFullScreen
        ? `
          .hevara-modal-children {
            margin-top: 16px;
          }
        `
        : `
          align-items: center;
          cursor: ${props.avoidClosedModal ? "default" : "pointer"}
        `
    }
    .hevara-modal-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 40px;
      ${MEDIA.MOBILE} {
        flex-direction: column;
      }
    }
  `
}

import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { isEmpty, omit, map } from "lodash"
import { withTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { Card, CardBody, Container, Row } from "reactstrap"
import { connect } from "react-redux";
import { compose } from "redux"

import { changeSidebarType } from "store/actions"
import { leftSidebarTypes } from "constants/layout"

import Breadcrumbs from "components/Common/Breadcrumb"
import DataItem from "components/DataItem"
import Upload from "models/upload"
import Loading from "components/Common/Spinner"
import { EmptyErrorState } from "components/EmptyState"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { getCompanyRole, getLastFourDigits, getStatus } from "utils/utils"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import ImageModal from "./ImageModal"
import EditUserModal from "./editUserModal"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import Avatar from "components/Avatar/Avatar"
import Link from "components/Link"
import Auth from "models/auth"
import UserModal from "models/user"
import Countries from "models/countries"
import CONST from "utils/constants"

const { SIZE } = CONST

import "./style.scss"

const PersonalDetails = (props) => {
  //meta title
  document.title = "Personal details | hevara DMS Platform"

  const [modal, setModal] = useState(false)
  const [userModal, setUserModal] = useState(false)
  const [editImage, setEditImage] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [profile, setProfile] = useState({})
  const [countries, setCountries] = useState([])
  const [user, setUser] = useState({})

  const fetchUserProfile = id => {
    UserModal.getUserDetails(id)
      .then(data => {
        setUser(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchCountries = () => {
    Countries.lookUpCountries()
      .then(data => {
        setCountries([
          {
            status: "Default",
            name: "Select Country",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      const userId = JSON.parse(profile).id

      Promise.all([fetchUserProfile(userId), fetchCountries()])
      setProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [])

  const updateImage = file => {
    setProcessing(true)

    const formData = new FormData()
    formData.append("file", file[0])
    formData.append("fileType", "PROFILE_IMAGE")
    formData.append("userId", user.id)

    Upload.updateUpload(user.image._id, formData)
      .then(() => {
        fetchUserProfile(user.id)
        toast.success(`Profile image updated successfully.`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => {
        setProcessing(false)
        setEditImage(false)
      })
  }

  const addImage = file => {
    setProcessing(true)

    const formData = new FormData()
    formData.append("file", file[0])
    formData.append("fileType", "PROFILE_IMAGE")
    formData.append("userId", user.id)

    Upload.addUpload(formData)
      .then(() => {
        fetchUserProfile(user.id)
        toast.success(`Profile image added successfully.`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const onClickUploadImage = file => {
    if (editImage) {
      updateImage(file)
    } else {
      addImage(file)
    }

    toggle()
  }

  const updateUser = values => {
    setProcessing(true)

    const payload = {
      ...(values && values.middleName
        ? { ...values, middleName: values.middleName }
        : omit(values, ["middleName"])),
    }

    UserModal.updateUser(user.id, payload)
      .then(user => {
        setUser(user)
        toast.success(`${user.fullName} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const getHeaderActions = () => {
    const actions = [
      {
        label: "Edit",
        icon: "mdi mdi-pencil",
        onClick: () => {
          userModaltoggle()
        },
        variant: BUTTON_VARIANT.SECONDARY,
        showInModal: true,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      closeSideTopBars(leftSidebarTypes.DEFAULT)
    } else {
      closeSideTopBars(leftSidebarTypes.HIDDEN)
      setModal(true)
    }
  }

  const userModaltoggle = () => {
    if (userModal) {
      setUserModal(false)
      closeSideTopBars(leftSidebarTypes.DEFAULT)
    } else {
      closeSideTopBars(leftSidebarTypes.HIDDEN)
      setUserModal(true)
    }
  }

  const closeSideTopBars = (type) => {
    props.changeSidebarType(type);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Personal details")}
            breadcrumbItem={props.t("Personal details")}
          />

          {/* Loading */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Profile"}
            description={"We are processing your request, please wait..."}
          />

          {/* IMAGE MODAL */}
          <ImageModal
            modalIsOpen={modal}
            onClickClose={toggle}
            onClickUploadImage={onClickUploadImage}
          />

          {/* EDIT USER MODAL */}
          <EditUserModal
            modalIsOpen={userModal}
            onClickClose={userModaltoggle}
            user={user}
            updateUser={values => updateUser(values)}
            companyId={user.companyId}
            countries={countries}
            toggle={userModaltoggle}
          />

          {!loading && (
            <Row>
              <Card>
                <CardBody>
                  {/* PAGE HEADER */}
                  <PageHeader
                    avatar={
                      user && {
                        initials: user.fullName,
                        image: user.image?.file,
                      }
                    }
                    title={user && user.fullName}
                    showBackButton={true}
                    showDivider={true}
                    actions={<HeaderActions actions={getHeaderActions()} />}
                    info={
                      <PageHeaderInfo>
                        {!isEmpty(user) && (
                          <>
                            <div className="mt-2">{getStatus(user.status)}</div>
                            <PageHeaderInfoText className="fw-semibold">
                              {getCompanyRole(user.companyRole)}
                            </PageHeaderInfoText>
                          </>
                        )}
                      </PageHeaderInfo>
                    }
                  />

                  <div className="hevara-user-detail">
                    <div className="tx-grid mb-3">
                      <div className="mb-4">
                        <h5 className="text-muted">Personal details</h5>
                        <div className="detail-grid">
                          <DataItem
                            label={props.t("First name")}
                            value={user.firstName}
                          />

                          <DataItem
                            label={props.t("Middle name")}
                            value={user.middleName}
                          />

                          <DataItem
                            label={props.t("Last name")}
                            value={user.lastName}
                          />

                          <DataItem
                            label={props.t("Email address")}
                            value={user.email}
                          />

                          <DataItem
                            label={props.t("Phone")}
                            value={getLastFourDigits(user.mobileNumber)}
                          />

                          <DataItem
                            label={props.t("Country")}
                            value={user.countryName}
                          />
                        </div>
                      </div>

                      {/* USER PROFILE PHOTO */}
                      <div className="mb-3 tx-avatar">
                        <Avatar
                          size={SIZE.XXL}
                          initials={user.fullName}
                          image={user.image?.file}
                          className="avatar-size"
                        />

                        <Link
                          icon="mdi mdi-camera"
                          className="d-flex justify-content-center mt-1"
                          onClick={
                            !isEmpty(user.image)
                              ? () => {
                                  setEditImage(true)
                                  toggle()
                                }
                              : () => toggle()
                          }
                        >
                          {!isEmpty(user.image) ? `Edit photo` : `Add photo`}
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center">
                    {/* ERROR STATE */}
                    <EmptyErrorState
                      visible={error}
                      title={`Something went wrong`}
                      description={`An error has occurred. Please try again later or contact hevara for support`}
                    />
                  </div>
                </CardBody>
              </Card>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

PersonalDetails.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = state => {
  return { ...state.Layout };
};

const mapDispatchToProps = {
  changeSidebarType,
  // Any other actions to map here
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(PersonalDetails);
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"

import Auth from "models/auth"
import PageHeader from "components/PageHeader"
import NavigationRow from "components/NavigationRow"
import Loading from "components/Common/Spinner"
import "./style.scss"

const Settings = (props) => {
  //meta title
  document.title = "Settings | hevara DMS Platform"

  const [userProfile, setUserProfile] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()

    if (profile) {
      setUserProfile(JSON.parse(profile))
      setLoading(false)
    }
  }, [])

  return (
    <React.Fragment>
      {/* Loading */}
      {loading && <Loading />}
      
      {!loading && userProfile && 
        <div className="page-content hevara-settings mb-4">
          <PageHeader title={`Settings`} />

          <div className="settings-group">
            <h4 className="text-truncate fw-semibold">User settings</h4>

            {/* USER PROFILE SECTION */}
            <p className="mt-4 font-size-16">User profile</p>
            <NavigationRow
              title={`Personal details`}
              description={`Name, phone, email...`}
              icon="bx bx-user"
              to={`/personal-details`}
            />

            <NavigationRow
              title={`Password`}
              description={`Change your account password`}
              icon="mdi mdi-security"
              to={`/change-password`}
            />

            <NavigationRow
              title={`Notifications`}
              description={`Choose your desired notifications`}
              icon="bx bx-notification-off"
              to={`/notification-preferences`}
            />

            <NavigationRow
              title={`Delete account`}
              description={`Erase your account and its related information`}
              icon="bx bx-trash-alt"
              to={`/delete-account`}
            />

            {/* WALLET SECTION */}
            {userProfile.permission.CAN_MANAGE_WALLETS &&
              <>
                <p className="mt-4 font-size-16">Wallet</p>
                <NavigationRow
                  title={`My wallet`}
                  description={`Create and view wallet information`}
                  icon="bx bxs-wallet-alt"
                  to={`/wallet`}
                />
              </>
            }

            {/* COMPANY SECTION */}
            <p className="mt-4 font-size-16">Company</p>

            {userProfile.permission.CAN_MANAGE_BILLING &&
              <NavigationRow
                title={`Billing`}
                description={`Invoices and billing information`}
                icon="bx bxs-wallet"
                to={`/billing`}
              />
            }

            <NavigationRow
              title={`Company profile`}
              description={`View company information`}
              icon="bx bxs-business"
              to={`/company-details`}
            />

            {/* GENERAL SECTION */}
            <p className="mt-4 font-size-16">General information</p>

            <NavigationRow
              title={`Terms of service`}
              description={`Platform terms of service`}
              icon="bx bx-globe"
              href={`https://hevara.africa/terms-of-service/`}
            />

            <NavigationRow
              title={`Privacy policy`}
              description={`Platform privacy policies`}
              icon="bx bx-globe"
              href={`https://hevara.africa/privacy-policy/`}
            />

            <NavigationRow
              title={`About hevara`}
              description={`Get to know your service provider`}
              icon="bx bx-book-bookmark"
              href={`https://hevara.africa/`}
            />
          </div>
        </div>
      }
    </React.Fragment>
  )
}

Settings.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Settings)

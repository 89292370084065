import React from "react"
import styled from "styled-components"

import Text from "components/atoms/Text/BasicText"
import Link from "components/Link"
import {
  getHelpNotificationStyles,
  HELP_VARIANTS,
} from "./HelpNotification.styles"

const StyledHelpNotification = styled.div.attrs({
  className: "hevara-help-notification",
})`
  ${getHelpNotificationStyles}
`

const StyledText = styled(Text).attrs({
  className: "hevara-help-notification-text",
})``

const StyledLink = styled(Link).attrs({
  className: "hevara-help-notification-link",
})``

const StyledIconWrapper = styled.div.attrs({
  className: "hevara-help-notification-icon-wrapper",
})`
  padding-top: 2px;
  margin-right: 8px;
  display: flex;
`

const StyledIcon = styled.i.attrs({
  className: "hevara-help-notification-icon",
})``

export const HelpNotification = ({
  variant,
  href,
  children,
  className,
  style,
  id,
}) => {
  const iconName =
    variant === HELP_VARIANTS.ERROR
      ? "mdi mdi-alert-outline"
      : "mdi mdi-information-outline"

  return (
    <StyledHelpNotification
      variant={variant}
      className={className}
      style={style}
    >
      <StyledIconWrapper>
        <StyledIcon size="16">
          <i className={iconName + " fw-bolder font-size-16 me-2"}></i>
        </StyledIcon>
        {variant === HELP_VARIANTS.LINK ? (
          <StyledLink href={href || ""}>{children}</StyledLink>
        ) : (
          <StyledText id={id}>{children}</StyledText>
        )}
      </StyledIconWrapper>
    </StyledHelpNotification>
  )
}

HelpNotification.defaultProps = {
  variant: HELP_VARIANTS.INFO,
  href: "",
  className: "",
  style: {},
  id: "help-notification",
}

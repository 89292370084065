import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Container, Form, Input, FormFeedback, Label } from "reactstrap"

import Auth from "models/auth"
import Loading from "components/Common/Spinner"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { PrimaryButton } from "components/Button"
import PageHeader from "components/PageHeader"

import "./style.scss"

const ChangePassword = props => {
  //meta title
  document.title = "Change password | hevara DMS Platform"

  const [processing, setProcessing] = useState(false)
  const [passwordShow, setPasswordShow] = useState(false)
  const [profile, setProfile] = useState({})
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  const updatePassword = values => {
    setProcessing(true)
    Auth.changeUserPassword(values)
      .then(data => {
        toast.success(data?.message)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (profile) {
      setProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }

    setLoading(false)
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values need to be changed
    enableReinitialize: true,

    initialValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("This field is required"),
      newPassword: Yup.string()
        .required("This field is required")
        .matches(
          /^(?=.*\d)(?=.*[a-zA-Z]).+$/,
          "Password must contain at least one letter and one digit"
        ),
      newPasswordRepeat: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Please re-enter your new password"),
    }),
    onSubmit: values => {
      const payload = {
        userId: profile.id,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      }
      updatePassword(payload)
    },
  })

  return (
    <React.Fragment>
      <div className="page-content hevara-password-chnage">
        <Container fluid>
          <PageHeader title={`Change password`} showBackButton={true} />

          {/* Loading */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Profile"}
            description={"We are processing your request, please wait..."}
          />

          <div className="settings-group">
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <div className="mb-3">
                <Label className="form-label">Old password</Label>
                <div className="input-group auth-pass-inputgroup">
                  <Input
                    name="oldPassword"
                    value={validation.values.oldPassword || ""}
                    type={passwordShow ? "text" : "password"}
                    placeholder="Enter old password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.oldPassword &&
                      validation.errors.oldPassword
                        ? true
                        : false
                    }
                  />
                  <button
                    onClick={() => setPasswordShow(!passwordShow)}
                    className="btn btn-light "
                    type="button"
                    id="password-addon"
                  >
                    <i className="mdi mdi-eye-outline"></i>
                  </button>
                </div>
                {validation.touched.oldPassword &&
                validation.errors.oldPassword ? (
                  <FormFeedback type="invalid">
                    {validation.errors.oldPassword}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">New password</Label>
                <div className="input-group auth-pass-inputgroup">
                  <Input
                    name="newPassword"
                    value={validation.values.newPassword || ""}
                    type={passwordShow ? "text" : "password"}
                    placeholder="Enter new password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.newPassword &&
                      validation.errors.newPassword
                        ? true
                        : false
                    }
                  />
                  <button
                    onClick={() => setPasswordShow(!passwordShow)}
                    className="btn btn-light "
                    type="button"
                    id="password-addon"
                  >
                    <i className="mdi mdi-eye-outline"></i>
                  </button>
                </div>
                {validation.touched.newPassword &&
                validation.errors.newPassword ? (
                  <FormFeedback type="invalid">
                    {validation.errors.newPassword}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Repeat new password</Label>
                <div className="input-group auth-pass-inputgroup">
                  <Input
                    name="newPasswordRepeat"
                    value={validation.values.newPasswordRepeat || ""}
                    type={passwordShow ? "text" : "password"}
                    placeholder="Enter repeat new password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.newPasswordRepeat &&
                      validation.errors.newPasswordRepeat
                        ? true
                        : false
                    }
                  />
                  <button
                    onClick={() => setPasswordShow(!passwordShow)}
                    className="btn btn-light "
                    type="button"
                    id="password-addon"
                  >
                    <i className="mdi mdi-eye-outline"></i>
                  </button>
                </div>
                {validation.touched.newPasswordRepeat &&
                validation.errors.newPasswordRepeat ? (
                  <FormFeedback type="invalid">
                    {validation.errors.newPasswordRepeat}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="d-flex flex-column align-items-center mt-4">
                <PrimaryButton type="submit" className="w-lg">
                  Confirm
                </PrimaryButton>
              </div>
            </Form>
          </div>
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

ChangePassword.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ChangePassword)

import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { omit } from "lodash"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import moment from "moment"
import { Card, CardBody, Container, Row } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import Fleet from "models/fleet"
import DataItem from "components/DataItem"
import Loading from "components/Common/Spinner"
import { EmptyErrorState } from "components/EmptyState"
import User from "models/user"
import Auth from "models/auth"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import Link from "components/Link"
import { BUTTON_VARIANT } from "components/Button"
import { getStatus } from "utils/utils"
import vehicleImage from "assets/images/vehicle.png"
import Warehouse from "models/warehouses"
import VehicleTypes from "models/vehicleTypes"
import CONST from "utils/constants"
import VehicleModal from "./vehicleModal"
import PageHeader, {
  PageHeaderInfo,
  PageHeaderInfoText,
} from "components/PageHeader"

const { ROLES } = CONST
import "./style.scss"

const VehicleDetails = props => {
  //meta title
  document.title = "Vehicle details | hevara DMS Platform"

  const { vehicleId } = useParams()

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const [fleetDetails, setFleetDetails] = useState({})
  const [fleet, setFleet] = useState({})
  const [vehicleTypes, setVehicleTypes] = useState([])
  const [routeAgents, setRouteAgents] = useState([])
  const [warehouses, setWarehouses] = useState([])

  const fetchVehicleData = id => {
    Fleet.getFleet(id)
      .then(data => {
        setFleetDetails(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchVehicleTypes = () => {
    VehicleTypes.lookUpVehicleTypes()
      .then(data => {
        setVehicleTypes([
          {
            status: "Default",
            name: "Select vehicle type",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchRouteAgents = id => {
    const filters = {
      companyId: id,
      companyRole: ROLES.ROUTE_AGENT,
    }

    User.getUsers(limit, page, filters)
      .then(data => {
        setRouteAgents([
          {
            status: "Default",
            fullName: "Select route agent",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data.results,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanyWarehouses = id => {
    Warehouse.lookUpCompanyWarehouses(id)
      .then(data => {
        setWarehouses([
          {
            status: "Default",
            warehouseName: "Select warehouse",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()

    if (vehicleId && profile) {
      const companyId = JSON.parse(profile).companyId

      Promise.all([
        fetchVehicleData(vehicleId),
        fetchVehicleTypes(),
        fetchRouteAgents(companyId),
        fetchCompanyWarehouses(companyId),
      ])

      setUserProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [vehicleId])

  const disableFleet = data => {
    setProcessing(true)

    const payload = {
      ...omit(data, [
        "id",
        "companyName",
        "routeAgentName",
        "vehicleTypeName",
        "warehouseName",
        "dateCreated",
      ]),
      status: "Disabled",
    }

    Fleet.updateFleet(data.id, payload)
      .then(fleet => {
        setFleetDetails(fleet)
        toast.success(`${fleet.registrationNumber} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableFleet = data => {
    setProcessing(true)

    const payload = {
      ...omit(data, [
        "id",
        "companyName",
        "routeAgentName",
        "vehicleTypeName",
        "warehouseName",
        "dateCreated",
      ]),
      status: "Active",
    }

    Fleet.updateFleet(data.id, payload)
      .then(fleet => {
        setFleetDetails(fleet)
        toast.success(`${fleet.registrationNumber} has been enabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const updateFleet = (vehicleId, values) => {
    setProcessing(true)

    Fleet.updateFleet(vehicleId, values)
      .then(fleet => {
        setFleetDetails(fleet)
        toast.success(`${fleet.registrationNumber} updated successfully`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const onClickEdit = data => {
    setFleet(data)
    setIsEdit(true)
    toggle()
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  const getHeaderActions = () => {
    const canManageFleets = userProfile.permission.CAN_MANAGE_FLEETS
    const isActive = fleetDetails.status === "Active"

    const actions = [
      canManageFleets && {
        label: "Edit",
        icon: "mdi mdi-pencil",
        onClick: () => onClickEdit(fleetDetails),
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      canManageFleets && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () =>
          isActive ? disableFleet(fleetDetails) : enableFleet(fleetDetails),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Vehicle details")}
            breadcrumbItem={props.t("Vehicle details")}
          />

          {/* VIHICLE MODAL */}
          <VehicleModal
            modalIsOpen={modal}
            onClickClose={toggle}
            fleet={fleet}
            isEdit={isEdit}
            updateFleet={updateFleet}
            companyId={userProfile.companyId}
            vehicleTypes={vehicleTypes}
            routeAgents={routeAgents}
            warehouses={warehouses}
            toggle={toggle}
          />

          {/* LOADING */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Vehicles"}
            description={"We are processing your request, please wait..."}
          />

          {!loading && (
            <Row>
              <Card>
                <CardBody>
                  {/* PAGE HEADER */}
                  <PageHeader
                    avatar={
                      fleetDetails && {
                        initials: fleetDetails.registrationNumber,
                        image: vehicleImage,
                      }
                    }
                    title={fleetDetails && fleetDetails.registrationNumber}
                    showBackButton={true}
                    showDivider={false}
                    actions={<HeaderActions actions={getHeaderActions()} />}
                    info={
                      fleetDetails && (
                        <PageHeaderInfo>
                          <div className="mt-2">
                            {getStatus(fleetDetails.status)}
                          </div>
                          <PageHeaderInfoText className="fw-semibold">
                            {fleetDetails.vehicleTypeName}
                          </PageHeaderInfoText>

                          <PageHeaderInfoText className="fw-semibold">
                            {moment(fleetDetails.dateCreated).format("LL")}
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      )
                    }
                  />

                  <div className="hevara-fleet-data">
                    <div className="tx-grid mb-3">
                      <div className="mb-4">
                        <h5 className="text-muted mb-4">Vehicle details</h5>

                        <div className="detail-grid">
                          <DataItem
                            label={props.t("Driver")}
                            value={fleetDetails.routeAgentName}
                            avatar={{ icon: "mdi mdi-account" }}
                          >
                            <Link
                              to={`/user-details/${fleetDetails.routeAgent}`}
                            >
                              View driver
                            </Link>
                          </DataItem>

                          <DataItem
                            label={props.t("Company")}
                            value={fleetDetails.companyName}
                            avatar={{ icon: "mdi mdi-office-building-outline" }}
                          />

                          <DataItem
                            label={props.t("Warehouse")}
                            value={
                              fleetDetails.warehouseName
                                ? fleetDetails.warehouseName
                                : "Global"
                            }
                            avatar={{ icon: "mdi mdi-warehouse" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center">
                    {/* ERROR STATE */}
                    <EmptyErrorState
                      visible={error}
                      title={`Something went wrong`}
                      description={`An error has occured. Please try again later or contact hevara for support`}
                    />
                  </div>
                </CardBody>
              </Card>
            </Row>
          )}
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

VehicleDetails.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(VehicleDetails)

import React from "react"
import styled from "styled-components"

import { SmallTextBold } from "components/atoms"
import {
  getGroupSeparatorLabelStyles,
  getGroupSeparatorStyles,
} from "./GroupSeparator.styles"

const StyledGroupSeparator = styled.div.attrs({
  className: "hevara-group-separator d-flex center-y center-x",
})`
  border: 2px solid "#f6f6f6";
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 4px;
  ${getGroupSeparatorStyles}
`

const StyledLabel = styled(SmallTextBold).attrs({
  className: "hevara-group-separator-label",
})`
  ${getGroupSeparatorLabelStyles}
`

export const GroupSeparator = ({ className, style, label }) => {
  return (
    <StyledGroupSeparator className={className} style={style}>
      <StyledLabel>{label}</StyledLabel>
    </StyledGroupSeparator>
  )
}

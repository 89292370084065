import React from "react"

import { Info } from "./Info"

const TableRowInfo = ({ text, subtext, style, className }) => {
  return (
    <Info text={text} subtext={subtext} style={style} className={className} />
  )
}

export default TableRowInfo

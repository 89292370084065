import React from "react"
import styled from "styled-components"

import Divider from "components/Divider/Divider"
import { getTableRowWrapperStyles } from "./TableRow.styles"

const StyledTableRow = styled.div.attrs({
  className: "hevara-table-row",
})``

const StyledTableRowWrapper = styled.div.attrs({
  className: "hevara-table-row-wrapper",
})`
  ${getTableRowWrapperStyles}
`

export const TableRow = ({
  showDivider,
  onClick,
  children,
  className,
  style,
}) => {
  return (
    <StyledTableRow className={className} style={style}>
      <StyledTableRowWrapper onClick={onClick}>
        {children}
      </StyledTableRowWrapper>
      {showDivider && <Divider />}
    </StyledTableRow>
  )
}

TableRow.defaultProps = {
  showDivider: true,
  onClick: undefined,
}

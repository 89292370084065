import React from "react";
import styled from "styled-components";
import { isEmpty } from "lodash";

import CONST from "components/atoms/constants";
import { Label, Hint, ErrorMessage } from "components/Commons";
import { spacing } from "components/atoms";
import Tooltip, { TOOLTIP_POSITIONS } from "components/Tooltip";
import { getFormFieldIconColor } from "../Commons.styles";

const { MEDIA } = CONST;

const StyledFieldContainerContainer = styled.div.attrs({
  className: 'hevara-text-field'
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 364px;
  ${MEDIA.MOBILE} {
    width: 100%;
  }
  ${(props) => props.disabled && `
    opacity: 0.4;
  `}
`;

const StyledLabelContainer = styled.div.attrs({
  className: 'hevara-text-field-label-container'
})`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledIcon = styled.i`
  color: ${getFormFieldIconColor};
  ${spacing('ml', 2)}
`

const StyledTooltipContainer = styled.div.attrs({
  className: 'hevara-text-field-label-tooltip-container'
})`
  display: flex;
  justify-content: center;
`

/**
 * Field Container UI component
 */
const FieldContainer = ({ 
  label,
  hint,
  error,
  disabled,
  className,
  style,
  children,
  labelIconName,
  labelToolTipContent,
  errorMessageID,
  id
}) => {
  return (
    <StyledFieldContainerContainer
      disabled={disabled}
      className={className}
      style={style}
    >
      <StyledLabelContainer>
        <Label label={label} htmlFor={id} />
        {labelIconName && (
          isEmpty(labelToolTipContent)
            ? <StyledIcon size='16'>
                <i className={labelIconName + " fw-bolder font-size-16"}></i>
              </StyledIcon>
            : <StyledTooltipContainer>
                <Tooltip
                  position={TOOLTIP_POSITIONS.RIGHT}
                  content={labelToolTipContent}
                >
                  <StyledIcon size='16'>
                    <i className={labelIconName + " fw-bolder font-size-16"}></i>
                  </StyledIcon>
                </Tooltip>
              </StyledTooltipContainer>
        )}
      </StyledLabelContainer>
      <Hint hint={hint} />
      {children}
      <ErrorMessage error={error} errorMessageID={errorMessageID} />
    </StyledFieldContainerContainer>
  )
}

FieldContainer.defaultProps = {
  hint: '',
  error: '',
  disabled: false,
}

export default FieldContainer
import React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Col, Row, Form, Input, FormFeedback, Label } from "reactstrap"

import { LargeModal, MODAL_STRETCH } from "components/modal"
import { PrimaryButton, SecondaryButton } from "components/Button"

const CompanyRoleModal = ({
  modalIsOpen,
  onClickClose,
  role,
  isEdit,
  updateCompanyRole,
  addCompanyRole,
  companyId,
  toggle,
}) => {
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      label: (role && role.label) || "",
      value: (role && role.value) || "",
      companyId: companyId && companyId,
      status: (role && role.status) || "",
    },
    validationSchema: Yup.object({
      label: Yup.string().required("Please enter company role label"),
      value: Yup.string().required("Please enter company role value"),
      status: Yup.string().required("Please select company role status"),
    }),
    onSubmit: values => {
      if (isEdit) {
        // update
        updateCompanyRole(role.id, values)
        validation.resetForm()
      } else {
        // add new
        addCompanyRole(values)
        validation.resetForm()
      }
      toggle()
    },
  })

  return (
    <LargeModal
      modalIsOpen={modalIsOpen}
      onClickClose={onClickClose}
      hideButtons={true}
      stretch={MODAL_STRETCH.NORMAL}
      title={isEdit ? `Edit ${role?.label}'s details` : `New company role`}
    >
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
        className="mt-4"
      >
        <Row>
          <Col className="col-12">
            <div className="mb-4">
              <Label className="form-label">Role label</Label>
              <Input
                name="label"
                type="text"
                placeholder="Role label"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.label || ""}
                invalid={
                  validation.touched.label && validation.errors.label
                    ? true
                    : false
                }
              />
              {validation.touched.label && validation.errors.label ? (
                <FormFeedback type="invalid">
                  {validation.errors.label}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-4">
              <Label className="form-label">Role value</Label>
              <Input
                name="value"
                type="text"
                placeholder="Role value"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.value || ""}
                invalid={
                  validation.touched.value && validation.errors.value
                    ? true
                    : false
                }
              />
              {validation.touched.value && validation.errors.value ? (
                <FormFeedback type="invalid">
                  {validation.errors.value}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-4">
              <Label className="form-label">Status</Label>
              <Input
                name="status"
                type="select"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.status || ""}
                invalid={
                  validation.touched.status && validation.errors.status
                    ? true
                    : false
                }
              >
                <option>Select status</option>
                <option value="Active">Active</option>
                <option value="Disabled">Disabled</option>
              </Input>
              {validation.touched.status && validation.errors.status ? (
                <FormFeedback status="invalid">
                  {validation.errors.status}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="hstack gap-2 justify-content-center mb-0 mt-4">
              <SecondaryButton type="button" onClick={toggle} className="me-4">
                Cancel
              </SecondaryButton>

              <PrimaryButton type="submit">
                {!!isEdit ? "Update company role" : "Add company role"}
              </PrimaryButton>
            </div>
          </Col>
        </Row>
      </Form>
    </LargeModal>
  )
}

export default CompanyRoleModal

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { withTranslation } from "react-i18next"

import MutliStepPage, { MultiStepHeader } from "components/MultiStepForm"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import Loading from "components/Common/Spinner"
import Fade from "components/Fade"
import { SmallModal } from "components/modal"
import ProductCategory from "./wizard/productCategory"
import ProductInformation from "./wizard/productInformation"
import ProductCategoryType from "./wizard/productType"
import Summary from "./wizard/summary"
import {
  getNextStep,
  getPreviousStep,
  getProgress,
  isLastStep,
} from "components/MultiStepForm/multistepFomr.utils"
import { FORM_STEPS, PRODUCT_FORM } from "./product.const"
import ProductCategories from "models/productCategories"
import Warehouse from "models/warehouses"
import Auth from "models/auth"
import ProductTypes from "models/productTypes"
import Product from "models/product"

import "./style.scss"

const NewProduct = props => {
  //meta title
  document.title = "Create product | hevara DMS Platform"

  const history = useNavigate()

  const [currentStep, setCurrentStep] = useState(FORM_STEPS[0])
  const [progress, setProgress] = useState(0)
  const [steps, setSteps] = useState(FORM_STEPS)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)

  const [categories, setCategories] = useState([])
  const [productTypes, setProductTypes] = useState([])
  const [selectedProductCategory, setSelectedProductCategory] = useState({})
  const [selectedProductType, setSelectedProductType] = useState({})
  const [productInformation, setProductInformation] = useState({})
  const [warehouses, setWarehouses] = useState([])
  const [userProfile, setUserProfile] = useState({})

  const fetchProductCategories = () => {
    ProductCategories.lookUpProductCategories()
      .then(data => {
        setCategories(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchCompanyWarehouses = id => {
    Warehouse.lookUpCompanyWarehouses(id)
      .then(data => {
        setWarehouses([
          {
            status: "Default",
            warehouseName: "Select warehouse",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchProductTypes = () => {
    ProductTypes.lookUpProductTypes()
      .then(data => {
        setProductTypes(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    setUserProfile(JSON.parse(profile))

    const companyId = JSON.parse(profile).companyId

    Promise.all([
      fetchProductCategories(),
      fetchCompanyWarehouses(companyId),
      fetchProductTypes(),
    ])
  }, [])

  useEffect(() => {
    setProgress(getProgress(currentStep, steps))
  }, [currentStep, steps])

  const onChangeProductCategory = value => {
    setSelectedProductCategory(value)
    setCurrentStep(getNextStep(currentStep, FORM_STEPS))
  }

  const onChangeProductType = value => {
    setSelectedProductType(value)
    setCurrentStep(getNextStep(currentStep, FORM_STEPS))
  }

  const onSubmit = () => {
    setProcessing(true)

    const payload = {
      name: productInformation.name,
      size: productInformation.size,
      description: productInformation.description,
      category: selectedProductCategory.id,
      categoryType: selectedProductType.value,
      stockQuantity: productInformation.stockQuantity,
      replenishingLevel: productInformation.replenishingLevel,
      primaryPrice: productInformation.primaryPrice,
      ...(productInformation.primaryDiscount && {
        primaryDiscount: productInformation.primaryDiscount,
      }),
      warehouseId: productInformation.warehouseId,
      companyId: userProfile && userProfile.companyId,
      manufacturerId: userProfile && userProfile.companyId,
    }

    Product.createProduct(payload)
      .then(data => {
        toast.success(`${data.name} created successfully.`)
        // redirect to product details
        history("/products")
      })
      .catch(error => {
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred during product creation"
        toast.error(errorMessage)
      })
      .finally(() => setProcessing(false))
  }

  const goNextStep = () => {
    setCurrentStep(getNextStep(currentStep, steps))
  }

  const goPreviousStep = () => {
    setCurrentStep(getPreviousStep(currentStep, steps))
  }

  const goSpecificStep = step => {
    setCurrentStep(step)
  }

  const onClickCancel = () => {
    if (isLastStep(currentStep, steps)) {
      exit()
    } else {
      setShowCancelModal(true)
    }
  }

  const onClickCloseModal = () => {
    setShowCancelModal(false)
  }

  const exit = () => {
    history("/products")
  }

  return (
    <MutliStepPage>
      <div className="page-content">
        {/* CANCEL MODAL */}
        <SmallModal
          modalIsOpen={showCancelModal}
          onClickClose={onClickCloseModal}
          title={"Are you sure you want to leave?"}
          confirmTxt={"Leave"}
          cancelTxt={"Stay"}
          onClickConfirm={exit}
        >
          <p className="font-size-16 m-0">
            All data will be lost if you leave now.
          </p>
        </SmallModal>

        {/* HEADER */}
        <MultiStepHeader
          progress={progress}
          hideBack={currentStep === PRODUCT_FORM.PRODUCT_CATEGORY}
          onClickBack={goPreviousStep}
          onClickClose={onClickCancel}
          title={`New product`}
        />

        {/* PROCESSING */}
        <LoadingBackdrop
          show={processing}
          description={"We are creating your product, please wait..."}
        />

        {/* Loading */}
        {loading && (
          <div className="d-flex column center-x center-y my-2">
            <Loading />
          </div>
        )}

        <Fade in={!loading}>
          {/* STEP 1 => PRODUCT CATEGORY SELECTION */}
          <Fade in={currentStep === PRODUCT_FORM.PRODUCT_CATEGORY}>
            <ProductCategory
              categories={categories}
              onChangeProductCategory={onChangeProductCategory}
            />
          </Fade>

          {/* STEP 2 => PRODUCT INFORMATION */}
          <Fade in={currentStep === PRODUCT_FORM.PRODUCT_INFORMATION}>
            <ProductInformation
              goNextStep={goNextStep}
              onClickCancel={onClickCancel}
              warehouses={warehouses}
              productInformation={productInformation}
              companyId={userProfile.companyId}
              handleSubmit={values => setProductInformation(values)}
            />
          </Fade>

          {/* STEP 3 => PRODUCT CATEGORY-TYPE SELECTION */}
          <Fade in={currentStep === PRODUCT_FORM.PRODUCT_CATEGORY_TYPE}>
            <ProductCategoryType
              productTypes={productTypes}
              onChangeProductType={onChangeProductType}
            />
          </Fade>

          {/* STEP 4 => SUMMARY */}
          <Fade in={currentStep === PRODUCT_FORM.SUMMARY}>
            <Summary
              selectedProductCategory={selectedProductCategory}
              productInformation={productInformation}
              selectedProductType={selectedProductType}
              warehouses={warehouses}
              handleSubmit={onSubmit}
              onClickCancel={onClickCancel}
              goSpecificStep={goSpecificStep}
            />
          </Fade>
        </Fade>
      </div>

      <ToastContainer />
    </MutliStepPage>
  )
}

NewProduct.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(NewProduct)

import React from "react"
import PropTypes from "prop-types"
import { pick, omit, clone } from "lodash"

import { EmptyState as HevaraEmptyState } from "./EmptyState"
import generalEmptyImage from "assets/images/empty-box.png"
import errorPlaceholderImage from "assets/images/no-connection.png"
import { BUTTON_VARIANT, BUTTON_SIZE } from "components/Button"
import Fade from "components/Fade"
import CONST from "utils/constants"

const { SIZE } = CONST

export const TITLE_SIZE = clone(SIZE)

const defaultPropTypes = {
  illustration: PropTypes.string,
  title: PropTypes.string,
  titleSize: PropTypes.oneOf([...Object.values(TITLE_SIZE)]),
  titleWeight: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  firstButton: PropTypes.object,
  secondButton: PropTypes.object,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  visible: PropTypes.bool,
}

const customStatePropTypes = omit(defaultPropTypes, [
  "illustration",
  "firstButton",
  "secondButton",
])

const stateWithButtonsPropTypes = {
  ...customStatePropTypes,
  ...pick(defaultPropTypes, ["firstButton", "secondButton"]),
}

function EmptyState({
  illustration,
  title,
  titleSize,
  titleWeight,
  description,
  firstButton,
  secondButton,
  className,
  containerClassName,
  visible,
  style,
  children,
}) {
  return (
    <Fade
      in={visible}
      className={visible ? `d-flex column center-y ${containerClassName}` : ""}
    >
      <HevaraEmptyState
        style={style}
        className={className}
        illustration={illustration || generalEmptyImage}
        title={title}
        titleSize={titleSize}
        titleWeight={titleWeight}
        description={description}
        firstButton={firstButton}
        secondButton={secondButton}
      >
        {children}
      </HevaraEmptyState>
    </Fade>
  )
}

function EmptyErrorState(props) {
  const {
    title,
    description,
    firstButton,
    secondButton,
    visible,
    illustration,
    className,
    containerClassName,
    style,
  } = props

  return (
    <EmptyState
      title={title}
      description={description || "There has been an error"}
      illustration={illustration || errorPlaceholderImage}
      firstButton={firstButton}
      secondButton={secondButton}
      className={className}
      containerClassName={containerClassName}
      style={style}
      visible={visible}
    />
  )
}

EmptyErrorState.prototypes = stateWithButtonsPropTypes

EmptyState.defaultPropTypes = {
  visible: false,
  title: "",
  description: "",
  titleSize: SIZE.S,
  titleWeight: "bold",
  illustration: generalEmptyImage,
  firstButton: undefined,
  secondButton: undefined,
  containerClassName: "",
  className: "",
  style: {},
}

EmptyErrorState.defaultPropTypes = {
  ...omit(EmptyState.defaultPropTypes, "illustration"),
}

export { EmptyState as default, EmptyErrorState }

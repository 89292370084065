export const TOOLTIP_POSITIONS = {
  TOP: "top",
  BOTTOM: "bottom",
  RIGHT: "right",
  LEFT: "left",
}

const getTheme = {
  color: {
    tooltip: {
      font: "#FFFFFF",
      background: "#412207",
    },
  },
}

export const getPositionStyles = (backgroundColor, position) => {
  switch (position) {
    case TOOLTIP_POSITIONS.BOTTOM: {
      return `
        top: calc(100% + 8px);
        left: 50%;
        transform: translate(-50%, 0);
        &:after {
          content: "";
          position: absolute;
          bottom: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent ${backgroundColor} transparent;
        }
      `
    }
    case TOOLTIP_POSITIONS.LEFT: {
      return `
        top: 50%;
        right: 100%;
        margin-right: 8px;
        transform: translate(0, -50%);
        &:after {
          content: "";
          position: absolute;
          bottom: calc(50% - 5px);
          right: -10px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent transparent ${backgroundColor}
        }
      `
    }
    case TOOLTIP_POSITIONS.RIGHT: {
      return `
        top: 50%;
        left: 100%;
        margin-left: 8px;
        transform: translate(0, -50%);
        &:after {
          content: "";
          position: absolute;
          bottom: calc(50% - 5px);
          left: -10px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent ${backgroundColor} transparent transparent;
        }
      `
    }
    default: {
      return `
        top: -8px;
        left: 50%;
        transform: translate(-50%, -100%);
        &:after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: ${backgroundColor} transparent transparent transparent;
        }
      `
    }
  }
}

export const getTooltipContentStyles = (theme, position, show) => {
  const backgroundColor = theme.color.tooltip.background
  const positionStyles = getPositionStyles(backgroundColor, position)
  const visibilityStyles = show
    ? `opacity: 1; visibility: visible;`
    : `opacity: 0; visibility: hidden;`

  return `
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 12px;
    z-index: 1;
    max-width: max-content;
    position: absolute;
    box-sizing: border-box;
    background: ${backgroundColor};
    transition: opacity .25s ease-in;
    ${visibilityStyles}
    ${positionStyles}
  `
}

export const getTooltipStyles = props => {
  const theme = getTheme
  const fontColor = theme.color.tooltip.font
  const tooltipContentStyles = getTooltipContentStyles(
    theme,
    props.position,
    props.showTooltipContent
  )

  return `
    display: inline-block;
    .hevara-tooltip-wrapper {
      position: relative;
      display: flex;
      align-items: center;
    }
    .hevara-tooltip-content {
      ${tooltipContentStyles}
    }
    .hevara-tooltip-text, .hevara-tooltip-title {
      color: ${fontColor}
    }
  `
}

import CONST from "utils/constants"

const { MEDIA } = CONST

const getButtonStyles = props => {
  if (props.hasBothButtons) {
    return `
      margin-right: 8px;
      ${MEDIA.MOBILE} {
        width: 100%;
        margin-right: 0;
        margin-bottom: 8px;
      }
    `
  }

  return `
    ${MEDIA.MOBILE} {
      width: 100%;
    }
  `
}

export const getButtonWrapperStyles = props => {
  return `
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    ${MEDIA.MOBILE} {
      flex-direction: column;
      width: 100%;
    }
    .hevara-empty-state-first-button .hevara-button {
      ${getButtonStyles(props)}
    }
  `
}

export const getIllustrationStyles = () => {
  return `
    object-fit: cover;
    width: 170px;
    height: 170px;
    margin-bottom: 24px;
  `
}

export const getEmptyStateStyles = () => {
  return `
    width: 100%;
    max-width: 558px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `
}

import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { omit, isEmpty } from "lodash"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import { withTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { Card, CardBody, Container, Row, Col } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import DataItem from "components/DataItem"
import Loading from "components/Common/Spinner"
import { EmptyErrorState } from "components/EmptyState"
import Auth from "models/auth"
import Upload from "models/upload"
import ProductCategories from "models/productCategories"
import ProductTypes from "models/productTypes"
import Warehouse from "models/warehouses"
import Catalogue from "models/productCatalogue"
import Company from "models/company"
import Manufacturers from "models/manufacturers"
import Distributors from "models/distributors"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { getStatus } from "utils/utils"
import { BUTTON_VARIANT } from "components/Button"
import PageHeader, { PageHeaderInfo, PageHeaderInfoText } from "components/PageHeader"
import FeedbackNotification, { FEEDBACK_VARIANTS } from "components/FeedbackNotification"
import { HeaderActions } from "components/PageHeader/HeaderActions"
import Link from "components/Link"
import Avatar from "components/Avatar/Avatar"
import ImageModal from "./imageModal"
import EditProductCatalogueModal from "./editProductCatalogueModal"
import CONST from "utils/constants"
import { BodyText, BodyTextBold } from "components/atoms"

const { SIZE, COMPANY_TYPE } = CONST

import "./style.scss"

const ProductCatalogueDetails = props => {
  //meta title
  document.title = "Product catalogue details | hevara DMS Platform"

  const { productCatalogueId } = useParams()

  const [modal, setModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [catalogueDetails, setCatalogueDetails] = useState({})
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const [productImageInfo, setProductImageInfo] = useState({})

  const [categories, setCategories] = useState([])
  const [productTypes, setProductTypes] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [companyInfo, setCompanyInfo] = useState({})
  const [manufacturers, setManufacturers] = useState([])
  const [distributors, setDistributors] = useState([])

  const fetchProductCatalogueDetails = id => {
    Catalogue.getProductCatalogue(id)
      .then(data => {
        setCatalogueDetails(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchProductCategories = () => {
    ProductCategories.lookUpProductCategories()
      .then(data => {
        setCategories([
          {
            status: "Default",
            name: "Select category",
            value: "",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchProductTypes = () => {
    ProductTypes.lookUpProductTypes()
      .then(data => {
        setProductTypes([
          {
            status: "Default",
            label: "Select type",
            value: "",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanyWarehouses = id => {
    Warehouse.lookUpCompanyWarehouses(id)
      .then(data => {
        setWarehouses([
          {
            status: "Default",
            warehouseName: "Select warehouse",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanyInfo = id => {
    Company.getCompany(id)
      .then(data => {
        setCompanyInfo(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchManufacturers = () => {
    Manufacturers.lookUpManufacturers()
      .then(data => {
        setManufacturers([
          {
            status: "Default",
            companyName: "Select manufacturer",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchDistributors = () => {
    Distributors.lookUpDistributors()
      .then(data => {
        setDistributors([
          {
            status: "Default",
            companyName: "Select distributor",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const onClickUploadImage = file => {
    toggle()

    setProcessing(true)

    const formData = new FormData()
    formData.append("file", file[0])
    formData.append("fileType", "PRODUCT_IMAGE")
    formData.append("productId", catalogueDetails.productId)
    formData.append("productCatalogueId", catalogueDetails.id)

    Upload.addUpload(formData)
      .then(data => {
        setProductImageInfo(data)
        toast.success(`Product image added successfully.`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const editProductCatalogueDetails = values => {
    setProcessing(true)

    const payload = { ...values, productId: catalogueDetails.productId }

    Catalogue.updateProductCatalogue(catalogueDetails.id, payload)
      .then(res => {
        const catalogue = res.tradingProduct
        setCatalogueDetails(catalogue)
        toast.success(`${catalogue.name} has been updated.`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const disableProductCatalogue = () => {
    setProcessing(true)

    const payload = {
      ...omit(catalogueDetails, ["id", "dateCreated"]),
      status: "Disabled",
    }

    Catalogue.updateProductCatalogue(catalogueDetails.id, payload)
      .then(res => {
        const catalogue = res.tradingProduct
        setCatalogueDetails(catalogue)
        toast.success(`${catalogue.name} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const enableProductCatalogue = () => {
    setProcessing(true)

    const payload = {
      ...omit(catalogueDetails, ["id", "dateCreated"]),
      status: "Active",
    }

    Catalogue.updateProductCatalogue(catalogueDetails.id, payload)
      .then(res => {
        const catalogue = res.tradingProduct
        setCatalogueDetails(catalogue)
        toast.success(`${catalogue.name} has been disabled`)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    if (productCatalogueId && profile) {
      const companyId = JSON.parse(profile).companyId

      Promise.all([
        fetchProductCatalogueDetails(productCatalogueId),
        fetchProductCategories(),
        fetchProductTypes(),
        fetchCompanyWarehouses(companyId),
        fetchCompanyInfo(companyId),
        fetchManufacturers(),
        fetchDistributors(),
      ])
      setUserProfile(JSON.parse(profile))
    } else {
      setError(true)
      toast.error("Something went wrong. Please try again later")
    }
  }, [productCatalogueId, productImageInfo])

  const getHeaderActions = () => {
    const isActive = catalogueDetails.status === "Active"

    const actions = [
      userProfile.permission.CAN_MANAGE_PRODUCTS && {
        label: "Edit",
        icon: "mdi mdi-pencil",
        onClick: () => {
          onClickEdit()
        },
        variant: BUTTON_VARIANT.PRIMARY,
        showInModal: true,
      },
      userProfile.permission.CAN_MANAGE_PRODUCTS && {
        label: isActive ? "Disable" : "Enable",
        icon: isActive ? "mdi mdi-trash-can" : "mdi mdi-bookmark-check-outline",
        onClick: () =>
          isActive ? disableProductCatalogue() : enableProductCatalogue(),
        variant: isActive ? BUTTON_VARIANT.CRITICAL : BUTTON_VARIANT.SECONDARY,
      },
    ].filter(Boolean)

    if (actions.length > 0) {
      actions[0].showInModal = false
    }

    return actions
  }

  const onClickEdit = () => {
    if (editModal) {
      setEditModal(false)
    } else {
      setEditModal(true)
    }
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  const showPriceBannerForWholesaler = companyInfo.type === COMPANY_TYPE.WHOLESALLER && !catalogueDetails.retailPrice
  const showPriceBannerForDistributor = companyInfo.type === COMPANY_TYPE.DISTRIBUTOR && !catalogueDetails.secondaryPrice

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Product catalogue details")}
            breadcrumbItem={props.t("Product catalogue details")}
          />

          {/* Loading */}
          {!error && loading && <Loading />}

          {/* PROCESSING */}
          <LoadingBackdrop
            show={processing}
            title={"Product catalogue"}
            description={"We are processing your request, please wait..."}
          />

          {/* IMAGE MODAL */}
          <ImageModal
            modalIsOpen={modal}
            onClickClose={toggle}
            onClickUploadImage={onClickUploadImage}
          />

          {/* EDIT PRODUCT INFORMATION MODAL */}
          <EditProductCatalogueModal
            modalIsOpen={editModal}
            onClickClose={onClickEdit}
            catalogue={catalogueDetails}
            companyId={userProfile.companyId}
            distributors={distributors}
            manufacturers={manufacturers}
            warehouses={warehouses}
            companyType={companyInfo.type}
            categories={categories}
            productTypes={productTypes}
            onClickEdit={onClickEdit}
            handleSubmit={values => editProductCatalogueDetails(values)}
          />

          {/* DETAILS */}
          {!loading && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* PAGE HEADER */}
                    <PageHeader
                      avatar={
                        catalogueDetails && {
                          initials: catalogueDetails.name,
                          image: catalogueDetails.image?.file,
                        }
                      }
                      title={`${catalogueDetails.name} (${catalogueDetails.size} ml)`}
                      showBackButton={true}
                      showDivider={true}
                      info={
                        <PageHeaderInfo>
                          <PageHeaderInfoText>
                            {getStatus(catalogueDetails.status)}
                          </PageHeaderInfoText>

                          <PageHeaderInfoText className="fw-semibold">
                            <p className="mb-0 fw-bold text-tertiary">
                              {catalogueDetails.code}
                            </p>
                          </PageHeaderInfoText>

                          <PageHeaderInfoText className="fw-semibold">
                            <p className="mb-0 fw-bold text-primary">
                              {catalogueDetails.categoryName} -{" "}
                              {catalogueDetails.categoryType}
                            </p>
                          </PageHeaderInfoText>
                        </PageHeaderInfo>
                      }
                      actions={<HeaderActions actions={getHeaderActions()} />}
                    />

                    {/* DETAILS SECTIONS */}
                    <div className="hevara-product-detail">
                      <div className="tx-grid mb-3">
                        {/* PRODUCT INFO */}
                        <div>
                          {/* BANNER FOR PRODUCT IMAGE */}
                          {!catalogueDetails.image && (
                            <FeedbackNotification
                              title={`Product image`}
                              message={`Upload product catalogue image by clicking on the link below. Your customers will be able to see this image when they view your product catalogue.`}
                              bottomActions={
                                <Link onClick={toggle}>Add photo</Link>
                              }
                              className="mb-4"
                            />
                          )}

                          {/* BANNER FOR DISTRIBUTOR */}
                          {showPriceBannerForDistributor && (
                            <FeedbackNotification
                              variant={FEEDBACK_VARIANTS.WARNING}
                              title={`Product price`}
                              message={`Product price is missing. Please update product information to include its price.`}
                              className="mb-4"
                            />
                          )}

                          {/* BANNER FOR WHOLESALER */}
                          {showPriceBannerForWholesaler && (
                            <FeedbackNotification
                              variant={FEEDBACK_VARIANTS.WARNING}
                              title={`Product price`}
                              message={`Product price is missing. Please update product information to include its price.`}
                              className="mb-4"
                            />
                          )}

                          {/* PRODUCT DESCRIPTION */}
                          <BodyTextBold className="mb-2 text-muted">Description</BodyTextBold>
                          <BodyText className="mb-4">
                            {catalogueDetails.description}
                          </BodyText>

                          {/* PRODUCT DETAILS */}
                          <BodyTextBold className="mb-2 text-muted">
                            Product details
                          </BodyTextBold>
                          <div className="detail-grid mb-5">
                            <DataItem
                              label={props.t("Name")}
                              value={`${catalogueDetails.name} (${catalogueDetails.size} ml)`}
                            />

                            <DataItem
                              label={props.t("Category")}
                              value={catalogueDetails.categoryName}
                            />

                            <DataItem
                              label={props.t("Type")}
                              value={catalogueDetails.categoryType}
                            />

                            {catalogueDetails.warehouseName && (
                              <DataItem
                                label={props.t("Warehouse")}
                                value={catalogueDetails.warehouseName}
                              />
                            )}
                          </div>

                          {/* PRODUCT PRICING */}
                          <BodyTextBold className="mb-2 text-muted">
                            Product pricing
                          </BodyTextBold>
                          <div className="detail-grid mb-5">
                            {/* DISTRIBUTOR PRICING */}
                            {companyInfo.type === COMPANY_TYPE.DISTRIBUTOR && (
                              <>
                                <DataItem
                                  label={props.t("Price")}
                                  value={`Ksh. ${
                                    catalogueDetails.secondaryPrice || 0
                                  }`}
                                />
                                {!!catalogueDetails.secondaryDiscount && (
                                  <DataItem
                                    label={props.t("Discount")}
                                    value={`${catalogueDetails.secondaryDiscount}% Off`}
                                  />
                                )}
                              </>
                            )}

                            {/* WHOLESALER PRICING */}
                            {companyInfo.type === COMPANY_TYPE.WHOLESALLER && (
                              <>
                                <DataItem
                                  label={props.t("Price")}
                                  value={`Ksh. ${
                                    catalogueDetails.retailPrice || 0
                                  }`}
                                />
                                {!!catalogueDetails.retailDiscount && (
                                  <DataItem
                                    label={props.t("Discount")}
                                    value={`${catalogueDetails.retailDiscount}% Off`}
                                  />
                                )}
                              </>
                            )}
                          </div>

                          {/* Other details */}
                          <BodyTextBold className="mb-2 text-muted">
                            Other details
                          </BodyTextBold>
                          <div className="detail-grid">
                            <DataItem
                              label={props.t("Stock quantity")}
                              value={`${catalogueDetails.stockQuantity || "-"}`}
                            />

                            <DataItem
                              label={props.t("Replenishing level")}
                              value={`${
                                catalogueDetails.replenishingLevel || "-"
                              }`}
                            />

                            <DataItem
                              label={props.t("Manufacturer")}
                              value={catalogueDetails.manufacturerName}
                            />
                          </div>
                        </div>

                        {/* PRODUCT IMAGE */}
                        <div className="mb-3 tx-avatar">
                          <div className="container-box">
                            <Avatar
                              size={SIZE.XXL}
                              initials={catalogueDetails.name}
                              image={catalogueDetails.image?.file}
                              className="avatar-size"
                            />
                          </div>

                          {isEmpty(catalogueDetails.image) && (
                            <Link
                              icon="mdi mdi-camera"
                              className="d-flex justify-content-center mt-1"
                              onClick={catalogueDetails.image?.file
                                ? () => console.log("Edit image, WIP")
                                : () => toggle()
                              }
                            >
                              {catalogueDetails.image?.file ? `Edit photo` : `Add photo`}
                            </Link>
                          )}

                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                      {/* ERROR STATE */}
                      <EmptyErrorState
                        visible={error}
                        title={`Something went wrong`}
                        description={`An error has occurred. Please try again later or contact hevara for support`}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      <ToastContainer />
    </React.Fragment>
  )
}

ProductCatalogueDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ProductCatalogueDetails)

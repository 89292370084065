import React, { useCallback } from "react"
import { filter } from "lodash"

import {
  ContextualModal,
  ContextualModalListItem,
  CONTEXTUAL_MODAL_LIST_ITEM_VARIANT,
  CONTEXTUAL_MODAL_POSITIONS,
} from "components/ContextualModalBox"
import {
  PrimaryButton,
  SecondaryButton,
  AccentButton,
  CriticalButton,
  SecondaryIconButton,
  BUTTON_VARIANT,
} from "components/Button"

import "./style.styl"
import { PageHeaderActions } from "."

const getActionButton = ({
  onClick,
  disabled,
  label,
  icon,
  variant,
  showInModal,
}) => {
  const className = showInModal ? "desktop-button" : ""

  switch (variant) {
    case BUTTON_VARIANT.ACCENT:
      return (
        <AccentButton
          key={label}
          onClick={onClick}
          disabled={disabled}
          leftIcon={icon}
          className={className}
        >
          {label}
        </AccentButton>
      )
    case BUTTON_VARIANT.CRITICAL:
      return (
        <CriticalButton
          key={label}
          onClick={onClick}
          disabled={disabled}
          leftIcon={icon}
          className={className}
        >
          {label}
        </CriticalButton>
      )
    case BUTTON_VARIANT.PRIMARY:
      return (
        <PrimaryButton
          key={label}
          onClick={onClick}
          disabled={disabled}
          leftIcon={icon}
          className={className}
        >
          {label}
        </PrimaryButton>
      )
    case BUTTON_VARIANT.SECONDARY:
    default:
      return (
        <SecondaryButton
          key={label}
          onClick={onClick}
          disabled={disabled}
          leftIcon={icon}
          className={className}
        >
          {label}
        </SecondaryButton>
      )
  }
}

const ActionWrapper = ({
  enableUploadFile = false,
  onClick,
  disabled,
  showInModal,
  children,
}) => {
  const className = showInModal ? "desktop-button" : ""

  // ADD INPUTFILE HERE
  return enableUploadFile ? "" : <>{children}</>
}

export const HeaderActions = ({ actions }) => {
  const getButtons = useCallback(() => {
    const buttons = actions.slice(0, 2)
    const modalItems = actions.slice(2, actions.length)
    const modalMobileButtons = filter(buttons, button => !!button.showInModal)
    const modalButtons = [...modalMobileButtons, ...modalItems]

    return { buttons, modalButtons, modalItems }
  }, [actions])

  const { buttons, modalButtons, modalItems } = getButtons()

  return (
    <PageHeaderActions className="hevara-header-actions-component">
      {/* BUTTONS */}
      {buttons.map((button, index) => (
        <ActionWrapper key={`header-action-button-${index}`} {...button}>
          {getActionButton(button)}
        </ActionWrapper>
      ))}

      {/* CONTEXTUAL MODAL ACTIONS */}
      {modalButtons.length > 0 && (
        <ContextualModal
          position={CONTEXTUAL_MODAL_POSITIONS.LEFT}
          buttonElement={
            <SecondaryIconButton
              name="mdi mdi-dots-vertical"
              className={modalItems.length === 0 ? "mobile-button" : ""}
            />
          }
          contentElement={modalButtons.map((button, index) => {
            const { label, onClick, disabled, variant, showInModal } = button

            const className = showInModal ? "mobile-button" : ""

            const listItemVariant =
              variant === BUTTON_VARIANT.CRITICAL
                ? CONTEXTUAL_MODAL_LIST_ITEM_VARIANT.CRITICAL
                : CONTEXTUAL_MODAL_LIST_ITEM_VARIANT.DEFAULT

            const key = `header-action-modal-item-${index}`

            return (
              <ActionWrapper key={key} {...button}>
                <ContextualModalListItem
                  label={label}
                  onClick={onClick}
                  variant={listItemVariant}
                  disabled={disabled}
                  className={className}
                />
              </ActionWrapper>
            )
          })}
        />
      )}
    </PageHeaderActions>
  )
}

HeaderActions.defaultProps = {
  actions: [],
}

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Link, useLocation, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { toast } from "react-toastify"
import { ToastContainer } from "react-toastify"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

import Auth from "models/auth"
import OtpInput from "components/OtpInput"
import Footer from "components/Footer"
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
import { PrimaryButton } from "components/Button"
import { EmptyErrorState } from "components/EmptyState"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import { BodyTextBold, BodyText } from "components/atoms"
import constants from "components/atoms/constants"
import { SmallModal } from "components/modal"

import EmailPlaceholder from "assets/images/emailPlaceholder.png"
import "./style.scss"

const { FONT: { VARIANT } } = constants;

const AccountSetup = props => {
  //meta title
  document.title = "Account Setup | hevara DMS Platform"

  const [modal, setModal] = useState(false)
  const [show, setShow] = useState(false)
  const [show2, setShow2] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [otp, setOtp] = useState("")

  const [expired, setExpired] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [requestedLink, setRequestedLink] = useState(false)
  const [tokenData, setTokenData] = useState({})

  // Read token value from url
  const history = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get("token")
  const userId = searchParams.get("id")

  const checkActivationToken = () => {
    Auth.checkOnboardingToken(token)
      .then(data => {
        setTokenData(data)
      })
      .catch(error => {
        if (error.response?.data?.code === 401) {
          setExpired(true)
        }
      })
      .finally(() => setLoading(false))
  }

  const requestActivationLink = () => {
    setProcessing(true)

    Auth.resendOnboardingEmail(userId)
      .then(data => {
        if (data) {
          setRequestedLink(true)
        } else {
          setError(true)
        }
      })
      .catch(error => {
        setError(true)
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    if (token) {
      checkActivationToken()
    } else {
      setError(true)
    }
  }, [token])

  //form validation: For passwords
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      password2: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Please enter your password")
        .matches(
          /^(?=.*\d)(?=.*[a-zA-Z]).+$/,
          "Password must contain at least one letter and one digit"
        ),
      password2: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Please re-enter your password"),
    }),
    onSubmit: values => {
      // Perform additional validation just to be sure => too much OCD, hehehehe, again
      if (values.password !== values.password2) {
        validation.setFieldError("password2", "Passwords must match")
      } else {
        setProcessing(true)
        // Request OTP Code here
        Auth.requestOTP(userId)
          .then(() => {
            setModal(true)
          })
          .catch(error => {
            toast.error(error.response?.data?.message)
          })
          .finally(() => setProcessing(false))
      }
    },
  })

  const handleOtpSubmit = otp => {
    setOtp(otp)
  }

  const accountSetUp = () => {
    setProcessing(true)
    toggle()

    const payload = {
      userId: userId || "",
      token: token || "",
      password: validation.values.password || "",
      otpCode: otp,
    }
    Auth.accountSetup(payload)
      .then(() => {
        history("/dashboard")
        validation.resetForm()
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setProcessing(false))
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  return (
    <div className="d-flex justify-content-center align-items-center hevara-activate">
      <LoadingBackdrop
        show={loading || processing}
        description={
          loading
            ? `We are processing your request, it will take just a moment to retrieve your information.`
            : `We are processing your request, it might take a moment, please wait...`
        }
      />

      {!loading && (
        <>
          {/* OTP MODAL */}
          <SmallModal
            modalIsOpen={modal}
            onClickClose={toggle}
            hideButtons={true}
            title={`Verification`}
          >
            <BodyText className="mb-5">
              Please enter the 4 digit code sent to your email address or SMS text received. The code will expire in 10 minutes.
            </BodyText>

            <OtpInput onSubmit={handleOtpSubmit} />

            <div className="d-flex justify-content-center align-items-center">
              <PrimaryButton className="mt-4" onClick={accountSetUp}>
                Confirm
              </PrimaryButton>
            </div>
          </SmallModal>

          {/* ERROR: EXPIRED TOKEN(EMAIL LINK) */}
          <EmptyErrorState
            className="empty-box"
            visible={!error && expired && !requestedLink}
            title={`Link expired`}
            description={`The link you are trying to use to access your account has expired. To get a new registration link, click on the button below to request a new one. A new link will be sent to your email address you registered with previously.`}
            firstButton={{
              label: `Request new link`,
              onClick: requestActivationLink,
              disabled: processing,
            }}
          />

          {/* ERROR: NEW EMAIL LINK NOTIFICATION SENT */}
          <EmptyErrorState
            className="empty-box"
            visible={!error && expired && requestedLink}
            title={`Request sent`}
            illustration={EmailPlaceholder}
            description={`A new link has been sent to your email address. Please check your inbox of spam folder.`}
          />

          {/* ERROR */}
          <EmptyErrorState
            className="empty-box"
            visible={error}
            title={`System error`}
            description={`Ooopsy! Sorry about that, it happens! Please try again later or contact hevara for support.`}
          />

          {/* FORM: */}
          {!error && !expired && !!token && !!userId && (
            <div className="account-pages">
              <div className="bg-overlay"></div>

              <Container>
                <Row className="justify-content-center">
                  <Col md={8} lg={6} xl={5}>
                    <Card className="overflow-hidden">
                      <div className="bg-primary-subtle">
                        <Row>
                          <Col xs={7}>
                            <div className="text-dark p-4">
                              <BodyTextBold variant={VARIANT.HEADING} className="text-tertiary">Account setup</BodyTextBold>
                              <BodyText className="text-muted">Set up your account credentials</BodyText>
                            </div>
                          </Col>
                          <Col className="col-5 align-self-end">
                            <img src={profile} alt="" className="img-fluid" />
                          </Col>
                        </Row>
                      </div>
                      <CardBody className="pt-0">
                        <div>
                          <Link to="/">
                            <div className="avatar-md profile-user-wid mb-4">
                              <span className="avatar-title rounded-circle bg-light">
                                <img
                                  src={logo}
                                  alt=""
                                  className="rounded-circle"
                                  height="34"
                                />
                              </span>
                            </div>
                          </Link>
                        </div>
                        <div className="p-2">
                          <Form
                            className="form-horizontal"
                            onSubmit={e => {
                              e.preventDefault()
                              validation.handleSubmit()
                              return false
                            }}
                          >
                            <div className="mb-3">
                              <Label className="form-label">Password</Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Input
                                  name="password"
                                  value={validation.values.password || ""}
                                  type={show ? "text" : "password"}
                                  placeholder="Enter Password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.password &&
                                    validation.errors.password
                                      ? true
                                      : false
                                  }
                                />

                                <button
                                  onClick={() => setShow(!show)}
                                  className="btn-light border-0"
                                  type="button"
                                  id="password-addon"
                                >
                                  <i className="mdi mdi-eye-outline"></i>
                                </button>
                              </div>
                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">
                                Repeat password
                              </Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Input
                                  name="password2"
                                  value={validation.values.password2 || ""}
                                  type={show2 ? "text" : "password"}
                                  placeholder="Repeat password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.password2 &&
                                    validation.errors.password2
                                      ? true
                                      : false
                                  }
                                />

                                <button
                                  onClick={() => setShow2(!show2)}
                                  className="btn-light border-0"
                                  type="button"
                                  id="password-addon"
                                >
                                  <i className="mdi mdi-eye-outline"></i>
                                </button>
                              </div>
                              {validation.touched.password2 &&
                              validation.errors.password2 ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password2}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="d-flex flex-column align-items-center">
                              <PrimaryButton type="submit" className="w-lg">
                                {loading ? "Processing..." : "Confirm"}
                              </PrimaryButton>
                            </div>
                          </Form>
                        </div>

                        <div className="mt-5 text-center">
                          <Footer />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </>
      )}

      <ToastContainer />
    </div>
  )
}

AccountSetup.propTypes = {
  history: PropTypes.object,
}

export default withRouter(AccountSetup)

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux";
import { compose } from "redux"

import MutliStepPage, { MultiStepHeader } from "components/MultiStepForm"
import LoadingBackdrop from "components/LoadingBackdrop/LoadingBackdrop"
import Loading from "components/Common/Spinner"
import Fade from "components/Fade"
import { SmallModal } from "components/modal"
import ProductCatalogueCategory from "./wizard/productCatalogueCategory"
import ProductCatalogueInformation from "./wizard/productCatalogueInformation"
import ProductCatalogueProductType from "./wizard/productCatalogueProductType"
import Summary from "./wizard/summary"
import { getNextStep, getPreviousStep, getProgress, isLastStep } from "components/MultiStepForm/multistepFomr.utils"
import { FORM_STEPS, CATALOGUE_FORM } from "./productCatalogue.const"
import ProductCategories from "models/productCategories"
import Warehouse from "models/warehouses"
import Auth from "models/auth"
import ProductTypes from "models/productTypes"
import ProductCatalogue from "models/productCatalogue"
import Company from "models/company"
import Manufacturers from "models/manufacturers"
import Distributors from "models/distributors"
import { changeSidebarType } from "store/actions"
import { leftSidebarTypes } from "constants/layout"

import "./style.scss"
import { BodyText } from "components/atoms"

const NewProductCatalogue = props => {
  //meta title
  document.title = "Create product | hevara DMS Platform"

  const history = useNavigate()

  const [currentStep, setCurrentStep] = useState(FORM_STEPS[0])
  const [progress, setProgress] = useState(0)
  const [steps, setSteps] = useState(FORM_STEPS)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)

  const [categories, setCategories] = useState([])
  const [productTypes, setProductTypes] = useState([])
  const [selectedCatalogueCategory, setSelectedCatalogueCategory] = useState({})
  const [selectedProductType, setSelectedProductType] = useState({})
  const [catalogueInformation, setCatalogueInformation] = useState({})
  const [warehouses, setWarehouses] = useState([])
  const [userProfile, setUserProfile] = useState({})
  const [companyInfo, setCompanyInfo] = useState({})
  const [manufacturers, setManufacturers] = useState([])
  const [distributors, setDistributors] = useState([])

  const fetchProductCategories = () => {
    ProductCategories.lookUpProductCategories()
      .then(data => {
        setCategories(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
      .finally(() => setLoading(false))
  }

  const fetchCompanyWarehouses = id => {
    Warehouse.lookUpCompanyWarehouses(id)
      .then(data => {
        setWarehouses([
          {
            status: "Default",
            warehouseName: "Select warehouse",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchProductTypes = () => {
    ProductTypes.lookUpProductTypes()
      .then(data => {
        setProductTypes(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchCompanyInfo = id => {
    Company.getCompany(id)
      .then(data => {
        setCompanyInfo(data)
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchManufacturers = () => {
    Manufacturers.lookUpManufacturers()
      .then(data => {
        setManufacturers([
          {
            status: "Default",
            companyName: "Select manufacturer",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  const fetchDistributors = () => {
    Distributors.lookUpDistributors()
      .then(data => {
        setDistributors([
          {
            status: "Default",
            companyName: "Select distributor",
            dateCreated: "2023-07-03T00:00:00Z",
            id: "default_id",
          },
          ...data,
        ])
      })
      .catch(error => {
        toast.error(error.response?.data?.message)
      })
  }

  useEffect(() => {
    const profile = Auth.getLoggedInUserData()
    setUserProfile(JSON.parse(profile))

    const companyId = JSON.parse(profile).companyId

    Promise.all([
      fetchProductCategories(),
      fetchCompanyWarehouses(companyId),
      fetchProductTypes(),
      fetchCompanyInfo(companyId),
      fetchManufacturers(),
      fetchDistributors(),
    ])
  }, [])

  useEffect(() => {
    setProgress(getProgress(currentStep, steps))
  }, [currentStep, steps])

  const onChangeCatalogueCategory = value => {
    setSelectedCatalogueCategory(value)
    setCurrentStep(getNextStep(currentStep, FORM_STEPS))
  }

  const onChangeProductType = value => {
    setSelectedProductType(value)
    setCurrentStep(getNextStep(currentStep, FORM_STEPS))
  }

  const onSubmit = () => {
    setProcessing(true)

    const payload = {
      name: catalogueInformation.name,
      size: catalogueInformation.size,
      description: catalogueInformation.description,
      category: selectedCatalogueCategory.id,
      categoryType: selectedProductType.value,
      stockQuantity: catalogueInformation.stockQuantity,
      replenishingLevel: catalogueInformation.replenishingLevel,
      onRequest: catalogueInformation.onRequest,
      ...(catalogueInformation.secondaryPrice && {
        secondaryPrice: catalogueInformation.secondaryPrice,
      }),
      ...(catalogueInformation.secondaryDiscount && {
        secondaryDiscount: catalogueInformation.secondaryDiscount,
      }),
      ...(catalogueInformation.retailPrice && {
        retailPrice: catalogueInformation.retailPrice,
      }),
      ...(catalogueInformation.retailDiscount && {
        retailDiscount: catalogueInformation.retailDiscount,
      }),
      ...(catalogueInformation.distributorId && {
        distributorId: catalogueInformation.distributorId,
      }),
      ...(catalogueInformation.wholesalerId && {
        wholesalerId: catalogueInformation.wholesalerId,
      }),
      warehouseId: catalogueInformation.warehouseId,
      companyId: catalogueInformation.companyId,
      manufacturerId: catalogueInformation.manufacturerId,
    }

    ProductCatalogue.createProductCatalogue(payload)
      .then(res => {
        const name = res.catalogueProduct.name
        toast.success(`${name} created successfully.`)
        exit()
      })
      .catch(error => {
        const errorMessage = error.response?.data?.message || "An error occurred during product creation."
        toast.error(errorMessage)
      })
      .finally(() => setProcessing(false))
  }

  const goNextStep = () => {
    setCurrentStep(getNextStep(currentStep, steps))
  }

  const goPreviousStep = () => {
    setCurrentStep(getPreviousStep(currentStep, steps))
  }

  const goSpecificStep = step => {
    setCurrentStep(step)
  }

  const onClickCancel = () => {
    if (isLastStep(currentStep, steps)) {
      exit()
    } else {
      setShowCancelModal(true)
    }
  }

  const onClickCloseModal = () => {
    setShowCancelModal(false)
  }

  const exit = () => {
    props.changeSidebarType(leftSidebarTypes.DEFAULT);
    history("/product-catalogue")
  }

  return (
    <MutliStepPage>
      {/* CANCEL MODAL */}
      <SmallModal
        modalIsOpen={showCancelModal}
        onClickClose={onClickCloseModal}
        title={"Are you sure you want to leave?"}
        confirmTxt={"Leave"}
        cancelTxt={"Stay"}
        onClickConfirm={exit}
      >
        <BodyText>All data will be lost if you leave now.</BodyText>
      </SmallModal>

      {/* HEADER */}
      <MultiStepHeader
        progress={progress}
        hideBack={currentStep === CATALOGUE_FORM.CATALOGUE_CATEGORY}
        onClickBack={goPreviousStep}
        onClickClose={onClickCancel}
        title={`New product catalogue`}
      />

      {/* PROCESSING */}
      <LoadingBackdrop
        show={processing}
        description={"We are creating your product catalogue. This might take a moment, please wait..."}
      />

      {/* Loading */}
      {loading && (
        <div className="d-flex column center-x center-y my-2">
          <Loading />
        </div>
      )}

      <Fade in={!loading}>
        {/* STEP 1 => PRODUCT CATALOGUE CATEGORY SELECTION */}
        <Fade in={currentStep === CATALOGUE_FORM.CATALOGUE_CATEGORY}>
          <ProductCatalogueCategory
            categories={categories}
            onChangeCatalogueCategory={onChangeCatalogueCategory}
          />
        </Fade>

        {/* STEP 2 => PRODUCT CATALOGUE INFORMATION */}
        <Fade in={currentStep === CATALOGUE_FORM.CATALOGUE_INFORMATION}>
          <ProductCatalogueInformation
            goNextStep={goNextStep}
            onClickCancel={onClickCancel}
            warehouses={warehouses}
            catalogueInformation={catalogueInformation}
            companyId={userProfile.companyId}
            companyType={companyInfo.type}
            manufacturers={manufacturers}
            distributors={distributors}
            handleSubmit={values => setCatalogueInformation(values)}
          />
        </Fade>

        {/* STEP 3 => PRODUCT CATALOGUE PRODUCT-TYPE SELECTION */}
        <Fade in={currentStep === CATALOGUE_FORM.CATALOGUE_CATEGORY_TYPE}>
          <ProductCatalogueProductType
            productTypes={productTypes}
            onChangeProductType={onChangeProductType}
          />
        </Fade>

        {/* STEP 4 => SUMMARY */}
        <Fade in={currentStep === CATALOGUE_FORM.SUMMARY}>
          <Summary
            selectedCatalogueCategory={selectedCatalogueCategory}
            catalogueInformation={catalogueInformation}
            selectedProductType={selectedProductType}
            companyType={companyInfo.type}
            warehouses={warehouses}
            manufacturers={manufacturers}
            distributors={distributors}
            handleSubmit={onSubmit}
            onClickCancel={onClickCancel}
            goSpecificStep={goSpecificStep}
          />
        </Fade>
      </Fade>
    </MutliStepPage>
  )
}

NewProductCatalogue.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = state => {
  return { ...state.Layout };
};

const mapDispatchToProps = {
  changeSidebarType,
  // Any other actions to map here
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(NewProductCatalogue);

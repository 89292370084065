import React from "react"
import {
  isString,
  isEmpty,
  isPlainObject,
  template,
  replace,
  pickBy,
  chain,
  keys,
  deburr,
  toUpper,
  map,
} from "lodash"

import Chip, { CHIP_VARIANTS } from "components/Chip"
import CONST from "./constants"

const {
  COMPANY_TYPE,
  ROLES,
  ORDER_STATUS,
  PAYMENT_STATUS,
  ORDER_TYPES,
  CUSTOMER_TYPES,
  WALLET_STATUS,
  CATEGORY_TYPE,
  TRANSACTION_STATUS,
  BILLING_PLANS
} = CONST

export function buildUrl(url, data) {
  if (
    isString(url) &&
    !isEmpty(url.trim()) &&
    isPlainObject(data) &&
    !isEmpty(data)
  ) {
    const taggedURL = url.replace(/(:)([a-z_]+)/gi, "${data.$2}")
    const templateFn = template(taggedURL)
    return templateFn({ data })
  }

  throw new Error(
    `Trying to call "buildUrl" with invalid params: (${url}, ${data})`
  )
}

export function queryParams(url, params) {
  const parameters = pickBy(params, value => !!value)
  if (isString(url) && !isEmpty(url.trim()) && isPlainObject(parameters)) {
    if (isEmpty(parameters)) {
      return url
    }
    const query = chain(keys(parameters))
      .map(p => `${p}=${parameters[p]}`)
      .join("&")

    return `${url}?${query}`
  }

  throw new Error(
    `Trying to call "buildUrl" with invalid params: (${url}, ${parameters})`
  )
}

export const formatAmount = (amount = 0, locale = 'en-KE', currency = '', numberOfDecimals = 2) => {
  return `${amount?.toLocaleString(locale, { maximumFractionDigits: numberOfDecimals, minimumFractionDigits: numberOfDecimals })} ${currency}`;
}

export const getStatus = status => {
  switch (status) {
    case "Active":
      return <Chip variant={CHIP_VARIANTS.SUCCESS}>Enabled</Chip>
    case "Disabled":
      return <Chip variant={CHIP_VARIANTS.DANGER}>Disabled</Chip>
  }
}

export const getOrderStatus = status => {
  switch (status) {
    case ORDER_STATUS.RECEIVED:
      return <Chip variant={CHIP_VARIANTS.NEUTRAL}>Received</Chip>
    case ORDER_STATUS.CONFIRMED:
      return <Chip variant={CHIP_VARIANTS.INFO}>Confirmed</Chip>
    case ORDER_STATUS.PROCESSING:
      return <Chip variant={CHIP_VARIANTS.INFO}>Processing</Chip>
    case ORDER_STATUS.PACKAGING:
      return <Chip variant={CHIP_VARIANTS.INFO}>Packaging</Chip>
    case ORDER_STATUS.RETURNED:
      return <Chip variant={CHIP_VARIANTS.WARNING}>Returned</Chip>
    case ORDER_STATUS.SHIPMENT:
      return <Chip variant={CHIP_VARIANTS.INFO}>Shipment</Chip>
    case ORDER_STATUS.DELIVERED:
      return <Chip variant={CHIP_VARIANTS.SUCCESS}>Delivered</Chip>
    case ORDER_STATUS.DISPATCHED:
      return <Chip variant={CHIP_VARIANTS.INFO}>Dispatched</Chip>
    case ORDER_STATUS.INTRANSIT:
      return <Chip variant={CHIP_VARIANTS.INFO}>In-transit</Chip>
  }
}

export const getPaymentStatus = status => {
  switch (status) {
    case PAYMENT_STATUS.PENDING_PAYMENT:
      return <Chip variant={CHIP_VARIANTS.NEUTRAL}>Pending</Chip>
    case PAYMENT_STATUS.PAID:
      return <Chip variant={CHIP_VARIANTS.SUCCESS}>Paid</Chip>
    case PAYMENT_STATUS.PATRIAL_PAYMENT:
      return <Chip variant={CHIP_VARIANTS.INFO}>Patrial</Chip>
  }
}

export const getWalletStatus = status => {
  switch (status) {
    case WALLET_STATUS.PENDING:
      return { label: 'Pending', variant: CHIP_VARIANTS.WARNING };
    case WALLET_STATUS.ACTIVE:
      return { label: 'Active', variant: CHIP_VARIANTS.SUCCESS };
    case WALLET_STATUS.CANCELLED:
      return { label: 'Cancelled', variant: CHIP_VARIANTS.DANGER };
  }
}

export const getTransactionStatus = status => {
  switch (status) {
    case TRANSACTION_STATUS.PENDING:
      return {
        label: 'Pending', 
        variant: CHIP_VARIANTS.WARNING
      };
    case TRANSACTION_STATUS.PROCESSING:
      return {
        label: 'Processing',
        variant: CHIP_VARIANTS.INFO
      };
    case TRANSACTION_STATUS.SUCCESS:
      return {
        label: 'Approved',
        variant: CHIP_VARIANTS.SUCCESS
      };
      case TRANSACTION_STATUS.FAILED:
        return {
          label: 'Failed',
          variant: CHIP_VARIANTS.DANGER
        };
  }
}

export const getCompanyType = type => {
  let typeName
  switch (type) {
    case COMPANY_TYPE.SUPER_ADMIN:
      typeName = "Super admin"
      break
    case COMPANY_TYPE.IMPORTER:
      typeName = "Importer"
      break
    case COMPANY_TYPE.MANUFACTURER:
      typeName = "Manufacturer"
      break
    case COMPANY_TYPE.DISTRIBUTOR:
      typeName = "Distributor"
      break
    case COMPANY_TYPE.WHOLESALLER:
      typeName = "Wholesaler"
      break
    case COMPANY_TYPE.VENDOR:
      typeName = "Vendor"
      break
    case COMPANY_TYPE.KEY_DISTRIBUTOR:
      typeName = "Key distributor"
      break
    default:
      typeName = ""
      break
  }
  return typeName
}

export const getLastFourDigits = (data) => {
  return `****${data?.slice(-4)}`
}

export const getCompanyRole = role => {
  let roleName
  switch (role) {
    case ROLES.SUPER_ADMIN:
      roleName = "Super admin"
      break
    case ROLES.ADMIN:
      roleName = "Administrator"
      break
    case ROLES.USER:
      roleName = "User"
      break
    case ROLES.COMPANY_ADMIN:
      roleName = "Company administrator"
      break
    case ROLES.ROUTE_AGENT:
      roleName = "Route agent"
      break
    case ROLES.ACCOUNTANT:
      roleName = "Accountant"
      break
    case ROLES.MANAGER:
      roleName = "Manager"
      break
    case ROLES.VENDOR:
      roleName = "Vendor"
      break
    default:
      roleName = role
      break
  }
  return roleName
}

export const getPaymentStatusLabel = status => {
  let label
  switch (status) {
    case PAYMENT_STATUS.PENDING_PAYMENT:
      label = "Pending payment"
      break
    case PAYMENT_STATUS.PAID:
      label = "Paid"
      break
    case PAYMENT_STATUS.PATRIAL_PAYMENT:
      label = "Partial payment"
      break
    default:
      label = status
      break
  }
  return label
}

export const getOrderTypeLabel = type => {
  let label
  switch (type) {
    case ORDER_TYPES.COUNTER:
      label = "Over the counter"
      break
    case ORDER_TYPES.ONLINE:
      label = "Online"
      break
    case ORDER_TYPES.SALES_FORCE:
      label = "Sales force"
      break
    default:
      label = type
      break
  }
  return label
}

export const getOrderStatusLabel = status => {
  let label
  switch (status) {
    case ORDER_STATUS.RECEIVED:
      label = "Received"
      break
    case ORDER_STATUS.CONFIRMED:
      label = "Confirmed"
      break
    case ORDER_STATUS.PROCESSING:
      label = "Processing"
      break
    case ORDER_STATUS.PACKAGING:
      label = "Packaging"
      break
    case ORDER_STATUS.RETURNED:
      label = "Returned"
      break
    case ORDER_STATUS.SHIPMENT:
      label = "Shipment"
      break
    case ORDER_STATUS.DELIVERED:
      label = "Delivered"
      break
    case ORDER_STATUS.DISPATCHED:
      label = "Dispatched"
      break
    case ORDER_STATUS.INTRANSIT:
      label = "In-transit"
      break
    default:
      label = status
      break
  }
  return label
}

export const getCustomerTypeLabel = type => {
  let label
  switch (type) {
    case CUSTOMER_TYPES.INDIVIDUAL:
      label = "Individual"
      break
    case CUSTOMER_TYPES.BAR:
      label = "Bar"
      break
    case CUSTOMER_TYPES.RESTAURANT:
      label = "Restaurant"
      break
    case CUSTOMER_TYPES.EATERY:
      label = "Eatery"
      break
    case CUSTOMER_TYPES.OTHER:
      label = "Other"
      break
    default:
      label = type
      break
  }
  return label
}

export const getCategoryTypeLabel = type => {
  let label
  switch (type) {
    case CATEGORY_TYPE.CANS:
      label = "Cans"
      break
    case CATEGORY_TYPE.BOTTLES:
      label = "Bottles"
      break
    default:
      label = type
      break
  }
  
  return label
}

export const getBillingPlansLabel = plan => {
  let label
  switch (plan) {
    case BILLING_PLANS.BUSINESS_PLAN:
      label = "Business plan"
      break
    case BILLING_PLANS.ECONOMY_PLAN:
      label = "Economy plan"
      break
      case BILLING_PLANS.ENTERPRISE_PLAN:
        label = "Enterprise plan"
        break
        case BILLING_PLANS.FREE_PLAN:
          label = "Free plan"
          break
    default:
      label = plan
      break
  }
  
  return label
}

export const transformPermission = value => {
  const parts = value.replace("CAN_", "").split("_")

  const transformedValue = parts
    .map((part, index) => {
      if (index === 0) {
        return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
      }
      return part.toLowerCase()
    })
    .join(" ")

  return transformedValue
}

export const getInitials = init => {
  // replace "weird" chars (more info https://lodash.com/docs/#deburr)
  let name = deburr(init)
  let initials = ""
  // remove extra chars that are NOT alphanumeric
  name = replace(name, /[^a-z0-9\s]+/gi, "")
  // remove extra spaces
  name = replace(name, /\s+/g, " ").trim()
  const words = name.split(" ")
  const count = words.length
  // if name is too short to abbreviate return same value
  if (name.length <= 3) {
    initials = name
    // if name has 1 word return first letter
  } else if (count === 1) {
    initials = name.substring(0, 1)
    // if name has 2 or 3 word return first letters
  } else if (count === 2 || count === 3) {
    initials = map(words, word => word.substring(0, 1)).join("")
    // if name has more than 3 word return the letters of the first 2 words
  } else if (count > 3) {
    initials = `${words[0][0]}${words[1][0]}`
  }
  return toUpper(initials) || "?"
}

export const getRGBA = (hex, opacity) => {
  const cleanedHex = hex.replace(
    /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
    (m, r, g, b) => "#" + r + r + g + g + b + b
  )
  const rgb =
    cleanedHex
      .substring(1)
      .match(/.{2}/g)
      ?.map(x => parseInt(x, 16)) || []
  const red = rgb[0]
  const green = rgb[1]
  const blue = rgb[2]

  return isEmpty(rgb) ? "" : `rgba(${red},${green},${blue},${opacity})`
}

export const groupListByKey = (
  list,
  objectKey = "name",
  useInitial = false
) => {
  return chain(list)
    .groupBy(c =>
      useInitial ? c[objectKey].charAt(0).toUpperCase() : c[objectKey]
    )
    .map((value, key) => ({ title: key, data: value }))
    .value()
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

export const getAccountNumber = (accountNumber) => {
  return `***${accountNumber?.slice(accountNumber.length - 4)}`
}

export const formatName = (name = '') => {
  if (name.length === 0) return '';  // Return an empty string if the input is empty
  return name[0].toUpperCase() + name.slice(1).toLowerCase();
}

export const formatVolume = (volumeInMl) => {
  if (volumeInMl < 1000) {
      return volumeInMl + ' ml';  // Return the value in milliliters
  } else {
      let volumeInLiters = volumeInMl / 1000;  // Convert to liters
      return volumeInLiters.toFixed(2) + ' Ltr';  // Format to 2 decimal places and add the liter unit
  }
}
import React, { useState } from "react"
import { map, omitBy } from "lodash"
import { Row, Col } from "reactstrap"

import { PrimaryButton, SecondaryButton } from "components/Button"
import { SelectionBox } from "components/SelectionBox/SelectionBox"
import EmptyState from "components/EmptyState"
import { HelpNotification, HELP_VARIANTS } from "components/HelpNotification"

import OnboardingImage from "assets/images/verification-img.png"
import "../onboarding.styles.scss"
import { BodyTextBold, VARIANT, BodyText } from "components/atoms"

const CompanyTypeForm = props => {
  const [error, setError] = useState(false)

  const onContinue = () => {
    if (!props.selectedCompanyType) {
      setError(true)
    } else {
      props.goNextStep()
    }
  }

  // Omit "SUPER_ADMIN" from companyTypes
  const filteredCompanyTypes = omitBy(props.companyTypes, ({ type }) => type === "SUPER_ADMIN")

  return (
    <div className="hevara-onboarding-user mt-4">
      <div>
        <BodyTextBold variant={VARIANT.HEADING}>Welcome!</BodyTextBold>
        <BodyTextBold variant={VARIANT.HEADING}>Choose your company type to start</BodyTextBold>
        <BodyText className="mt-4 mb-4">
          Tell us your company belongs to which type below.
        </BodyText>

        {map(filteredCompanyTypes, type => (
          <SelectionBox
            key={type.index}
            title={type.name}
            avatar={{ image: type.image, initials: type.name }}
            description={type.description}
            selected={type.type === props.selectedCompanyType}
            selectedIcon="mdi mdi-check"
            onClick={() => {
              setError(false)
              props.onChange("selectedCompanyType", type.type)
            }}
            className="mt-2"
          />
        ))}

        {error && (
          <HelpNotification className="mt-1" variant={HELP_VARIANTS.ERROR}>
            You must choose one of the company types before continuing
          </HelpNotification>
        )}

        <Row>
          <Col>
            <div className="hstack gap-2 mb-0 mt-4">
              <PrimaryButton onClick={onContinue} className="me-4">
                Continue
              </PrimaryButton>

              <SecondaryButton onClick={props.onClickCancel}>
                Cancel
              </SecondaryButton>
            </div>
          </Col>
        </Row>
      </div>

      <EmptyState
        visible={true}
        illustration={OnboardingImage}
        className="onboarding-user-ph-image"
        containerClassName="onboarding-user-ph"
      />
    </div>
  )
}

export default CompanyTypeForm

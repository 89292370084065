import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getTransfers(limit = 10, page = 1, filters = {}) {
    const parameters = {
      limit,
      page,
      userId: filters.userId,
      companyId: filters.companyId,
      debit_walletId: filters.debit_walletId,
      credit_walletId: filters.credit_walletId,
      wallet_Id: filters.wallet_Id,
      amount: filters.amount,
      status: filters.status,
    }
    const url = queryParams(API.getApi().TRANSFERS.GET_TRANSFERS, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getTransfer(transferId) {
    return axios.get(`${API.getApi().TRANSFERS.GET_TRANSFER}/${transferId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  createTransfer(values) {
    return axios.post(API.getApi().TRANSFERS.TRANSFER, values)
      .then(async ({ data }) => {
        return data
      })
  },
}

import React from "react"
import { map } from "lodash"
import { Row, Col } from "reactstrap"
import styled from "styled-components"

import { PrimaryButton } from "components/Button"

import "../style.scss"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  padding: 24px;
  border: 2px solid #dadada;
  border-radius: 16px;
  &:hover {
    background: rgba(0, 0, 0, 0.02);
    cursor: pointer;
  }
`

function CardGridItem({ category, onSelectProductType }) {
  return (
    <StyledContainer
      className="text-truncate"
      onClick={() => onSelectProductType(category.value)}
    >
      <p className="fw-bold font-size-16 mb-5">{category.label}</p>

      <PrimaryButton className="w-100">Select</PrimaryButton>
    </StyledContainer>
  )
}

const ProductType = props => {
  const { productTypes, onChangeProductType } = props

  return (
    <div className="mt-4">
      <div>
        <h2 className="fw-bold">Choose product type</h2>
        <p className="mt-4 font-size-14 mb-4">
          Select a product type that best describes the product.
        </p>

        <Row>
          <Col xl="12">
            <Row>
              {map(productTypes, productType => (
                <Col md="3" key={productType.value} className="mb-4">
                  <CardGridItem
                    key={productType.value}
                    category={productType}
                    onSelectProductType={() => onChangeProductType(productType)}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ProductType

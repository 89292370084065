import axios from "axios"
import API from "config/api.config"
import { queryParams } from "utils/utils"

export default {
  getFleets(limit = 10, page = 1, filters = {}) {
    const parameters = {
      limit,
      page,
      registrationNumber: filters.registrationNumber,
      companyId: filters.companyId,
      warehouseId: filters.warehouseName,
      routeAgent: filters.routeAgent,
    }
    const url = queryParams(API.getApi().FLEETS.GET_FLEETS, parameters)
    return axios.get(url).then(async ({ data }) => {
      return data
    })
  },
  getFleet(vehicleId) {
    return axios
      .get(`${API.getApi().FLEETS.GET_FLEET}/${vehicleId}`)
      .then(async ({ data }) => {
        return data
      })
  },
  addFleet(values) {
    return axios
      .post(API.getApi().FLEETS.ADD_FLEET, values)
      .then(async ({ data }) => {
        return data
      })
  },
  updateFleet(vehicleId, values) {
    return axios
      .patch(`${API.getApi().FLEETS.UPDATE_FLEET}/${vehicleId}`, values)
      .then(async ({ data }) => {
        return data
      })
  },
}
